import { ReactNode, useState } from "react";
import ReactDOM from 'react-dom'

type Props = {
  children: ReactNode,
  content: string
  open: boolean
}

export function SideBarTooltip({ children, content, open }: Props) {
  const [tooltip, setTooltip] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });

  const handleMouseEnter = (e: any) => {
    setTooltipPosition({ x: e.target.getBoundingClientRect().right + 25, y: e.target.getBoundingClientRect().top });
    setTooltip(true);
  };

  const handleMouseLeave = () => {
    setTooltip(false);
  };

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children}
      {!open && tooltip && ReactDOM.createPortal(
        <div
          className="fixed"
          style={{ top: tooltipPosition.y, left: tooltipPosition.x }}
        >
          <div className="px-2 py-1 relative bg-third-400 dark:bg-gray text-white dark:text-gray-250 rounded-lg z-50 shadow-lg">
            <div className="absolute left-[-8px] top-1/2 transform -translate-y-1/2">
              <div className="w-2 h-2 border-t-[6px] border-t-transparent border-b-[6px] border-b-transparent border-r-[6px] border-r-third-400 dark:border-r-gray" />
            </div>
            {content}
          </div>
        </div>,
        document.body
      )}
    </div>
  )
}