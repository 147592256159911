import { ButtonSize, H2, Paragraph, Button, ButtonType } from 'components'
import { paths } from 'config/paths'
import { useRouter } from 'next/router'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BiMessageSquareError } from 'react-icons/bi'

interface GlobalErrorProps {
  resetError: () => void
  errorMessage: string
}

export default function GlobalError({
  resetError,
  errorMessage
}: GlobalErrorProps) {
  const { t } = useTranslation()
  const router = useRouter()
  const [copied, setCopied] = useState(false)

  return (
    <div className="dark:bg-black">
      <div className=" h-[100vh] flex flex-col justify-center max-w-[480px] mx-auto">
        <BiMessageSquareError
          className="mx-auto dark:text-gray-400 text-gray-200"
          size={60}
        />
        <H2 fontWeight="bold" className="text-center dark:text-gray-200 mt-2">
          {t('bondary.global.title')}
        </H2>
        <Paragraph className="text-center dark:text-gray-300 ">
          {t('bondary.global.description')}
        </Paragraph>
        <div className="flex items-center justify-between mb-2">
          <Paragraph className="dark:text-gray-200 ">
            {t('bondary.global.errorMessage')}
          </Paragraph>
          {!copied ? (
            <Button
              buttonSize={ButtonSize.Small}
              onClick={() => {
                navigator.clipboard.writeText(errorMessage)
                setCopied(true)
                setTimeout(() => {
                  setCopied(false)
                }, 2000)
              }}
            >
              {t('bondary.global.copy')}
            </Button>
          ) : (
            <Paragraph className="bg-primary py-1 px-3 rounded-md">
              {t('bondary.global.copiedMessage')}
            </Paragraph>
          )}
        </div>
        <div className="bg-white-200 dark:bg-gray max-h-20 overflow-y-auto rounded-md px-2 py-2 dark:text-gray-200">
          {errorMessage}
        </div>
        <div className="flex flex-row justify-center gap-8 mt-8">
          <Button
            onClick={() => {
              resetError()
              router.push(paths.organizations)
            }}
          >
            {t('bondary.global.backButton')}
          </Button>
          <Button
            buttonType={ButtonType.Secondary}
            onClick={() => {
              window.open(paths.support.ticket, '_ blank')
            }}
          >
            {t('bondary.global.button')}
          </Button>
        </div>
      </div>
    </div>
  )
}
