import Image from 'next/image'
import AzureAd from 'assets/imgs/azure-ad.png'
import { useIdentityProvider } from 'hooks/useIdentityProvider'
import {
  Button,
  ButtonType,
  EditCard,
  H2,
  InfoTooltip,
  Paragraph,
  SpinLoading,
  Tooltip,
  TooltipPosition,
  UpgradeButton
} from 'components'
import { DeleteIdpButton } from './delete-idp-button'
import { useAuth } from 'contexts/auth-context'
import { CreateIdpButton } from './create-idp-button'
import { useTranslation } from 'next-i18next'
import { paths } from 'config/paths'
import { ConfigsButton } from './configs-button'
import { BiLink } from 'react-icons/bi'
import { toast } from 'react-hot-toast'
import appConfig from 'config/app-config'

export function IdentityProvider() {
  const { data, loading } = useIdentityProvider()
  const { user } = useAuth()
  const { t } = useTranslation()
  const identityProviderAccess = user?.plan?.identity_provider ?? false

  return (
    <EditCard>
      <div className="flex justify-between items-start">
        <div className="flex items-center">
          <H2>{t('configuration.identityProvider.title')}</H2>
          <InfoTooltip
            position={TooltipPosition.LeftBottom}
            className={'ml-2'}
            description={t('configuration.identityProvider.tooltip')}
            documentation={paths.documentation.identityProvider}
          />
        </div>

        {identityProviderAccess === false ? (
          <UpgradeButton plan={'Enterprise'} type={'Long'} />
        ) : (
          <ConfigsButton />
        )}
      </div>

      <Paragraph className="!text-gray dark:!text-gray-300 max-w-lg">
        {t('configuration.identityProvider.description')}
      </Paragraph>

      <div className="py-4 dark:text-white">
        {loading === true ? (
          <div className="h-[70px] flex items-center justify-center">
            <SpinLoading />
          </div>
        ) : data?.length > 0 ? (
          <table className="min-w-full ">
            <thead className=" ">
              <tr className="text-left bg-white-200 dark:bg-gray ">
                <th className="w-[30%] pl-5  py-2 rounded-tl-md">
                  {t('configuration.identityProvider.identityProvider')}
                </th>
                <th>{t('configuration.identityProvider.metadataUrl')}</th>
                <th className="text-center">
                  {t('configuration.identityProvider.organizations')}
                </th>
                <th className="rounded-tr-md"></th>
              </tr>
            </thead>
            <tbody>
              {data.map(item => {
                return (
                  <tr
                    key={item.identityProvider}
                    className="border-b dark:border-gray last:border-b-0"
                  >
                    <td className="pl-5">{item.identityProvider}</td>
                    <td className="max-w-[100px] truncate">
                      {item.metadataUrl}
                    </td>
                    <td className="text-center">{item.organizations.length}</td>
                    <td className="flex gap-2">
                      <Tooltip
                        content={t('configuration.identityProvider.loginLink')}
                      >
                        <Button
                          onClick={() => {
                            toast.success(t('common.copied'))
                            navigator.clipboard.writeText(
                              `${appConfig.appBaseUrl}/sso/login?identityProvider=${item.identityProvider}`
                            )
                          }}
                          buttonType={ButtonType.Ghost}
                          className="w-8 h-8 bg-white-200 dark:bg-gray-400 items-center !text-black"
                        >
                          <BiLink size={18} />
                        </Button>
                      </Tooltip>
                      <DeleteIdpButton
                        identityProvider={item.identityProvider}
                      />
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        ) : (
          <div className="w-[140px] relative h-[70px] ">
            <Image
              src={AzureAd}
              alt="Azure AD"
              layout="fill"
              className="object-cover"
            />
          </div>
        )}
      </div>
      {identityProviderAccess && (
        <CreateIdpButton>
          {t('configuration.identityProvider.createButton')}
        </CreateIdpButton>
      )}
    </EditCard>
  )
}
