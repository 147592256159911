import Image from 'next/image'
import { classNames } from 'util/shared'
import IconStar from 'assets/imgs/icons/star.svg'

export function Checkbox({
  text,
  checkValue,
  setCheckValue,
  className,
  disabled
}: any) {
  return (
    <fieldset className={classNames(className, 'space-y-5')}>
      <div className="relative flex items-start">
        <div className="flex items-center h-5">
          <input
            id={text}
            disabled={disabled}
            aria-describedby="comments-description"
            name={text}
            type="checkbox"
            checked={checkValue}
            onChange={e => {
              setCheckValue(e.target.checked)
            }}
            className="cursor-pointer focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded accent-green-600"
          />
        </div>
        {text ? (
          <div className="ml-3 text-sm dark:text-white">
            <label
              data-multiline={true}
              htmlFor={text}
              className="font-medium text-gray-700 relative cursor-pointer"
            >
              {text}
              {disabled && (
                <span className="absolute w-4 h-4 t -top-2 -right-4">
                  <Image src={IconStar} alt="Enterprise" />
                </span>
              )}
            </label>
          </div>
        ) : null}
      </div>
    </fieldset>
  )
}
