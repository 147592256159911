import { useMutation } from '@apollo/client'
import { Button } from 'components/shared/buttons'
import ConfirmModal from 'components/shared/modal/confirm-modal'
import { useTranslation } from 'next-i18next'
import { DELETE_MEMBER } from 'queries/members'
import { useState } from 'react'
import { toast } from 'react-hot-toast'
import { HiOutlineTrash } from 'react-icons/hi'
import { IoIosWarning } from 'react-icons/io'

export function DeleteButton({ member, refetch, org }: any) {
  const { t } = useTranslation()
  const [openConfirmModal, setOpenConfirmModal] = useState(false)
  const [delMember] = useMutation(DELETE_MEMBER)

  async function deleteMember() {
    toast.loading(`${t('org.members.Saving')}...`)
    try {
      const result = await delMember({
        variables: {
          organizationId: org?.id,
          userOrganizationId: member?.id
        }
      })

      if (result?.data?.delete_member?.success) {
        refetch()
        toast.remove()
        toast.success(`${t('org.members.Member removed')}!`)
        setOpenConfirmModal(false)
      } else {
        throw new Error()
      }
    } catch (error) {
      toast.remove()
      toast.error(`${t('org.members.Something went wrong')}`)
    }
  }

  return (
    <>
      <Button
        onClick={() => {
          setOpenConfirmModal(true)
        }}
        className="dark:hover:bg-pink hover:bg-pink hover:text-white duration-200 dark:bg-black flex justify-center dark:text-primary items-center w-8 h-8"
      >
        <HiOutlineTrash size={20} />
      </Button>
      <ConfirmModal
        modalOpen={openConfirmModal}
        setModalOpen={setOpenConfirmModal}
        title={t('org.members.Are you sure delete this member?')}
        subtitle={t('org.members.You can invite this member again if you want')}
        buttonText={t('org.members.Delete')}
        cancelText={t('org.members.Cancel')}
        icon={<IoIosWarning size={30} className="text-pink" />}
        buttonAction={deleteMember}
      />
    </>
  )
}
