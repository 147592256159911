import React from 'react'

interface EditableDisclaimerCardProps {
  readOnly: boolean
  texts: any
  language: string
  legislation: string
  handleStateChange: (value: string, kind: string, attribute: string) => void
}

export default function EditableDisclaimerCard({
  readOnly,
  texts,
  language,
  legislation,
  handleStateChange
}: EditableDisclaimerCardProps) {
  return (
    <div className="flex-1 max-w-[460px] bg-[#eee] rounded-lg overflow-hidden">
      <div className="p-4">
        <div className="relative group w-[80%]">
          <input
            readOnly={readOnly}
            type="text"
            value={texts?.[language]?.[legislation]?.titles?.card}
            onChange={e => handleStateChange(e.target.value, 'titles', 'card')}
            maxLength={50}
            className="w-full font-bold text-primary bg-[#eee] group focus:bg-[#ddd] outline-[#00DD80] px-2 py-1 cursor-pointer focus:cursor-text	 duration-200"
          />
          <div className="group-focus-within:block group-focus-within:z-10 hidden absolute right-4 -bottom-3 text-xs bg-primary px-1 rounded-sm">
            <span>
              {texts?.[language]?.[legislation]?.titles?.card?.length}
            </span>
            /40
          </div>
        </div>
        <div className="relative group w-full">
          <textarea
            readOnly={readOnly}
            value={texts?.[language]?.[legislation]?.bodyTexts?.card}
            onChange={e =>
              handleStateChange(e.target.value, 'bodyTexts', 'card')
            }
            maxLength={650}
            className="w-full resize-none scroll-1 text-gray bg-[#eee] group focus:bg-[#ddd] outline-[#00DD80] px-2 py-1 cursor-pointer focus:cursor-text	 duration-200"
          />
          <div className="group-focus-within:block group-focus-within:z-10 hidden absolute right-4 -bottom-3 text-xs bg-primary px-1 rounded-sm">
            <span>
              {texts?.[language]?.[legislation]?.bodyTexts?.card?.length}
            </span>
            /170
          </div>
        </div>

        <div className="flex">
          <div className="relative group ">
            <input
              readOnly={readOnly}
              value={texts?.[language]?.[legislation]?.links?.policy}
              onChange={e =>
                handleStateChange(e.target.value, 'links', 'policy')
              }
              maxLength={25}
              className="w-[150px] text-sm font-bold text-primary bg-[#eee] group focus:bg-[#ddd] outline-[#00DD80] px-1 py-1 cursor-pointer focus:cursor-text	 duration-200"
            />
            <div className="group-focus-within:block group-focus-within:z-10 hidden absolute right-4 -bottom-3 text-xs bg-primary px-1 rounded-sm">
              <span>
                {texts?.[language]?.[legislation]?.links?.policy?.length}
              </span>
              /25
            </div>
          </div>
          <div className="font-bold text-primary pt-[2px]">-</div>
          <div className="relative group ">
            <input
              readOnly={readOnly}
              value={texts?.[language]?.[legislation]?.links?.cookies}
              onChange={e =>
                handleStateChange(e.target.value, 'links', 'cookies')
              }
              maxLength={25}
              className="w-[150px] text-sm font-bold text-primary bg-[#eee] group focus:bg-[#ddd] outline-[#00DD80] px-1 py-1 cursor-pointer focus:cursor-text	 duration-200"
            />
            <div className="group-focus-within:block group-focus-within:z-10 hidden absolute right-4 -bottom-3 text-xs bg-primary px-1 rounded-sm">
              <span>
                {texts?.[language]?.[legislation]?.links?.cookies?.length}
              </span>
              /25
            </div>
          </div>
          <div className="font-bold text-primary pt-[2px]">-</div>
          <div className="relative group ">
            <input
              readOnly={readOnly}
              value={texts?.[language]?.[legislation]?.links?.terms}
              onChange={e =>
                handleStateChange(e.target.value, 'links', 'terms')
              }
              maxLength={20}
              className="w-[100px] text-sm font-bold text-primary bg-[#eee] group focus:bg-[#ddd] outline-[#00DD80] px-1 py-1 cursor-pointer focus:cursor-text	 duration-200"
            />
            <div className="group-focus-within:block group-focus-within:z-10 hidden absolute right-4 -bottom-3 text-xs bg-primary px-1 rounded-sm">
              <span>
                {texts?.[language]?.[legislation]?.links?.terms?.length}
              </span>
              /20
            </div>
          </div>
        </div>
        {legislation !== 'ccpa' && (
          <div className="flex">
            <div className="relative group ">
              <input
                readOnly={readOnly}
                value={texts?.[language]?.[legislation]?.links?.optout}
                onChange={e =>
                  handleStateChange(e.target.value, 'links', 'optout')
                }
                maxLength={20}
                className="w-[80px] text-sm font-bold text-primary bg-[#eee] group focus:bg-[#ddd] outline-[#00DD80] px-1 py-1 cursor-pointer focus:cursor-text	 duration-200"
              />
              <div className="group-focus-within:block group-focus-within:z-10 hidden absolute right-4 -bottom-3 text-xs bg-primary px-1 rounded-sm">
                <span>
                  {texts?.[language]?.[legislation]?.links?.optout?.length}
                </span>
                /20
              </div>
            </div>
          </div>
        )}

        <div className="flex items-center justify-between mt-2">
          <div className="py-[2px]   overflow-hidden  flex-1 max-w-[120px] text-center">
            <div className="relative group ">
              <input
                readOnly={readOnly}
                value={
                  texts?.[language]?.[legislation]?.buttons?.showPreferences
                }
                onChange={e =>
                  handleStateChange(
                    e.target.value,
                    'buttons',
                    'showPreferences'
                  )
                }
                maxLength={35}
                className="w-[100px] text-center font-bold text-gray bg-[#eee] group focus:bg-[#ddd] outline-[#00DD80] px-2 py-1 cursor-pointer focus:cursor-text	 duration-200"
              />
              <div className="group-focus-within:block group-focus-within:z-10 hidden absolute right-4 -bottom-3 text-xs bg-primary px-1 rounded-sm">
                <span>
                  {
                    texts?.[language]?.[legislation]?.buttons?.showPreferences
                      ?.length
                  }
                </span>
                /35
              </div>
            </div>
          </div>

          {legislation === 'ccpa' ? (
            <div className="py-[2px]  border rounded-full border-gray flex-1 max-w-[160px] text-center">
              <div className="relative group ">
                <input
                  readOnly={readOnly}
                  value={texts?.[language]?.[legislation]?.buttons?.doNotSell}
                  onChange={e =>
                    handleStateChange(e.target.value, 'buttons', 'doNotSell')
                  }
                  maxLength={35}
                  className="w-[140px] text-center font-bold text-gray bg-[#eee] group focus:bg-[#ddd] outline-[#00DD80] px-2 py-1 cursor-pointer focus:cursor-text	 duration-200"
                />
                <div className="group-focus-within:block group-focus-within:z-10 hidden absolute right-4 -bottom-3 text-xs bg-primary px-1 rounded-sm">
                  <span>
                    {
                      texts?.[language]?.[legislation]?.buttons?.doNotSell
                        ?.length
                    }
                  </span>
                  /35
                </div>
              </div>
            </div>
          ) : (
            <div className="py-[2px]  border rounded-full border-gray flex-1 max-w-[120px] text-center">
              <div className="relative group">
                <input
                  readOnly={readOnly}
                  value={texts?.[language]?.[legislation]?.buttons?.rejectAll}
                  onChange={e =>
                    handleStateChange(e.target.value, 'buttons', 'rejectAll')
                  }
                  maxLength={35}
                  className="w-[100px] text-center font-bold text-gray bg-[#eee] group focus:bg-[#ddd] outline-[#00DD80] px-2 py-1 cursor-pointer focus:cursor-text	 duration-200"
                />
                <div className="group-focus-within:block group-focus-within:z-10 hidden absolute right-4 -bottom-3 text-xs bg-primary px-1 rounded-sm">
                  <span>
                    {
                      texts?.[language]?.[legislation]?.buttons?.rejectAll
                        ?.length
                    }
                  </span>
                  /35
                </div>
              </div>
            </div>
          )}
          <div className="py-[2px] overflow-hidden bg-primary rounded-full flex-1 max-w-[120px] text-center">
            <div className="relative group ">
              <input
                readOnly={readOnly}
                value={texts?.[language]?.[legislation]?.buttons?.acceptAll}
                onChange={e =>
                  handleStateChange(e.target.value, 'buttons', 'acceptAll')
                }
                maxLength={35}
                className="w-[100px] text-center font-bold text-gray bg-primary group focus:bg-[#ddd] outline-[#00DD80] px-2 py-1 cursor-pointer focus:cursor-text	 duration-200"
              />
              <div className="group-focus-within:block group-focus-within:z-10 hidden absolute right-4 -bottom-3 text-xs bg-primary px-1 rounded-sm">
                <span>
                  {texts?.[language]?.[legislation]?.buttons?.acceptAll?.length}
                </span>
                /35
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
