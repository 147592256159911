import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import translationsPt from '../public/locales/pt/translation.json'
import translationsEn from '../public/locales/en/translation.json'

const resources = {
  pt: { translation: translationsPt },
  en: { translation: translationsEn }
}

export default i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    interpolation: {
      escapeValue: false
    },
    supportedLngs: ['en', 'pt'],
    fallbackLng: ['en']
  })

export enum PlatformLanguage {
  pt = 'pt',
  en = 'en'
}

export const currentLanguage: PlatformLanguage = (
  Object.values(PlatformLanguage) as PlatformLanguage[]
).includes(i18n.language as PlatformLanguage)
  ? (i18n.language as PlatformLanguage)
  : PlatformLanguage.en
