import { gql } from '@apollo/client'

export const GET_IDENTITY_PROVIDER = gql`
  query GetUserIdentityProvider($email: String!) {
    users(where: { email: { _eq: $email } }) {
      sso_identity_providers {
        organizations
        metadata_url
        identity_provider
        attribute_mapping
      }
    }
  }
`

export const CREATE_IDENTITY_PROVIDER = gql`
  mutation CreateIdentityProvider(
    $identityProvider: String!
    $metadataUrl: String!
    $attributeMapping: String!
    $organizations: [String]!
  ) {
    create_identity_provider(
      object: {
        identityProvider: $identityProvider
        metadataUrl: $metadataUrl
        attributeMapping: $attributeMapping
        organizations: $organizations
      }
    ) {
      data
      message
      success
      errorCode
      errorDetails
    }
  }
`

export const DELETE_IDENTITY_PROVIDER = gql`
  mutation DeleteIdentityProvider($identityProvider: String!) {
    delete_identity_provider(object: { identityProvider: $identityProvider }) {
      message
      success
      errorCode
      errorDetails
    }
  }
`
