import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from 'components'
import { formatNumber } from 'util/format'
import { useAuth } from 'contexts/auth-context'
import { useRouter } from 'next/router'
import { classNames } from 'util/shared'
import { useStorage } from 'contexts/storage-context'

export default function SideBarPlanCard() {
  const { t } = useTranslation()
  const router = useRouter()

  const { activeOrganizationDetails } = useStorage()
  const { user } = useAuth()

  function upgradePlan() {
    router.push('/plans')
  }

  return (
    <>
      {activeOrganizationDetails && activeOrganizationDetails?.usage && (
        <div
          className="p-4 bg-third-600 dark:bg-black rounded-lg"
          data-tour="limits-step"
        >
          <div className="text-sm text-white">
            <div className="text-white opacity-70 capitalize">
              <b>{t(`sideBar.${activeOrganizationDetails?.plan?.name}`)}</b>
            </div>
            <div className="flex text-xs justify-between mt-4  text-white opacity-70">
              <div className="capitalize">
                <b>{t('sideBar.visitors')}</b>
              </div>
              <div>
                {formatNumber(activeOrganizationDetails?.usage?.uniqueUsers)}/
                {formatNumber(activeOrganizationDetails?.plan?.unique_users)}
              </div>
            </div>
            <div className="h-1 bg-gray w-full rounded-full relative overflow-hidden">
              <div
                style={{
                  width: `calc(${
                    (activeOrganizationDetails?.usage?.uniqueUsers * 100) /
                    activeOrganizationDetails?.plan?.unique_users
                  }%)`
                }}
                className={classNames(
                  'absolute  duration-200 left-0 top-0 bg-primary h-full min-w-[5px] max-w-[100%]',
                  (activeOrganizationDetails?.usage?.uniqueUsers * 100) /
                    activeOrganizationDetails?.plan?.unique_users >=
                    80 &&
                    (activeOrganizationDetails?.usage?.uniqueUsers * 100) /
                      activeOrganizationDetails?.plan?.unique_users <
                      100
                    ? 'bg-yellow'
                    : (activeOrganizationDetails?.usage?.uniqueUsers * 100) /
                        activeOrganizationDetails?.plan?.unique_users >=
                      100
                    ? 'bg-pink'
                    : 'bg-green'
                )}
              ></div>
            </div>
            <div className="flex text-xs justify-between mt-4  text-white opacity-70">
              <div className="capitalize">
                <b>{t('sideBar.disclaimers')}</b>
              </div>
              <div>
                {activeOrganizationDetails?.usage?.disclaimers}/
                {activeOrganizationDetails?.plan?.disclaimers}
              </div>
            </div>
            <div className="h-1 bg-gray w-full rounded-full relative overflow-hidden">
              <div
                style={{
                  width: `calc(${
                    (activeOrganizationDetails?.usage?.disclaimers * 100) /
                    activeOrganizationDetails?.plan?.disclaimers
                  }%)`
                }}
                className={classNames(
                  'absolute duration-200 left-0 top-0 bg-primary h-full min-w-[5px] max-w-[100%]',
                  (activeOrganizationDetails?.usage?.disclaimers * 100) /
                    activeOrganizationDetails?.plan?.disclaimers >=
                    80 &&
                    (activeOrganizationDetails?.usage?.disclaimers * 100) /
                      activeOrganizationDetails?.plan?.disclaimers <
                      100
                    ? 'bg-yellow'
                    : (activeOrganizationDetails?.usage?.disclaimers * 100) /
                        activeOrganizationDetails?.plan?.disclaimers >=
                      100
                    ? 'bg-pink'
                    : 'bg-green'
                )}
              ></div>
            </div>
            {activeOrganizationDetails?.owner.email === user?.email && (
              <Button onClick={upgradePlan} className="mt-4 w-full">
                {t('sideBar.upgrade')}
              </Button>
            )}
          </div>
        </div>
      )}
    </>
  )
}
