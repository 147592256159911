import { useTranslation } from "next-i18next";
import { toast } from "react-hot-toast";

export function useHandleError() {
  const { t } = useTranslation()

  const defaultMessage = t('requestResponse.Something went wrong')

  function handleError(errorMessage?: string) {
    if (!errorMessage) {
      return toast.error(defaultMessage);
    }

    return toast.error(errorMessage);
  }

  return { handleError };
}
