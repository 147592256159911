import { fadeIn } from 'public/animation/motion'
import React, { useState } from 'react'
import { FaChevronUp, FaChevronDown } from 'react-icons/fa6'
import { classNames } from 'util/shared'
import { motion } from 'framer-motion'

export interface Column<T> {
  key: keyof T
  label: string | any
  align?: 'left' | 'center' | 'right'
  sortable?: boolean
}

interface GenericTableProps<T> {
  columns: Column<T>[]
  data: T[]
  loading?: boolean
  withoutElements: React.ReactNode
}

export const Table = <T,>({
  columns,
  data,
  loading = false,
  withoutElements
}: GenericTableProps<T>) => {
  const [sortConfig, setSortConfig] = useState<{
    key: keyof T
    direction: 'asc' | 'desc'
  } | null>(null)

  const sortedData = React.useMemo(() => {
    if (sortConfig !== null) {
      return [...data].sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? -1 : 1
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? 1 : -1
        }
        return 0
      })
    }
    return data
  }, [data, sortConfig])

  const requestSort = (key: keyof T) => {
    let direction: 'asc' | 'desc' = 'asc'
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === 'asc'
    ) {
      direction = 'desc'
    }
    setSortConfig({ key, direction })
  }

  const getClassNamesFor = (key: keyof T) => {
    if (!sortConfig) {
      return
    }
    return sortConfig.key === key ? sortConfig.direction : undefined
  }

  return (
    <div className="border border-gray-100 dark:border-gray-500 rounded-md px-3 py-1 dark:text-white ">
      {!loading && sortedData.length === 0 ? (
        withoutElements
      ) : (
        <div>
          <table className="w-full border-separate border-spacing-y-2 ">
            <thead>
              <tr>
                {columns.map(column => (
                  <th
                    key={column.key as string}
                    className={classNames(
                      `px-4 py-3 cursor-pointer !text-right`
                    )}
                    onClick={() => column.sortable && requestSort(column.key)}
                  >
                    {loading ? (
                      <div className="animate-pulse bg-white-200 dark:bg-gray w-full h-5 rounded-sm"></div>
                    ) : (
                      <motion.div
                        initial="initial"
                        animate="animate"
                        variants={fadeIn()}
                        className={classNames(
                          'flex items-center gap-2 dark:text-white !font-bold select-none text-sm',
                          getClassNamesFor(column.key) && '!text-primary',
                          column.align === 'right'
                            ? 'justify-end text-end'
                            : column.align === 'center'
                            ? 'justify-center text-center'
                            : 'justify-start text-start'
                        )}
                      >
                        {column.label}
                        {column.sortable ? (
                          <div className={`flex flex-col `}>
                            <FaChevronUp
                              className={
                                getClassNamesFor(column.key) === 'desc'
                                  ? 'text-primary'
                                  : 'dark:text-gray-250'
                              }
                              size={10}
                            ></FaChevronUp>
                            <FaChevronDown
                              className={
                                getClassNamesFor(column.key) === 'asc'
                                  ? 'text-primary'
                                  : 'dark:text-gray-250'
                              }
                              size={10}
                            ></FaChevronDown>
                          </div>
                        ) : null}
                      </motion.div>
                    )}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="space-y-2">
              {loading
                ? Array.from({ length: 5 }).map((_, rowIndex) => (
                    <tr key={rowIndex}>
                      {columns.map(column => (
                        <td
                          key={column.key as string}
                          className={`px-4 last:rounded-tr-lg last:rounded-br-lg first:rounded-tl-lg first:rounded-bl-lg bg-white-200 dark:bg-gray py-2 text-${
                            column.align || 'left'
                          }`}
                        >
                          <div className="animate-pulse bg-white-200 dark:bg-gray-500 w-full h-5 rounded-sm"></div>
                        </td>
                      ))}
                    </tr>
                  ))
                : sortedData.map((item, rowIndex) => (
                    <tr key={rowIndex}>
                      {columns.map(column => (
                        <motion.td
                          initial="initial"
                          animate="animate"
                          variants={fadeIn()}
                          key={column.key as string}
                          className={`px-4 last:rounded-tr-lg last:rounded-br-lg first:rounded-tl-lg first:rounded-bl-lg bg-white-200 dark:bg-gray py-2 text-${
                            column.align || 'left'
                          }`}
                        >
                          {item[column.key] as unknown as React.ReactNode}
                        </motion.td>
                      ))}
                    </tr>
                  ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  )
}
