import React from 'react'
import { useTranslation } from 'react-i18next'
import { classNames } from 'util/shared'
export default function RoleCard({ role, children }: any) {
  const { t } = useTranslation()
  return (
    <div>
      <div
        style={{ display: 'inline-block' }}
        className={classNames(
          role === 'owner'
            ? 'bg-purple'
            : role === 'admin'
            ? 'bg-lightblue'
            : role === 'analyst'
            ? 'bg-orange'
            : role === 'dpo'
            ? 'bg-yellow'
            : role === 'viewer'
            ? 'bg-primary'
            : null,
          'text-white px-2 text-sm rounded capitalize'
        )}
      >
        {t(`roles.${children}`)}
      </div>
    </div>
  )
}
