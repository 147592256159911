import { SmallText } from '../typography'

interface ColorInputProps {
  label: string
  value: string
  onChange: (value: string) => void
}

export function ColorInput({ label, value, onChange }: ColorInputProps) {
  return (
    <div>
      <SmallText className="dark:!text-gray-250">{label}</SmallText>
      <div className="w-28 h-11 flex gap-2 items-center p-1 border border-gray-200 rounded-md bg-white-200 dark:border-gray dark:bg-gray-500">
        <input
          type="color"
          className="custom-input-color"
          value={value}
          onChange={e => onChange(e.target.value)}
        />
        <span className="dark:text-white">{value}</span>
      </div>
    </div>
  )
}
