export interface AppCofig {
  apiBaseUrl: string
  tagBaseUrl: string
  appBaseUrl: string
  hubUrl: string
  hasuraBaseUrl: string
  recaptchaSiteKey: string
  clarityId: string
  thirdPartyServices: ThirdPartyServices
  environment: Environment
  maintenance: boolean
  cognito: Cognito
}

export interface ThirdPartyServices {
  freshChatToken: string
  gtmId: string
}
export interface Cognito {
  identityId: string
  replyUrl: string
}

export enum Environment {
  Dev = 'dev',
  Prod = 'prod',
  Local = 'local'
}

const appConfig: AppCofig = {
  maintenance: process.env.SHOW_MAINTAINANCE_PAGE === 'true' ? true : false,
  appBaseUrl: process.env.APP_BASE_URL ?? '',
  tagBaseUrl: process.env.TAG_BASE_URL ?? '',
  apiBaseUrl: process.env.API_BASE_URL ?? '',
  hubUrl: process.env.NEXT_PUBLIC_HUB_URL ?? '',
  hasuraBaseUrl: process.env.HASURA_BASE_URL ?? '',
  recaptchaSiteKey: process.env.RECAPTCHA_SITE_KEY ?? '',
  thirdPartyServices: {
    freshChatToken: process.env.FRESHCHAT_TOKEN ?? '',
    gtmId: process.env.GTM_ID ?? ''
  },
  cognito: {
    identityId: process.env.COGNITO_IDENTITY_ID ?? '',
    replyUrl: process.env.COGNITO_REPLY_URL ?? ''
  },
  clarityId: process.env.CLARITY_ID ?? '',
  environment:
    (process.env.ADOPT_ENVIRONMENT as Environment) ?? Environment.Prod
}

export default appConfig
