import { SmallText } from '../typography'

interface PixelsInputProps {
  label: string
  value: number
  onChange: (value: string) => void
}

export function PixelsInput({ label, value, onChange }: PixelsInputProps) {
  return (
    <div>
      <SmallText className="dark:!text-gray-250">{label}</SmallText>
      <div className="w-full flex border border-gray-200 dark:border-gray-400 dark:bg-gray box-border h-auto rounded-md hover:border-primary-200 duration-500">
        <input
          value={value}
          onChange={e => onChange(e.target.value)}
          maxLength={3}
          className="bg-white-200 p-2 focus:outline-none resize-none appearance-none rounded-md relative block dark:text-gray-250 dark:bg-gray w-[70%] border-none"
        />
        <span className="grid place-items-center dark:text-gray-250 bg-gray-100 dark:bg-gray-500 border-l border-l-gray-400 w-14 rounded-r-md">
          px
        </span>
      </div>
    </div>
  )
}
