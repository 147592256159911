import React, { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { Link } from 'components'
import { useTranslation } from 'react-i18next'
import { MdChatBubbleOutline } from 'react-icons/md'
import { HiOutlinePlus } from 'react-icons/hi'
import { useRouter } from 'next/router'
import { classNames } from 'util/shared'

export default function DisclaimerDropdown({
  children,
  activeOrganization,
  items
}: any) {
  const { t } = useTranslation()
  const { query } = useRouter()
  const { disclaimerPathname } = query

  return (
    <Menu as="div" className="relative inline-block text-left z-20">
      {({ open }) => (
        <>
          <Menu.Button className="relative flex items-center text-gray-600 dark:text-white">
            {children}
          </Menu.Button>

          <Transition
            show={open}
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              static
              className="origin-top-right absolute cursor-pointer rounded-md w-60 m-2 shadow-lg shadow-black bg-third-600 dark:bg-gray focus:outline-none"
            >
              {items?.length !== 0 ? (
                items?.map((item: any, index: number) => (
                  <Menu.Button className="w-full" key={index}>
                    <Link
                      href={`/org/${activeOrganization?.pathname}/disclaimer/${item.pathname}`}
                      className={classNames(
                        index === 0 && 'rounded-t-md',
                        disclaimerPathname === item.pathname &&
                          'bg-third-700 !text-primary dark:bg-black dark:text-primary',
                        'p-2 flex gap-2 items-center text-white dark:text-gray-250 hover:dark:text-white hover:bg-third-700 hover:dark:bg-black duration-300'
                      )}
                    >
                      <MdChatBubbleOutline size={14} />
                      {item.pathname}
                    </Link>
                  </Menu.Button>
                ))
              ) : (
                <div className="text-sm text-white dark:text-gray-250 py-8 text-center w-full px-4">
                  {t('sideBar.youDontHaveAnyDisclaimersYet')}
                </div>
              )}
              <Menu.Button className="w-full border-t-2 border-gray-200">
                <Link
                  href={`/org/${activeOrganization?.pathname}/disclaimers`}
                  className="p-2 flex gap-2 rounded-b-md items-center text-white dark:text-gray-250 hover:bg-third-700 hover:dark:bg-black hover:dark:text-white duration-300"
                >
                  <HiOutlinePlus size={14} className="text-primary" />
                  {t('sideBar.newDisclaimer')}
                </Link>
              </Menu.Button>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  )
}
