import React from 'react'
import Image from 'next/image'
import IconStar from 'assets/imgs/icons/star.svg'
import IconStarPurple from 'assets/imgs/icons/star-purple.svg'
import IconAdopt from 'assets/imgs/icons/adopt.svg'
import IconLock from 'assets/imgs/icons/lock.svg'
import IconCookie from 'assets/imgs/icons/cookie.svg'
import IconAdd from 'assets/imgs/icons/add-image.svg'
import { getBase64 } from 'util/shared'

const icons = [
  { name: 'adopt', icon: IconAdopt },
  { name: 'lock', icon: IconLock },
  { name: 'cookie', icon: IconCookie },
  { name: 'custom', icon: IconAdd },
  { name: 'add', icon: IconAdd }
]

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

function returnIcon(icons: any[], param: any) {
  const icon = icons.find(icon => icon.name === param)
  return icon.icon
}

export default function IconButton({
  imageIcon,
  setImageIcon,
  setImageBase64,
  setOpenupgradeModal,
  ...props
}: any) {
  function onImageChange(event: any) {
    const img = event.target.files[0]

    if (event.target.files && img) {
      getBase64(img).then((data: any) => {
        const result = data.split('base64,')[1]
        setImageIcon(URL.createObjectURL(img))
        setImageBase64(String(result))
        props.setIcon('custom')
      })
    }
  }
  return (
    <div className="relative mr-3 pt-1">
      {props.icon === 'add' ? (
        <input
          className="hidden"
          onChange={onImageChange}
          id="fileInput"
          accept="image/png, image/jpeg"
          type="file"
        />
      ) : null}
      <button
        onClick={() => {
          if (props.hasAccess === true) {
            if (props.icon === 'add') {
              const x = document.getElementById('fileInput')
              x?.click()
            } else {
              props.setIcon(props.icon)
            }
          } else {
            setOpenupgradeModal(true)
          }
        }}
        className={classNames(
          props.iconSelected !== props.icon ? 'opacity-70' : '',
          props.icon === 'add' ? 'border-2 border-primary opacity-100' : '',
          'h-10 w-10 rounded-full duration-200 flex justify-center items-center overflow-hidden'
        )}
      >
        {props.icon === 'custom' && imageIcon ? (
          <div className="border-primary border w-10 h-10 rounded-full flex items-center justify-center overflow-hidden">
            <img className="max-w-10 max-h-10 " src={imageIcon} />
          </div>
        ) : (
          <Image
            draggable={false}
            src={returnIcon(icons, props.icon)}
            alt="Lock"
          />
        )}
      </button>
      {!props.hasAccess ? (
        <>
          {props.plan === 'Business' ? (
            <div className="h-4 w-4 absolute top-0 right-0">
              <Image src={IconStar} alt="Business" />
            </div>
          ) : props.plan === 'Enterprise' ? (
            <div className="h-4 w-4 absolute top-0 right-0">
              <Image src={IconStarPurple} alt="Enterprise" />
            </div>
          ) : null}
        </>
      ) : null}
    </div>
  )
}
