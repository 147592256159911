import { gql } from '@apollo/client'

export const CREATE_SCAN = gql`
  mutation ($disclaimerId: String!) {
    scan_disclaimer(
      object: {
        disclaimerId: $disclaimerId
        keepLastConfigs: true
        isAuthenticated: true
      }
    ) {
      data
      success
      message
      errorCode
      errorDetails
    }
  }
`

export const DOWNLOAD_SCAN = gql`
  mutation ($disclaimerId: String!, $language: String) {
    download_scan_report(
      object: { disclaimerId: $disclaimerId, language: $language }
    ) {
      message
      success
      data {
        base64File
        fileName
      }
      errorCode
      errorDetails
    }
  }
`

export const DOWNLOAD_SCAN_CSV = gql`
  mutation DownloadTagsCsv($disclaimerId: String!) {
    download_tags_csv(object: { disclaimerId: $disclaimerId }) {
      message
      success
      data {
        base64File
        fileName
      }
      errorCode
      errorDetails
    }
  }
`

export const TEST_AUTH_SCAN = gql`
  mutation ($loginUrl: String!, $authenticationSteps: [AuthenticationStep]!) {
    test_auth_scan(
      object: { loginUrl: $loginUrl, authenticationSteps: $authenticationSteps }
    ) {
      message
      success
      data
      errorCode
      errorDetails
    }
  }
`
export const SAVE_EDIT_AUTH_SCAN = gql`
  mutation (
    $disclaimerId: String!
    $scanAuthId: String
    $scanAuthentication: ScanAuthentication
  ) {
    add_edit_scan_auth(
      object: {
        disclaimerId: $disclaimerId
        scanAuthId: $scanAuthId
        scanAuthentication: $scanAuthentication
      }
    ) {
      message
      success
      errorCode
      errorDetails
    }
  }
`

export const GET_SCAN = gql`
  subscription ($disclaimerId: uuid!) {
    scan(
      where: {
        disclaimer_id: { _eq: $disclaimerId }
        scan_type: { _in: ["NFzZYW3T", "XAhXMd76", "BA0kUj89"] }
      }
      limit: 1
      order_by: { created_at: desc }
    ) {
      id
      created_at
      scan_state {
        name
      }
      user {
        email
      }
    }
  }
`

export const GET_TAGS_WITH_DETAILS = gql`
  query GetTagsWithDetails($id: uuid, $language: String!) {
    tags(where: { id: { _eq: $id } }) {
      name
      short_tag_id
      local_storages {
        name
        blocked
        region
        local_storage_list {
          id
          local_storage_contents(where: { language: { _eq: $language } }) {
            id
            description
          }
        }
      }
      cookies {
        name
        description
        blocked
        region
        expiration_milliseconds
        source
        cookie_list {
          id
          cookie_contents(where: { language: { _eq: $language } }) {
            description
          }
        }
      }
    }
  }
`
export const GET_TAGS = gql`
  query (
    $disclaimerPathname: String!
    $organizationId: uuid!
    $version: Int!
    $optionsVersion: Int!
  ) {
    disclaimers(
      where: {
        pathname: { _eq: $disclaimerPathname }
        organization_id: { _eq: $organizationId }
      }
      limit: 1
    ) {
      id
      scan_scheduleds(
        where: {
          deleted_at: { _is_null: true }
          scan_type: { _eq: "NFzZYW3T" }
        }
      ) {
        id
        cron
        scheduled_to
        scan_type
      }

      tags(where: { version: { _eq: $version } }) {
        name
        id
        short_tag_id
        tags_category {
          name
        }
        auto_block
        sources
      }
      disclaimer_options(where: { version: { _eq: $optionsVersion } }) {
        uncheck_tags
      }
    }
  }
`

export const GET_SCAN_INSTALLATION_SCHEDULES = gql`
  query ($disclaimerPathname: String!, $organizationId: uuid!) {
    disclaimers(
      where: {
        pathname: { _eq: $disclaimerPathname }
        organization_id: { _eq: $organizationId }
      }
      limit: 1
    ) {
      id
      scan_scheduleds(
        where: {
          deleted_at: { _is_null: true }
          scan_type: { _eq: "I0rjyaiZ" }
        }
      ) {
        id
        cron
        scan_type
      }
    }
  }
`

export const RESTORE_TAG_VERSION = gql`
  mutation RestoreTagVersion($disclaimerId: String!, $version: Int!) {
    restore_tags(object: { disclaimerId: $disclaimerId, version: $version }) {
      success
      message
      errorCode
      errorDetails
    }
  }
`

export const LIST_SCAN_VERSIONS = gql`
  mutation ListScanVersions($disclaimerId: String!) {
    list_scan_versions(object: { disclaimerId: $disclaimerId }) {
      success
      message
      data {
        tagsByVersion {
          cookies
          createdAt
          localStorages
          tags
          unknowTags
          version
        }
      }
      errorCode
      errorDetails
    }
  }
`

export const GET_TAG_DETAILS = gql`
  query GetTagDetails(
    $version: Int!
    $disclaimerPathname: String!
    $organizationPathname: String!
  ) {
    organization(where: { pathname: { _eq: $organizationPathname } }) {
      disclaimers(where: { pathname: { _eq: $disclaimerPathname } }) {
        id
        tags(where: { version: { _in: [$version] } }) {
          version
          tags_category {
            name
          }
          name
          auto_block
          tag_category
          id
          short_tag_id
        }
      }
    }
  }
`

export const GET_MOCKED_CHANGELOG = gql`
  query MOCKED_CHANGELOG {
    disclaimers(where: { pathname: { _eq: "testedovitim" } }) {
      id
      tags(where: { version: { _in: [1, 2, 3, 4] } }) {
        version
        tags_category {
          name
        }
        name
        auto_block
      }
    }
  }
`

export const GET_ADOPT_TAG = gql`
  query ($disclaimerId: uuid!, $version: Int!) {
    disclaimers(where: { id: { _eq: $disclaimerId } }, limit: 1) {
      id
      tags(
        where: {
          _and: [{ version: { _eq: $version } }, { name: { _eq: "AdOpt" } }]
        }
        limit: 1
      ) {
        name
        short_tag_id
        tags_category {
          name
        }
      }
    }
  }
`

export const GET_TAGS_VERSION = gql`
  subscription GetTagsVersion(
    $disclaimerPathname: String!
    $organizationId: uuid!
  ) {
    disclaimers(
      where: {
        pathname: { _eq: $disclaimerPathname }
        organization_id: { _eq: $organizationId }
      }
      limit: 1
    ) {
      id
      disclaimer_versions(limit: 1, order_by: { main_version: desc }) {
        disclaimer_options_version
        tags_version
      }
    }
  }
`

export const SAVE_PUBLISH_TAGS = gql`
  mutation SavePublishTags($tagData: [TagData]!, $disclaimerId: String!) {
    save_publish_tags(
      object: { tagsData: $tagData, disclaimerId: $disclaimerId }
    ) {
      success
      message
      data
      errorCode
      errorDetails
    }
  }
`
export const EDIT_VENDORS = gql`
  mutation EditVendors($vendorsIds: [String]!, $disclaimerId: String!) {
    edit_vendors(
      object: { disclaimerId: $disclaimerId, vendorIds: $vendorsIds }
    ) {
      data {
        created_at
        deleted_at
        id
        vendor_id
        version
      }
      message
      success
      errorDetails
      errorCode
    }
  }
`

export const SCHEDULE_SCAN = gql`
  mutation ScheduleScan(
    $disclaimerId: String!
    $cron: String
    $scanType: String!
  ) {
    schedule_scan(
      object: { disclaimerId: $disclaimerId, scanType: $scanType, cron: $cron }
    ) {
      success
      message
      data
      errorCode
      errorDetails
    }
  }
`

export const SCHEDULE_INSTALL_SCAN = gql`
  mutation ScheduleInstallScan(
    $disclaimerId: String!
    $cron: String!
    $warningEmail: String
  ) {
    schedule_install_scan(
      object: {
        disclaimerId: $disclaimerId
        cron: $cron
        warningEmail: $warningEmail
      }
    ) {
      success
      message
      errorCode
      errorDetails
    }
  }
`

export const CANCEL_SCAN = gql`
  mutation CancelScan($scanScheduleId: String!) {
    cancel_scan(object: { scanScheduleId: $scanScheduleId }) {
      success
      message
      data
      errorCode
      errorDetails
    }
  }
`

export const GET_AUTH_SCAN_EVENTS = gql`
  query ScanAuthentication($disclaimerId: uuid, $version: Int!) {
    scan_authentication(
      where: {
        deleted_at: { _is_null: true }
        disclaimer_id: { _eq: $disclaimerId }
        version: { _eq: $version }
      }
    ) {
      name
      id
      login_url
      scan_domain_authentications {
        id
        scan_domain {
          url
          id
        }
      }
      authentication_steps {
        element_id
        value
        authentication_type_id
      }
    }
  }
`
export const REMOVE_AUTH_SCAN_EVENTS = gql`
  mutation RemoveScanAuth($authScanId: String!) {
    remove_scan_auth(object: { scanAuthId: $authScanId }) {
      success
      message
      data
      errorCode
      errorDetails
    }
  }
`

export const GET_DISCLIAMER_WEBSITES = gql`
  query Disclaimers($disclaimerId: uuid) {
    disclaimers(where: { id: { _eq: $disclaimerId } }) {
      scan_domains {
        url
        id
      }
    }
  }
`

export const EDIT_TAG_SOURCES = gql`
  mutation EditTagSources(
    $disclaimerId: String!
    $shortTagId: String!
    $tagSources: [String]!
  ) {
    edit_tag_sources(
      object: {
        disclaimerId: $disclaimerId
        shortTagId: $shortTagId
        tagSources: $tagSources
      }
    ) {
      success
      message
      errorCode
      errorDetails
    }
  }
`

export const CHECK_DISCLAIMER_INSTALLATION = gql`
  mutation ($disclaimerId: String!) {
    check_disclaimer_installation(object: { disclaimerId: $disclaimerId }) {
      data {
        checkInstallationScanId
        errors {
          code
          message
        }
      }
      success
      errorCode
      errorDetails
    }
  }
`

export const GET_INSTALLATION_SCAN = gql`
  subscription ($checkInstallationScanId: uuid!) {
    scan(
      where: { id: { _eq: $checkInstallationScanId } }
      limit: 1
      order_by: { created_at: desc }
    ) {
      id
      created_at
      scan_state {
        name
      }
    }
  }
`

export const GET_DISCLAIMER_INSTALLATION_RESULTS = gql`
  mutation GetDisclaimerInstallationResults(
    $disclaimerId: String
    $scanId: String
  ) {
    get_disclaimer_installation_resuts(
      object: { disclaimerId: $disclaimerId, scanId: $scanId }
    ) {
      data {
        date
        url
        result {
          checkList {
            step
            printUrl
            rightCode
            wrongCode
            errorCode
            message
            type
          }
          statusCode
        }
      }
      message
      success
      errorCode
      errorDetails
    }
  }
`
