import React from 'react'
import { AiOutlineLoading } from 'react-icons/ai'

export function SpinLoading() {
  return (
    <div className="animate-spin h-5 w-5 mr-3 flex items-center justify-center text-primary">
      <AiOutlineLoading size={25} />
    </div>
  )
}
