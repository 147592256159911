import { gql } from '@apollo/client'

export const GET_DPO_USERS = gql`
  query GetDpoUserIds($organizationId: uuid!) {
    organization(where: { id: { _eq: $organizationId } }) {
      user_organization(
        where: {
          role: { _in: ["aW6ZQ7iQ", "FFbMI2zu", "VsPE5e1z"] }
          deleted_at: { _is_null: true }
        }
      ) {
        users {
          id
          email
        }
      }
    }
  }
`

export const GET_REQUEST_PAGES_IDS = gql`
  query GetRequestPageIds($organizationId: uuid!) {
    requests_page_master(where: { organization_id: { _eq: $organizationId } }) {
      requests_pages(order_by: { version: desc }, limit: 1) {
        requests_page_master_id
        name
      }
    }
  }
`

export const GET_REQUEST_LINKS_BY_DISCLAIMER_ID = gql`
  query GetRequestLinksByDisclaimerId($disclaimerId: uuid!) {
    disclaimer_request_page(
      where: { disclaimer_id: { _eq: $disclaimerId } }
      order_by: { version: desc }
      limit: 1
    ) {
      has_links
      has_requests_page
      requests_page_master_id
      request_links {
        language
        url
        default
      }
    }
  }
`

export const GET_REQUEST_PAGE_BY_ID = gql`
  query GetRequestPageById($organizationId: uuid!, $id: uuid!) {
    requests_page_master(where: { organization_id: { _eq: $organizationId } }) {
      requests_pages(
        where: { id: { _eq: $id } }
        order_by: { version: desc }
        limit: 1
      ) {
        id
        name
        user_dpo_id
        extra_fields
        language_configuration
        requests_page_requests {
          legislation
          requests
        }
        requests_page_styles {
          style
        }
        requests_page_texts {
          language
          texts
        }
      }
    }
  }
`

export const ADD_EDIT_REQUESTS_PAGE = gql`
  mutation AddEditRequestsPage(
    $organizationId: String!
    $name: String!
    $requestsPageId: String
    $dpoUserId: String!
    $disclaimerId: String
    $languages: RequestsPagesLanguages
    $texts: [RequestsPagesTexts]
    $documents: RequestsPagesDocs
    $extraFields: [String]
    $requests: [RequestsPageRequests]
    $style: String!
  ) {
    add_edit_requests_pages(
      object: {
        organizationId: $organizationId
        name: $name
        requestsPageId: $requestsPageId
        dpoUserId: $dpoUserId
        disclaimerId: $disclaimerId
        languages: $languages
        texts: $texts
        documents: $documents
        extraFields: $extraFields
        requests: $requests
        style: $style
      }
    ) {
      success
      message
      errorCode
      errorDetails
    }
  }
`

export const LINK_REQUEST_PAGE_TO_DISCLAIMER = gql`
  mutation LinkRequestPageToDisclaimer(
    $disclaimerIds: [String]!
    $masterRequestPageId: String
    $hasRequestPage: Boolean
  ) {
    link_request_page_to_disclaimer(
      object: {
        disclaimerIds: $disclaimerIds
        masterRequestPageId: $masterRequestPageId
        hasRequestPage: $hasRequestPage
      }
    ) {
      success
      message
      errorCode
      errorDetails
    }
  }
`

export const ADD_REQUEST_PAGE_LINKS_TO_DISCLAIMER = gql`
  mutation AddRequestPageLinksToDisclaimer(
    $disclaimerId: String!
    $requestLinks: [RequestLinkInput]!
  ) {
    add_request_links_to_disclaimer(
      object: { disclaimerId: $disclaimerId, requestLinks: $requestLinks }
    ) {
      success
      message
      errorCode
      errorDetails
    }
  }
`
