import { ReactNode, useState } from 'react'
import { motion } from 'framer-motion'
import { fadeIn } from 'public/animation/motion'
import { classNames } from 'util/shared'
import { HiOutlineMinus, HiOutlinePlus } from 'react-icons/hi'

interface SideBarAccordionProps {
  sideBarOpen: boolean
  title: string
  fixedChildren: ReactNode
  children: ReactNode
  className?: string
}

export function SideBarAccordion({
  sideBarOpen,
  title,
  fixedChildren,
  children,
  className
}: SideBarAccordionProps) {
  const formattedTitle = sideBarOpen ? title : `${title.slice(0, 3)}`

  const [openAccordion, setAccordionOpen] = useState(true)

  return (
    <div className="py-4">
      <div
        className={classNames(
          sideBarOpen ? 'justify-between' : 'text-[10px]',
          'flex text-white dark:text-gray-400 items-center cursor-pointer mb-2 px-4',
          className
        )}
        onClick={() => setAccordionOpen(prev => !prev)}
      >
        <span className="mr-1">{formattedTitle}</span>
        {openAccordion ? (
          <div>
            <HiOutlineMinus />
          </div>
        ) : (
          <div>
            <HiOutlinePlus />
          </div>
        )}
      </div>
      {sideBarOpen && fixedChildren}
      {openAccordion && (
        <motion.div initial="initial" animate="animate" variants={fadeIn()}>
          <div className="text-gray-700 bg-gray-50">{children}</div>
        </motion.div>
      )}
    </div>
  )
}
