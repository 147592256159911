import { useOrganizationDetails } from './useOrganizationDetails'
import { useRouter } from 'next/router'
import { useState, useEffect, useMemo } from 'react'
import { Disclaimer } from 'models/oganization'

export function useDisclaimer() {
  const { data: activeOrganizationDetails } = useOrganizationDetails()

  const router = useRouter()
  const { disclaimerPathname } = router.query
  const [activeDisclaimerPath, setActiveDisclaimerPath] = useState<string>()

  const disclaimersOrdered: Disclaimer[] = useMemo(() => {
    const objectsWithDel: Disclaimer[] = []
    const objectsWithoutDel: Disclaimer[] = []
    activeOrganizationDetails?.disclaimers.forEach((obj: Disclaimer) => {
      if (obj.pathname.includes('del')) {
        objectsWithDel.push(obj)
      } else {
        objectsWithoutDel.push(obj)
      }
    })

    objectsWithoutDel.sort((a, b) => a.pathname.localeCompare(b.pathname))

    return objectsWithoutDel.concat(objectsWithDel)
  }, [activeOrganizationDetails])

  useEffect(() => {
    if (disclaimerPathname) {
      setActiveDisclaimerPath(disclaimerPathname as string)
    } else {
      setActiveDisclaimerPath(disclaimersOrdered[0]?.pathname)
    }
  }, [disclaimerPathname, activeOrganizationDetails])

  return { activeDisclaimerPath }
}
