import { Paragraph, Button, ButtonSize, ButtonType } from 'components'
import { motion } from 'framer-motion'
import { fadeInUp } from 'public/animation/motion'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BsQuestionCircle } from 'react-icons/bs'
import { classNames } from 'util/shared'

interface TooltipProps {
  position?: TooltipPosition
  description?: string
  documentation?: string
  video?: string
  className?: string
  containerClassName?: string
}

export enum TooltipPosition {
  LeftBottom = 'left-bottom',
  RightBottom = 'right-bottom',
  Center = 'center'
}

export function InfoTooltip({
  position = TooltipPosition.RightBottom,
  documentation,
  description,
  className,
  video,
  containerClassName
}: TooltipProps) {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)

  function closeTolltip() {
    setOpen(false)
  }

  const posistion = {
    [TooltipPosition.LeftBottom]: { triangle: 'left-3', card: 'mt-2 -left-2' },
    [TooltipPosition.RightBottom]: {
      triangle: 'right-3',
      card: 'mt-2 -right-2'
    },
    [TooltipPosition.Center]: {
      triangle: 'left-1/2 translate-x-[-50%]',
      card: 'mt-2 left-[-165px]'
    }
  }

  return (
    <>
      <div
        className={classNames('relative fill-white inline-block ', className)}
      >
        <Button
          buttonType={ButtonType.Ghost}
          onClick={() => setOpen(prev => !prev)}
          className="dark:!text-[#fff]"
        >
          <BsQuestionCircle size={19} />
        </Button>

        {open && (
          <motion.div
            initial="initial"
            animate="animate"
            variants={fadeInUp}
            className={classNames(
              'absolute z-[51] w-[360px]',
              containerClassName,
              posistion[position].card
            )}
          >
            <div
              className={classNames(
                'w-6 overflow-hidden absolute  inline-block',
                posistion[position].triangle
              )}
            >
              <div className="h-4 w-4 bg-gray dark:bg-white rotate-45 transform origin-bottom-left"></div>
            </div>
            <div className="top-3 px-5 py-4 flex flex-col  justify-between position absolute bg-gray dark:bg-white rounded-md w-full ">
              <Paragraph className="!text-white dark:!text-black whitespace-pre-line">
                {description}
              </Paragraph>
              <div className="flex items-center justify-between">
                {documentation && (
                  <Button
                    className="underline text-white dark:text-black"
                    buttonSize={ButtonSize.Small}
                    buttonType={ButtonType.Ghost}
                    onClick={() => {
                      window.open(documentation, '_ blank')
                    }}
                  >
                    {t('tooltip.documentationButton')}
                  </Button>
                )}
                {video && (
                  <Button
                    buttonSize={ButtonSize.Small}
                    buttonType={ButtonType.Primary}
                    onClick={() => {
                      window.open(video, '_ blank')
                    }}
                  >
                    {t('tooltip.videoButton')}
                  </Button>
                )}
              </div>
            </div>
          </motion.div>
        )}
      </div>
      {open && (
        <div
          onClick={closeTolltip}
          className="w-full  h-full absolute z-[50] top-0 left-0 "
        ></div>
      )}
    </>
  )
}
