import { useMutation } from '@apollo/client'
import { UpgradeModal, CreateOrganization, Button } from 'components'
import { useStorage } from 'contexts/storage-context'
import { useHandleError } from 'hooks/useHandleError'
import { useTranslation } from 'next-i18next'
import { CREATE_ORGANIZATION } from 'queries/organization'

import { useState } from 'react'
import { toast } from 'react-hot-toast'

export function CreateOrganizationButton({ ...props }) {
  const [upgradeModalOpen, setUpgradeModalOpen] = useState(false)

  const [open, setOpen] = useState(false)
  const [mutateFunction] = useMutation(CREATE_ORGANIZATION)
  const { t } = useTranslation()
  const { refetchOrganizations } = useStorage()
  const { handleError } = useHandleError()

  async function addOrganization(name: string, pathname: string) {
    toast.loading(`${t('organizations.Saving')}...`)

    try {
      const result = await mutateFunction({
        variables: {
          name: name,
          pathname: pathname
        }
      })
      if (result.data.create_organization.success) {
        refetchOrganizations()
        toast.dismiss()
        toast.success(`${t('organizations.The organization was created')}!`)
        setOpen(false)
      } else {
        toast.dismiss()
        if (
          result.data.create_organization.message ===
          'You have reached the maximum number of organizations for your plan'
        ) {
          setUpgradeModalOpen(true)
          setOpen(false)
        } else {
          handleError(result.data.create_organization.message)
        }
      }
    } catch (error: any) {
      toast.dismiss()
      handleError()
    }
  }
  return (
    <>
      <Button {...props} onClick={() => setOpen(true)}>
        {t('organizations.Create organization')}
      </Button>
      <CreateOrganization
        modalOpen={open}
        setModalOpen={setOpen}
        addOrganization={addOrganization}
      />
      <UpgradeModal
        modalOpen={upgradeModalOpen}
        setModalOpen={setUpgradeModalOpen}
      />
    </>
  )
}
