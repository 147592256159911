import { gql } from '@apollo/client'

export const GET_USER = gql`
  query ($email: String!) {
    users(where: { email: { _eq: $email } }) {
      id
      name
      email
      email_verify
      role
    }
  }
`
export const EMAIL_VERIFY = gql`
  mutation ($email: String!) {
    email_verify(object: { email: $email }) {
      success
      message
      data
      errorCode
      errorDetails
    }
  }
`
export const GET_USAGE = gql`
  mutation GetUsage {
    get_user_usage {
      data
      message
      success
      errorCode
      errorDetails
    }
  }
`

export const GET_ORGANIZATION_USAGE = gql`
  mutation GetOrganizationUsage($organizationId: String!) {
    get_organization_usage(object: { organizationId: $organizationId }) {
      data
      message
      success
      errorCode
      errorDetails
    }
  }
`

export const GET_USER_PLAN = gql`
  query GetUserPlan($email: String!) {
    users(where: { email: { _eq: $email } }) {
      id
      name
      phone_number
      email
      email_verify
      language
      completed_tutorial
      global_role
      migrated_documents_v2
      subscription {
        status
        current_period_end
        stripe_subscription
        subscription_plan {
          id
          name
          domains
          organizations
          disclaimers
          unique_users
          disclaimer_close
          amp
          sdk
          api
          callbacks
          privacy_report
          recurring_interval
          scan_frequency_id
          can_edit_disclaimer_text
          can_edit_dark_light_css
          webhooks
          controller_type
          recurring_interval
          controller {
            name
          }
          price
          identity_provider
        }
      }
    }
  }
`

export const UPDATE_USER_LANGUAGE = gql`
  mutation ($language: String!) {
    update_user_language(object: { language: $language }) {
      message
      success
      errorCode
      errorDetails
    }
  }
`

export const UPDATE_USER_NAME = gql`
  mutation ($name: String!) {
    update_user_name(object: { name: $name }) {
      message
      success
      errorCode
      errorDetails
    }
  }
`

export const UPDATE_USER = gql`
  mutation ($name: String!, $phoneNumber: String!) {
    update_user(object: { name: $name, phoneNumber: $phoneNumber }) {
      success
    }
  }
`

export const UPDATE_USER_TUTORIAL = gql`
  mutation UpdateUserTutorial($id: uuid!) {
    update_users(
      where: { id: { _eq: $id } }
      _set: { completed_tutorial: true }
    ) {
      affected_rows
    }
  }
`
