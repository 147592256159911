import Image from 'next/image'
import { fadeInUp } from 'public/animation/motion'
import { motion } from 'framer-motion'
import { useTheme } from 'contexts/theme-context'
import maintenance from 'assets/imgs/maintenance.svg'
import maintenanceLight from 'assets/imgs/maintenance-light.svg'
import logo from 'assets/imgs/logo-horizontal.svg'
import logoDark from 'assets/imgs/dark-logo-horizontal.svg'
import { useTranslation } from 'next-i18next'
import { BodyText, H1 } from '../typography'

export function MaintenancePage() {
  const { theme } = useTheme()
  const { t } = useTranslation()
  return (
    <div className="min-h-screen flex items-center ">
      <div className="hidden md:flex bg-primary-400 w-2/5 dark:bg-gray h-screen  justify-center items-center">
        <motion.div
          initial="initial"
          animate="animate"
          variants={fadeInUp}
          exit={{ opacity: 0, transition: { duration: 0.3 } }}
          className="max-w-4xl"
        >
          {theme === 'dark' ? (
            <Image src={maintenance} alt="Picture of the author" />
          ) : (
            <Image src={maintenanceLight} alt="Picture of the author" />
          )}
        </motion.div>
      </div>
      <div className="h-screen w-full md:w-3/5 dark:bg-black flex justify-center items-center">
        <div className="max-w-400 flex flex-col items-center">
          {theme === 'dark' ? (
            <Image width="120" src={logoDark} alt="Adopt logo" />
          ) : (
            <Image width="120" src={logo} alt="Adopt logo" />
          )}
          <H1 className="mt-5  !text-primary font-bold">
            {t('maintenance.title')}
          </H1>
          <BodyText className=" dark:text-white text-center mt-1">
            {t('maintenance.description')}
          </BodyText>
        </div>
      </div>
    </div>
  )
}
