/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import React, { Fragment, ReactNode } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { Link } from 'components'
import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'
import { paths } from 'config/paths'
import { useAuth } from 'contexts/auth-context'
import { useStorage } from 'contexts/storage-context'
import { BiTrophy, BiWorld } from 'react-icons/bi'
import { FiHelpCircle, FiSettings } from 'react-icons/fi'
import { MdChatBubbleOutline } from 'react-icons/md'
import { LuSparkles } from 'react-icons/lu'
import { IoExitOutline } from 'react-icons/io5'

export default function HeaderDropdown({ children }: { children: ReactNode }) {
  const router = useRouter()
  const { t } = useTranslation()
  const { handleLogout } = useAuth()
  const { organizationsData } = useStorage()

  function logout() {
    handleLogout()
    router.push('/login')
  }

  const openTutorialPath = organizationsData?.length
    ? `${paths.org.disclaimers.replace(
        ':organizationPathname',
        organizationsData[0].pathname
      )}?openTutorial=true`
    : ''

  return (
    <Menu as="div" className="relative inline-block text-left z-40">
      {({ open }) => (
        <>
          <div>
            <Menu.Button className="flex items-center text-black dark:text-white">
              {children}
            </Menu.Button>
          </div>

          <Transition
            show={open}
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              static
              className="origin-top-right absolute cursor-pointer rounded-md w-60 m-2 shadow-xl shadow-gray-400 dark:shadow-black bg-white dark:bg-gray focus:outline-none divide-y divide-gray-250 dark:divide-gray-500"
            >
              <Menu.Button className="w-full">
                <Link
                  href={paths.organizations}
                  className={
                    'p-2 flex gap-2 items-center text-black dark:text-gray-250 rounded-t-md hover:dark:text-white hover:bg-gray-75 hover:dark:bg-black duration-300'
                  }
                >
                  <BiWorld size={16} />
                  {t('headerDropdown.myOrganizations')}
                </Link>
              </Menu.Button>
              <Menu.Button className="w-full">
                <Link
                  href={paths.plan}
                  className={
                    'p-2 flex gap-2 items-center text-black dark:text-gray-250 hover:dark:text-white hover:bg-gray-75 hover:dark:bg-black duration-300'
                  }
                >
                  <BiTrophy size={16} />
                  {t('headerDropdown.myPlan')}
                </Link>
              </Menu.Button>
              <Menu.Button className="w-full">
                <Link
                  href={paths.accountConfiguration}
                  className={
                    'p-2 flex gap-2 items-center text-black dark:text-gray-250 hover:dark:text-white hover:bg-gray-75 hover:dark:bg-black duration-300'
                  }
                >
                  <FiSettings size={16} />
                  {t('headerDropdown.accountSetup')}
                </Link>
              </Menu.Button>
              <Menu.Button className="w-full !border-t-2 !border-gray-200">
                <Link
                  href={paths.support.documents}
                  target={'_blank'}
                  className={
                    'p-2 flex gap-2 items-center text-black dark:text-gray-250 hover:dark:text-white hover:bg-gray-75 hover:dark:bg-black duration-300'
                  }
                >
                  <FiHelpCircle size={16} />
                  {t('headerDropdown.help')}
                </Link>
              </Menu.Button>
              <Menu.Button className="w-full">
                <Link
                  href={paths.support.ticket}
                  target={'_blank'}
                  className={
                    'p-2 flex gap-2 items-center text-black dark:text-gray-250 hover:dark:text-white hover:bg-gray-75 hover:dark:bg-black duration-300'
                  }
                >
                  <MdChatBubbleOutline size={16} />
                  {t('headerDropdown.openTicket')}
                </Link>
              </Menu.Button>
              <Menu.Button className="w-full">
                <Link
                  href={openTutorialPath}
                  className={
                    'p-2 flex gap-2 items-center text-black dark:text-gray-250 hover:dark:text-white hover:bg-gray-75 hover:dark:bg-black duration-300'
                  }
                >
                  <LuSparkles size={16} />
                  {t('headerDropdown.tutorial')}
                </Link>
              </Menu.Button>
              <Menu.Button
                className="!border-t-2 !border-gray-200 w-full rounded-b-md text-left p-2 text-sm flex gap-2 items-center dark:hover:font-bold text-pink hover:dark:bg-black hover:bg-gray-75"
                onClick={logout}
              >
                <IoExitOutline size={16} />
                {t('headerDropdown.logout')}
              </Menu.Button>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  )
}
