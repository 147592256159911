import React from 'react'

export default function DotsLoading() {
  return (
    <div className="w-screen  h-screen dark:bg-black text-2xl font-bold flex justify-center items-center flex-col">
      <div className="dark:text-white font-lg">Loading</div>
      <div className="mt-2 flex">
        <div className="animate-bounce w-2 h-2 mr-2 rounded-full bg-gray-200"></div>
        <div
          style={{ animationDelay: '150ms' }}
          className="animate-bounce w-2 h-2 mr-2 rounded-full bg-gray-200"
        ></div>
        <div
          style={{ animationDelay: '300ms' }}
          className="animate-bounce w-2 h-2 mr-2 rounded-full bg-gray-200"
        ></div>
        <div
          style={{ animationDelay: '450ms' }}
          className="animate-bounce w-2 h-2 mr-2 rounded-full bg-gray-200"
        ></div>
        <div
          style={{ animationDelay: '600ms' }}
          className="animate-bounce w-2 h-2 mr-2 rounded-full bg-gray-200"
        ></div>
        <div
          style={{ animationDelay: '750ms' }}
          className="animate-bounce w-2 h-2  rounded-full bg-gray-200"
        ></div>
      </div>
    </div>
  )
}
