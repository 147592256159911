import React, { useEffect, useState } from 'react'
import { Button, ButtonType, Input } from 'components'
import { useIdentityProvider } from 'hooks/useIdentityProvider'
import { BiLink } from 'react-icons/bi'
import { useTranslation } from 'next-i18next'
import { MultipleSelect } from '../select/multiple-select'
import { useStorage } from 'contexts/storage-context'
import { formatUrl } from 'util/format'
import { Validator } from 'util/validator'
import { toast } from 'react-hot-toast'
import { useHandleError } from 'hooks/useHandleError'
import { ModalWrapper } from './modal-wrapper'

interface CreateIdentityProviderModalProps {
  modalOpen: boolean
  setModalOpen: (value: boolean) => void
}

const defaultData = {
  identityProvider: '',
  metadataUrl: '',
  attributeMapping: JSON.stringify({
    email: 'emailaddress',
    name: 'name'
  })
}
const defaultDataError = {
  identityProvider: '',
  metadataUrl: '',
  organizations: ''
}

export interface OrganizationsSelectedsProps {
  name: string
  value: string
}

export function CreateIdentityProviderModal({
  modalOpen,
  setModalOpen
}: CreateIdentityProviderModalProps) {
  const { t } = useTranslation()
  const { createIdentityProvider } = useIdentityProvider()
  const { organizationsData } = useStorage()
  const { handleError } = useHandleError()

  const [data, setData] = useState(defaultData)
  const [selecteds, setSelecteds] = useState<OrganizationsSelectedsProps[]>([])
  const [dataError, setDataError] = useState(defaultDataError)

  useEffect(() => {
    if (modalOpen) {
      setDataError(defaultDataError)
      setData(defaultData)
      setSelecteds([])
    }
  }, [modalOpen])

  function isValid() {
    let valid = true
    if (selecteds.length === 0) {
      valid = false
    }
    if (!Validator.isValidUrl(data.metadataUrl)) {
      valid = false
      setDataError(prev => prev && { ...prev, metadataUrl: 'Invalid url' })
    }
    return valid
  }

  async function submit() {
    toast.loading(t('common.loading'))
    if (isValid()) {
      const result = await createIdentityProvider({
        ...data,
        organizations: selecteds.map(item => item.value)
      })

      toast.dismiss()
      if (result.success) {
        setModalOpen(false)
      } else {
        handleError(result.message)
      }
    }
  }

  return (
    <ModalWrapper
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      headerProps={{
        icon: <BiLink className="text-primary" size={25} />,
        title: t('createIdentityProviderModal.title'),
        subtitle: t('createIdentityProviderModal.subtitle')
      }}
      modalSize="medium"
    >
      <Input
        label={t('createIdentityProviderModal.identityProvider')}
        value={data.identityProvider}
        fieldError={dataError.identityProvider}
        events={{
          onChange: (e: { value: string }) => {
            setDataError(defaultDataError)
            setData(prev => ({
              ...prev,
              identityProvider: formatUrl(e.value)
            }))
          }
        }}
      />
      <Input
        label={t('createIdentityProviderModal.metadataUrl')}
        value={data.metadataUrl}
        fieldError={dataError.metadataUrl}
        contClass={'my-4'}
        events={{
          onChange: (e: { value: string }) => {
            setDataError(defaultDataError)
            setData(prev => ({
              ...prev,
              metadataUrl: e.value
            }))
          }
        }}
      />
      <MultipleSelect
        label={t('createIdentityProviderModal.organizations')}
        fieldError={dataError?.organizations}
        params={
          organizationsData?.map((item: { name: string; id: string }) => {
            return {
              name: item.name,
              value: item.id
            }
          }) ?? []
        }
        selecteds={selecteds}
        setSelecteds={data => {
          setDataError(defaultDataError)
          setSelecteds(data)
        }}
      />

      <div className="flex justify-end mt-8 gap-3">
        <Button
          buttonType={ButtonType.Secondary}
          onClick={() => setModalOpen(false)}
          className="dark:bg-black "
        >
          {t('createIdentityProviderModal.cancel')}
        </Button>
        <Button buttonType={ButtonType.Primary} onClick={() => submit()}>
          {t('createIdentityProviderModal.create')}
        </Button>
      </div>
    </ModalWrapper>
  )
}
