import React, { useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'

import {
  Accordion,
  Button,
  ButtonType,
  PagesHeader,
  SpinLoading
} from 'components'
import { AiOutlineAudit } from 'react-icons/ai'
import { FaRegEdit } from 'react-icons/fa'
import { IoColorPaletteOutline, IoList } from 'react-icons/io5'
import { Title } from 'components/pages/request-page/accordion/Title'
import { GeneralInfoContent } from 'components/pages/request-page/accordion/GeneralInfoContent'
import { TextsContent } from 'components/pages/request-page/accordion/TextsContent.tsx'
import { LegislationContent } from 'components/pages/request-page/accordion/LegislationContent'
import { CustomizationContent } from 'components/pages/request-page/accordion/CustomizationContent'
import { MdOutlineRemoveRedEye } from 'react-icons/md'
import { Preview } from 'components/pages/request-page/preview'
import { FormData } from './types'
import { initialData } from './constants'
import { LegislationsTypes } from 'util/enums'
import { useHandleError } from 'hooks/useHandleError'
import { useRequestPage } from 'hooks/useRequestPage'

type CreateRequestPageProps = {
  disclaimerId?: string
  requestPageId?: string
}

export default function CreateRequestPage({
  disclaimerId,
  requestPageId
}: CreateRequestPageProps) {
  const { t } = useTranslation()
  const router = useRouter()
  const { handleError } = useHandleError()
  const { requestPageById, loading, addEditRequestsPage } = useRequestPage(
    requestPageId !== 'create' ? requestPageId : ''
  )

  console.log('requestPageById', requestPageById)
  // console.log({ disclaimerId, requestPageId })

  const [formData, setFormData] = useState<FormData>(initialData)
  const [accordionIndex, setAccordionIndex] = useState<number | null>(null)
  const [selectedLanguage, setSelectedLanguage] = useState(
    initialData.languages.fallback
  )
  const [selectedLegislation, setSelectedLegislation] = useState<string>(
    LegislationsTypes.LGPD
  )

  function handleAccordionClick(index: number) {
    setAccordionIndex(prevIndex => (prevIndex === index ? null : index))
  }

  // const findChanges = (initial: any, current: any): any => {
  //   // Armazena as alterações
  //   const changes: any = Array.isArray(current) ? [] : {}

  //   // Percorre as chaves do objeto atual
  //   for (const key in current) {
  //     // Verifica se o valor atual é um objeto e se não é nulo
  //     if (typeof current[key] === 'object' && current[key] !== null) {
  //       // Se for um objeto ou array, chama a função recursivamente
  //       const nestedChanges = findChanges(initial[key], current[key])

  //       // Só adiciona as alterações se houver mudanças no objeto aninhado
  //       if (Object.keys(nestedChanges).length > 0) {
  //         changes[key] = nestedChanges
  //       }
  //     } else if (current[key] !== initial[key]) {
  //       // Se não for um objeto, compara diretamente os valores
  //       changes[key] = current[key]
  //     }
  //   }

  //   return changes
  // }

  async function handleSave() {
    // console.log('formData', formData)
    // return

    if (!isFormDataValid()) {
      return
    }

    // const ObjWithChanges = findChanges(initialData, formData)
    // console.log('ObjWithChanges', ObjWithChanges)
    // return

    const formDataFormatted = {
      ...formData,
      style: JSON.stringify(formData.style)
    }

    toast.loading(t('createRequestPage.saving'))
    try {
      const result = await addEditRequestsPage(
        formDataFormatted,
        disclaimerId,
        (requestPageId = requestPageId !== 'create' ? requestPageId : '')
      )

      if (result?.success) {
        toast.dismiss()
        toast.success(t('createRequestPage.success'))
        router.back()
      } else {
        toast.dismiss()
        handleError(result?.message)
      }
    } catch (error) {
      toast.dismiss()
      handleError()
    }
  }

  function isFormDataValid() {
    const isNameValid = !!formData.name

    const isDpoUserValid = !!formData.dpoUserId

    const isTextsValid = formData.texts.every(
      text => !!text.title && !!text.introduction
    )
    const isFontsValid = Object.values(formData.style.fonts).every(
      font => !!font.lineHeight && font.size
    )
    const isMarginsValid =
      !!formData.style.margins.horizontally &&
      !!formData.style.margins.vertically
    const isRoundedValid =
      !!formData.style.rounded.banner && !!formData.style.rounded.button

    if (!isNameValid) {
      handleError('Preencha o nome da página na seção de Informações Gerais')
    }
    if (!isDpoUserValid) {
      handleError(
        'Selecione o DPO ou Encarregado de dados na seção de Informações Gerais'
      )
    }
    if (!isTextsValid) {
      handleError('Preencha os campos Título e Introdução na seção de conteúdo')
    }
    if (!isFontsValid) {
      handleError(
        'Os campos Tamanho e Entre-linhas na personalização das fontes não pode ser nulo'
      )
    }
    if (!isMarginsValid) {
      handleError(
        'Os valores não podem ser nulos na personalização das margens'
      )
    }
    if (!isRoundedValid) {
      handleError(
        'Os valores não podem ser nulos na personalização do arredondamento'
      )
    }

    return (
      isNameValid &&
      isDpoUserValid &&
      isTextsValid &&
      isMarginsValid &&
      isRoundedValid
    )
  }

  function handleCancel() {
    setFormData(initialData)
    router.back()
  }

  const accordionData = [
    {
      title: (
        <Title
          title={t('createRequestPage.accordions.generalInfo.title')}
          subTitle={t('createRequestPage.accordions.generalInfo.subTitle')}
          icon={<AiOutlineAudit />}
          isOpen={accordionIndex === 0}
        />
      ),
      content: <GeneralInfoContent {...{ formData, setFormData }} />
    },
    {
      title: (
        <Title
          title={t('createRequestPage.accordions.texts.title')}
          subTitle={t('createRequestPage.accordions.texts.subTitle')}
          icon={<FaRegEdit />}
          isOpen={accordionIndex === 1}
        />
      ),
      content: (
        <TextsContent
          {...{ formData, setFormData, selectedLanguage, setSelectedLanguage }}
        />
      )
    },
    {
      title: (
        <Title
          title={t('createRequestPage.accordions.legislation.title')}
          subTitle={t('createRequestPage.accordions.legislation.subTitle')}
          icon={<IoList />}
          isOpen={accordionIndex === 2}
        />
      ),
      content: (
        <LegislationContent
          {...{
            formData,
            setFormData,
            selectedLegislation,
            setSelectedLegislation
          }}
        />
      )
    },
    {
      title: (
        <Title
          title={t('createRequestPage.accordions.customization.title')}
          subTitle={t('createRequestPage.accordions.customization.subTitle')}
          icon={<IoColorPaletteOutline />}
          isOpen={accordionIndex === 3}
        />
      ),
      content: <CustomizationContent {...{ formData, setFormData }} />
    }
  ]

  return loading ? (
    <div className="h-screen flex justify-center items-center">
      <SpinLoading />
    </div>
  ) : (
    <>
      <PagesHeader
        title={t('createRequestPage.title')}
        subtitle={t('createRequestPage.subTitle')}
        page="optout"
        className="mb-5"
        button={
          <Button buttonType={ButtonType.Secondary}>
            {t('createRequestPage.restoreVersionsButton')}
          </Button>
        }
      />

      <div className="px-10 grid xl:grid-cols-2 grid-cols-1 gap-8">
        <div className="flex flex-col gap-6">
          {accordionData.map(({ title, content }, index) => (
            <Accordion
              key={index}
              customTitle={title}
              isOpen={accordionIndex === index}
              onClick={() => handleAccordionClick(index)}
            >
              {content}
            </Accordion>
          ))}
        </div>
        <div>
          <Preview {...{ formData, selectedLanguage, selectedLegislation }} />
        </div>
      </div>

      <div className="pt-10 mt-8 mx-10 border-t border-gray-200 dark:border-gray-500 flex gap-4 justify-between">
        <Button
          buttonType={ButtonType.Outline}
          iconLeft={MdOutlineRemoveRedEye}
        >
          {t('createRequestPage.previewButton')}
        </Button>

        <div className="flex gap-4 items-center">
          <Button buttonType={ButtonType.Secondary} onClick={handleCancel}>
            {t('createRequestPage.cancelButton')}
          </Button>
          <Button onClick={handleSave}>
            {t('createRequestPage.saveButton')}
          </Button>
        </div>
      </div>
    </>
  )
}
