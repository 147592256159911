// eslint-disable-next-line import/named
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker'
import { GoCalendar } from 'react-icons/go'

export function DatePicker({ ...props }: ReactDatePickerProps) {
  return (
    <div className="flex items-center relative custom-datepicker">
      <ReactDatePicker autoComplete="off" {...props} />
      <GoCalendar className="absolute right-4" />
    </div>
  )
}
