import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState
} from 'react'
import i18next from 'i18next'

import { Question, QuestionText } from 'models/documents'
import { GET_QUESTIONS } from 'queries/documents'
import { useQuery } from '@apollo/client'
import { convertBase64 } from 'util/shared'
import { DocumentTypes } from 'components/shared/document-management'
import { useStorage } from './storage-context'

interface DocumentQuestionsProviderProps {
  documentType: DocumentTypes
  children: ReactNode
}

interface DocumentQuestionsContextType {
  questions: Question[]
  loading: boolean
  formFields: FormFields
  setFormFields: Dispatch<SetStateAction<FormFields>>
  errors: ErrorsProps
  setErrors: Dispatch<SetStateAction<ErrorsProps>>
  styleFields: StyleFields
  setStyleFields: Dispatch<SetStateAction<StyleFields>>
  renderContentControl: RenderContentControl
  setRenderContentControl: Dispatch<SetStateAction<RenderContentControl>>
  resultCardType: ResultCardTypes
  setResultCardType: Dispatch<SetStateAction<ResultCardTypes>>
  scanId: string
  setScanId: Dispatch<SetStateAction<string>>
  resetStyleFieldsData: () => void
  handleTextInputChange: (id: string, value: any) => void
  handleToggleInputChange: (id: string) => void
  handleCheckboxInputChange: (event: boolean, value: string, id: string) => void
  getTransformedStyleFields: (styles: StyleFields) => Promise<any>
  iframeModalOpen: boolean
  setIframeModalOpen: Dispatch<SetStateAction<boolean>>
  shouldBlockCustomStyles: boolean
  initialStyleData: StyleFields
}

export type FormFields = {
  [key: string]: {
    value: any
    label: string
    options: FormFieldsOptions[]
  }
}

export type FormFieldsOptions = {
  name: string
  value: string | boolean
}

export type ErrorsProps = Record<string, string>

export type RenderContentControl =
  | 'cookies'
  | 'privacy'
  | 'styles'
  | 'resultCard'

export type ResultCardTypes = 'loading' | 'success' | 'error'

export type TextStyle = {
  fonts: string
  color: string
  size: number
}

export type Margins = {
  left: number
  right: number
  top: number
  bottom: number
}

export type DeviceStyle = {
  backgroundColor: string
  headerColor: string
  logoPosition: string
  margins: Margins
  title: TextStyle
  subTitle: TextStyle
  bodyText: TextStyle
}

export type StyleTheme = {
  logo: File | null
  web: DeviceStyle
  mobile: DeviceStyle
}

export type StyleFields = {
  template: string
  light: StyleTheme
  dark: StyleTheme
  theme: string
  device: string
}

const initialStyleData = {
  template: 'standard',
  light: {
    logo: null,
    web: {
      backgroundColor: '#ffffff',
      headerColor: '#ffffff',
      logoPosition: 'center',
      margins: {
        left: 80,
        right: 80,
        top: 32,
        bottom: 32
      },
      title: {
        fonts: 'roboto',
        color: '#0D1117',
        size: 48
      },
      subTitle: {
        fonts: 'roboto',
        color: '#009959',
        size: 32
      },
      bodyText: {
        fonts: 'roboto',
        color: '#0D1117',
        size: 16
      }
    },
    mobile: {
      backgroundColor: '#ffffff',
      headerColor: '#ffffff',
      logoPosition: 'center',
      margins: {
        left: 24,
        right: 24,
        top: 32,
        bottom: 32
      },
      title: {
        fonts: 'roboto',
        color: '#0D1117',
        size: 28
      },
      subTitle: {
        fonts: 'roboto',
        color: '#009959',
        size: 20
      },
      bodyText: {
        fonts: 'roboto',
        color: '#0D1117',
        size: 12
      }
    }
  },
  dark: {
    logo: null,
    web: {
      backgroundColor: '#0D1117',
      headerColor: '#0D1117',
      logoPosition: 'center',
      margins: {
        left: 80,
        right: 80,
        top: 32,
        bottom: 32
      },
      title: {
        fonts: 'roboto',
        color: '#ffffff',
        size: 48
      },
      subTitle: {
        fonts: 'roboto',
        color: '#009959',
        size: 32
      },
      bodyText: {
        fonts: 'roboto',
        color: '#ffffff',
        size: 16
      }
    },
    mobile: {
      backgroundColor: '#0D1117',
      headerColor: '#0D1117',
      logoPosition: 'center',
      margins: {
        left: 24,
        right: 24,
        top: 32,
        bottom: 32
      },
      title: {
        fonts: 'roboto',
        color: '#ffffff',
        size: 28
      },
      subTitle: {
        fonts: 'roboto',
        color: '#009959',
        size: 20
      },
      bodyText: {
        fonts: 'roboto',
        color: '#ffffff',
        size: 12
      }
    }
  },
  theme: 'light',
  device: 'web'
}

export const DocumentQuestionsContext = createContext(
  {} as DocumentQuestionsContextType
)

export function DocumentQuestionsProvider({
  documentType,
  children
}: DocumentQuestionsProviderProps) {
  const [formFields, setFormFields] = useState<FormFields>({})
  const [styleFields, setStyleFields] = useState<StyleFields>(initialStyleData)
  const [errors, setErrors] = useState<ErrorsProps>({})
  const [renderContentControl, setRenderContentControl] =
    useState<RenderContentControl>('' as RenderContentControl)
  const [resultCardType, setResultCardType] = useState<ResultCardTypes>(
    '' as ResultCardTypes
  )
  const [scanId, setScanId] = useState('')
  const [iframeModalOpen, setIframeModalOpen] = useState(false)

  const { activeOrganizationDetails } = useStorage()

  const shouldBlockCustomStyles = !activeOrganizationDetails?.plan?.can_edit_document_style ?? true

  const { data, loading } = useQuery(GET_QUESTIONS, {
    fetchPolicy: 'network-only',
    variables: { documentType }
  })

  function getInputLabel(questionsText: QuestionText[]) {
    return (
      questionsText?.find(
        (text: any) => text.document_language === i18next.language
      )?.text ?? ''
    )
  }

  function getInputOptions(
    questionsText: QuestionText[],
    question_type: string
  ) {
    const currentQuestionTexts = questionsText?.find(
      ({ document_language }: any) => document_language === i18next.language
    )

    if (!currentQuestionTexts) return []

    return currentQuestionTexts.question_options_texts.map(
      ({ value, name }) => {
        const option: FormFieldsOptions = {
          name: String(value),
          value: name
        }
        if (question_type === 'toggle' || question_type === 'radial') {
          option.value = name.includes('yes') ? true : false
        }
        return option
      }
    )
  }

  const handleTextInputChange = (id: string, value: any) => {
    setFormFields(prevFields => ({
      ...prevFields,
      [id]: {
        ...prevFields[id],
        value
      }
    }))
    setErrors(prevErrors => ({
      ...prevErrors,
      [id]: ''
    }))
  }

  const handleToggleInputChange = (id: string) => {
    setFormFields(prev => ({
      ...prev,
      [id]: {
        ...prev[id],
        value: !prev[id]?.value
      }
    }))
  }

  function handleCheckboxInputChange(
    event: boolean,
    value: string,
    id: string
  ) {
    const newCheckboxes = [...(formFields[id]?.value ?? [])]

    if (event) {
      newCheckboxes.push(value)
    } else {
      const index = newCheckboxes.indexOf(value)
      if (index > -1) {
        newCheckboxes.splice(index, 1)
      }
    }

    setFormFields(prevFields => ({
      ...prevFields,
      [id]: {
        ...prevFields[id],
        value: newCheckboxes
      }
    }))
  }

  function initializeFormFields(questions: Question[]) {
    const initialFormFields: FormFields = {}

    const inputTypesMap = {
      dropdown: [],
      checkbox: [],
      radial: false,
      toggle: false,
      date_picker: ''
    }

    for (const { id, question_type, questions_texts } of questions) {
      initialFormFields[id] = {
        value: inputTypesMap[question_type as keyof typeof inputTypesMap] ?? '',
        label: getInputLabel(questions_texts),
        options: getInputOptions(questions_texts, question_type)
      }
    }

    setFormFields(initialFormFields)
  }

  useEffect(() => {
    if (!loading && data) {
      initializeFormFields(data.questions)
    }
  }, [data, loading])

  function resetStyleFieldsData() {
    setStyleFields(initialStyleData)
  }

  const convertToPx = (value: number): string => `${value}px`

  async function getTransformedStyleFields(styles: StyleFields) {
    const transformMargins = (margins: Margins): { [key: string]: string } => {
      return Object.fromEntries(
        Object.entries(margins).map(([key, value]) => [key, convertToPx(value)])
      )
    }

    const transformTextStyles = (textStyles: TextStyle) => {
      return {
        ...textStyles,
        size: convertToPx(textStyles.size)
      }
    }

    const transformDevice = async (device: DeviceStyle) => {
      return {
        ...device,
        margins: transformMargins(device.margins),
        title: transformTextStyles(device.title),
        subTitle: transformTextStyles(device.subTitle),
        bodyText: transformTextStyles(device.bodyText)
      }
    }

    const transformTheme = async (theme: StyleTheme) => {
      return {
        ...theme,
        logo: theme.logo ? await convertBase64(theme.logo) : null,
        web: await transformDevice(theme.web),
        mobile: await transformDevice(theme.mobile)
      }
    }

    return {
      ...styles,
      light: await transformTheme(styles.light),
      dark: await transformTheme(styles.dark)
    }
  }

  return (
    <DocumentQuestionsContext.Provider
      value={{
        questions: data?.questions,
        loading,
        formFields,
        setFormFields,
        errors,
        setErrors,
        styleFields,
        setStyleFields,
        renderContentControl,
        setRenderContentControl,
        resultCardType,
        setResultCardType,
        scanId,
        setScanId,
        resetStyleFieldsData,
        handleTextInputChange,
        handleToggleInputChange,
        handleCheckboxInputChange,
        getTransformedStyleFields,
        iframeModalOpen,
        setIframeModalOpen,
        shouldBlockCustomStyles,
        initialStyleData
      }}
    >
      {children}
    </DocumentQuestionsContext.Provider>
  )
}
export const useDocumentQuestions = () => useContext(DocumentQuestionsContext)
