import { Button } from 'components/shared/buttons'
import { useState } from 'react'
import { FormData } from '../types'
import defaultTexts from 'util/defaultTexts/requestPageTexts.json'

type PreviewProps = {
  formData: FormData
  selectedLanguage: string
  selectedLegislation: string
}

interface InputTexts {
  [key: string]: string
}

export function Preview({
  formData,
  selectedLanguage,
  selectedLegislation
}: PreviewProps) {
  const [requests, setRequests] = useState<string[]>([])

  function handleRequests(request: string) {
    setRequests(prev =>
      prev.includes(request)
        ? prev.filter(req => req !== request)
        : [...prev, request]
    )
  }

  return (
    <div
      style={{
        backgroundColor: formData.style.colors.form,
        padding: `${formData.style.margins.vertically}px ${formData.style.margins.horizontally}px`
      }}
      className="w-full rounded-lg py-8 px-6 space-y-4"
    >
      <h1
        className="text-2xl text-center"
        style={{
          color: formData.style.colors.title,
          fontFamily: formData.style.fonts.title.family,
          fontSize: formData.style.fonts.title.size,
          lineHeight: `${formData.style.fonts.title.lineHeight}px`
        }}
      >
        {formData.texts.find(ln => ln.language === selectedLanguage)?.title}
      </h1>
      <p
        style={{
          color: formData.style.colors.texts,
          fontFamily: formData.style.fonts.texts.family,
          fontSize: formData.style.fonts.texts.size,
          lineHeight: `${formData.style.fonts.texts.lineHeight}px`
        }}
      >
        {
          formData.texts.find(ln => ln.language === selectedLanguage)
            ?.introduction
        }
      </p>
      {Object.values(formData.documents).filter(Boolean).length > 0 && (
        <div>
          <h3
            style={{
              color: formData.style.colors.texts,
              fontFamily: formData.style.fonts.texts.family,
              fontSize: formData.style.fonts.texts.size,
              lineHeight: `${formData.style.fonts.texts.lineHeight}px`
            }}
          >
            {
              formData.texts.find(ln => ln.language === selectedLanguage)
                ?.docsTitle
            }
          </h3>

          <ul className="list-disc px-8 py-2">
            {formData.documents.privacyMasterId && (
              <li
                className="underline cursor-pointer py-1"
                style={{
                  color: formData.style.colors.button,
                  fontFamily: formData.style.fonts.texts.family,
                  fontSize: formData.style.fonts.texts.size,
                  lineHeight: `${formData.style.fonts.texts.lineHeight}px`
                }}
              >
                {
                  defaultTexts[selectedLanguage as keyof typeof defaultTexts]
                    .documents.policy
                }
              </li>
            )}
            {formData.documents.cookiesMasterId && (
              <li
                className="underline  cursor-pointer py-1"
                style={{
                  color: formData.style.colors.button,
                  fontFamily: formData.style.fonts.texts.family,
                  fontSize: formData.style.fonts.texts.size,
                  lineHeight: `${formData.style.fonts.texts.lineHeight}px`
                }}
              >
                {
                  defaultTexts[selectedLanguage as keyof typeof defaultTexts]
                    .documents.cookies
                }
              </li>
            )}
            {formData.documents.termsMasterId && (
              <li
                className="underline cursor-pointer py-1"
                style={{
                  color: formData.style.colors.button,
                  fontFamily: formData.style.fonts.texts.family,
                  fontSize: formData.style.fonts.texts.size,
                  lineHeight: `${formData.style.fonts.texts.lineHeight}px`
                }}
              >
                {
                  defaultTexts[selectedLanguage as keyof typeof defaultTexts]
                    .documents.terms
                }
              </li>
            )}
          </ul>
        </div>
      )}
      <p
        style={{
          color: formData.style.colors.texts,
          fontFamily: formData.style.fonts.texts.family,
          fontSize: formData.style.fonts.texts.size,
          lineHeight: `${formData.style.fonts.texts.lineHeight}px`
        }}
      >
        {
          defaultTexts[selectedLanguage as keyof typeof defaultTexts].titles
            .requestTypes
        }
        *
      </p>
      <div className="rounded-lg border border-gray-250 p-3 flex flex-wrap gap-4 items-center">
        {formData.requests
          .find(req => req.legislation === selectedLegislation)
          ?.requests.map((item, index) => (
            <button
              key={index}
              className="rounded-md px-2 duration-200"
              style={{
                backgroundColor: requests.includes(item)
                  ? formData.style.colors.button
                  : `${formData.style.colors.texts}1a`,
                color: requests.includes(item)
                  ? formData.style.colors.buttonText
                  : formData.style.colors.texts,
                fontFamily: formData.style.fonts.button.family,
                fontSize: formData.style.fonts.button.size,
                lineHeight: `${formData.style.fonts.button.lineHeight}px`,
                borderRadius: formData.style.rounded.button
              }}
              onClick={() => handleRequests(item)}
            >
              {
                (
                  defaultTexts[selectedLanguage as keyof typeof defaultTexts][
                    'requestTypes'
                  ] as InputTexts
                )[item]
              }
            </button>
          ))}
      </div>

      <div>
        <label
          className="font-bold mb-1"
          style={{
            color: formData.style.colors.texts,
            fontFamily: formData.style.fonts.texts.family,
            fontSize: formData.style.fonts.texts.size,
            lineHeight: `${formData.style.fonts.texts.lineHeight}px`
          }}
        >
          {
            defaultTexts[selectedLanguage as keyof typeof defaultTexts]
              .inputTexts.email
          }
          *
        </label>
        <input
          maxLength={50}
          className="focus:outline-none dark:text-white resize-none scroll-1 appearance-none relative block w-full px-3 h-[40px] box-border rounded-md bg-white dark:bg-gray border border-gray-400"
          style={{
            color: formData.style.colors.texts,
            fontFamily: formData.style.fonts.texts.family,
            fontSize: formData.style.fonts.texts.size,
            lineHeight: `${formData.style.fonts.texts.lineHeight}px`
          }}
        />
      </div>
      {formData.extraFields.map(field => (
        <div>
          <label
            className="font-bold mb-1"
            htmlFor={field}
            style={{
              color: formData.style.colors.texts,
              fontFamily: formData.style.fonts.texts.family,
              fontSize: formData.style.fonts.texts.size,
              lineHeight: `${formData.style.fonts.texts.lineHeight}px`
            }}
          >
            {
              (
                defaultTexts[selectedLanguage as keyof typeof defaultTexts][
                  'inputTexts'
                ] as InputTexts
              )[field]
            }
            *
            {field === 'observations' && (
              <span className="opacity-40 pl-1">
                (
                {
                  defaultTexts[selectedLanguage as keyof typeof defaultTexts][
                    'inputTexts'
                  ].optionalObservations
                }
                )
              </span>
            )}
          </label>
          {field === 'observations' ? (
            <textarea
              id={field}
              maxLength={250}
              rows={5}
              className="focus:outline-none dark:text-white resize-none scroll-1 appearance-none relative block w-full px-3 py-2 box-border rounded-md bg-white dark:bg-gray border border-gray-400"
              style={{
                color: formData.style.colors.texts,
                fontFamily: formData.style.fonts.texts.family,
                fontSize: formData.style.fonts.texts.size,
                lineHeight: `${formData.style.fonts.texts.lineHeight}px`
              }}
            />
          ) : (
            <input
              id={field}
              maxLength={50}
              className="focus:outline-none dark:text-white resize-none scroll-1 appearance-none relative block w-full px-3 h-[40px] box-border rounded-md bg-white dark:bg-gray border border-gray-400"
              style={{
                color: formData.style.colors.texts,
                fontFamily: formData.style.fonts.texts.family,
                fontSize: formData.style.fonts.texts.size,
                lineHeight: `${formData.style.fonts.texts.lineHeight}px`
              }}
            />
          )}
        </div>
      ))}

      <div className="flex justify-center">
        <Button
          style={{
            backgroundColor: formData.style.colors.button,
            color: formData.style.colors.buttonText,
            fontFamily: formData.style.fonts.button.family,
            fontSize: formData.style.fonts.button.size,
            lineHeight: `${formData.style.fonts.button.lineHeight}px`,
            borderRadius: formData.style.rounded.button
          }}
        >
          {
            defaultTexts[selectedLanguage as keyof typeof defaultTexts].buttons
              .sendRequest
          }
        </Button>
      </div>
    </div>
  )
}
