import i18next from 'i18next'
import { LanguagesTypes, LegislationsTypes } from 'util/enums'
import { FormData } from './types'
import defaultTexts from 'util/defaultTexts/requestPageTexts.json'

export const initialData: FormData = {
  name: '',
  dpoUserId: '',
  languages: {
    fallback:
      i18next.language === 'en'
        ? LanguagesTypes.English
        : LanguagesTypes.Portuguese,
    languages: Object.values(LanguagesTypes)
  },
  texts: Object.entries(defaultTexts).map(([key, value]) => ({
    language: key,
    title: value.titles.pageName,
    introduction: value.bodyTexts.optout,
    docsTitle: value.titles.documents
  })),
  documents: {
    privacyMasterId: '',
    termsMasterId: '',
    cookiesMasterId: ''
  },
  extraFields: ['name', 'country'],
  requests: Object.values(LegislationsTypes).map(legislation => ({
    legislation,
    requests: ['dataCorrection', 'dataAccess']
  })),
  style: {
    logo: '',
    colors: {
      background: '#FFFFFF',
      form: '#21262D',
      title: '#00DD80',
      texts: '#FAFBFC',
      button: '#00DD80',
      buttonText: '#0D1117'
    },
    fonts: {
      title: {
        family: 'nunito',
        size: 22,
        lineHeight: 24
      },
      texts: {
        family: 'nunito',
        size: 16,
        lineHeight: 18
      },
      button: {
        family: 'nunito',
        size: 14,
        lineHeight: 24
      }
    },
    margins: {
      horizontally: 20,
      vertically: 20
    },
    rounded: {
      banner: 16,
      button: 6
    }
  }
}