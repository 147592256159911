import { useTour } from '@reactour/tour'
import { BodyText, Button, ButtonType, H1 } from 'components'
import { useTranslation } from 'next-i18next'
import { TourStepper } from './stepper'

interface TourIntroProps {
  title: string
  description: string
  withTourStep?: boolean
}

export function TourIntro({
  title,
  description,
  withTourStep = false
}: TourIntroProps) {
  const { setCurrentStep, setIsOpen } = useTour()
  const { t } = useTranslation()

  return (
    <div className="relative">
      <div className='flex items-center justify-between mb-2'>
        <H1 className="font-bold">{title}</H1>
        {withTourStep && (
          <Button
            onClick={() => setIsOpen(false)}
            buttonType={ButtonType.Tertiary}
          >
            {t('tourSystem.skip')}
          </Button>
        )}
      </div>
      <BodyText className="mb-10 whitespace-pre-line">{description}</BodyText>

      {withTourStep ? (
        <TourStepper />
      ) : (
        <div className="flex justify-center gap-4">
          <Button
            onClick={() => setIsOpen(false)}
            buttonType={ButtonType.Secondary}
          >
            {t('tourSystem.skipTutorial')}
          </Button>
          <Button
            buttonType={ButtonType.Primary}
            onClick={() => setCurrentStep(1)}
          >
            {t('tourSystem.start')}
          </Button>
        </div>
      )}
    </div>
  )
}
