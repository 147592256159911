import React from 'react'
import { classNames } from 'util/shared'

export function ProgressBar({ progress = 0 }: any) {
  return (
    <div className="w-full h-3 rounded-full bg-white-200 dark:bg-gray relative">
      <div
        style={{ width: `${progress}%` }}
        className={classNames(
          'duration-700 absolute min-w-xs max-w-100 rounded-full left-0 top-0 w-20 h-3 ',
          progress <= 100 ? 'bg-primary' : 'bg-pink'
        )}
      ></div>
    </div>
  )
}
