import React from 'react'
import { Switch } from '@headlessui/react'
import { classNames } from 'util/shared'

interface ToggleSwitchProps {
  state: boolean
  setState: () => void
  disabled?: boolean
  className?: string
}

export function ToggleSwitch({
  state,
  setState,
  disabled = false,
  className
}: ToggleSwitchProps) {
  return (
    <Switch.Group as="div" className="flex">
      <Switch
        checked={state}
        onChange={() => {
          if (disabled === false) {
            setState()
          }
        }}
        className={classNames(
          disabled ? 'cursor-default' : 'cursor-pointer',
          state
            ? 'bg-primary-500 border-primary-500 dark:border-primary-500'
            : 'bg-gray-400 dark:bg-gray border-gray-400 dark:border-gray',
          'relative inline-flex flex-shrink-0 h-6 w-11 border-2 rounded-full transition-colors ease-in-out duration-200 focus:outline-none',
          className
        )}
      >
        <span className="sr-only">Use setting</span>
        <span
          aria-hidden="true"
          className={classNames(
            state ? 'translate-x-5' : 'translate-x-0',
            'inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
          )}
        />
      </Switch>
    </Switch.Group>
  )
}
