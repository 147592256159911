import {
  UploadImageInput,
  ColorInput,
  FontFamilySelect,
  PixelsInput
} from 'components'
import { H2, SmallText } from 'components/shared/typography'
import { useTranslation } from 'next-i18next'
import { getOnlyNumber } from 'util/format'
import { AccordionContentProps } from '../types'

export const CustomizationContent = ({
  formData,
  setFormData
}: AccordionContentProps) => {
  const { t } = useTranslation()
  // const [croppedImage, setCroppedImage] = useState<string | null>(null)

  const updateStyle = (path: (string | number)[], value: any) => {
    setFormData(prevState => {
      const newState = { ...prevState }
      let current: any = newState

      for (let i = 0; i < path.length - 1; i++) {
        current = current[path[i]]
      }

      current[path[path.length - 1]] = value

      return newState
    })
  }

  const handleUploadImageInput = (croppedImage: string | null) => {
    if (!croppedImage) return

    setFormData(prevState => ({
      ...prevState,
      style: {
        ...prevState.style,
        logo: croppedImage
      }
    }))
  }

  console.log(formData)

  return (
    <div className="flex flex-col gap-4 p-5 w-full">
      <H2>{t('createRequestPage.accordions.customization.logo')}</H2>
      <UploadImageInput
        // croppedImage={formData.style.logo}
        onSave={handleUploadImageInput}
      />
      <SmallText className="dark:!text-gray-250">
        {t('createRequestPage.accordions.customization.logoLabel')}
      </SmallText>

      <div className="flex gap-4 flex-wrap">
        <ColorInput
          label={t(
            'createRequestPage.accordions.customization.backgroundInput'
          )}
          value={formData.style.colors.background}
          onChange={value =>
            updateStyle(['style', 'colors', 'background'], value)
          }
        />
        <ColorInput
          label={t('createRequestPage.accordions.customization.formInput')}
          value={formData.style.colors.form}
          onChange={value => updateStyle(['style', 'colors', 'form'], value)}
        />
        <ColorInput
          label={t('createRequestPage.accordions.customization.titleInput')}
          value={formData.style.colors.title}
          onChange={value => updateStyle(['style', 'colors', 'title'], value)}
        />
        <ColorInput
          label={t('createRequestPage.accordions.customization.textInput')}
          value={formData.style.colors.texts}
          onChange={value => updateStyle(['style', 'colors', 'texts'], value)}
        />
        <ColorInput
          label={t(
            'createRequestPage.accordions.customization.buttonAndLinksInput'
          )}
          value={formData.style.colors.button}
          onChange={value => updateStyle(['style', 'colors', 'button'], value)}
        />
        <ColorInput
          label={t(
            'createRequestPage.accordions.customization.buttonTextInput'
          )}
          value={formData.style.colors.buttonText}
          onChange={value =>
            updateStyle(['style', 'colors', 'buttonText'], value)
          }
        />
      </div>

      <div className="flex flex-col gap-4">
        <div className="grid grid-cols-4 gap-4">
          <div className="col-span-2">
            <FontFamilySelect
              label={t(
                'createRequestPage.accordions.customization.titleSelect'
              )}
              value={formData.style.fonts.title.family}
              onChange={selected =>
                updateStyle(
                  ['style', 'fonts', 'title', 'family'],
                  selected.value
                )
              }
            />
          </div>
          <PixelsInput
            label={t('createRequestPage.accordions.customization.sizeInput')}
            value={formData.style.fonts.title.size}
            onChange={value =>
              updateStyle(
                ['style', 'fonts', 'title', 'size'],
                getOnlyNumber(value)
              )
            }
          />
          <PixelsInput
            label={t(
              'createRequestPage.accordions.customization.lineHeightInput'
            )}
            value={formData.style.fonts.title.lineHeight}
            onChange={value =>
              updateStyle(
                ['style', 'fonts', 'title', 'lineHeight'],
                getOnlyNumber(value)
              )
            }
          />
        </div>
        <div className="grid grid-cols-4 gap-4">
          <div className="col-span-2">
            <FontFamilySelect
              label={t(
                'createRequestPage.accordions.customization.textAndLinksSelect'
              )}
              value={formData.style.fonts.texts.family}
              onChange={selected =>
                updateStyle(
                  ['style', 'fonts', 'texts', 'family'],
                  selected.value
                )
              }
            />
          </div>
          <PixelsInput
            label={t('createRequestPage.accordions.customization.sizeInput')}
            value={formData.style.fonts.texts.size}
            onChange={value =>
              updateStyle(
                ['style', 'fonts', 'texts', 'size'],
                getOnlyNumber(value)
              )
            }
          />
          <PixelsInput
            label={t(
              'createRequestPage.accordions.customization.lineHeightInput'
            )}
            value={formData.style.fonts.texts.lineHeight}
            onChange={value =>
              updateStyle(
                ['style', 'fonts', 'texts', 'lineHeight'],
                getOnlyNumber(value)
              )
            }
          />
        </div>
        <div className="grid grid-cols-4 gap-4">
          <div className="col-span-2">
            <FontFamilySelect
              label={t(
                'createRequestPage.accordions.customization.fontFamilySelect'
              )}
              value={formData.style.fonts.button.family}
              onChange={selected =>
                updateStyle(
                  ['style', 'fonts', 'button', 'family'],
                  selected.value
                )
              }
            />
          </div>
          <PixelsInput
            label={t('createRequestPage.accordions.customization.sizeInput')}
            value={formData.style.fonts.button.size}
            onChange={value =>
              updateStyle(
                ['style', 'fonts', 'button', 'size'],
                getOnlyNumber(value)
              )
            }
          />
          <PixelsInput
            label={t(
              'createRequestPage.accordions.customization.lineHeightInput'
            )}
            value={formData.style.fonts.button.lineHeight}
            onChange={value =>
              updateStyle(
                ['style', 'fonts', 'button', 'lineHeight'],
                getOnlyNumber(value)
              )
            }
          />
        </div>
      </div>

      <div className="grid grid-cols-2 gap-4">
        <div>
          <H2>{t('createRequestPage.accordions.customization.margins')}</H2>
          <div className="flex justify-between items-center gap-4">
            <PixelsInput
              label={t(
                'createRequestPage.accordions.customization.horizontally'
              )}
              value={formData.style.margins.horizontally}
              onChange={value =>
                updateStyle(
                  ['style', 'margins', 'horizontally'],
                  getOnlyNumber(value)
                )
              }
            />
            <PixelsInput
              label={t('createRequestPage.accordions.customization.vertically')}
              value={formData.style.margins.vertically}
              onChange={value =>
                updateStyle(
                  ['style', 'margins', 'vertically'],
                  getOnlyNumber(value)
                )
              }
            />
          </div>
        </div>
        <div>
          <H2>{t('createRequestPage.accordions.customization.rounded')}</H2>
          <div className="flex justify-between items-center gap-4">
            <PixelsInput
              label={t('createRequestPage.accordions.customization.formInput')}
              value={formData.style.rounded.banner}
              onChange={value =>
                updateStyle(
                  ['style', 'rounded', 'banner'],
                  getOnlyNumber(value)
                )
              }
            />
            <PixelsInput
              label={t(
                'createRequestPage.accordions.customization.buttonInput'
              )}
              value={formData.style.rounded.button}
              onChange={value =>
                updateStyle(
                  ['style', 'rounded', 'button'],
                  getOnlyNumber(value)
                )
              }
            />
          </div>
        </div>
      </div>
    </div>
  )
}
