import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { motion } from 'framer-motion'
import { fadeIn } from 'public/animation/motion'
import { classNames } from 'util/shared'
import { PlanCard } from './plan-card'
import { PlanRecurring, PlanType } from 'models/plans'
import { useQuery } from '@apollo/client'
import { GET_USER_PLAN } from 'queries/user'
import { useAuth } from 'contexts/auth-context'

export function PlansSection() {
  const { t } = useTranslation()
  const { user } = useAuth()
  const [recurring, setRecurring] = useState(PlanRecurring.Month)
  const { data: userPlanData } = useQuery(GET_USER_PLAN, {
    variables: {
      email: user?.email
    }
  })

  const userPlan = useMemo(() => {
    return userPlanData?.users?.[0]?.subscription?.subscription_plan
  }, [userPlanData])

  const plans = {
    month: [
      {
        price: 0,
        recurring: PlanRecurring.Month,
        planType: PlanType.Starter,
        featuresLength: 13
      },
      {
        price: 15,
        recurring: PlanRecurring.Month,
        planType: PlanType.Business,
        featuresLength: 12
      },
      {
        price: 75,
        recurring: PlanRecurring.Month,
        planType: PlanType.Enterprise,
        featuresLength: 7
      }
    ],
    year: [
      {
        price: 0,
        recurring: PlanRecurring.Year,
        planType: PlanType.Starter,
        featuresLength: 13
      },
      {
        price: 12,
        recurring: PlanRecurring.Year,
        planType: PlanType.Business,
        featuresLength: 12,
        off: 20
      },
      {
        price: 55,
        recurring: PlanRecurring.Year,
        planType: PlanType.Enterprise,
        featuresLength: 7,
        off: 26
      }
    ]
  }

  return (
    <motion.div initial="initial" animate="animate" variants={fadeIn()}>
      <div className="px-10">
        <div>
          <div>
            <div className="font-bold dark:text-white text-3xl text-center mx-auto">
              {t('plans.period.title')}
            </div>
            <div className="flex w-48 h-10 mt-4 mx-auto  items-center relative cursor-pointer bg-white-200  dark:bg-gray rounded-md overflow-hidden">
              <div
                className={classNames(
                  'absolute h-full bg-primary top-0 w-24  duration-700 rounded-md',
                  recurring === PlanRecurring.Month ? 'left-0' : 'left-24'
                )}
              ></div>
              <div
                onClick={() => setRecurring(PlanRecurring.Month)}
                className={classNames(
                  'w-24  relative z-10 text-center',
                  recurring === PlanRecurring.Month ? null : 'dark:text-white'
                )}
              >
                {t('plans.period.month')}
              </div>
              <div
                onClick={() => setRecurring(PlanRecurring.Year)}
                className={classNames(
                  'w-24  relative z-10 text-center',
                  recurring === PlanRecurring.Month ? 'dark:text-white' : null
                )}
              >
                {t('plans.period.year')}
              </div>
            </div>
            <div className=" mt-4 font-bold dark:text-white  text-center mx-auto">
              {t('plans.period.description')}
            </div>
          </div>
        </div>
      </div>
      <div className="mt-10 px-10 grid gap-x-2 gap-y-4 grid-cols-1 md:grid-cols-3 ">
        {plans[recurring].map((planData, index) => {
          return (
            <PlanCard key={index} userPlan={userPlan} planData={planData} />
          )
        })}
      </div>
    </motion.div>
  )
}
