import { motion } from 'framer-motion'
import { ModalHeader, ModalHeaderProps } from 'components'
import { modalAnimation } from 'public/animation/motion'
import { classNames } from 'util/shared'

interface ModalWrapperProps {
  modalOpen: boolean
  setModalOpen: (status: boolean) => void
  children: React.ReactNode
  headerProps?: Omit<ModalHeaderProps, 'setModalOpen'>
  classes?: ModalClasses
  modalSize?: 'small' | 'medium' | 'large' | 'auto'
}

const modalSizeMap = {
  auto: '',
  small: 'max-w-500 w-3/4 ',
  medium: 'max-w-600 w-3/4 ',
  large: 'max-w-[1250px] w-3/4 '
}

interface ModalClasses {
  modalWrapper?: string
}

export function ModalWrapper({
  modalOpen,
  setModalOpen,
  children,
  headerProps,
  classes,
  modalSize = 'small'
}: ModalWrapperProps) {
  return (
    <>
      {modalOpen && (
        <div className="fixed z-50 top-0 left-0 bg-opacity-90 w-screen h-screen bg-black flex items-center justify-center">
          <motion.div
            initial="initial"
            animate="animate"
            variants={modalAnimation}
            className={classNames(
              'z-50 dark:text-white rounded-md  py-8 px-10 bg-white dark:bg-gray',
              modalSizeMap[modalSize],
              classes?.modalWrapper
            )}
          >
            {headerProps && (
              <ModalHeader
                icon={headerProps.icon}
                title={headerProps.title}
                subtitle={headerProps.subtitle}
                setModalOpen={setModalOpen}
              ></ModalHeader>
            )}
            {children}
          </motion.div>
        </div>
      )}
    </>
  )
}
