import { onError } from '@apollo/client/link/error'
import ApolloClient from 'apollo-client'
import { WebSocketLink } from 'apollo-link-ws'
import { from, split } from 'apollo-link'
import { getMainDefinition } from 'apollo-utilities'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { setContext } from '@apollo/client/link/context'
import { HttpLink } from '@apollo/client'

export function handleClient() {
  // ========= Getting headers ===========
  const getHeaders = () => {
    const adoptlang = localStorage.getItem('i18nextLng')
    const headers = {} as any
    if (typeof window !== 'undefined') {
      const token = window.localStorage.getItem('adopt-token')
      if (token) {
        headers.authorization = `Bearer ${token}`
      } else {
        headers.authorization = ''
      }
    }

    return {
      headers: {
        ...headers,
        'adopt-lang': adoptlang
      }
    }
  }

  // ======== Error ===========

  const errorLink = onError(({ graphQLErrors }) => {
    if (graphQLErrors) {
      for (const err of graphQLErrors) {
        switch (err?.extensions?.code) {
          case 'invalid-jwt':
            if (typeof window !== 'undefined') {
              window.localStorage.removeItem('adopt-token')
              window.location.href = '/login'
            }
            return
          case 'invalid-headers':
            if (typeof window !== 'undefined') {
              window.localStorage.removeItem('adopt-token')
              window.location.href = '/login'
            }
            return
          case 'UNAUTHENTICATED':
            return
        }
      }
    }
  })

  // ======== Links ===========

  const httpLink = new HttpLink({
    uri: operation =>
      `https://${process.env.HASURA_BASE_URL}?${operation.operationName}`
  })

  const authLink = setContext((_, { headers }) => {
    const token = localStorage.getItem('adopt-token')
    const adoptlang = localStorage.getItem('i18nextLng')

    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
        'adopt-lang': adoptlang
      }
    }
  })

  const httpLinkConcat = authLink.concat(httpLink)

  const wsLink = process.browser
    ? new WebSocketLink({
        uri: `wss://${process.env.HASURA_BASE_URL}`,
        options: {
          lazy: true,
          reconnect: true,
          connectionParams: getHeaders()
        }
      })
    : null

  const link = process.browser
    ? split(
        ({ query }) => {
          const { kind, operation }: any = getMainDefinition(query)
          return kind === 'OperationDefinition' && operation === 'subscription'
        },
        wsLink as any,
        httpLinkConcat as any
      )
    : httpLinkConcat

  // ======== Client ===========

  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: from([errorLink as any, link]),
    cache: new InMemoryCache()
  }) as any
}
