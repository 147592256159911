import { motion } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import { fadeIn, modalAnimation } from 'public/animation/motion'
import { RiChat4Line } from 'react-icons/ri'
import { useTranslation } from 'react-i18next'
import { classNames } from 'util/shared'
import { IoClose } from 'react-icons/io5'
import { formatUrl } from 'util/format'
import {
  Button,
  ButtonType,
  Input,
  ModalHeader,
  UpgradeModal
} from 'components'
import { Validator } from 'util/validator'
import { useHandleError } from 'hooks/useHandleError'
import { useStorage } from 'contexts/storage-context'
import { toast } from 'react-hot-toast'
import { useMutation } from '@apollo/client'
import { EDIT_DISCLAIMER } from 'queries/disclaimers'

const defaultData = {
  pathName: '',
  disclaimerId: '',
  urls: [] as Array<string>
}

interface EditDisclaimerModalProps {
  open: boolean
  handleClose: () => void
  disclaimerData: any
  handleEdit: (newPathname: string) => void
}

export function EditDisclaimerModal({
  open,
  handleClose,
  disclaimerData,
  handleEdit
}: EditDisclaimerModalProps) {
  const [editDisclaimer] = useMutation(EDIT_DISCLAIMER)
  const [upgradeModalOpen, setUpgradeModalOpen] = useState(false)

  const [nameError, setNameError] = useState('')
  const [urlError, setUrlError] = useState('')
  const [url, setUrl] = useState<string>('')
  const [data, setData] = useState(defaultData)

  const [moreThanOne, setMoreThanOne] = useState(false)
  const { t } = useTranslation()
  const { handleError } = useHandleError()

  const { activeOrganizationDetails } = useStorage()
  const domains = activeOrganizationDetails?.plan?.domains ?? 1

  useEffect(() => {
    if (disclaimerData) {
      setData(disclaimerData)
      setMoreThanOne(false)
      setNameError('')
      if (disclaimerData.urls && disclaimerData.urls.length > 1) {
        setMoreThanOne(true)
      }
    }
  }, [disclaimerData])

  function isValidLink() {
    let valid = true
    if (data.urls.length === domains) {
      setUpgradeModalOpen(true)
      valid = false
    }

    if (Validator.isValidUrl(encodeURI(url)) === false) {
      handleError(t('modal.createDisclaimer.Type a valid URL'))
      valid = false
    }

    return valid
  }

  function handleAddLink() {
    if (isValidLink()) {
      const prev = data
      setData({ ...prev, urls: [url, ...data.urls] })
      setUrl('')
      if (prev.urls.length === 1 && !moreThanOne) {
        setMoreThanOne(true)
      }
    }
  }

  function isValid() {
    let valid = true
    if (data.pathName.length === 0) {
      setNameError(t('modal.createDisclaimer.Type a name for your disclaimer'))
      valid = false
    }
    if (data.urls.length === 0) {
      setUrlError(`* ${t('modal.createDisclaimer.Add at least 1 site')}`)
      valid = false
    }

    return valid
  }

  async function handleSave() {
    if (isValid()) {
      toast.loading(t('org.disclaimers.Saving'))
      try {
        const result = await editDisclaimer({
          variables: data
        })
        toast.dismiss()

        if (result?.data?.edit_disclaimer?.success) {
          handleEdit(data.pathName)
          toast.success(`${t('org.disclaimers.Disclaimer updated')}!`)
          handleClose()
        } else {
          handleError(result?.data?.edit_disclaimer?.message)
        }
      } catch (error: any) {
        toast.dismiss()
        if (error.message.includes('disclaimers_pathname_key')) {
          setNameError(t('org.disclaimers.This name is already in use'))
        } else {
          handleError()
        }
      }
    }
  }

  return (
    <>
      {open ? (
        <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-screen h-screen bg-black bg-opacity-90">
          <motion.div
            initial="initial"
            animate="animate"
            variants={modalAnimation}
            className="z-50 w-11/12 px-8 py-6 bg-white rounded-md dark:text-white max-w-600 dark:bg-gray"
          >
            <ModalHeader
              icon={<RiChat4Line className="text-primary" size={35} />}
              title={t('modal.createDisclaimer.Create disclaimer')}
              subtitle={t(
                'modal.createDisclaimer.Select on how many sites or URL this disclaimer will be used'
              )}
              setModalOpen={handleClose}
            ></ModalHeader>
            <Input
              label={t('modal.createDisclaimer.Name')}
              placeholder={t(
                'modal.createDisclaimer.Type a name for your disclaimer'
              )}
              value={data.pathName}
              dis
              fieldError={nameError}
              events={{
                onChange: (e: any) => {
                  setNameError('')
                  setData(prev => ({ ...prev, pathName: formatUrl(e.value) }))
                }
              }}
            />
            <div className="flex mt-2">
              <div
                onClick={() => {
                  if (data.urls.length < 2) {
                    setMoreThanOne(false)
                  }
                }}
                className={classNames(
                  'cursor-pointer  duration-200 border  rounded flex-1 mr-1 p-2 border-gray-200',
                  !moreThanOne ? 'opacity-100' : 'opacity-40 hover:opacity-100 '
                )}
              >
                <div className="flex items-center">
                  <div
                    className={classNames(
                      'border  rounded-full w-4 h-4 flex items-center justify-center',
                      !moreThanOne ? 'border-primary' : 'border-gray-200 '
                    )}
                  >
                    {!moreThanOne ? (
                      <div className="w-2 h-2 rounded-full bg-primary"></div>
                    ) : null}
                  </div>
                  <div className="ml-2 font-bold text-primary">
                    {t('modal.createDisclaimer.1 site')}
                  </div>
                </div>
                <div>
                  {t(
                    'modal.createDisclaimer.The disclaimer will be responsible for only one site'
                  )}
                </div>
              </div>
              <div
                className={classNames(
                  'cursor-pointer  duration-200 border  rounded flex-1 ml-1 p-2 border-gray-200',
                  moreThanOne ? 'opacity-100' : 'opacity-40 hover:opacity-100 '
                )}
              >
                <div
                  onClick={() => {
                    if (domains > 1) {
                      setMoreThanOne(true)
                    }
                  }}
                >
                  <div className="flex items-center">
                    <div
                      className={classNames(
                        'border  rounded-full w-4 h-4 flex items-center justify-center',
                        moreThanOne ? 'border-primary' : 'border-gray-200 '
                      )}
                    >
                      {moreThanOne ? (
                        <div className="w-2 h-2 rounded-full bg-primary"></div>
                      ) : null}
                    </div>
                    <div className="ml-2 font-bold text-primary">
                      {t('modal.createDisclaimer.2 or more sites')}
                    </div>
                  </div>
                  <div>
                    {t(
                      'modal.createDisclaimer.The disclaimer will be responsible for one or more sites'
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div
              className={classNames(
                'border  rounded p-2 mt-2 overflow-y-scroll scroll-1  h-32 border-gray-200'
              )}
            >
              <div className="mb-2 font-bold">Url</div>
              <div
                className={classNames(
                  'flex items-center border rounded  p-1',
                  urlError.length !== 0 ? 'border-pink' : 'border-gray-200'
                )}
              >
                <input
                  type="text"
                  value={url}
                  onChange={e => {
                    setUrl(e.target.value)
                    setUrlError('')
                  }}
                  className="w-full bg-white focus:outline-none dark:bg-gray"
                />
                <Button onClick={handleAddLink}>
                  {t('modal.createDisclaimer.addButton')}
                </Button>
              </div>
              {urlError.length !== 0 ? (
                <motion.div
                  initial="initial"
                  animate="animate"
                  variants={fadeIn()}
                >
                  {urlError}
                </motion.div>
              ) : null}
              <div>
                {data.urls?.map((item: any, index: any) => {
                  return (
                    <div
                      className="flex items-center justify-between px-2 py-1 mt-1 border border-gray-200 rounded"
                      key={index}
                    >
                      <div>{item}</div>
                      <button
                        onClick={() =>
                          setData(prev => {
                            return {
                              ...prev,
                              urls: prev.urls.filter((element: any) => {
                                return element !== item
                              })
                            }
                          })
                        }
                      >
                        <IoClose />
                      </button>
                    </div>
                  )
                })}
              </div>
            </div>

            <div className="flex justify-end gap-3 mt-5">
              <Button
                buttonType={ButtonType.Secondary}
                onClick={handleClose}
                className="dark:bg-black"
              >
                {t('modal.createDisclaimer.Cancel')}
              </Button>
              <Button onClick={handleSave}>
                {t('modal.createDisclaimer.Save')}
              </Button>
            </div>
          </motion.div>
        </div>
      ) : null}
      <UpgradeModal
        modalOpen={upgradeModalOpen}
        setModalOpen={setUpgradeModalOpen}
      />
    </>
  )
}
