import React from 'react'
import { BsImageAlt } from 'react-icons/bs'
import {
  HiOutlineThumbUp,
  HiOutlineThumbDown,
  HiOutlineClipboardCheck
} from 'react-icons/hi'
import { DiCode } from 'react-icons/di'
import { RiChatCheckLine } from 'react-icons/ri'
import { CgFileDocument } from 'react-icons/cg'
import { VscKey } from 'react-icons/vsc'
import { Tooltip } from 'components'
import { classNames } from 'util/shared'
import { useTranslation } from 'react-i18next'
import { IoExitOutline } from 'react-icons/io5'

export function FakeDisclaimerCard() {
  const { t } = useTranslation()

  const items = [
    {
      configs: [
        {
          icon: <RiChatCheckLine size={16} />,
          name: t('org.disclaimers.Style'),
          tooltip: t('org.disclaimers.Style-Tooltip'),
          pathname: 'style',
          configured: false
        }
      ]
    },

    {
      tourStep: 'documents-step',
      configs: [
        {
          icon: <HiOutlineClipboardCheck size={18} />,
          name: t('org.disclaimers.Policy'),
          tooltip: t('org.disclaimers.Policy-Tooltip'),
          pathname: 'policy',
          configured: true
        },
        {
          icon: <CgFileDocument size={18} />,
          name: t('org.disclaimers.Terms'),
          tooltip: t('org.disclaimers.Terms-Tooltip'),
          pathname: 'terms',
          configured: false
        },
        {
          icon: <IoExitOutline size={18} />,
          name: t('org.disclaimers.OptOut'),
          tooltip: t('org.disclaimers.OptOut-Tooltip'),
          pathname: 'opt-out',
          configured: false
        }
      ]
    },

    {
      tourStep: 'tag-step',
      configs: [
        {
          icon: <VscKey size={18} />,
          name: t('org.disclaimers.Code'),
          tooltip: t('org.disclaimers.Code-Tooltip'),
          pathname: 'installation',
          configured: false
        }
      ]
    },
    {
      tourStep: 'scan-step',
      configs: [
        {
          icon: <DiCode size={24} />,
          name: t('org.disclaimers.Scan'),
          tooltip: t('org.disclaimers.Scan-Tooltip'),
          pathname: 'tags',
          configured: false
        }
      ]
    }
  ]
  return (
    <div
      data-tour="intro-step"
      className="w-full max-w-100 
      lg:max-w-49 lg:w-2/4 lg:min-w-49 
      border py-3 px-3 mb-5 rounded-lg dark:border-gray-400 border-gray-200 hover:border-primary duration-200 flex-1"
    >
      <div className="flex justify-between  items-center ">
        <div className="flex-1">
          <div className="flex justify-between ">
            <div className="flex items-center flex-1">
              <div className="mr-2 w-9 h-9 rounded-full border border-gray-200 dark:bg-gray text-primary-400 flex items-center justify-center">
                <BsImageAlt />
              </div>
              <div>
                <div className="bg-white-200 dark:bg-gray rounded-sm w-32 h-4 mb-2"></div>
                <div className="bg-white-200 dark:bg-gray rounded-sm w-20 h-4"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div data-tour="initialConfiguration-step" className="flex mt-4">
        {items?.map((item, index) => {
          return (
            <div className="flex" data-tour={item.tourStep} key={index}>
              {item.configs.map((conf, index) => {
                return (
                  <div className={`step-${index}`} key={index}>
                    <Tooltip size="Auto" content={conf.tooltip}>
                      <div
                        data-delay-show="40"
                        key={index}
                        className="flex justify-center flex-wrap dark:text-white items-center cursor-pointer opacity-80 hover:opacity-100 duration-200"
                      >
                        <div
                          className={classNames(
                            'w-8 h-8  flex justify-center items-center rounded-full mr-1 ml-1',
                            conf.configured
                              ? 'bg-primary text-white'
                              : 'bg-transparent border border-pink text-pink'
                          )}
                        >
                          {conf.icon}
                        </div>
                        <div className="text-xs hidden truncate lg:block">
                          {conf.name}
                        </div>
                      </div>
                    </Tooltip>
                  </div>
                )
              })}
            </div>
          )
        })}
      </div>
      <div>
        <div className="flex items-center mt-6">
          <div className="text-primary-400 flex items-center">
            <HiOutlineThumbUp className="" />
            <span className="mx-1 text-gray-200">Opt-in</span>
            <div className="bg-white-200 dark:bg-gray rounded-sm w-8 h-4"></div>
          </div>
          <div className="ml-6 text-primary-400 flex items-center">
            <HiOutlineThumbDown style={{ color: '#EE447B' }} />
            <span className="mx-1 text-gray-200">Opt-out</span>
            <div className="bg-white-200 dark:bg-gray rounded-sm w-8 h-4"></div>
          </div>
        </div>
      </div>
    </div>
  )
}
