import i18next from 'i18next'
import {
  PillButton,
  PillButtonType
} from 'components/shared/buttons/pill-button'
import { LegislationSelect } from 'components/shared/legislation-select'
import { Link } from 'components/shared/link'
import { H2, SmallText } from 'components/shared/typography'
import { useTranslation } from 'next-i18next'
import { useState } from 'react'
import { IoIosHelpCircleOutline } from 'react-icons/io'
import { RequestTypesModal } from '../request-types-modal'
import { AccordionContentProps } from '../types'
import defaultTexts from 'util/defaultTexts/requestPageTexts.json'

interface LegislationContentProps extends AccordionContentProps {
  selectedLegislation: string
  setSelectedLegislation: React.Dispatch<React.SetStateAction<string>>
}

export const LegislationContent = ({
  formData,
  setFormData,
  selectedLegislation,
  setSelectedLegislation
}: LegislationContentProps) => {
  const { t } = useTranslation()

  const [modalOpen, setModalOpen] = useState(false)

  const requests =
    i18next.language === 'en'
      ? Object.keys(defaultTexts.en.requestTypes)
      : Object.keys(defaultTexts.pt.requestTypes)

  const isRequestInList = (requests: string[], request: string) => {
    return requests.includes(request)
  }

  const addRequest = (requests: string[], request: string) => {
    return [...requests, request]
  }

  const removeRequest = (requests: string[], request: string) => {
    return requests.filter(r => r !== request)
  }

  function handleRequestsClick(request: string) {
    setFormData(prevData => ({
      ...prevData,
      requests: prevData.requests.map(req =>
        req.legislation === selectedLegislation
          ? {
              ...req,
              requests: isRequestInList(req.requests, request)
                ? removeRequest(req.requests, request)
                : addRequest(req.requests, request)
            }
          : req
      )
    }))
  }

  return (
    <div className="flex flex-col gap-4 p-5 w-full">
      <div>
        <SmallText className="dark:!text-gray-250">
          {t('createRequestPage.accordions.legislation.section')}
        </SmallText>
        <Link
          href="https://see-more.com.br"
          className="text-primary text-sm"
          target="_blank"
        >
          {t('createRequestPage.accordions.legislation.clickHere')}
        </Link>
      </div>

      <LegislationSelect
        containerClasses={'!w-[220px]'}
        legislation={selectedLegislation}
        setLegislation={setSelectedLegislation}
      />

      <div className="flex gap-2 items-center">
        <H2>{t('createRequestPage.accordions.legislation.requestTypes')}</H2>
        <IoIosHelpCircleOutline
          size={18}
          className="text-gray-400 cursor-pointer"
          onClick={() => setModalOpen(true)}
        />
      </div>

      <div className="flex gap-3 items-center flex-wrap">
        {requests.map((req, index) => (
          <PillButton
            buttonType={
              formData.requests
                .find(req => req.legislation === selectedLegislation)
                ?.requests.includes(req)
                ? PillButtonType.Selected
                : PillButtonType.Default
            }
            key={index}
            onClick={() => handleRequestsClick(req)}
          >
            {t(`createRequestPage.accordions.legislation.${req}`)}
          </PillButton>
        ))}
      </div>

      {modalOpen && <RequestTypesModal {...{ modalOpen, setModalOpen }} />}
    </div>
  )
}
