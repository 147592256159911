import React, { ReactElement } from 'react'
import DotsLoading from 'components/shared/loading/dots-loading'
import { useAuth } from 'contexts/auth-context'
import { Guards, GuardType } from 'util/enums'
import { paths } from 'config/paths'
// import appConfig from 'config/app-config'
// import { useLocalStorage } from 'hooks/useLocalstorage'
// import { MaintenancePage } from 'components'

const isBrowser = () => typeof window !== 'undefined'

interface ProtectedRouteProps {
  guard: GuardType
  router: any
  children: ReactElement<any, any>
}

export default function ProtectedRoute({
  guard,
  router,
  children
}: ProtectedRouteProps) {
  // const [disableMaintenancePage] = useLocalStorage(
  //   'disableAdoptMaintenancePage',
  //   ''
  // )

  const { authenticated, loading } = useAuth()

  if (loading) {
    return <DotsLoading />
  }

  if (isBrowser() && !authenticated && guard === Guards.OnlyAuthorized) {
    router.push(paths.login)
    return <DotsLoading />
  }

  if (isBrowser() && authenticated && guard === Guards.OnlyUnauthorized) {
    router.push(paths.organizations)
    return <DotsLoading />
  }

  // if (
  //   appConfig.maintenance === true &&
  //   disableMaintenancePage != true &&
  //   router.pathname !== '/opt-out'
  // )
  //   return <MaintenancePage />

  return children
}
