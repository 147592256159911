import { motion } from 'framer-motion'
import React from 'react'
import { modalAnimation } from '../../public/animation/motion'
import { AiOutlineScan } from 'react-icons/ai'
import { useTranslation } from 'next-i18next'
import { BodyText, Button, SpinLoading, ModalHeader } from 'components'

export default function ScanAuthTestModal({
  testData,
  modalOpen,
  setModalOpen
}: any) {
  const { t } = useTranslation()
  return (
    <>
      {modalOpen ? (
        <div className="fixed z-50 top-0 left-0 bg-opacity-90 w-screen h-screen bg-black flex items-center justify-center">
          <motion.div
            initial="initial"
            animate="animate"
            variants={modalAnimation}
            className="z-50  rounded-md  w-3/4 max-w-[700px] py-10 px-10 bg-white dark:bg-gray"
          >
            <ModalHeader
              icon={<AiOutlineScan className="text-primary" size={35} />}
              title={t('scanAuthTestModal.title')}
              subtitle={t('scanAuthTestModal.subtitle')}
              setModalOpen={setModalOpen}
            ></ModalHeader>
            <div className="min-h-[40px]">
              {testData ? (
                <>
                  <img
                    className="max-w-[400px] mx-auto"
                    src={'data:image/jpeg;base64,' + testData?.data?.print}
                    alt="Print from scanner"
                  />
                  <BodyText className="mt-4 text-center">
                    <b
                      className={
                        testData?.success ? 'text-primary' : 'text-pink'
                      }
                    >
                      {t('scanAuthTestModal.result')}
                    </b>
                    {testData?.success
                      ? t('scanAuthTestModal.success')
                      : testData?.data?.message}
                  </BodyText>
                </>
              ) : (
                <div className="flex h-full justify-center gap-1 items-center">
                  <SpinLoading />
                  <BodyText>{t('scanAuthTestModal.scanning')}</BodyText>
                </div>
              )}
            </div>
            <div className="mt-6  flex justify-center">
              <Button
                className="px-10"
                onClick={() => {
                  setModalOpen(false)
                }}
              >
                Ok
              </Button>
            </div>
          </motion.div>
        </div>
      ) : null}
    </>
  )
}
