import { ChevronDownIcon } from '@heroicons/react/solid'
import { BodyText, H2 } from 'components/shared/typography'
import { ReactNode } from 'react'
import { classNames } from 'util/shared'

type TitleProps = {
  title: string
  subTitle: string
  icon: ReactNode
  isOpen: boolean
}

export const Title = ({ title, subTitle, icon, isOpen }: TitleProps) => (
  <div className="rounded-lg bg-white-200 dark:bg-gray py-2 px-4 flex items-center justify-between w-full">
    <div className="flex gap-4 items-center">
      <div className="text-primary rounded-full bg-white dark:bg-gray-500 flex items-center justify-center p-2">
        {icon}
      </div>
      <div>
        <H2 className="!text-primary">{title}</H2>
        <BodyText className="!text-gray-250">{subTitle}</BodyText>
      </div>
    </div>
    <ChevronDownIcon
      className={classNames(
        isOpen ? '-rotate-180' : 'rotate-0',
        'h-6 w-6 transform transition-all duration-500 ease-in-out text-gray-250 dark:text-white'
      )}
    />
  </div>
)
