import React from 'react'
import { LockClosedIcon } from '@heroicons/react/solid'
import { VscLoading } from 'react-icons/vsc'

export function LoadingButton({ loading, children }: any) {
  return (
    <button
      type="submit"
      className="group mt-12 relative w-full font-bold flex justify-center py-2 px-4  text-sm rounded-md text-white dark:text-black bg-primary hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-400"
    >
      <span className="absolute left-0 inset-y-0 flex items-center pl-3">
        {loading ? (
          <VscLoading className="animate-spin  dark:text-black" />
        ) : (
          <LockClosedIcon
            className="h-5 w-5 text-white dark:text-black"
            aria-hidden="true"
          />
        )}
      </span>
      {children}
    </button>
  )
}
