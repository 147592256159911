import React, { ReactNode } from 'react'
import { Button, ButtonType } from 'components'
import { ModalWrapper } from './modal-wrapper'

type ConfirmModalProps = {
  modalOpen: boolean
  setModalOpen: (open: boolean) => void
  title: string
  subtitle: string
  buttonText: string
  cancelText: string
  icon: ReactNode
  buttonAction: () => void
}

export default function ConfirmModal({
  modalOpen,
  setModalOpen,
  title,
  subtitle,
  buttonText,
  cancelText,
  icon,
  buttonAction
}: ConfirmModalProps) {
  return (
    <ModalWrapper
      modalSize="auto"
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
    >
      <div className="mb-4 flex justify-center">{icon}</div>
      <div className="text-lg dark:text-white text-center font-bold">
        {title}
      </div>
      <div className="text-md dark:text-gray-300 text-center ">{subtitle}</div>
      <div className="mt-6 gap-3 flex justify-center">
        <Button
          buttonType={ButtonType.Outline}
          onClick={() => setModalOpen(false)}
        >
          {cancelText}
        </Button>
        <Button buttonType={ButtonType.Secondary} onClick={buttonAction}>
          {buttonText}
        </Button>
      </div>
    </ModalWrapper>
  )
}
