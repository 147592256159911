import { useMutation, useQuery } from '@apollo/client'

import { useStorage } from 'contexts/storage-context'
import { ADD_EDIT_REQUESTS_PAGE, GET_DPO_USERS, GET_REQUEST_PAGE_BY_ID } from 'queries/privacyPage'
import { FormData } from 'components/pages/request-page/types'
import { useDocuments } from './useDocuments'
import { RawListDocumentProps } from 'models/documents'
import { DocumentTypes } from 'components/shared/document-management'
import { documentTypes } from 'pages/org/[organizationPathname]/documents'

interface FormDataParam extends Omit<FormData, 'style'> {
  style: string
}

type DpoUsersList = {
  name: string
  value: string
}

export const useRequestPage = (requestPageId?: string) => {
  const { activeOrganizationDetails } = useStorage()
  const { listDocuments, listDocumentsLoading } = useDocuments({})

  const [addEditRequestsPageMutation] = useMutation(ADD_EDIT_REQUESTS_PAGE)

  const { data: dpoUsersList, loading: dpoUsersLoading } = useQuery(GET_DPO_USERS, {
    skip: !activeOrganizationDetails?.id,
    fetchPolicy: 'cache-first',
    variables: {
      organizationId: activeOrganizationDetails?.id
    }
  })

  const { data: requestPageById, loading: requestPageByIdLoading } = useQuery(GET_REQUEST_PAGE_BY_ID, {
    skip: !activeOrganizationDetails?.id || !requestPageId,
    fetchPolicy: 'network-only',
    variables: {
      organizationId: activeOrganizationDetails?.id,
      id: requestPageId
    }
  })

  async function addEditRequestsPage(
    options: FormDataParam,
    disclaimerId?: string,
    requestsPageId?: string
  ) {
    const result = await addEditRequestsPageMutation({
      variables: {
        organizationId: activeOrganizationDetails?.id,
        disclaimerId,
        requestsPageId,
        ...options
      }
    })
    return result?.data?.add_edit_requests_pages
  }

  function formatDpoUsersList(data: any): DpoUsersList[] {
    if (dpoUsersLoading || !data) {
      return [{
        name: '',
        value: ''
      }]
    }

    const { user_organization } = data?.organization[0] ?? {}
    const usersList = []
    for (const { users } of user_organization) {
      usersList.push(users)
    }
    return usersList?.map(user => ({
      name: user.email,
      value: user.id
    }))
  }

  function formatListDocuments(
    data: RawListDocumentProps | undefined,
    documentType: DocumentTypes
  ) {
    if (listDocumentsLoading) return

    const formattedData = data?.document_master
      ?.map(document => {
        return {
          id: document.id,
          document_type: document.document_type,
          document_name:
            document[documentTypes[document.document_type]]?.[0]?.document_name
        }
      })
      .filter(({ document_type }) => document_type === documentType)

    return formattedData ?? []
  }

  const privacyDocuments = formatListDocuments(listDocuments, 'privacy')?.map(
    ({ id, document_name }) => ({
      name: document_name,
      value: id
    })
  )
  const cookieDocuments = formatListDocuments(listDocuments, 'cookies')?.map(
    ({ id, document_name }) => ({
      name: document_name,
      value: id
    })
  )
  const termDocuments = formatListDocuments(listDocuments, 'terms')?.map(
    ({ id, document_name }) => ({
      name: document_name,
      value: id
    })
  )

  const loading = dpoUsersLoading || requestPageByIdLoading || listDocumentsLoading

  return { dpoUsers: formatDpoUsersList(dpoUsersList), privacyDocuments, cookieDocuments, termDocuments, requestPageById, loading, addEditRequestsPage }
}
