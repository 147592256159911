import React from 'react'
import { useTranslation } from 'next-i18next'
import { Tooltip } from '../tooltip'
import { useRouter } from 'next/router'
import { paths } from 'config/paths'
import { PiStarFourFill } from 'react-icons/pi'

interface UpgradeButtonProps {
  plan: 'Business' | 'Enterprise'
  type: 'Long' | 'Small'
  className?: string
}

enum Plan {
  Business = 'Business',
  Enterprise = 'Enterprise'
}

enum ButtonType {
  Long = 'Long',
  Small = 'Small'
}

export function UpgradeButton({
  plan,
  type = ButtonType.Small,
  className
}: UpgradeButtonProps) {
  const router = useRouter()
  const { t } = useTranslation()

  const iconColor = {
    [Plan.Business]: 'text-yellow group-hover:text-black group-hover:text-white',
    [Plan.Enterprise]: 'text-purple group-hover:text-black dark:group-hover:text-white'
  }

  const bgColor = {
    [Plan.Business]: 'dark:text-white bg-gray-100 hover:bg-yellow dark:bg-gray-500 hover:dark:bg-yellow',
    [Plan.Enterprise]: 'dark:text-white bg-gray-100 hover:bg-purple dark:bg-gray-500 hover:dark:bg-purple'
  }

  return (
    <Tooltip
      size="Auto"
      content={
        type === ButtonType.Small
          ? t('upgradePlan.avaiableOn', { plan })
          : undefined
      }
    >
      <button
        onClick={() => router.push(paths.plans)}
        className={`flex gap-2 items-center py-1 px-3 rounded-md cursor-pointer duration-500 ${bgColor[plan]} ${className}`}
      >
        <PiStarFourFill size={16} className={`w-4 h-4 relative group-hover:rotate-90 duration-500 ${iconColor[plan]}`} />
        {type === ButtonType.Small
          ? t('upgradePlan.upgrade')
          : t('upgradePlan.avaiableOn', { plan })}
      </button>
    </Tooltip >
  )
}
