import { gql } from '@apollo/client'

export const INVITE_MEMBER = gql`
  mutation InviteMember(
    $email: String!
    $role: String!
    $orgId: String!
    $organizationName: String!
  ) {
    invite_member(
      object: {
        email: $email
        role: $role
        orgId: $orgId
        organizationName: $organizationName
      }
    ) {
      success
      message
      errorCode
      errorDetails
    }
  }
`
export const EDIT_MEMBER = gql`
  mutation EditMember(
    $organizationId: String!
    $userOrganizationId: String!
    $newRole: String!
  ) {
    edit_member(
      object: {
        organizationId: $organizationId
        userOrganizationId: $userOrganizationId
        newRole: $newRole
      }
    ) {
      success
      message
      errorCode
      errorDetails
    }
  }
`

export const DELETE_MEMBER = gql`
  mutation DeleteMember(
    $organizationId: String!
    $userOrganizationId: String!
  ) {
    delete_member(
      object: {
        organizationId: $organizationId
        userOrganizationId: $userOrganizationId
      }
    ) {
      success
      message
      errorCode
      errorDetails
    }
  }
`
