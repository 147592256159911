import { motion } from 'framer-motion'
import React, { useState } from 'react'
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'
import { IoIosWarning } from 'react-icons/io'
import { fadeIn } from 'public/animation/motion'
import InputMask from 'react-input-mask'
import { classNames } from 'util/shared'
import { H3 } from 'components'

export function Input(props: any) {
  const {
    id,
    name,
    value,
    validate,
    placeholder,
    maxLength,
    label = '',
    tooltip,
    type = 'text',
    events = {},
    classes = '',
    contClass,
    fieldError,
    disabled,
    eye = false,
    mask
  } = props

  const [typeAux, setType] = useState(type)
  const { onChange, ...restEvents } = events

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (typeof onChange === 'function') {
      onChange({
        value: event?.target?.value
      })
    }
  }

  const fieldProps = {
    ...restEvents,
    id,
    name,
    value,
    validate,
    maxLength,
    placeholder,
    disabled,
    onChange: handleChange
  }

  return (
    <div className={contClass}>
      {label && (
        <div className="flex items-center">
          <H3 fontWeight="bold" className="mb-1">
            {label}
          </H3>
          {tooltip}
        </div>
      )}
      {typeAux === 'textarea' ? (
        <>
          <textarea
            type={typeAux}
            className={classNames(
              classes,
              'focus:outline-none dark:text-white resize-none scroll-1 appearance-none relative block w-full px-3 py-2 box-border rounded-md bg-white dark:bg-black border dark:autocompleteInput',
              fieldError?.length > 0 ? 'border-pink' : 'border-gray-100'
            )}
            {...fieldProps}
          />
        </>
      ) : mask ? (
        <InputMask
          {...fieldProps}
          className={classNames(
            classes,
            'focus:outline-none dark:text-white resize-none scroll-1 appearance-none relative block w-full px-3 py-2 box-border rounded-md bg-white dark:bg-black border dark:autocompleteInput',
            fieldError?.length > 0 ? 'border-pink' : 'border-gray-100'
          )}
          mask={mask}
          maskChar={null}
        />
      ) : (
        <div className="relative">
          <input
            type={typeAux}
            className={classNames(
              classes,
              'focus:outline-none dark:text-white resize-none scroll-1 appearance-none relative block w-full px-3 h-11 box-border rounded-md bg-white dark:bg-black border dark:autocompleteInput',
              fieldError?.length > 0 ? 'border-pink' : 'border-gray-400'
            )}
            {...fieldProps}
          />
          {fieldError?.length > 0 ? (
            <motion.div initial="initial" animate="animate" variants={fadeIn()}>
              <IoIosWarning
                className={classNames(
                  'absolute top-1/2 transform -translate-y-1/2 text-pink',
                  eye === true ? 'right-14' : 'right-5'
                )}
                size={20}
              />
            </motion.div>
          ) : null}
          {eye === true ? (
            <>
              {type === 'password' ? (
                <AiOutlineEyeInvisible
                  onClick={() => {
                    setType('text')
                  }}
                  className="absolute cursor-pointer right-5 top-1/2 transform -translate-y-1/2 text-gray dark:text-white"
                  size={20}
                />
              ) : (
                <AiOutlineEye
                  onClick={() => {
                    setType('password')
                  }}
                  className="absolute cursor-pointer right-5 top-1/2 transform -translate-y-1/2 text-gray dark:text-white"
                  size={20}
                />
              )}
            </>
          ) : null}
        </div>
      )}
      {fieldError?.length > 0 ? (
        <motion.div initial="initial" animate="animate" variants={fadeIn()}>
          <p className="text-sm pl-1 text-pink">{`* ${fieldError}`}</p>
        </motion.div>
      ) : null}
    </div>
  )
}
