import { useLazyQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import { GET_ORGANIZATION_WITH_DISCLAIMERS } from 'queries/organization'
import { useRouter } from 'next/router'
import moment from 'moment'
import { DisclaimerData } from 'models/disclaimer'

const DISCLAIMER_PER_FETCH = 300
const thisMonth = moment().startOf('month').format('YYYY-MM-DD [00:00:00]')

export function formatDisclaimerData(data: any) {
  return data?.organization[0]?.disclaimers?.map((disclaimer: any) => {
    const version =
      disclaimer?.disclaimer_versions[0]?.disclaimer_website_version
    const domains = disclaimer?.disclaimer_websites.filter((domain: any) => {
      return domain.version === version ? true : false
    })
    const metrics = {} as any

    disclaimer?.monthly_metrics?.forEach((domainData: any) => {
      Object.keys(domainData)?.forEach(value => {
        if (value !== '__typename') {
          metrics[value] =
            parseInt(metrics[value] ?? 0) + parseInt(domainData[value])
        }
      })
    })
    return {
      disclaimer_websites: domains,
      metrics: metrics,
      disclaimer_status: disclaimer.disclaimer_status,
      disclaimer_versions: disclaimer.disclaimer_versions,
      id: disclaimer.id,
      pathname: disclaimer.pathname,
      tags: disclaimer.tags,
      disclaimer_documents: disclaimer.disclaimer_documents
    }
  })
}

export function useFetchDisclaimerList() {
  const router = useRouter()
  const { organizationPathname } = router.query

  const [list, setList] = useState<Array<DisclaimerData>>([])
  const [loading, setLoading] = useState(true)
  const [loadingInitialData, setLoadingInitialData] = useState(true)
  const [page, setPage] = useState(0)
  const [hasMore, setHasMore] = useState(true)
  const [listKey, setListKey] = useState(Date.now())
  const [
    loadDisclaimerData,
    { loading: organizationLoading, data: organizationData }
  ] = useLazyQuery(GET_ORGANIZATION_WITH_DISCLAIMERS, {
    fetchPolicy: 'network-only'
  })

  useEffect(() => {
    if (!organizationLoading && organizationData) {
      const disclaimers = formatDisclaimerData(organizationData)
      setList(prev => {
        const disclaimerAux = prev ?? []
        for (let x = 0; x < disclaimers?.length; x++) {
          const existantDisclaimer = disclaimerAux.find(
            item => item.id === disclaimers[x]?.id
          )
          if (!existantDisclaimer) {
            disclaimerAux.push(disclaimers[x])
          } else {
            const index = disclaimerAux.indexOf(existantDisclaimer)
            disclaimerAux[index] = disclaimers[x]
          }
        }

        return disclaimerAux
      })

      if (disclaimers?.length < DISCLAIMER_PER_FETCH) {
        setHasMore(false)
      }
      setLoading(false)
      setLoadingInitialData(false)
    }
  }, [organizationData, organizationLoading])

  useEffect(() => {
    setLoading(true)
    try {
      loadDisclaimerData({
        variables: {
          pathname: organizationPathname,
          month: thisMonth,
          discalimerLimit: DISCLAIMER_PER_FETCH,
          disclaimerOffset: page * DISCLAIMER_PER_FETCH
        }
      })
    } catch (err) {
      setLoading(false)
    }
  }, [page, listKey])

  const handleLoadMore = () => {
    if (hasMore) {
      setPage(prevPage => prevPage + 1)
    }
  }

  const fetchInitialData = () => {
    setLoadingInitialData(true)
    setLoading(true)
    setHasMore(true)

    setList([])
    setPage(0)
    setListKey(Date.now())
  }

  return {
    loading,
    list,
    hasMore,
    loadingInitialData,
    handleLoadMore,
    fetchInitialData
  }
}
