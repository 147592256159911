import React from 'react'
import { useTranslation } from 'react-i18next'
import { RiArrowDownSLine } from 'react-icons/ri'
import { useStorage } from 'contexts/storage-context'
import DisclaimerDropdown from 'components/shared/dropdown/disclaimer-dropdown'

export default function SideBarDisclaimerOption() {
  const { activeOrganizationDetails, activeDisclaimerPath } = useStorage()
  const { t } = useTranslation()

  return (
    <DisclaimerDropdown
      items={activeOrganizationDetails?.disclaimers}
      activeOrganization={activeOrganizationDetails}
    >
      <div className="flex items-center justify-between p-3 mx-4 text-white bg-third-500 dark:bg-gray-500 border border-white dark:border-primary rounded-lg dark:hover:text-primary hover:bg-third-600 dark:hover:bg-black duration-300">
        <div className="font-bold truncate w-44 text-left">
          {activeDisclaimerPath &&
          activeOrganizationDetails?.disclaimers?.some(
            item => item.pathname === activeDisclaimerPath
          )
            ? activeDisclaimerPath
            : t('sideBar.selectADisclaimer')}
        </div>
        <RiArrowDownSLine size={16} />
      </div>
    </DisclaimerDropdown>
  )
}
