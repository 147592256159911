const IconLock = (props: any) => (
  <svg
    fill={props.fill}
    version="1.1"
    id="Camada_1"
    x="0px"
    y="0px"
    viewBox="0 0 64.5 67"
  >
    <style type="text/css"></style>
    <g>
      <path
        className="st0"
        d="M20,48h24.5V31.2H20V48z M31,33.5h2.5c2.1,0,3.7,1.7,3.7,3.6c0,1.6-1,2.9-2.5,3.4c0,0.1,0,0.1,0,0.2v4.7h-5
		v-4.7c0-0.1,0-0.1,0-0.2c-1.4-0.5-2.5-1.8-2.5-3.4C27.3,35.1,29,33.5,31,33.5z"
      />
      <path
        className="st0"
        d="M32.3,0C14.8,0,0.7,15,0.7,33.5S14.8,67,32.3,67s31.6-15,31.6-33.5S49.7,0,32.3,0z M49.5,47.9
		c0,2.6-2.2,4.7-5,4.7H20c-2.7,0-5-2.1-5-4.7V31.1c0-2.6,2.2-4.7,5-4.7h2.5v-1.9c0-5,3.7-9.5,8.9-10c4.7-0.4,8.7,2.4,10,6.4
		c0.5,1.6-0.7,3.2-2.4,3.2c-1,0-1.9-0.5-2.2-1.5c-0.6-2.1-2.9-3.6-5.3-3.3c-2.5,0.4-4.2,2.6-4.2,5v2.1h17.2c2.7,0,5,2.2,5,4.9V47.9z
		"
      />
    </g>
  </svg>
)

export default IconLock
