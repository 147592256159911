import { useTranslation } from 'next-i18next'
import { IoIosCheckmarkCircleOutline } from 'react-icons/io'
import { IoCloseCircleOutline } from 'react-icons/io5'

interface PasswordValidatorProps {
  password: string
}

export function PasswordValidator({ password }: PasswordValidatorProps) {
  const { t } = useTranslation()

  const validations = [
    {
      label: t('passwordValidation.length'),
      validation: password.length >= 8
    },
    {
      label: t('passwordValidation.lowercase'),
      validation: /[a-z ]/g.test(password)
    },
    {
      label: t('passwordValidation.uppercase'),
      validation: /[A-Z]/g.test(password)
    },
    {
      label: t('passwordValidation.number'),
      validation: /[0-9]/g.test(password)
    },
    {
      label: t('passwordValidation.specialCharacter'),
      validation: /[$&+,.:;=?@#|'<>^*()%!-]/g.test(password)
    }
  ]

  return (
    <>
      {validations.map((item, index) => {
        return (
          <div key={index} className="flex items-center ">
            {item.validation ? (
              <IoIosCheckmarkCircleOutline
                className="mr-2 text-primary"
                size={16}
              />
            ) : (
              <IoCloseCircleOutline className="mr-2 text-gray-400" size={16} />
            )}
            <span>{item.label}</span>
          </div>
        )
      })}
    </>
  )
}
