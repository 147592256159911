import React from 'react'
import { Button, ButtonSize, ButtonType, Link } from 'components'
import { BiWorld } from 'react-icons/bi'
import { useTranslation } from 'react-i18next'
import RoleCard from './role-card'
import { OrganizationsData } from 'hooks/storage/useOrganizations'

interface OrganizationCardProps {
  item: OrganizationsData
}

export function OrganizationCard({ item }: OrganizationCardProps) {
  const { t } = useTranslation()
  return (
    <Link
      href={`/org/${item?.pathname}/disclaimers`}
      data-test-id="organizationCard"
    >
      <div className=" px-10 pb-1 pointer">
        <div
          className="flex hover:border-primary border-gray-300 border-opacity-30 
        dark:border-opacity-100 dark:border-gray dark:hover:border-primary 
        duration-700 border rounded-lg"
        >
          {item?.logo ? (
            <div className="w-20 h-20 m-3 bg-white-200 dark:bg-gray text-primary flex justify-center items-center ">
              <img className="rounded-md" src={item?.logo} alt="Logo" />
            </div>
          ) : (
            <div className="w-20 h-20 m-3 bg-white-200 dark:bg-gray  text-primary flex justify-center items-center rounded-md">
              <BiWorld size={30} />
            </div>
          )}
          <div className="flex-1 flex justify-center flex-col">
            <div className="text-lg	dark:text-white">{item?.name}</div>
            <RoleCard role={item?.role}>{item?.role}</RoleCard>
          </div>
          <div className="flex items-end p-2">
            <Button
              buttonSize={ButtonSize.Small}
              buttonType={ButtonType.Tertiary}
              className="bg-white-200 dark:text-white dark:bg-gray"
            >
              {t('organizations.See more')}
            </Button>
          </div>
        </div>
      </div>
    </Link>
  )
}
