import { gql } from '@apollo/client'

export const SEND_FEEDBACK = gql`
  mutation SendFeedback(
    $rating: Int!
    $review: String
    $pageUrl: String!
    $featureId: String!
  ) {
    send_feedback(
      object: {
        rating: $rating
        review: $review
        pageUrl: $pageUrl
        featureId: $featureId
      }
    ) {
      success
      message
      errorCode
    }
  }
`
