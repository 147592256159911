import React, { useState, useCallback, useRef, useEffect } from 'react'
import Cropper from 'react-easy-crop'
import { Button, ButtonType } from '../buttons'
import { IoCloudUploadOutline } from 'react-icons/io5'
import { useTranslation } from 'next-i18next'
import { ModalHeader } from '../header/modal-header'
import Image from 'next/image'
import { BiTrash } from 'react-icons/bi'

interface UploadImageProps {
  // croppedImage: string
  onSave: (croppedImage: string) => void
}

interface Area {
  width: number
  height: number
  x: number
  y: number
}

export function UploadImageInput({ onSave }: UploadImageProps) {
  const { t } = useTranslation()

  const [imageSrc, setImageSrc] = useState<string | null>(null)
  const [modalOpen, setModalOpen] = useState(false)
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null)
  const [croppedImage, setCroppedImage] = useState<string | null>(null)

  const inputRef = useRef<HTMLInputElement | null>(null)
  const canvasRef = useRef<HTMLCanvasElement | null>(null)

  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    if (file) {
      const reader = new FileReader()
      reader.onload = () => {
        setImageSrc(reader.result as string)
      }
      reader.readAsDataURL(file)
    }
  }

  const onCropComplete = useCallback((_, croppedAreaPixels: Area) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const createImage = (url: string): Promise<HTMLImageElement> =>
    new Promise((resolve, reject) => {
      const image = document.createElement('img')
      image.addEventListener('load', () => resolve(image))
      image.addEventListener('error', error => reject(error))
      image.src = url
    })

  async function getCroppedImg(
    imageSrc: string,
    pixelCrop: Area,
    flip = { horizontal: false, vertical: false }
  ): Promise<string | null> {
    const image = await createImage(imageSrc)
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')

    if (!ctx) {
      return null
    }

    // set canvas size to match the bounding box
    canvas.width = image.width
    canvas.height = image.height

    // translate canvas context to a central location to allow rotating and flipping around the center
    ctx.translate(image.width / 2, image.height / 2)
    ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1)
    ctx.translate(-image.width / 2, -image.height / 2)
    ctx.drawImage(image, 0, 0)

    const croppedCanvas = document.createElement('canvas')

    const croppedCtx = croppedCanvas.getContext('2d')

    if (!croppedCtx) {
      return null
    }

    // Set the size of the cropped canvas
    croppedCanvas.width = pixelCrop.width
    croppedCanvas.height = pixelCrop.height

    // Draw the cropped image onto the new canvas
    croppedCtx.drawImage(
      canvas,
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
      0,
      0,
      pixelCrop.width,
      pixelCrop.height
    )

    // As Base64 string
    const base64 = croppedCanvas.toDataURL('image/png')
    onSave(base64)

    // As a blob
    return new Promise((resolve, reject) => {
      croppedCanvas.toBlob(file => {
        if (file) {
          resolve(URL.createObjectURL(file))
        } else {
          reject()
        }
      }, 'image/png')
    })
  }

  const showCroppedImage = async () => {
    try {
      const croppedImage = await getCroppedImg(
        imageSrc as string,
        croppedAreaPixels as Area
      )
      setCroppedImage(croppedImage)
      setModalOpen(false)
    } catch (e) {
      console.error(e)
    }
  }

  function resetData() {
    setCrop({ x: 0, y: 0 })
    setZoom(1)
    setCroppedAreaPixels(null)
    setCroppedImage(null)
    setImageSrc(null)
  }

  useEffect(() => {
    if (imageSrc) {
      setModalOpen(true)
    }
  }, [imageSrc])

  return (
    <div>
      <input
        ref={inputRef}
        type="file"
        accept="image/*"
        onChange={onFileChange}
        className="hidden"
      />
      {croppedImage ? (
        <div className="flex gap-4 items-center">
          <Image src={croppedImage} width={100} height={100} />
          <Button
            className="!text-pink"
            buttonType={ButtonType.Ghost}
            onClick={resetData}
            iconLeft={BiTrash}
          >
            Remover
          </Button>
        </div>
      ) : (
        <Button
          buttonType={ButtonType.Secondary}
          onClick={() => inputRef.current?.click()}
          iconLeft={IoCloudUploadOutline}
        >
          Selecionar Imagem
        </Button>
      )}

      {modalOpen && (
        <div className="fixed z-50 top-0 left-0 bg-opacity-90 w-screen h-screen bg-black flex items-center justify-center">
          <div className="max-w-600 w-3/4 z-50 dark:text-white rounded-md  py-8 px-10 bg-white dark:bg-gray">
            <ModalHeader
              icon={<IoCloudUploadOutline size={24} className="text-primary" />}
              title={'Editar imagem'}
              subtitle={
                'Envie uma imagem e defina a área que deseja recortar antes de salvar'
              }
              setModalOpen={setModalOpen}
            ></ModalHeader>
            {imageSrc && (
              <div
                style={{
                  position: 'relative',
                  width: '100%',
                  height: '400px'
                }}
              >
                <Cropper
                  image={imageSrc}
                  crop={crop}
                  zoom={zoom}
                  aspect={1}
                  onCropChange={setCrop}
                  onZoomChange={setZoom}
                  onCropComplete={onCropComplete}
                />
              </div>
            )}
            <canvas ref={canvasRef} className="hidden" />
            <div className="controls my-2">
              <input
                type="range"
                value={zoom}
                min={1}
                max={5}
                step={0.1}
                onChange={e => {
                  setZoom(+e.target.value)
                }}
                className="w-full h-1 bg-black rounded-lg appearance-none cursor-pointer outline-none"
              />
            </div>
            <div className="mt-4 flex items-center justify-center">
              <Button onClick={showCroppedImage}>
                Salvar Imagem Recortada
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
