import { createContext, ReactNode, useContext } from 'react'
import { UserData, useUserSession } from 'hooks/useUserSession'
import { logout } from 'services/auth-service'

interface AuthProviderProps {
  children: ReactNode
}

interface AuthContextType {
  user: UserData | undefined
  setUser: React.Dispatch<React.SetStateAction<UserData | undefined>>
  authenticated: boolean
  loading: boolean
  handleSignIn: (token: string) => void
  handleLogout: () => void
}

export const AuthContext = createContext({} as AuthContextType)

export function AuthProvider({ children }: AuthProviderProps) {
  const { authenticated, setAuthenticated, user, setUser, loading } =
    useUserSession()

  function handleSignIn(token: string) {
    localStorage.setItem('adopt-token', token)
    setAuthenticated(true)
  }

  async function handleLogout() {
    await logout()
    setAuthenticated(false)
    setUser(undefined)
    localStorage.removeItem('adopt-token')
  }

  return (
    <AuthContext.Provider
      value={{
        authenticated,
        handleSignIn,
        handleLogout,
        user,
        setUser,
        loading
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
export const useAuth = () => useContext(AuthContext)
