import React from 'react'
import { IoClose } from 'react-icons/io5'

export interface ModalHeaderProps {
  icon: React.ReactNode
  title: string
  subtitle: string
  setModalOpen: (status: boolean) => void
}

export function ModalHeader({
  icon,
  title,
  subtitle,
  setModalOpen
}: ModalHeaderProps) {
  return (
    <div className="flex justify-between mb-7 dark:text-white">
      <div className="flex items-center justify-center w-[54px] h-[54px] rounded-full bg-white-200 dark:bg-black p-1">
        {icon}
      </div>
      <div className="flex flex-col items-start justify-start w-[90%] pl-4">
        <p className="text-[18px] font-bold text-start">{title}</p>
        <p className="text-gray-200 text-start">{subtitle}</p>
      </div>
      <div className="cursor-pointer" onClick={() => setModalOpen(false)}>
        <IoClose size={18} />
      </div>
    </div>
  )
}
