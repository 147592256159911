import React, { useEffect, useState } from 'react'
import { BiWorld } from 'react-icons/bi'
import { useTranslation } from 'react-i18next'
import { classNames } from 'util/shared'
import { formatUrl } from 'util/format'
import { Input, Button, ButtonType } from 'components'
import { ModalWrapper } from './modal-wrapper'

export function CreateOrganization({
  modalOpen,
  setModalOpen,
  addOrganization
}: any) {
  const [nameError, setNameError] = useState('')
  const [pathnameError, setPathnameError] = useState(false)
  const [changedPathname, setChangedPathname] = useState(false)

  const [name, setName] = useState('')
  const [pathname, setPathname] = useState('')
  const { t } = useTranslation()

  useEffect(() => {
    if (modalOpen) {
      setNameError('')
      setPathnameError(false)
      setChangedPathname(false)
      setName('')
      setPathname('')
    }
  }, [modalOpen])

  function validateOrg() {
    let withoutError = true
    if (name.length === 0) {
      setNameError('Digite um nome')
      withoutError = false
    }
    if (pathname.length === 0) {
      setPathnameError(true)
      withoutError = false
    }
    if (withoutError) {
      addOrganization(name, pathname)
    }
  }

  return (
    <ModalWrapper
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      headerProps={{
        icon: <BiWorld className="text-primary" size={35} />,
        title: t('organizations.Create your organization'),
        subtitle: t('organizations.modalDescription')
      }}
    >
      <Input
        label={t('org.settings.Name')}
        placeholder="Type a name"
        fieldError={nameError}
        events={{
          onChange: (e: any) => {
            if (!changedPathname) {
              setPathname(formatUrl(e.value))
            }
            setNameError('')
            setName(e.value)
          }
        }}
      />

      <div className="font-bold mt-5">
        {t('org.settings.Organization link')}
      </div>
      <div className="dark:text-gray-200">
        {t(
          'org.settings.Here you can choose a unique link for your organization'
        )}
      </div>
      <div className="flex items-center">
        <div>https://dash.goadopt.io/org/</div>
        <input
          id="pathname"
          name="pathname"
          value={pathname}
          maxLength={40}
          onChange={e => {
            setChangedPathname(true)
            setPathnameError(false)
            setPathname(formatUrl(e.target.value))
          }}
          autoComplete="off"
          className={classNames(
            'focus:outline-none appearance-none relative block w-full px-3 py-2 ml-1 box-border rounded-md dark:bg-gray border',
            pathnameError ? 'border-pink' : 'border-gray-200'
          )}
        />
      </div>
      <div className="flex justify-end mt-5 gap-3">
        <Button
          buttonType={ButtonType.Secondary}
          onClick={() => setModalOpen(false)}
        >
          {t('org.settings.Cancel')}
        </Button>
        <Button onClick={() => validateOrg()}>{t('org.settings.Save')}</Button>
      </div>
    </ModalWrapper>
  )
}
