import React from 'react'

const badgeColors = {
  userRoles: {
    admin: 'bg-red-500',
    dpo: 'bg-green-500',
    viewer: 'bg-blue-500'
  },
  documentTypes: {
    privacy: 'bg-purple',
    terms: 'bg-orange',
    cookies: 'bg-lightblue'
  }
}

export type BadgePurpose = keyof typeof badgeColors

interface BadgeProps {
  purpose: BadgePurpose
  type: string
  children: string
}

const getBackgroundColor = <P extends BadgePurpose>(
  purpose: P,
  type: string
) => {
  const colors = badgeColors[purpose]
  if (colors && type in colors) {
    return colors[type as keyof typeof colors]
  }

  return 'bg-gray-500'
}

export const Badge = ({ purpose, type, children }: BadgeProps) => {
  const backgroundColor = getBackgroundColor(purpose, type)

  return (
    <span
      className={`${backgroundColor} rounded-sm px-1 text-xs h-5 text-white uppercase whitespace-nowrap`}
    >
      {children}
    </span>
  )
}
