import React from 'react'
import upgradeIcon from 'assets/imgs/upgrade-icon.png'
import rocketImage from 'assets/imgs/rocket-image.png'
import Image from 'next/image'
import { useTranslation } from 'react-i18next'
import { IoClose } from 'react-icons/io5'
import { ChangePlanButton } from 'components'
import { ModalWrapper } from './modal-wrapper'

export enum UpgradeModalPlans {
  business = 'business',
  enterprise = 'enterprise'
}

interface UpgradeModalProps {
  modalOpen: boolean
  setModalOpen: (state: boolean) => void
  planName?: UpgradeModalPlans | undefined
  description?: string | undefined
}

export function UpgradeModal({
  modalOpen,
  setModalOpen,
  planName,
  description
}: UpgradeModalProps) {
  const { t } = useTranslation()

  return (
    <ModalWrapper modalOpen={modalOpen} setModalOpen={setModalOpen}>
      <div className="flex justify-end w-full h-1 ">
        <button onClick={() => setModalOpen(false)}>
          <IoClose className="dark:text-white" size={18} />
        </button>
      </div>
      <div className="flex justify-center mb-6">
        <div className="relative h-36 w-36">
          <div className="absolute w-full h-full">
            <Image
              src={upgradeIcon}
              width={140}
              height={140}
              alt="upgrade icon"
            />
          </div>
          <div className="absolute -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
            <div className="rocketAnimation hover:scale-2">
              <Image
                src={rocketImage}
                width={27}
                height={70}
                alt="upgrade icon"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="text-lg font-bold text-center dark:text-white">
        {planName
          ? t('upgradeModal.titleCustom', { planName })
          : t('upgradeModal.title')}
      </div>
      <div className="text-center text-md dark:text-gray-300 ">
        {description ?? t('upgradeModal.description')}
      </div>
      <div className="flex justify-center mt-6">
        <ChangePlanButton>{t('disclaimer.style.Upgrade')}</ChangePlanButton>
      </div>
    </ModalWrapper>
  )
}
