import React, { useEffect, useState } from 'react'
import { Button, ButtonType, Select, SpinLoading } from 'components'
import { RiChat4Line } from 'react-icons/ri'
import { useTranslation } from 'react-i18next'
import { useHandleError } from 'hooks/useHandleError'
import { ModalWrapper } from './modal-wrapper'
import toast from 'react-hot-toast'
import { useDocuments } from 'hooks/useDocuments'
import { useStorage } from 'contexts/storage-context'
import { documentTypes } from 'pages/org/[organizationPathname]/documents'
import { RawListDocumentProps } from 'models/documents'

interface AddDocumentToDisclaimerProps {
  open: boolean
  handleClose: () => void
  documentType: string | undefined
}

export function AddDocumentToDisclaimer({
  open,
  handleClose,
  documentType
}: AddDocumentToDisclaimerProps) {
  const { activeDisclaimerPath, activeOrganizationDetails } = useStorage()
  const { t } = useTranslation()
  const { handleError } = useHandleError()
  const [disclaimerId, setDisclaimerId] = useState('')
  const [documentIdSelected, setDocumentIdSelected] = useState('')

  const { addDocumentToDisclaimer, listDocuments, listDocumentsLoading } =
    useDocuments({ filterByDocumentType: documentType })

  async function handleSubmit() {
    if (isValid()) {
      toast.loading(t('modal.addDocumentToDisclaimer.associating'))
      try {
        const result = await addDocumentToDisclaimer(documentIdSelected, [
          disclaimerId
        ])

        if (result?.success) {
          toast.dismiss()
          toast.success(
            `${t('modal.addDocumentToDisclaimer.associatingSuccess')}!`
          )
          handleClose()
        } else {
          toast.dismiss()
          handleError(result?.message)
        }
      } catch (error) {
        toast.dismiss()
        handleError()
      }
    }
  }

  function isValid() {
    let valid = true
    if (!documentIdSelected) {
      handleError(t('modal.addDocumentToDisclaimer.selectDocument'))
      valid = false
    }

    return valid
  }

  function formatListDocuments(data: RawListDocumentProps | undefined) {
    const formattedData = data?.document_master
      ?.map(document => {
        return {
          id: document.id,
          document_type: document.document_type,
          document_name:
            document[documentTypes[document.document_type]]?.[0]?.document_name
        }
      })
      .filter(({ document_type }) => document_type === documentType)

    return formattedData ?? []
  }

  console.log(listDocuments)

  useEffect(() => {
    if (!activeOrganizationDetails) return
    const id =
      activeOrganizationDetails?.disclaimers?.find(
        ({ pathname }) => pathname === activeDisclaimerPath
      )?.id ?? ''
    setDisclaimerId(id)
  }, [activeOrganizationDetails, activeDisclaimerPath])

  return (
    <ModalWrapper
      modalOpen={open}
      setModalOpen={handleClose}
      headerProps={{
        icon: <RiChat4Line size={35} className="text-primary" />,
        title: t('modal.addDocumentToDisclaimer.title'),
        subtitle: t('modal.addDocumentToDisclaimer.subTitle')
      }}
    >
      {listDocumentsLoading ? (
        <div className="flex justify-center">
          <SpinLoading />
        </div>
      ) : formatListDocuments(listDocuments)?.length > 0 ? (
        <Select
          label={t('modal.addDocumentToDisclaimer.selectDocument')}
          containerClasses={'mt-2'}
          params={formatListDocuments(listDocuments)?.map(
            ({ id, document_name }) => ({
              name: document_name,
              value: id
            })
          )}
          value={documentIdSelected ?? ''}
          onChange={e => {
            setDocumentIdSelected(e.value as string)
          }}
        />
      ) : (
        <div className="py-2 px-1 rounded-md bg-white-200 dark:bg-black">
          {t('modal.addDocumentToDisclaimer.noDocument')}
        </div>
      )}

      {!listDocumentsLoading && (
        <div className="flex justify-end mt-5">
          <Button
            buttonType={ButtonType.Secondary}
            onClick={handleClose}
            className="dark:bg-black mr-2"
          >
            {t('modal.addDocumentToDisclaimer.cancel')}
          </Button>
          <Button onClick={() => handleSubmit()}>
            {t('modal.addDocumentToDisclaimer.save')}
          </Button>
        </div>
      )}
    </ModalWrapper>
  )
}
