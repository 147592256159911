import React from 'react'
import { classNames } from 'util/shared'
import router, { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'
import { Link } from './side-bar'
import { useHandleError } from 'hooks/useHandleError'
import { SideBarTooltip } from '../tooltip/sideBarTooltip'

interface SideBarOptionProps {
  index?: number | string
  link: Link
  open: boolean
}

export default function SideBarOption({
  index,
  link,
  open
}: SideBarOptionProps) {
  const { t } = useTranslation()
  const nextRouter = useRouter()
  const { handleError } = useHandleError()

  function changePage(href: string) {
    if (!href.includes('undefined')) {
      if (link.target === '_blank') {
        window.open(href, '_ blank')
      } else {
        router.push(href)
      }
    } else {
      handleError(t('sideBar.selectADisclaimer'))
    }
  }

  return (
    <SideBarTooltip content={link.name} open={open}>
      <button
        key={index}
        onClick={() => changePage(link.path)}
        className={classNames(
          'flex items-center w-full p-2 text-white hover:dark:text-primary hover:bg-shadow dark:hover:bg-black rounded-lg duration-300',
          nextRouter.asPath === `${link.path}`
            ? 'dark:text-primary bg-shadow dark:bg-black'
            : 'dark:text-gray-250',
        )}>
        {link.icon}
        {open && <span className="font-bold truncate ml-3">{link.name}</span>}
      </button >

    </SideBarTooltip>
  )
}
