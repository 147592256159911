import { classNames } from 'util/shared'
import { motion } from 'framer-motion'

interface StepperProps {
  numberOfSteps: number
  currentStep: number
}
export function Stepper({ numberOfSteps, currentStep }: StepperProps) {
  return (
    <div className="flex items-center justify-between relative">
      {Array.from(Array(numberOfSteps).keys()).map(item => {
        return (
          <div
            key={item}
            className="w-11 h-11 rounded-full relative overflow-hidden"
          >
            {item === currentStep && (
              <motion.div
                initial="initial"
                animate="animate"
                variants={{
                  initial: {
                    opacity: 0
                  },
                  animate: {
                    opacity: 1,
                    transition: {
                      delay: 1,
                      duration: 1,
                      ease: [0.6, -0.05, 0.01, 0.99]
                    }
                  }
                }}
                className="w-full h-full text-xl flex items-center justify-center z-[2] absolute bg-primary"
              >
                {item + 1}
              </motion.div>
            )}
            <div
              className={classNames(
                'w-full h-full text-xl flex items-center justify-center relative z-[1]',
                currentStep <= item
                  ? 'bg-white-200 dark:bg-gray'
                  : 'bg-primary text-black'
              )}
            >
              {item + 1}
            </div>
          </div>
        )
      })}
      <div className="absolute h-[1px] w-full top-1/2 left-0 transform -translate-y-1/2 bg-white-200 dark:bg-gray"></div>
      <motion.div
        initial="initial"
        animate="animate"
        variants={{
          initial: {
            width: `${((currentStep - 1) * 100) / (numberOfSteps - 1)}%`
          },
          animate: {
            width: `${(currentStep * 100) / (numberOfSteps - 1)}%`,
            transition: {
              duration: 2,
              ease: [0.6, -0.05, 0.01, 0.99]
            }
          }
        }}
        className="absolute h-[1px] top-1/2 left-0 transform -translate-y-1/2 bg-primary"
        // style={{ width: `${(currentStep * 100) / (numberOfSteps - 1)}%` }}
      ></motion.div>
    </div>
  )
}
