import { useQuery } from '@apollo/client'
import { useStorage } from 'contexts/storage-context'
import { GET_DISCLAIMER_VERSION } from 'queries/disclaimers'

interface RawDisclaimerVersionsProps {
  disclaimers: Array<{ disclaimer_versions: Array<DisclaimerVersionsProps> }>
}

interface DisclaimerVersionsProps {
  disclaimer_version: number
  terms_version: number
  disclaimer_style_version: number
  policy_version: number
  opt_out_version: number
  disclaimer_text_version: number
  disclaimer_website_version: number
  tags_version: number
  scan_auth_version: number
  disclaimer_options_version: number
  vendors_version: number
}
export const useDisclaimerVersions = () => {
  const { activeDisclaimerPath, activeOrganizationDetails } = useStorage()

  const { data, loading, refetch } = useQuery<RawDisclaimerVersionsProps>(
    GET_DISCLAIMER_VERSION,
    {
      fetchPolicy: 'network-only',
      skip: !activeDisclaimerPath && !activeOrganizationDetails?.id,
      variables: {
        disclaimerPathname: activeDisclaimerPath,
        organizationId: activeOrganizationDetails?.id
      }
    }
  )

  function formatter(data: RawDisclaimerVersionsProps | undefined) {
    return data?.disclaimers?.[0]?.disclaimer_versions?.[0]
  }

  return { data: formatter(data), loading, refetch }
}
