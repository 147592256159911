export enum DeviceType {
  Web = 'Gk2kyEu5',
  Mobile = 'xqgmno2m'
}

export enum Role {
  Owner = 'aW6ZQ7iQ',
  Admin = 'FFbMI2zu',
  Dpo = 'VsPE5e1z',
  Analyst = 'wY9Ep1pu',
  Viewer = 'PJ0yc1R4'
}

export enum InverseRoles {
  aW6ZQ7iQ = 'Owner',
  FFbMI2zu = 'Admin',
  VsPE5e1z = 'Dpo',
  wY9Ep1pu = 'Analyst',
  PJ0yc1R4 = 'Viewer'
}

export enum RoleImportance {
  aW6ZQ7iQ = 4,
  FFbMI2zu = 3,
  VsPE5e1z = 2,
  wY9Ep1pu = 1,
  PJ0yc1R4 = 0
}

export type GuardType = Guards | null
export enum Guards {
  OnlyUnauthorized = 'ONLY_UNAUTHORIZED',
  OnlyAuthorized = 'ONLY_AUTHORIZED'
}

export enum AuthenticationStepType {
  Type = 'T0It4k4e',
  Click = 'C0lbQkQF'
}

export enum EventLogTypes {
  FirstVisit = 'w8biHzeN',
  AcceptAll = 'tRKQ7dKQ',
  RejectAll = 'mDqCrhkp',
  AcceptPartial = 'fshfFh0B',
  DeleteRequest = 'tKDDt9FW',
  DownloadRequest = 'Az1cOdP2'
}

export enum LanguagesTypes {
  Portuguese = 'pt',
  PortuguesePortugal = 'pt-PT',
  English = 'en',
  EnglishUnitedKingdom = 'en-GB',
  Bulgarian = 'bg',
  Croatian = 'hr',
  Czech = 'cs',
  Danish = 'da',
  Dutch = 'nl',
  Estonian = 'et',
  Finnish = 'fi',
  French = 'fr',
  German = 'de',
  Greek = 'el',
  Hungarian = 'hu',
  Italian = 'it',
  Irish = 'ga',
  Latvian = 'lv',
  Lithuanian = 'lt',
  Maltese = 'mt',
  Polish = 'pl',
  Romanian = 'ro',
  Slovak = 'sk',
  Spanish = 'es',
  SpanishLatinAmerica = 'es-LA',
  Swedish = 'sv',
  Hindi = 'hi',
  Chinese = 'zh',
  ChineseTaiwan = 'zh-TW',
  Japanese = 'ja',
  Arabic = 'ar'
}

export enum LegislationsTypes {
  // Europe
  GDPR = 'gdpr',
  CNIL = 'cnil',
  NFADP = 'nfadp',
  UK_GDPR = 'uk-gdpr',

  // Brazil
  LGPD = 'lgpd',

  // Canada
  PIPEDA = 'pipeda',
  LAW_25 = 'law-25',

  // EUA
  PIPA = 'pipa',
  TDPSA = 'tdpsa',
  FDBR = 'fdbr',
  OCPA = 'ocpa',
  CPA = 'cpa',
  PDP = 'pdp',
  CCPA = 'ccpa',
  UCPA = 'ucpa',
  VCPA = 'vcpa',
  CTDPA = 'ctdpa',

  // Asia
  DPDPA = 'dpdpa',
  PDPL = 'pdpl',
  APPI = 'appi',
  PDPA_MALASIA = 'pdpa-malasia',
  PDPA_THAILANDIA = 'pdpa-thailandia',
  PIPL = 'pipl',

  // Africa
  POPIA = 'popia',

  // Oceania
  APA = 'apa'
}
export enum TcfLegislationsTypes {
  GDPR_TCF = 'gdpr-tcf',
  CNIL_TCF = 'cnil-tcf',
  NFADP_TCF = 'nfadp-tcf',
  UK_GDPR_TCF = 'uk-gdpr-tcf',

  LGPD = 'lgpd',

  PIPEDA = 'pipeda',
  LAW_25 = 'law-25',
  PIPA = 'pipa',
  TDPSA = 'tdpsa',
  FDBR = 'fdbr',
  OCPA = 'ocpa',
  CPA = 'cpa',
  PDP = 'pdp',
  CCPA = 'ccpa',
  UCPA = 'ucpa',
  VCPA = 'vcpa',
  CTDPA = 'ctdpa',

  DPDPA = 'dpdpa',
  PDPL = 'pdpl',
  APPI = 'appi',
  PDPA_MALASIA = 'pdpa-malasia',
  PDPA_THAILANDIA = 'pdpa-thailandia',
  PIPL = 'pipl',

  POPIA = 'popia',

  APA = 'apa'
}

export interface CreditCard {
  brand: CreditCardBrand
  created: number
  customer: string
  cvcCheck: string
  email: string
  expMonth: number
  expYear: number
  id: string
  last4: string
  defaultPaymentMethod: boolean
}

export enum CreditCardBrand {
  Amex = 'amex',
  Diners = 'diners',
  Discover = 'discover',
  Jcb = 'jcb',
  Mastercard = 'mastercard',
  Unionpay = 'unionpay',
  Visa = 'visa',
  Unknown = 'unknown'
}

export interface BaseResponse {
  data: unknown
  success: boolean
  message: string
}

export enum CampaignInfo {
  utmSource = 'utm_source',
  utmMedium = 'utm_medium',
  utmCampaign = 'utm_campaign',
  utmTerm = 'utm_term',
  utmContent = 'utm_content'
}

export type PlatformLanguageType = 'en' | 'pt'
