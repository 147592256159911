import { useLocalStorage } from 'hooks/useLocalstorage'
import { createContext, useContext, useEffect } from 'react'

export const ThemeContext = createContext({} as any)

export function ThemeProvider({ children }: any) {
  const [theme, setTheme] = useLocalStorage('theme', 'light')

  useEffect(() => {
    if (theme === 'dark') {
      document.documentElement.classList.add('dark')
    } else {
      document.documentElement.classList.remove('dark')
    }
  }, [theme])

  return (
    <ThemeContext.Provider
      value={{
        theme,
        setTheme
      }}
    >
      {children}
    </ThemeContext.Provider>
  )
}

export const useTheme = () => useContext(ThemeContext)
