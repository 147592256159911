import { useMutation, useQuery } from '@apollo/client'
import {
  CREATE_IDENTITY_PROVIDER,
  DELETE_IDENTITY_PROVIDER,
  GET_IDENTITY_PROVIDER
} from 'queries/identityProvider'
import { useAuth } from 'contexts/auth-context'

export function formatData(data: any): IdPProps[] {
  return data?.users[0]?.sso_identity_providers.map((item: RawIdPProps) => {
    return {
      identityProvider: item.identity_provider,
      metadataUrl: item.metadata_url,
      attributeMapping: item.attribute_mapping,
      organizations: item.organizations
    }
  })
}

interface CreateIdPProps {
  identityProvider: string
  metadataUrl: string
  attributeMapping: string
  organizations: string[]
}
interface RawIdPProps {
  identity_provider: string
  metadata_url: string
  attribute_mapping: string
  organizations: string[]
}
interface IdPProps {
  identityProvider: string
  metadataUrl: string
  attributeMapping: string
  organizations: string[]
}

export function useIdentityProvider() {
  const { user } = useAuth()

  const { refetch, data, loading } = useQuery(GET_IDENTITY_PROVIDER, {
    fetchPolicy: 'network-only',
    variables: { email: user?.email }
  })
  const [createIdP] = useMutation(CREATE_IDENTITY_PROVIDER)
  const [deleteIdP] = useMutation(DELETE_IDENTITY_PROVIDER)

  async function createIdentityProvider(data: CreateIdPProps) {
    const result = await createIdP({
      variables: {
        ...data
      }
    })
    refetch()

    return result?.data?.create_identity_provider
  }

  async function deleteIdentityProvider(identityProvider: string) {
    try {
      const result = await deleteIdP({
        variables: {
          identityProvider
        }
      })
      refetch()

      return result?.data?.delete_identity_provider
    } catch (error) {
      /* empty */
    }
  }

  return {
    loading,
    refetch,
    data: formatData(data),
    createIdentityProvider,
    deleteIdentityProvider
  }
}
