import { motion } from 'framer-motion'
import React from 'react'
import { fadeIn } from 'public/animation/motion'
import { H3 } from 'components'
import PhoneInput from 'react-phone-input-2'
import { classNames } from 'util/shared'
import { IoIosWarning } from 'react-icons/io'

export function PhoneNumberInput(props: any) {
  const { value, events = {}, label, contClass, fieldError } = props

  const { onChange } = events

  return (
    <div className={contClass}>
      <H3 fontWeight="bold">{label}</H3>

      <div className="relative">
        <PhoneInput
          country={'br'}
          value={value}
          inputClass={classNames(
            '!w-[100%] !py-2 !h-[42px] dark:!text-white dark:!bg-gray !pr-20 bg-white dark:bg-black border dark:autocompleteInput',
            fieldError.length > 0 ? '!border-pink' : '!border-gray-400'
          )}
          buttonClass={classNames(
            fieldError.length > 0 ? '!border-pink' : '!border-gray-400'
          )}
          dropdownClass="dark:!text-black"
          onChange={value => onChange(value)}
        />
        {fieldError.length > 0 && (
          <motion.div initial="initial" animate="animate" variants={fadeIn()}>
            <IoIosWarning
              className={classNames(
                'absolute top-1/2 z-10 transform -translate-y-1/2 text-pink right-5'
              )}
              size={20}
            />
          </motion.div>
        )}
      </div>

      {fieldError.length > 0 ? (
        <motion.div initial="initial" animate="animate" variants={fadeIn()}>
          <p className="text-sm pl-1 dark:text-white">{`* ${fieldError}`}</p>
        </motion.div>
      ) : null}
    </div>
  )
}
