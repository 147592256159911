import { useMutation } from '@apollo/client'
import { TourProvider } from '@reactour/tour'
import { TourIntro } from 'components/pages/tour/intro'
import { AuthContext } from 'contexts/auth-context'
import { useTheme } from 'contexts/theme-context'
import { useTranslation } from 'next-i18next'
import { UPDATE_USER_TUTORIAL } from 'queries/user'
import { ReactElement, useContext } from 'react'

interface TourSystemProps {
  children: ReactElement<any, any>
}
export default function TourSystem({ children }: TourSystemProps) {
  const { t } = useTranslation()
  const { theme } = useTheme()

  const { user } = useContext(AuthContext)
  const [updateUserTutorial] = useMutation(UPDATE_USER_TUTORIAL)
  const steps = [
    {
      selector: '[data-tour="intro-step"]',
      content: (
        <TourIntro
          title={t('tourSystem.intro.title')}
          description={t('tourSystem.intro.description')}
        />
      )
    },
    {
      selector: '[data-tour="initialConfiguration-step"]',
      content: (
        <TourIntro
          title={t('tourSystem.initialConfiguration.title')}
          description={t('tourSystem.initialConfiguration.description')}
          withTourStep
        />
      )
    },

    {
      selector: '[data-tour="documents-step"]',
      content: (
        <TourIntro
          title={t('tourSystem.documents.title')}
          description={t('tourSystem.documents.description')}
          withTourStep
        />
      )
    },
    {
      selector: '[data-tour="scan-step"]',
      content: (
        <TourIntro
          title={t('tourSystem.scan.title')}
          description={t('tourSystem.scan.description')}
          withTourStep
        />
      )
    },
    {
      selector: '[data-tour="tag-step"]',
      content: (
        <TourIntro
          title={t('tourSystem.tag.title')}
          description={t('tourSystem.tag.description')}
          withTourStep
        />
      )
    },
    {
      selector: '[data-tour="dashboard-step"]',
      content: (
        <TourIntro
          title={t('tourSystem.dashboard.title')}
          description={t('tourSystem.dashboard.description')}
          withTourStep
        />
      )
    },
    {
      selector: '[data-tour="limits-step"]',
      content: (
        <TourIntro
          title={t('tourSystem.limits.title')}
          description={t('tourSystem.limits.description')}
          withTourStep
        />
      )
    }
  ]

  function handleTutorialOpen() {
    updateUserTutorial({
      variables: {
        id: user?.id
      }
    })
  }

  return (
    <TourProvider
      position={'right'}
      styles={{
        popover: base => ({
          ...base,
          '--reactour-accent': 'red',
          borderRadius: 10,
          background: theme === 'dark' ? '#21262D' : 'white',
          color: theme === 'dark' ? 'white' : 'black',
          maxWidth: '500px'
        }),
        svgWrapper: base => ({
          ...base,
          rx: 10,
          background: 'green !important',
          opacity: '0.9 !important',
          backgroundOpacity: '0.9 !important',
          border: '1px solid yellow !important'
        }),
        maskArea: base => ({
          ...base,
          rx: 10,
          background: 'green !important',
          opacity: '0.9 !important',
          backgroundOpacity: '0.9 !important',
          border: '1px solid yellow !important'
        }),
        maskRect: base => ({
          ...base,
          background: 'red !important',
          opacity: '0.8 !important',
          backgroundOpacity: '0.8 !important',
          border: '1px solid yellow !important'
        }),

        badge: base => ({
          ...base,
          left: '-0.8125em',
          right: 'auto',
          background: '#00DD80',
          color: '#0D1117'
        }),
        controls: base => ({ ...base, marginTop: 60 })
      }}
      showCloseButton={false}
      showNavigation={false}
      afterOpen={handleTutorialOpen}
      disableInteraction
      onClickHighlighted={e => {
        e.stopPropagation()
      }}
      steps={steps}
    >
      {children}
    </TourProvider>
  )
}
