import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReactCountryFlag from 'react-country-flag'
import { Button, ButtonType, EditCard, ToggleSwitch } from 'components'
import { OptOutTexts } from 'hooks/useOptOutVersions'
import { ModalWrapper } from 'components/shared/modal/modal-wrapper'
import 'react-datepicker/dist/react-datepicker.css'
import { BsTranslate } from 'react-icons/bs'
import { classNames } from 'util/shared'
import {
  DisclaimerOptions,
  useDisclaimerConfig
} from 'hooks/useDisclaimerConfig'
import toast from 'react-hot-toast'
import { useHandleError } from 'hooks/useHandleError'
import { AiOutlineLoading } from 'react-icons/ai'
import { LanguagesTypes } from 'util/enums'

interface DetailsModalProps {
  modalOpen: boolean
  setModalOpen: (props: boolean) => void
  setLanguagesList: (props: string[]) => void
  setLanguageDefault: (props: string) => void
}

export interface DisclaimerOptOutData {
  opt_out_email: string
  no_opt_out: boolean
  version: number
  created_at: string
  disclaimer_id: string
  opt_out_texts: OptOutTexts | undefined
}

export const LanguageFlags = {
  en: <ReactCountryFlag countryCode="US" svg />,
  pt: <ReactCountryFlag countryCode="BR" svg />,
  'en-GB': <ReactCountryFlag countryCode="GB" svg />,
  'pt-PT': <ReactCountryFlag countryCode="PT" svg />,
  bg: <ReactCountryFlag countryCode="BG" svg />,
  hr: <ReactCountryFlag countryCode="HR" svg />,
  cs: <ReactCountryFlag countryCode="CZ" svg />,
  da: <ReactCountryFlag countryCode="DK" svg />,
  nl: <ReactCountryFlag countryCode="NL" svg />,
  et: <ReactCountryFlag countryCode="EE" svg />,
  fi: <ReactCountryFlag countryCode="FI" svg />,
  fr: <ReactCountryFlag countryCode="FR" svg />,
  de: <ReactCountryFlag countryCode="DE" svg />,
  el: <ReactCountryFlag countryCode="GR" svg />,
  hu: <ReactCountryFlag countryCode="HU" svg />,
  it: <ReactCountryFlag countryCode="IT" svg />,
  ga: <ReactCountryFlag countryCode="IE" svg />,
  lv: <ReactCountryFlag countryCode="LV" svg />,
  lt: <ReactCountryFlag countryCode="LT" svg />,
  mt: <ReactCountryFlag countryCode="MT" svg />,
  pl: <ReactCountryFlag countryCode="PL" svg />,
  ro: <ReactCountryFlag countryCode="RO" svg />,
  sk: <ReactCountryFlag countryCode="SK" svg />,
  es: <ReactCountryFlag countryCode="ES" svg />,
  'es-LA': <ReactCountryFlag countryCode="MX" svg />,
  sv: <ReactCountryFlag countryCode="SE" svg />,
  hi: <ReactCountryFlag countryCode="IN" svg />,
  zh: <ReactCountryFlag countryCode="CN" svg />,
  'zh-TW': <ReactCountryFlag countryCode="TW" svg />,
  ja: <ReactCountryFlag countryCode="JP" svg />,
  ar: <ReactCountryFlag countryCode="SA" svg />
}

export default function LanguageConfigsModal({
  modalOpen,
  setModalOpen,
  setLanguagesList,
  setLanguageDefault
}: DetailsModalProps) {
  const { t } = useTranslation()
  const { handleError } = useHandleError()

  const [selectedLanguages, setSelectedLanguages] = useState({
    fallBackLanguage: 'pt',
    languagesToUse: Object.keys(LanguageFlags)
  })
  const [optionsData, setOptionsData] = useState<DisclaimerOptions>(
    {} as DisclaimerOptions
  )

  const { data, loading, saveDisclaimerOptions, refetchVersions } =
    useDisclaimerConfig()

  function handleLanguageDefault(language: string) {
    setSelectedLanguages(prev => ({
      ...prev,
      fallBackLanguage: language
    }))
  }

  function handleToggle(language: string) {
    const newLanguagesToUse = [...selectedLanguages.languagesToUse]

    const index = newLanguagesToUse.indexOf(language)

    if (index === -1) {
      newLanguagesToUse.push(language)
    } else {
      newLanguagesToUse.splice(index, 1)
    }

    setSelectedLanguages(prev => ({
      ...prev,
      languagesToUse: newLanguagesToUse
    }))
  }

  useEffect(() => {
    if (modalOpen && data && !loading) {
      setOptionsData({
        consentTTLDays:
          data?.disclaimer_options?.[0]?.consent_ttl_in_days ?? 60,
        hideAfterConsent:
          data?.disclaimer_options?.[0]?.hide_disclaimer ?? false,
        uncheckTags: data?.disclaimer_options?.[0]?.uncheck_tags ?? true,
        manualBlock: data?.disclaimer_options?.[0]?.manual_block ?? false,
        ignoredUrls: data?.disclaimer_options?.[0]?.ignore_urls ?? [],
        fallBackLanguage:
          data?.disclaimer_options?.[0]?.languages_configuration?.fallback ??
          'pt',
        languagesToUse:
          data?.disclaimer_options?.[0]?.languages_configuration?.languages ??
          Object.keys(LanguageFlags)
      })

      setSelectedLanguages({
        fallBackLanguage:
          data?.disclaimer_options?.[0]?.languages_configuration?.fallback ??
          'pt',
        languagesToUse:
          data?.disclaimer_options?.[0]?.languages_configuration?.languages ??
          Object.keys(LanguageFlags)
      })
    }
  }, [modalOpen, data, loading])

  async function saveLanguageConfigs() {
    const { fallBackLanguage, languagesToUse } = selectedLanguages

    const languageDefault = languagesToUse.filter(
      language => fallBackLanguage === language
    )[0]

    if (!languagesToUse.length) {
      handleError(t('modal.languageConfigs.selectLanguageError'))
      return
    }

    if (!languagesToUse.includes(fallBackLanguage)) {
      handleError(t('modal.languageConfigs.selectDefaultError'))
      return
    }

    if (data?.id) {
      toast.loading(t('modal.languageConfigs.loading'))
      const result = await saveDisclaimerOptions(data.id, {
        ...optionsData,
        fallBackLanguage,
        languagesToUse
      })
      toast.dismiss()
      if (result.success) {
        refetchVersions()
        toast.success(t('modal.languageConfigs.savedSuccessfully'))
        setLanguagesList(
          Object.values(LanguagesTypes).filter(language =>
            languagesToUse.includes(language)
          )
        )
        setLanguageDefault(languageDefault)
        setModalOpen(false)
      } else {
        handleError(result?.message)
      }
    } else {
      handleError()
    }
  }

  return (
    <ModalWrapper
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      headerProps={{
        icon: <BsTranslate className="text-primary" size={22} />,
        title: t('modal.languageConfigs.title'),
        subtitle: t('modal.languageConfigs.subTitle')
      }}
      modalSize="medium"
    >
      {loading ? (
        <div className="dark:text-white h-[45vh] flex items-center justify-center">
          <div className="flex items-center justify-center w-5 h-5 mr-3 animate-spin text-primary">
            <AiOutlineLoading size={25} />
          </div>
          <div>{t('modal.languageConfigs.loading')}</div>
        </div>
      ) : (
        <>
          <div className="overflow-y-scroll scroll-1 pr-2 h-[45vh] flex flex-col gap-4">
            {Object.entries(LanguageFlags).map(
              ([languageCode, flag], index) => (
                <EditCard className="dark:border-gray-400" key={index}>
                  <div className="flex justify-between items-center">
                    <div>
                      {t(`languages.${languageCode}`)} {flag}
                      <p className="text-gray-400">{languageCode}</p>
                    </div>
                    <div className="flex gap-4">
                      <button
                        onClick={() => handleLanguageDefault(languageCode)}
                        className={classNames(
                          'text-sm rounded-lg',
                          selectedLanguages?.fallBackLanguage === languageCode
                            ? 'bg-lightblue text-white-200 dark:text-black px-4'
                            : 'underline text-lightblue'
                        )}
                      >
                        {selectedLanguages?.fallBackLanguage === languageCode
                          ? t(`modal.languageConfigs.default`)
                          : t(`modal.languageConfigs.setAsDefault`)}
                      </button>
                      <ToggleSwitch
                        className={classNames(
                          selectedLanguages?.languagesToUse?.includes(
                            languageCode
                          )
                            ? 'bg-primary-500 border-primary-500 '
                            : 'dark:bg-gray-200 dark:border-gray-200'
                        )}
                        state={selectedLanguages?.languagesToUse?.includes(
                          languageCode
                        )}
                        setState={() => handleToggle(languageCode)}
                      />
                    </div>
                  </div>
                </EditCard>
              )
            )}
          </div>
          <div className="flex justify-end mt-5">
            <Button
              buttonType={ButtonType.Secondary}
              onClick={() => setModalOpen(false)}
              className="dark:bg-black mr-2"
            >
              {t('modal.createDisclaimer.Cancel')}
            </Button>
            <Button onClick={() => saveLanguageConfigs()}>
              {t('modal.createDisclaimer.Save')}
            </Button>
          </div>
        </>
      )}
    </ModalWrapper>
  )
}
