import React, { Component, ReactNode } from 'react'
import GlobalError from './global-error'
import 'react-datepicker/dist/react-datepicker.css'

interface Props {
  children?: ReactNode
}

interface State {
  hasError: boolean
}

export class GlobalErrorBoundary extends Component<Props, State> {
  state = { hasError: false, message: '' }

  static getDerivedStateFromError(error: any) {
    return {
      hasError: true,
      message: error.message
    }
  }

  handleRerenderButtonClick = () => {
    this.setState({ hasError: false })
    this.forceUpdate()
  }

  render() {
    if (this.state.hasError) {
      return (
        <GlobalError
          errorMessage={this.state.message}
          resetError={this.handleRerenderButtonClick}
        />
      )
    }

    return this.props.children
  }
}
