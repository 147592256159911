import React, { useState } from 'react'
import { useStorage } from 'contexts/storage-context'
import { useTranslation } from 'next-i18next'
import toast from 'react-hot-toast'
import { useRouter } from 'next/router'
import { EditDisclaimerModal } from '../modal/edit-disclaimer'
import TransferDisclaimer from '../modal/transfer-disclaimer'
import ConfirmModal from '../modal/confirm-modal'
import { IoIosWarning } from 'react-icons/io'
import { BLOCK_DISCLAIMER, DELETE_DISCLAIMER } from 'queries/disclaimers'
import { useMutation } from '@apollo/client'
import { useHandleError } from 'hooks/useHandleError'
import { DisclaimerData } from 'models/disclaimer'
import { Dropdown } from '.'
import ReplicateModal from 'components/modal/replicate-modal'

interface DisclaimerEditDropdownProps {
  children: React.ReactNode
  disclaimerData: DisclaimerData
  availableItems: Array<Items>
  refetch: () => void
}

type Items =
  | 'configure'
  | 'edit'
  | 'replicate'
  | 'transfer'
  | 'copyToClipboard'
  | 'delete'
  | 'block'

export default function DisclaimerEditDropdown({
  children,
  disclaimerData,
  availableItems,
  refetch
}: DisclaimerEditDropdownProps) {
  const { t } = useTranslation()
  const { handleError } = useHandleError()
  const router = useRouter()
  const { activeOrganizationDetails } = useStorage()
  const [openModal, setOpenModal] = useState<Items | undefined>()

  const [deleteDisclaimer] = useMutation(DELETE_DISCLAIMER)
  const [blockDisclaimer] = useMutation(BLOCK_DISCLAIMER)

  async function handleDeleteDisclaimer() {
    toast.loading(`${t('org.disclaimers.Saving')}...`)
    try {
      await deleteDisclaimer({
        variables: {
          id: disclaimerData?.id
        }
      })
      toast.dismiss()
      toast.success(`${t('org.disclaimers.Disclaimer deleted')}`)

      refetch()
      setOpenModal(undefined)
    } catch (error) {
      toast.dismiss()
      handleError()
    }
  }

  async function handleBlockDisclaimer() {
    toast.loading(t('org.disclaimers.changing'))
    try {
      const disclaimerStatus = disclaimerData?.disclaimer_status?.name
      const result = await blockDisclaimer({
        variables: {
          disclaimerId: disclaimerData?.id,
          unblock:
            disclaimerStatus !== 'offline' && disclaimerStatus !== 'paused'
              ? false
              : true
        }
      })
      toast.dismiss()
      if (result?.data?.block_disclaimer?.success) {
        refetch()
        toast.success(`${t('org.disclaimers.statusChanged')}`)
      } else {
        handleError()
      }
    } catch (error: any) {
      toast.dismiss()
      handleError()
    }
  }

  const items: Record<Items, { label: string; onClick: () => void }> = {
    configure: {
      label: t('org.disclaimers.Configure'),
      onClick: () =>
        router.push(
          `/org/${activeOrganizationDetails?.pathname}/disclaimer/${disclaimerData?.pathname}`
        )
    },
    edit: {
      label: t('org.disclaimers.Edit'),
      onClick: () => setOpenModal('edit')
    },
    replicate: {
      label: t('modal.replicateWarningModal.title'),
      onClick: () => setOpenModal('replicate')
    },
    transfer: {
      label: t('org.disclaimers.Transfer'),
      onClick: () => setOpenModal('transfer')
    },
    copyToClipboard: {
      label: t('org.disclaimers.copyToClipboard'),
      onClick: () => {
        navigator.clipboard.writeText(disclaimerData?.id)
        toast.success(`${t('org.disclaimers.copied')}!`)
      }
    },
    block: {
      label: t('org.disclaimers.block'),
      onClick: handleBlockDisclaimer
    },
    delete: {
      label: t('org.disclaimers.Delete'),
      onClick: () => setOpenModal('delete')
    }
  }

  return (
    <>
      <Dropdown
        items={availableItems?.map(item => ({
          onClick: items[item].onClick,
          label: items[item].label
        }))}
      >
        {children}
      </Dropdown>

      <EditDisclaimerModal
        open={openModal === 'edit'}
        handleClose={() => setOpenModal(undefined)}
        disclaimerData={{
          disclaimerId: disclaimerData?.id,
          pathName: disclaimerData?.pathname,
          urls: disclaimerData?.disclaimer_websites.map(
            (website: { url: string }) => website.url
          )
        }}
        handleEdit={() => refetch()}
      ></EditDisclaimerModal>
      <ReplicateModal
        modalOpen={openModal === 'replicate'}
        setModalOpen={() => setOpenModal(undefined)}
        disclaimerData={disclaimerData}
        refetchDisclaimers={refetch}
      />
      <TransferDisclaimer
        open={openModal === 'transfer'}
        handleClose={() => setOpenModal(undefined)}
        refetch={refetch}
        disclaimerId={disclaimerData?.id}
      />
      <ConfirmModal
        modalOpen={openModal === 'delete'}
        setModalOpen={() => setOpenModal(undefined)}
        title={t('org.disclaimers.Are you sure delete this disclaimer?')}
        subtitle={t(
          "org.disclaimers.If you delete the disclaimer you can't recover it"
        )}
        buttonText={t('org.settings.Delete')}
        cancelText={t('org.settings.Cancel')}
        icon={<IoIosWarning size={30} className="text-pink" />}
        buttonAction={handleDeleteDisclaimer}
      />
    </>
  )
}
