import { CodeArea, BodyText, SpinLoading } from 'components'
import { MdContentCopy } from 'react-icons/md'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'next-i18next'

interface CardCodeAreaProps {
  loading?: boolean
  copyButton?: boolean
  codeAreaValue: string
  title?: string
  copiedText?: string
}

export function CardCodeArea({
  title,
  loading = false,
  codeAreaValue,
  copiedText,
  copyButton = true
}: CardCodeAreaProps) {
  const { t } = useTranslation()
  return (
    <div className="w-full bg-white-200 dark:bg-gray py-3 px-2 rounded-md overflow-hidden relative">
      {(title || copyButton) && (
        <div className="flex justify-between items-center mb-4">
          {title && <BodyText className="!text-gray-200">{title}</BodyText>}
          {copyButton && (
            <div
              onClick={() => {
                if (!loading) {
                  navigator.clipboard.writeText(codeAreaValue)
                  if (copiedText) {
                    toast.success(copiedText)
                  }
                }
              }}
              className="px-2 py-1 cursor-pointer bg-white-300 dark:bg-gray-400 hover:dark:bg-primary rounded-md text-gray hover:text-black hover:bg-primary duration-200 flex items-center gap-2"
            >
              <div>
                <MdContentCopy />
              </div>
              <div>{t('disclaimer.installation.copy')}</div>
            </div>
          )}
        </div>
      )}

      {!loading ? (
        <CodeArea value={codeAreaValue} />
      ) : (
        <div className="h-20 flex items-center justify-center">
          <SpinLoading />
        </div>
      )}
    </div>
  )
}
