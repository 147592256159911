import { Environment } from 'config/app-config'

export type PlanTypes =
  | 'starter'
  | 'business_monthly'
  | 'business_yearly'
  | 'enterprise_monthly'
  | 'enterprise_yearly'

export enum PlanType {
  Starter = 'Starter',
  Business = 'Business',
  Enterprise = 'Enterprise'
}

export enum PlanRecurring {
  Month = 'month',
  Year = 'year'
}

export enum SubscriptionPlanUpdateStatus {
  SubscriptionChangeRequested = 'subscriptionChangeRequested',
  SubscriptionChangeCompleted = 'subscriptionChangeCompleted',
  PlanUpdateStarted = 'planUpdateStarted',
  PlanUpdateCompleted = 'planUpdateCompleted',

  SubscriptionChangeError = 'subscriptionChangeError',
  SubscriptionUpdateError = 'subscriptionUpdateError',
  UpdateNotFinalized = 'updateNotFinalized'
}

export const subscriptionPlanUpdateWithError = [
  SubscriptionPlanUpdateStatus.SubscriptionChangeError,
  SubscriptionPlanUpdateStatus.SubscriptionUpdateError,
  SubscriptionPlanUpdateStatus.UpdateNotFinalized
]

export const PlanPrices = {
  [Environment.Dev]: {
    [PlanRecurring.Month]: {
      [PlanType.Starter]: 'price_1K20BNGMtSA7FK8f1XA45tLU',
      [PlanType.Business]: 'price_1OJi3zGMtSA7FK8fcUV9WsnZ',
      [PlanType.Enterprise]: 'price_1OMbr8GMtSA7FK8fbViBZsJ0'
    },
    [PlanRecurring.Year]: {
      [PlanType.Starter]: 'price_1K20BNGMtSA7FK8f1XA45tLU',
      [PlanType.Business]: 'price_1OMbqmGMtSA7FK8fqJuqAs4V',
      [PlanType.Enterprise]: 'price_1OREXpGMtSA7FK8fAi2Y5zhF'
    }
  },
  [Environment.Prod]: {
    [PlanRecurring.Month]: {
      [PlanType.Starter]: 'price_1JyJk9GMtSA7FK8f9D1CACHh',
      [PlanType.Business]: 'price_1ORZ0nGMtSA7FK8fPHFQZY7k',
      [PlanType.Enterprise]: 'price_1ORYrAGMtSA7FK8fnrtJ3p56'
    },
    [PlanRecurring.Year]: {
      [PlanType.Starter]: 'price_1JyJk9GMtSA7FK8f9D1CACHh',
      [PlanType.Business]: 'price_1ORZ15GMtSA7FK8fhz5nuZGS',
      [PlanType.Enterprise]: 'price_1ORYrbGMtSA7FK8fkhtkRCXe'
    }
  }
}
