import React from 'react'
import { H2, Paragraph } from 'components'
import { classNames } from 'util/shared'

interface EditCardProps {
  title?: string
  description?: string
  className?: string
  children: React.ReactNode
}

export function EditCard({
  title,
  description,
  children,
  className
}: EditCardProps) {
  return (
    <div
      className={classNames(
        `
        px-5 py-5 dark:text-white  
        hover:border-primary border-gray-300 border-opacity-30 
        dark:border-opacity-100 dark:border-gray dark:hover:border-primary 
        duration-700 border rounded-lg`,
        className
      )}
    >
      {title && <H2 fontWeight="bold">{title}</H2>}
      {description && <Paragraph>{description}</Paragraph>}
      <div className="w-full">{children}</div>
    </div>
  )
}
