import { Role, RoleImportance } from "./enums";

export const Validator = (function () {
  const validationRegex = {
    uid: /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i,
    whitespace: /[ ]/g,
    url: /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i,
    email:
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  };

  return {
    isValidEmail: function (str: string): boolean {
      return validationRegex.email.test(str);
    },

    isValidUrl: function (str: string): boolean {
      return validationRegex.url.test(str);
    },
    isValidUid: function (str: string): boolean {
      return validationRegex.uid.test(str);
    },
    hasWhitespace: function (str: string): boolean {
      return validationRegex.whitespace.test(str);
    },
    hasPermission: function (minimumRole: Role, userRole?: Role): boolean {
      if (!userRole || RoleImportance[minimumRole] > RoleImportance[userRole])
        return false;
      return true;
    },
    isValidPhoneNumber: function (str: string): boolean {
      return str?.length >= 10 && str?.length <= 16;
    },
    isValidPassword: function (str: string): boolean {
      const specialCaracter = /[$&+,:.;=?@#|'<>^*()%!-]/g.test(str);
      const lowercase = /[a-z]/g.test(str);
      const uppercase = /[A-Z]/g.test(str);
      const number = /[0-9]/g.test(str);
      const length = str.length >= 8;

      return specialCaracter && lowercase && uppercase && number && length;
    },
  };
})();
