import { gql } from '@apollo/client'

export const GET_DOCUMENTS = gql`
  query GetDocuments($organizationId: uuid!) {
    document_master(
      where: { organization_id: { _eq: $organizationId } }
      order_by: { created_at: desc }
    ) {
      id
      created_at
      document_type
      disclaimer_documents {
        id
      }
      disclaimerDocumentsByDocumentMasterTermsId {
        id
      }
      disclaimerDocumentsByDocumentMasterPrivacyId {
        id
      }
      document_cookies(limit: 1, order_by: { version: desc }) {
        document_name
        updated_at
        document_cookies_texts {
          language
        }
      }
      document_privacies(limit: 1, order_by: { version: desc }) {
        document_name
        updated_at
        document_privacy_texts {
          language
        }
      }
      document_terms(limit: 1, order_by: { version: desc }) {
        document_name
        updated_at
        document_terms_texts {
          language
        }
      }
    }
  }
`

export const GET_DOCUMENT_BY_ID = gql`
  query GetDocumentById($documentId: uuid!) {
    document_master(where: { id: { _eq: $documentId } }) {
      document_cookies(limit: 1, order_by: { version: desc }) {
        document_name
        version
        document_cookies_texts {
          generated_text
          language
          url
          is_upload
        }
      }
      document_privacies(limit: 1, order_by: { version: desc }) {
        document_name
        version
        document_privacy_texts {
          generated_text
          language
          url
          is_upload
        }
      }
      document_terms(limit: 1, order_by: { version: desc }) {
        document_name
        version
        document_terms_texts {
          generated_text
          language
          url
          is_upload
        }
      }
    }
  }
`

export const GET_DOCUMENTS_BY_DISCLAIMER_ID = gql`
  query GetDocumentsByDisclaimerId($disclaimerId: uuid!) {
    disclaimer_documents(
      where: { disclaimer_id: { _eq: $disclaimerId } }
      order_by: { version: desc }
      limit: 1
    ) {
      document_master {
        document_cookies(order_by: { version: desc }, limit: 1) {
          document_master_id
          has_doc
          document_name
          document_cookies_texts {
            language
            is_upload
            generated_text
            url
          }
        }
      }
      documentMasterByDocumentMasterPrivacyId {
        document_privacies(order_by: { version: desc }, limit: 1) {
          document_master_id
          has_doc
          document_name
          document_privacy_texts {
            is_upload
            language
            url
            generated_text
          }
        }
      }
      documentMasterByDocumentMasterTermsId {
        document_terms(order_by: { version: desc }, limit: 1) {
          document_master_id
          has_doc
          document_name
          document_terms_texts {
            language
            is_upload
            generated_text
            url
          }
        }
      }
    }
  }
`

export const GET_VERSIONS_BY_DISCLAIMER_ID = gql`
  query GetVersionsByDisclaimerId($disclaimerId: uuid!) {
    disclaimer_documents(
      where: { disclaimer_id: { _eq: $disclaimerId } }
      order_by: { version: desc }
      limit: 1
    ) {
      document_master {
        document_cookies(order_by: { version: desc }) {
          version
          document_master_id
          document_name
          updated_at
          has_doc
          document_cookies_texts {
            language
            generated_text
            is_upload
            url
          }
        }
      }
      documentMasterByDocumentMasterPrivacyId {
        document_privacies(order_by: { version: desc }) {
          version
          document_master_id
          document_name
          updated_at
          has_doc
          document_privacy_texts {
            language
            generated_text
            is_upload
            url
          }
        }
      }
      documentMasterByDocumentMasterTermsId {
        document_terms(order_by: { version: desc }) {
          version
          updated_at
          document_master_id
          document_name
          has_doc
          document_terms_texts {
            language
            generated_text
            is_upload
            url
          }
        }
      }
    }
  }
`

export const RESTORE_DOCUMENT = gql`
  mutation ($masterDocumentId: String!, $version: Int!) {
    restore_document_version(
      object: { masterDocumentId: $masterDocumentId, version: $version }
    ) {
      success
      message
      errorCode
      errorDetails
    }
  }
`

export const GET_QUESTIONS = gql`
  query Questions($documentType: String!) {
    questions(where: { document_type: { _eq: $documentType } }) {
      category
      document_type
      id
      question_order
      question_type
      questions_texts {
        id
        document_language
        text
        question_options_texts {
          value
          id
          name
          question_text_id
        }
      }
      unlocked_by_question_id
      unlocks_question_id
    }
  }
`

export const CREATE_COOKIE_POLICY = gql`
  mutation CreateCookiePolicy(
    $disclaimerId: String
    $documentName: String
    $language: String!
    $organizationId: String!
    $masterDocumentId: String
    $answers: [AnswerObject]!
    $jsonStyle: jsonb
  ) {
    create_cookie_policy_document(
      object: {
        disclaimerId: $disclaimerId
        language: $language
        organizationId: $organizationId
        masterDocumentId: $masterDocumentId
        documentName: $documentName
        answers: $answers
        json_style: $jsonStyle
      }
    ) {
      data {
        completeText
        documentCookieId
        documentMasterId
        version
      }
      errorCode
      errorDetails
      message
      success
    }
  }
`

export const CREATE_PRIVACY_POLICY = gql`
  mutation CreatePrivacyPolicy(
    $disclaimerId: String
    $language: String!
    $organizationId: String!
    $docMasterId: String
    $documentName: String
    $answers: [Answer]!
    $jsonStyle: jsonb
    $scanId: String
  ) {
    generate_privacy_document(
      object: {
        disclaimerId: $disclaimerId
        language: $language
        organizationId: $organizationId
        docMasterId: $docMasterId
        documentName: $documentName
        answers: $answers
        json_style: $jsonStyle
        scanId: $scanId
      }
    ) {
      data {
        errorList {
          message
          questionId
          errorCodeKey
        }
        completeText
        documentPrivacyId
        documentMasterId
        version
      }
      errorCode
      errorDetails
      message
      success
    }
  }
`

export const ADD_EDIT_COOKIE_DOCUMENT = gql`
  mutation AddEditCookieDocument(
    $disclaimerId: String
    $language: String!
    $organizationId: String!
    $masterDocumentId: String
    $base64: String
    $text: String
    $url: String
    $documentName: String
    $noDoc: Boolean
  ) {
    add_edit_cookie_document(
      object: {
        disclaimerId: $disclaimerId
        language: $language
        organizationId: $organizationId
        masterDocumentId: $masterDocumentId
        base64: $base64
        text: $text
        url: $url
        documentName: $documentName
        noDoc: $noDoc
      }
    ) {
      data {
        url
        completeText
        documentCookieId
        documentPrivacyId
        documentMasterId
        language
        version
      }
      errorCode
      errorDetails
      message
      success
    }
  }
`

export const ADD_EDIT_PRIVACY_DOCUMENT = gql`
  mutation AddEditPrivacyDocument(
    $disclaimerId: String
    $language: String!
    $organizationId: String!
    $masterDocumentId: String
    $base64: String
    $text: String
    $url: String
    $documentName: String
    $noDoc: Boolean
  ) {
    add_edit_privacy_document(
      object: {
        disclaimerId: $disclaimerId
        language: $language
        organizationId: $organizationId
        masterDocumentId: $masterDocumentId
        base64: $base64
        text: $text
        url: $url
        documentName: $documentName
        noDoc: $noDoc
      }
    ) {
      data {
        url
        completeText
        documentCookieId
        documentPrivacyId
        documentMasterId
        language
        version
      }
      errorCode
      errorDetails
      message
      success
    }
  }
`

export const ADD_EDIT_TERMS_DOCUMENT = gql`
  mutation AddEditTermsDocument(
    $disclaimerId: String
    $language: String!
    $organizationId: String!
    $masterDocumentId: String
    $base64: String
    $text: String
    $url: String
    $documentName: String
    $noDoc: Boolean
  ) {
    add_edit_terms_document(
      object: {
        disclaimerId: $disclaimerId
        language: $language
        organizationId: $organizationId
        masterDocumentId: $masterDocumentId
        base64: $base64
        text: $text
        url: $url
        documentName: $documentName
        noDoc: $noDoc
      }
    ) {
      data {
        url
        completeText
        documentCookieId
        documentPrivacyId
        documentMasterId
        language
        version
      }
      errorCode
      errorDetails
      message
      success
    }
  }
`

export const ADD_DOCUMENT_TO_DISCLAIMER = gql`
  mutation AddDocumentToDisclaimer(
    $masterDocumentId: String!
    $disclaimerIds: [String]!
  ) {
    add_document_to_disclaimer(
      object: {
        masterDocumentId: $masterDocumentId
        disclaimerIds: $disclaimerIds
      }
    ) {
      data {
        disclaimer_id
        document_master_cookies_id
        document_master_privacy_id
        document_master_terms_id
      }
      errorCode
      errorDetails
      message
      success
    }
  }
`

export const DELETE_DOCUMENT = gql`
  mutation DeleteDocument($docMasterId: String!) {
    delete_document(object: { docMasterId: $docMasterId }) {
      errorCode
      errorDetails
      message
      success
    }
  }
`

export const ADD_SCAN_FOR_AI = gql`
  mutation AddScanForAi($url: String!, $testBefore: Boolean) {
    add_scan_for_AI(object: { url: $url, testBefore: $testBefore }) {
      errorCode
      errorDetails
      data {
        aiScanId
      }
      message
      success
    }
  }
`

export const LIST_DOCUMENTS = gql`
  mutation ListDocuments(
    $organizationId: String!
    $documentMasterIds: [String]
  ) {
    list_documents(
      object: {
        organizationId: $organizationId
        documentMasterIds: $documentMasterIds
      }
    ) {
      errorCode
      errorDetails
      data {
        document_master_id
        disclaimer_quantity
        disclaimers {
          id
          pathname
        }
      }
      message
      success
    }
  }
`

export const DELETE_LANGUAGE_FROM_DOCUMENT = gql`
  mutation DeleteLanguageFromDocument(
    $masterDocumentId: String!
    $language: String!
  ) {
    delete_language_from_document(
      object: { masterDocumentId: $masterDocumentId, language: $language }
    ) {
      errorCode
      errorDetails
      message
      success
    }
  }
`
