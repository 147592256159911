import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'next-i18next'
import { AiOutlinePlus } from 'react-icons/ai'

import { Dropdown } from '../dropdown'
import { useDisclaimerConfig } from 'hooks/useDisclaimerConfig'
import { LanguagesTypes } from 'util/enums'
import { TiDeleteOutline } from 'react-icons/ti'
import toast from 'react-hot-toast'
import { useHandleError } from 'hooks/useHandleError'
import { CustomTooltip } from '../tooltip/customTooltip'
import ConfirmModal from '../modal/confirm-modal'
import { RiDeleteBinLine } from 'react-icons/ri'
interface LanguagesOptions {
  name: string
  value: string
}

interface DeleteLanguageResponse {
  success: boolean
  message?: string
}

interface CountriesChipSelectProps {
  languageSelected: string
  setLanguageSelected: Dispatch<SetStateAction<string>>
  languageButtons: string[]
  setLanguageButtons: Dispatch<SetStateAction<string[]>>
  deleteLanguageFromDocument: (
    masterDocumentId: string,
    language: string
  ) => Promise<DeleteLanguageResponse>
  masterDocumentIds: string[]
}

const LanguageFlags = {
  it: '🇮🇹',
  pt: '🇧🇷',
  en: '🇺🇸',
  bg: '🇧🇬',
  hr: '🇭🇷',
  cs: '🇨🇿',
  da: '🇩🇰',
  nl: '🇳🇱',
  et: '🇪🇪',
  fi: '🇫🇮',
  fr: '🇫🇷',
  de: '🇩🇪',
  el: '🇬🇷',
  hu: '🇭🇺',
  ga: '🇮🇪',
  lv: '🇱🇻',
  lt: '🇱🇹',
  mt: '🇲🇹',
  pl: '🇵🇱',
  ro: '🇷🇴',
  sk: '🇸🇰',
  es: '🇪🇸',
  sv: '🇸🇪',
  hi: '🇮🇳',
  zh: '🇨🇳',
  ja: '🇯🇵',
  ar: '🇸🇦',
  'pt-PT': '🇵🇹',
  'en-GB': '🇬🇧',
  'es-LA': '🇲🇽',
  'zh-TW': '🇹🇼'
}

const showCountriesQuantity = 4

export function CountriesChipSelect({
  languageSelected,
  setLanguageSelected,
  languageButtons,
  setLanguageButtons,
  deleteLanguageFromDocument,
  masterDocumentIds
}: CountriesChipSelectProps) {
  const { t } = useTranslation()
  const { handleError } = useHandleError()

  const [notSavedLanguages, setNotSavedLanguages] = useState<string[]>([])
  const [languagesList, setLanguagesList] = useState<LanguagesOptions[]>([])
  const [currentLanguageHover, setCurrentLanguageHover] = useState('')
  const [confirmModal, setConfirmModal] = useState({
    open: false,
    language: ''
  })

  const { data: disclaimerData, loading: disclaimerLoading } =
    useDisclaimerConfig()

  useEffect(() => {
    if (!disclaimerLoading) {
      const languagesData =
        disclaimerData?.disclaimer_options?.[0]?.languages_configuration
          ?.languages ?? Object.values(LanguagesTypes)

      const languagesFormatted = languagesData?.map((language: string) => ({
        value: language,
        name: t(`languages.${language}`)
      }))

      setLanguagesList(languagesFormatted)
    }
  }, [disclaimerData, disclaimerLoading])

  function handleAddNewLanguage(value: string) {
    setLanguageButtons(prev => [...prev, value])
    setNotSavedLanguages(prev => [...prev, value])
  }

  function handleLanguageSelected(value: string) {
    const indexToMove = languageButtons.indexOf(value)
    const itemToMove = languageButtons.splice(indexToMove, 1)[0]
    languageButtons.splice(2, 0, itemToMove)
    setLanguageSelected(value)
  }

  const dropdownLanguageOptions = useMemo(
    () =>
      languagesList
        .filter(({ value }) => !languageButtons?.includes(value))
        .map(({ name, value }) => ({
          label: name,
          onClick: () => handleAddNewLanguage(value)
        })),
    [languageButtons, languagesList]
  )

  const dropdownLanguageButtonOptions = useMemo(
    () =>
      languagesList
        .filter(({ value }) => languageButtons?.includes(value))
        .map(({ name, value }) => ({
          label: name,
          onClick: () => handleLanguageSelected(value)
        })),
    [languageButtons, languagesList]
  )

  function isLanguageNotSaved(language: string) {
    return !!notSavedLanguages.find(ln => ln === language)
  }

  async function handleDeleteLanguage() {
    const isMasterDocumentIdsEmpty =
      masterDocumentIds.filter(Boolean).length === 0

    if (isLanguageNotSaved(confirmModal.language) || isMasterDocumentIdsEmpty) {
      setLanguageButtons(prev =>
        prev.filter(ln => ln !== confirmModal.language)
      )
      setNotSavedLanguages(prev =>
        prev.filter(ln => ln !== confirmModal.language)
      )
      setConfirmModal(() => ({ open: false, language: '' }))
      return
    }

    toast.loading(t('org.documents.deleteLanguageFromDocument'))

    try {
      const deletePromises = masterDocumentIds
        .filter(Boolean)
        .map(masterDocumentId =>
          deleteLanguageFromDocument(masterDocumentId, confirmModal.language)
        )
      const results = await Promise.all(deletePromises)

      toast.dismiss()
      if (results.every(res => res.success)) {
        toast.success(t('org.documents.deleteLanguageFromDocumentSuccess'))
        setLanguageButtons(prev =>
          prev.filter(ln => ln !== confirmModal.language)
        )
        setNotSavedLanguages(prev =>
          prev.filter(ln => ln !== confirmModal.language)
        )
        setConfirmModal(() => ({ open: false, language: '' }))
      } else {
        results.forEach(res => {
          if (!res.success) {
            handleError(res?.message)
          }
        })
      }
    } catch {
      toast.dismiss()
      handleError()
    }
  }

  return (
    <div className="flex gap-4">
      <div className="flex -space-x-2">
        {languageButtons?.slice(0, showCountriesQuantity).map(language => (
          <div
            onMouseEnter={() => setCurrentLanguageHover(language)}
            onMouseLeave={() => setCurrentLanguageHover('')}
            onClick={() => {
              setLanguageSelected(language)
            }}
            className={`h-10 flex items-center rounded-full whitespace-nowrap border border-gray-200 cursor-pointer bg-white-200 dark:bg-gray duration-300 ${
              languageSelected === language
                ? 'border-primary px-6 z-10'
                : 'hover:border-gray-400 dark:hover:border-white-200 p-3'
            }`}
          >
            <div
              className={`flex gap-2 items-center ${
                languageSelected === language ? 'border-primary' : ''
              }`}
            >
              {currentLanguageHover === language ||
              languageSelected === language ? (
                <div className="flex items-center gap-2">
                  <span
                    className={`${
                      languageSelected === language
                        ? 'text-primary'
                        : 'text-gray dark:text-white-200'
                    }`}
                  >
                    {t(`languages.${language}`)}
                  </span>
                  {currentLanguageHover === language &&
                    languageButtons.length > 1 && (
                      <CustomTooltip
                        content={t('org.documents.deleteLanguageTooltip')}
                      >
                        <button
                          onClick={() =>
                            setConfirmModal(() => ({
                              open: true,
                              language
                            }))
                          }
                        >
                          <TiDeleteOutline
                            size={24}
                            className="text-gray-250 hover:text-gray dark:hover:text-white duration-300"
                          />
                        </button>
                      </CustomTooltip>
                    )}
                </div>
              ) : (
                LanguageFlags[language as keyof typeof LanguageFlags]
              )}
            </div>
          </div>
        ))}
        {languageButtons?.length > showCountriesQuantity && (
          <Dropdown
            items={dropdownLanguageButtonOptions}
            classes="max-h-52 overflow-y-scroll scroll-1 !-left-52"
          >
            <div
              className={`text-gray-400 dark:text-white-200 h-10 p-2 flex items-center rounded-full border border-gray-200 bg-white-200 hover:border-gray-400 dark:bg-gray dark:hover:border-white-200 duration-300 `}
            >
              +{languageButtons?.length - showCountriesQuantity}
            </div>
          </Dropdown>
        )}
        {languagesList?.length !== languageButtons?.length && (
          <Dropdown
            items={dropdownLanguageOptions}
            classes="max-h-52 overflow-y-scroll scroll-1 !-left-52"
          >
            <div
              className={`p-2 flex items-center   rounded-full border border-gray-200 cursor-pointer bg-white-200 hover:border-gray-400 dark:bg-gray dark:hover:border-white-200 duration-300 `}
            >
              <AiOutlinePlus
                className="text-gray-400 dark:text-white-200"
                size={22}
              />
            </div>
          </Dropdown>
        )}
      </div>

      <ConfirmModal
        modalOpen={confirmModal.open}
        setModalOpen={open => setConfirmModal(prev => ({ ...prev, open }))}
        title={`${t('modal.confirmationDeleteDocumentsLanguage.title')} ${t(
          `languages.${confirmModal.language}`
        )}`}
        subtitle={t('modal.confirmationDeleteDocumentsLanguage.subtitle')}
        buttonText={t('modal.confirmationDeleteDocumentsLanguage.delete')}
        cancelText={t('modal.confirmationDeleteDocumentsLanguage.cancel')}
        icon={
          <RiDeleteBinLine
            size={44}
            className="text-pink rounded-full border-2 border-pink p-2"
          />
        }
        buttonAction={handleDeleteLanguage}
      />
    </div>
  )
}
