/* eslint-disable import/named */
import React, { ButtonHTMLAttributes } from 'react'
import { IconType } from 'react-icons/lib'
import { VscLoading } from 'react-icons/vsc'
import { classNames } from 'util/shared'

interface PillButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  buttonType?: PillButtonType
  disabled?: boolean
  className?: string
  children?: any
  onClick?: any
  id?: string
  loading?: boolean
  iconLeft?: IconType
  iconRight?: IconType
}

export enum PillButtonType {
  Default = 'default',
  Selected = 'selected'
}

export function PillButton({
  buttonType = PillButtonType.Default,
  disabled,
  className,
  children,
  onClick,
  id,
  loading,
  iconLeft: IconLeft,
  iconRight: IconRight,
  ...props
}: PillButtonProps) {
  const sharedClasses = `rounded-full duration-200 px-6 h-10
  relative text-black font-bold flex items-center justify-center disabled:bg-white-200 disabled:dark:bg-gray disabled:dark:text-primary
 
  `

  const classes = {
    [PillButtonType.Default]: `${sharedClasses} 
     border  
     dark:bg-gray dark:border-gray dark:text-gray-250 dark:hover:bg-gray-500 dark:hover:border-gray-500 dark:gray-250 dark:hover:text-white dark:focus:border-gray-500 dark:focus:text-white dark:disabled:bg-black dark:disabled:border-black dark:disabled:gray-500
     bg-white-200 border-white-200 hover:bg-gray-75 disabled:bg-pink
  `,
    [PillButtonType.Selected]: `${sharedClasses}
     border border-primary dark:border-primary
     dark:bg-gray dark:text-primary dark:hover:bg-gray-500 dark:disabled:bg-black dark:disabled:gray-500
     bg-white-200 hover:bg-gray-75 text-primary
    `
  }

  let isCustomWidth = false

  if (className?.startsWith('w')) isCustomWidth = true

  return (
    <button
      id={id}
      type="submit"
      className={classNames(classes[buttonType], className)}
      disabled={disabled ?? false}
      onClick={onClick}
      {...props}
    >
      {IconLeft && !loading && (
        <span className={`pr-3 ${isCustomWidth && 'mr-auto'}`}>
          <IconLeft className="h-5 w-5" />
        </span>
      )}
      {loading && (
        <span className={`pr-3 ${isCustomWidth && 'mr-auto'}`}>
          <VscLoading className="h-5 w-5 animate-spin" />
        </span>
      )}
      <div className={`flex items-center ${isCustomWidth && 'absolute'}`}>
        {children}
      </div>
      {IconRight && !loading && (
        <span className={`pl-3 ${isCustomWidth && 'ml-auto'}`}>
          <IconRight className="h-5 w-5" />
        </span>
      )}
    </button>
  )
}
