import React from 'react'
import NextLink from 'next/link'
import PropTypes from 'prop-types'

export function Link({ target, href, children, className, ...props }: any) {
  return (
    <NextLink href={href} className={className} {...props}>
      <a className={className} target={target}>
        {children}
      </a>
    </NextLink>
  )
}

Link.prototype = {
  href: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired
}
