import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import 'react-datepicker/dist/react-datepicker.css'
import ptBR from 'date-fns/locale/pt-BR'
import { registerLocale } from 'react-datepicker'
import { H3, Select, Checkbox, Button, ButtonType } from 'components'
import { useMutation, useQuery } from '@apollo/client'
import { OrganizationsData } from 'hooks/storage/useOrganizations'
import { useStorage } from 'contexts/storage-context'
import { GET_DISCLAIMERS_SIMPLIFIED } from 'queries/organization'
import { useRouter } from 'next/router'
import { CLONE_DISCLAIMER } from 'queries/disclaimers'
import toast from 'react-hot-toast'
import { MdContentCopy } from 'react-icons/md'
import { DisclaimerData } from 'models/disclaimer'
import { ModalWrapper } from 'components/shared/modal/modal-wrapper'
import { useMutationHandler } from 'hooks/useMutationHandler'

registerLocale('pt', ptBR)

const optionsInTag = [
  {
    name: 'style'
  },
  {
    name: 'policy'
  },
  {
    name: 'terms'
  },
  {
    name: 'optout'
  },
  {
    name: 'options'
  }
]

interface Checkboxes {
  style: boolean
  policy: boolean
  terms: boolean
  optout: boolean
  options: boolean
}

interface DisclaimerSelectedType {
  name: string
  value: string
}

export default function ReplicateModal({
  modalOpen,
  setModalOpen,
  disclaimerData,
  refetchDisclaimers
}: ReplicateModalWarningProps) {
  const { organizationsData } = useStorage()

  const router = useRouter()

  const [organizationSelected, setOrganizationSelected] =
    useState<OrganizationsData>({} as OrganizationsData)

  const [disclaimerSelected, setDisclaimerSelected] =
    useState<DisclaimerSelectedType>({} as DisclaimerSelectedType)

  const { data: disclaimersData } = useQuery(GET_DISCLAIMERS_SIMPLIFIED, {
    fetchPolicy: 'network-only',
    variables: { pathname: organizationSelected.pathname ?? router.pathname }
  })

  useEffect(() => {
    if (organizationsData) setOrganizationSelected(organizationsData[0])
  }, [organizationsData])

  const { t } = useTranslation()

  const [checkboxesData, setCheckboxesData] = useState<Checkboxes>({
    style: true,
    policy: true,
    terms: true,
    optout: true,
    options: true
  })

  const [loadingReplicate, setLoadingReplicate] = useState(false)

  const [newDisclaimer, setNewDisclaimer] = useState(false)

  const [cloneDisclaimer] = useMutation(CLONE_DISCLAIMER)
  const mutationHandler = useMutationHandler()

  const [errors, setErrors] = useState({
    messageError: '',
    maxDisclaimers: false,
    optout: false
  } as ErrorsProps)

  const handleCloneDisclaimer = async () => {
    setLoadingReplicate(true)
    const result = await mutationHandler({
      fetch: () => {
        return cloneDisclaimer({
          variables: {
            disclaimerId: disclaimerData.id,
            style: checkboxesData.style,
            terms: checkboxesData.terms,
            policy: checkboxesData.policy,
            optout: checkboxesData.optout,
            options: checkboxesData.options,
            targetDisclaimerId: !newDisclaimer ? disclaimerSelected.value : null
          }
        })
      },
      texts: {
        loading: t('modal.replicateWarningModal.loadingRequest')
      }
    })
    if (result.success) {
      toast.success(t('modal.replicateWarningModal.success'))
      setModalOpen(false)
      setErrors({
        messageError: '',
        maxDisclaimers: false,
        optout: false
      })
      refetchDisclaimers()
    }
    setLoadingReplicate(false)
  }

  return (
    <ModalWrapper
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      headerProps={{
        icon: <MdContentCopy size={35} className="text-primary" />,
        title: t('modal.replicateWarningModal.title'),
        subtitle: t('modal.replicateWarningModal.description')
      }}
    >
      <p className="text-gray-300 text-[16px]">
        {t('modal.replicateWarningModal.warning')} {disclaimerData.pathname}
      </p>
      {errors.messageError !== '' && (
        <p className="text-pink-400">* {errors.messageError}</p>
      )}
      {!newDisclaimer && (
        <div className="mt-5 font-bold">
          <Select
            label={t('modal.replicateWarningModal.selectOrganization')}
            params={
              organizationsData?.map((item: OrganizationsData) => {
                return {
                  value: item.id,
                  ...item
                }
              }) ?? []
            }
            value=""
            onChange={(e: any) => {
              setOrganizationSelected(e)
              setDisclaimerSelected({ name: '', value: '' })
            }}
          />
        </div>
      )}
      {!newDisclaimer &&
        (disclaimersData?.organization?.[0]?.disclaimers?.length > 1 ? (
          <div className="mt-5 font-bold">
            <Select
              label={t('modal.scheduleDownloadConsents.disclaimer')}
              params={disclaimersData?.organization?.[0]?.disclaimers
                ?.filter(
                  (disclaimer: disclaimerProps) =>
                    disclaimer.id !== disclaimerData.id
                )
                .map((item: { pathname: string; id: string }) => {
                  return {
                    name: `${item.pathname} - ${item.id}`,
                    value: item.id
                  }
                })}
              value=""
              onChange={e => {
                setDisclaimerSelected({
                  name: e.name,
                  value: String(e.value)
                })
              }}
            />
          </div>
        ) : (
          <p className="text-gray-300 text-[16px]">
            {t('modal.replicateWarningModal.disclaimersNotFound')}
          </p>
        ))}
      <Checkbox
        className="my-4"
        checkValue={newDisclaimer}
        setCheckValue={setNewDisclaimer}
        text={t(`modal.replicateWarningModal.newDisclaimer`)}
        textClassName="font-bold"
      />
      {newDisclaimer && (
        <p className="text-[16px] text-gray-300 mb-4">
          * {t('modal.replicateWarningModal.newDisclaimerInfo')}
        </p>
      )}
      <H3 fontWeight="bold">
        {t('modal.replicateWarningModal.optionsReplicateText')}
      </H3>
      <div className="grid grid-cols-2 gap-x-2">
        {optionsInTag.map((item: any) => {
          return (
            <Checkbox
              className={'mt-1'}
              checkValue={checkboxesData[item.name as keyof Checkboxes]}
              setCheckValue={() => {
                setCheckboxesData({
                  ...checkboxesData,
                  [item.name]: !checkboxesData[item.name as keyof Checkboxes]
                })
              }}
              text={t(`modal.replicateWarningModal.${item.name}`)}
            />
          )
        })}
      </div>
      <div className="flex items-center justify-end mt-5">
        <Button
          onClick={() => setModalOpen(false)}
          className="mr-2 dark:bg-black dark:text-white"
          buttonType={ButtonType.Secondary}
        >
          {t('modal.scheduleDownloadConsents.cancel')}
        </Button>
        <Button
          disabled={
            (!(disclaimersData?.organization?.[0]?.disclaimers?.length > 1) &&
              !newDisclaimer) ||
            loadingReplicate
          }
          loading={loadingReplicate}
          onClick={handleCloneDisclaimer}
        >
          {t('modal.replicateWarningModal.clone')}
        </Button>
      </div>
    </ModalWrapper>
  )
}

interface ReplicateModalWarningProps {
  modalOpen: boolean
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  disclaimerData: DisclaimerData
  refetchDisclaimers: any
}

interface disclaimerProps {
  id: string
  pathname: string
  name: string
}

interface ErrorsProps {
  messageError: string
  maxDisclaimers: boolean
  optout: boolean
}
