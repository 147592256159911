// eslint-disable-next-line import/named
import { FetchResult } from '@apollo/client'
import { toast } from 'react-hot-toast'
import { useHandleError } from './useHandleError'

interface MutationHandlerProps {
  fetch: () => Promise<
    FetchResult<any, Record<string, any>, Record<string, any>>
  >
  texts: {
    loading: string
    success?: string
    error?: string
  }
}

export function useMutationHandler() {
  const { handleError } = useHandleError()

  async function mutationHandler({ fetch, texts }: MutationHandlerProps) {
    if (texts.loading) toast.loading(texts.loading)

    try {
      const result = await fetch()
      toast.dismiss()
      const responseKey = Object.keys(result?.data)[0]
      const responseData = result?.data?.[responseKey]

      if (responseData.success) {
        if (texts.success) toast.success(texts.success)
      } else {
        handleError(responseData.message)
      }
      return responseData
    } catch (error) {
      toast.dismiss()
      handleError()
      return {
        success: false,
        message: 'Something went wrong'
      }
    }
  }

  return mutationHandler
}
