import { useTheme } from 'contexts/theme-context'
import { useCallback } from 'react'
import Particles from 'react-particles'
import { loadFull } from 'tsparticles'

export function ParticlesBackground() {
  const { theme } = useTheme()

  const particlesInit = useCallback(async engine => {
    await loadFull(engine)
  }, [])

  return (
    <Particles
      id="tsparticles"
      init={particlesInit}
      options={{
        background: {
          color: {
            value: theme === 'dark' ? '#0D1117' : '#fff'
          }
        },
        fpsLimit: 120,
        interactivity: {
          events: {
            onClick: {
              enable: true,
              mode: 'push'
            },
            onHover: {
              enable: true,
              mode: 'repulse'
            },
            resize: true
          },
          modes: {
            push: {
              quantity: 2
            },
            repulse: {
              distance: 100,
              duration: 0.9
            }
          }
        },
        particles: {
          color: {
            value: theme === 'dark' ? '#fff' : '#0D1117'
          },
          links: {
            color: theme === 'dark' ? '#fff' : '#0D1117',
            distance: 150,
            enable: true,
            opacity: 0.1,
            width: 1
          },
          collisions: {
            enable: true
          },
          move: {
            enable: true,
            outModes: {
              default: 'bounce'
            },
            random: false,
            speed: 0.5,
            straight: false
          },
          number: {
            density: {
              enable: true,
              area: 800
            },
            value: 70
          },
          opacity: {
            value: 0.1
          },
          shape: {
            type: 'circle'
          },
          size: {
            value: { min: 1, max: 5 }
          }
        },
        detectRetina: true
      }}
    />
  )
}
