import { useDisclaimer } from 'hooks/storage/useDisclaimer'
import { useOrganizationDetails } from 'hooks/storage/useOrganizationDetails'
import {
  OrganizationsData,
  useOrganizations
} from 'hooks/storage/useOrganizations'
import { Organization } from 'models/oganization'
import { createContext, useContext } from 'react'

interface StorageContextProps {
  organizationsData: OrganizationsData[] | undefined
  loadingOrganizations: boolean
  loadingOrganizationDetails: boolean
  refetchOrganizations: () => void
  refetchOrganizationDetails: () => void
  activeOrganizationDetails: Organization | undefined
  activeDisclaimerPath: string | undefined
}

export const StorageContext = createContext({} as StorageContextProps)

export function StorageProvider({ children }: { children: React.ReactNode }) {
  const {
    data: organizationsData,
    loading: loadingOrganizations,
    refetch: refetchOrganizations
  } = useOrganizations()

  const {
    data: activeOrganizationDetails,
    loading: loadingOrganizationDetails,
    refetch: refetchOrganizationDetails
  } = useOrganizationDetails()

  const { activeDisclaimerPath } = useDisclaimer()

  return (
    <StorageContext.Provider
      value={{
        activeDisclaimerPath,
        activeOrganizationDetails,
        loadingOrganizationDetails,
        organizationsData,
        loadingOrganizations,
        refetchOrganizations,
        refetchOrganizationDetails
      }}
    >
      {children}
    </StorageContext.Provider>
  )
}

export const useStorage = () => useContext(StorageContext)
