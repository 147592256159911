import { Button, ButtonType, IdentityProviderConfigsModal } from 'components'
import { useTranslation } from 'next-i18next'
import { useState } from 'react'
import { FiSettings } from 'react-icons/fi'

export function ConfigsButton() {
  const { t } = useTranslation()
  const [openConfirmModal, setOpenConfirmModal] = useState(false)

  return (
    <>
      <Button
        buttonType={ButtonType.Secondary}
        onClick={() => {
          setOpenConfirmModal(true)
        }}
      >
        <div className=" flex gap-2 items-center">
          {t('configuration.identityProvider.configsButton')}
          <FiSettings size={18} />
        </div>
      </Button>
      <IdentityProviderConfigsModal
        modalOpen={openConfirmModal}
        setModalOpen={setOpenConfirmModal}
      />
    </>
  )
}
