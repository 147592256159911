import React from 'react'

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(' ')
}

export default function StatusBadge({ status }: any) {
  return (
    <span
      className={classNames(
        status === 'online' || status === 'active' || status === 'enabled'
          ? 'border-primary bg-primary'
          : status === 'error' || status === 'offline' || status === 'disabled'
          ? 'border-pink bg-pink'
          : status === 'incomplete' || status === 'paused'
          ? 'border-yellow bg-yellow'
          : null,
        'w-4 h-4 border  bg-opacity-30 rounded-full '
      )}
    />
  )
}
