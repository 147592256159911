import { useTour } from '@reactour/tour'
import { Button, ButtonType } from 'components'
import { useTranslation } from 'next-i18next'
import { HiChevronLeft, HiChevronRight } from 'react-icons/hi'
import { classNames } from 'util/shared'

export function TourStepper() {
  const { setCurrentStep, currentStep, setIsOpen, steps } = useTour()
  const { t } = useTranslation()

  return (
    <div>
      <div className="flex justify-center items-center gap-4">
        <Button
          buttonType={ButtonType.Ghost}
          onClick={() => setCurrentStep(currentStep - 1)}
        >
          <HiChevronLeft className="text-gray-200" size={20} />
        </Button>
        {steps.map((step, index) => {
          return (
            <div
              onClick={() => setCurrentStep(index)}
              className={classNames(
                'w-2 h-2 rounded-full cursor-pointer',
                currentStep === index
                  ? 'bg-primary'
                  : 'bg-white-200 dark:bg-gray-300'
              )}
              key={index}
            ></div>
          )
        })}
        <Button
          buttonType={ButtonType.Ghost}
          className="dark:text-white"
          onClick={() => {
            if (currentStep === steps.length - 1) {
              setIsOpen(false)
            } else {
              setCurrentStep(currentStep + 1)
            }
          }}
        >
          {currentStep === steps.length - 1 ? (
            null
          ) : (
            <HiChevronRight className="text-gray-200" size={20} />
          )}
        </Button>
      </div>

      <div className="flex justify-center gap-4 mt-4">
        <Button
          buttonType={ButtonType.Secondary}
          onClick={() => setCurrentStep(currentStep - 1)}
        >
          {t('tourSystem.back')}
        </Button>
        <Button
          buttonType={ButtonType.Primary}
          onClick={() => {
            if (currentStep === steps.length - 1) {
              setIsOpen(false)
            } else {
              setCurrentStep(currentStep + 1)
            }
          }}
        >
          {currentStep === steps.length - 1 ? (
            <>{t('tourSystem.close')}</>
          ) : (
            <>{t('tourSystem.next')}</>
          )}
        </Button>
      </div>
    </div>
  )
}
