import { motion } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { fadeIn } from 'public/animation/motion'
import { useTranslation } from 'react-i18next'
import { classNames } from 'util/shared'
import { IoClose } from 'react-icons/io5'
import { Button, ButtonType } from 'components'
import { BsGear } from 'react-icons/bs'
import { useHandleError } from 'hooks/useHandleError'
import { useMutation } from '@apollo/client'
import { EDIT_TAG_SOURCES } from 'queries/tags'
import { ModalWrapper } from './modal-wrapper'

export function AutoBlockSourcesModal({
  disclaimerId,
  data,
  modalOpen,
  setModalOpen,
  setCookieModalOpen
}: any) {
  const { t } = useTranslation()
  const { handleError } = useHandleError()

  const [urlError, setUrlError] = useState('')
  const [url, setUrl] = useState('')
  const [sources, setSources] = useState<string[]>([])

  const [editTagSources] = useMutation(EDIT_TAG_SOURCES)

  async function saveAutoBlockConfigs() {
    if (sources?.length === 0) {
      setUrlError(`* ${t('modal.autoBlockConfigs.messageError')}`)
      return
    }

    if (data?.id) {
      toast.loading(t('modal.autoBlockConfigs.loading'))
      const result = await editTagSources({
        variables: {
          disclaimerId: disclaimerId,
          shortTagId: data.tagId,
          tagSources: sources
        }
      })
      toast.dismiss()
      if (result?.data?.edit_tag_sources?.success) {
        toast.success(t('modal.autoBlockConfigs.savedSuccessfully'))
        setModalOpen(false)
        setCookieModalOpen(false)
      } else {
        handleError(result?.data?.edit_tag_sources?.message)
      }
    } else {
      handleError()
    }
  }

  function handleAddLink() {
    if (!url) {
      setUrlError(`* ${t('modal.autoBlockConfigs.messageError')}`)
      return
    }

    setSources([url, ...sources])
    setUrl('')
  }

  useEffect(() => {
    if (!data.sources) {
      return
    }

    const sources = Object.keys(data.sources).filter(
      source => data.sources[source].using
    )
    setSources(sources)
  }, [data])

  return (
    <ModalWrapper
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      headerProps={{
        icon: <BsGear className="text-primary" size={35} />,
        title: t('modal.autoBlockConfigs.title'),
        subtitle: t('modal.autoBlockConfigs.subTitle2')
      }}
      modalSize="medium"
    >
      <div
        className={classNames(
          'border  rounded p-2 mt-2 overflow-y-scroll scroll-1 h-60 border-gray-200'
        )}
      >
        <div className="font-bold mb-2">Url</div>
        <div
          className={classNames(
            'flex items-center border rounded  p-1',
            urlError.length !== 0 ? 'border-pink' : 'border-gray-200'
          )}
        >
          <input
            type="text"
            value={url}
            onChange={e => {
              setUrl(e.target.value)
              setUrlError('')
            }}
            className="focus:outline-none w-full bg-white dark:bg-gray"
          />
          <Button onClick={handleAddLink}>
            {t('modal.createDisclaimer.addButton')}
          </Button>
        </div>
        {urlError.length !== 0 ? (
          <motion.div initial="initial" animate="animate" variants={fadeIn()}>
            {urlError}
          </motion.div>
        ) : null}
        <div>
          {sources?.map((item: any, index: any) => {
            return (
              <div
                className="flex justify-between items-center border rounded border-gray-200 py-1 px-2  mt-1"
                key={index}
              >
                <div className="w-100 break-all">{item}</div>
                <button
                  onClick={() =>
                    setSources((prev: any) => {
                      return prev.filter((element: any) => {
                        return element !== item
                      })
                    })
                  }
                >
                  <IoClose />
                </button>
              </div>
            )
          })}
        </div>
      </div>

      <div className="flex justify-end mt-5">
        <Button
          buttonType={ButtonType.Secondary}
          onClick={() => setModalOpen(false)}
          className="dark:bg-black mr-2"
        >
          {t('modal.createDisclaimer.Cancel')}
        </Button>
        <Button onClick={() => saveAutoBlockConfigs()}>
          {t('modal.createDisclaimer.Save')}
        </Button>
      </div>
    </ModalWrapper>
  )
}
