import React, { useEffect, useState } from 'react'
import { Button, ButtonType } from 'components'
import { RiChat4Line } from 'react-icons/ri'
import { useTranslation } from 'react-i18next'
import { useRouter } from 'next/router'
import { useStorage } from 'contexts/storage-context'
import { OrganizationsData } from 'hooks/storage/useOrganizations'
import { useHandleError } from 'hooks/useHandleError'
import { ModalWrapper } from './modal-wrapper'
import toast from 'react-hot-toast'
import { useMutation } from '@apollo/client'
import { TRANSFER_DISCLAIMER } from 'queries/disclaimers'

interface TransferDisclaimerProps {
  open: boolean
  handleClose: () => void
  refetch: () => void
  disclaimerId: string
}

export default function TransferDisclaimer({
  open,
  handleClose,
  refetch,
  disclaimerId
}: TransferDisclaimerProps) {
  const router = useRouter()
  const { t } = useTranslation()
  const [transferDisclaimer] = useMutation(TRANSFER_DISCLAIMER)
  const { refetchOrganizationDetails, organizationsData } = useStorage()
  const { organizationPathname } = router.query
  const [organizations, setOrganizations] = useState<Array<OrganizationsData>>()
  const [organizationSelected, setOrganizationSelected] = useState('')
  const { handleError } = useHandleError()

  useEffect(() => {
    if (open) {
      setOrganizationSelected('')
      setOrganizations(
        organizationsData?.filter(org => {
          return org?.role === 'owner' && org?.pathname !== organizationPathname
        })
      )
    }
  }, [open])

  async function handleTransferDisclaimer() {
    if (isValid()) {
      toast.loading(t('org.disclaimers.Transfering'))
      try {
        const result = await transferDisclaimer({
          variables: {
            disclaimerId,
            newOrganizationId: organizationSelected
          }
        })
        toast.dismiss()
        if (result?.data?.transfer_disclaimer?.success) {
          refetch()
          toast.success(`${t('org.disclaimers.Disclaimer transferred')}!`)
          refetchOrganizationDetails()
          handleClose()
        } else {
          handleError(result?.data?.transfer_disclaimer?.message)
        }
      } catch (error: any) {
        toast.dismiss()
        handleError()
      }
    }
  }

  function isValid() {
    let valid = true
    if (organizationSelected === '') {
      handleError(t('modal.transferDisclaimer.Select a organization'))
      valid = false
    }

    return valid
  }

  return (
    <ModalWrapper
      modalOpen={open}
      setModalOpen={handleClose}
      headerProps={{
        icon: <RiChat4Line size={35} className="text-primary" />,
        title: t('modal.transferDisclaimer.Transfer disclaimer'),
        subtitle: t(
          'modal.transferDisclaimer.Select an organization to transfer this disclaimer'
        )
      }}
    >
      <div>{t('modal.transferDisclaimer.Select an organization')}</div>
      <div className="px-1 rounded-md bg-white-200 dark:bg-black">
        {organizations && organizations.length > 0 ? (
          <select
            className="w-full py-2 outline-none text-black dark:text-white bg-white-200 dark:bg-black"
            name=""
            id=""
            value={organizationSelected}
            onChange={e => setOrganizationSelected(e.target.value)}
          >
            <option value="">
              {t('modal.transferDisclaimer.Choose a organization')}
            </option>
            {organizations?.map((org, index) => {
              return (
                <option key={index} value={org?.id}>
                  {org?.name}
                </option>
              )
            })}
          </select>
        ) : (
          <div className="py-2">
            {t("modal.transferDisclaimer.You don't have another organization")}
          </div>
        )}
      </div>

      <div className="flex justify-end mt-5">
        <Button
          buttonType={ButtonType.Tertiary}
          onClick={handleClose}
          className="dark:bg-black mr-2"
        >
          {t('modal.transferDisclaimer.Cancel')}
        </Button>
        <Button onClick={() => handleTransferDisclaimer()}>
          {t('modal.transferDisclaimer.Save')}
        </Button>
      </div>
    </ModalWrapper>
  )
}
