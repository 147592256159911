import React from 'react'
import IconAdopt from 'assets/imgs/icons/adopt'
import { classNames, hexToRGBA } from 'util/shared'
import IconLock from 'assets/imgs/icons/lock'
import IconCookie from 'assets/imgs/icons/cookie'

export function Disclaimer({
  open,
  controllerPosition = 'left',
  cardPosition = 'same',
  icon,
  imgIcon,
  setOpen,
  primaryColor,
  textColor,
  bgColor,
  text,
  disclaimerTheme,
  buttonsWithSameColor
}: any) {
  const SmallCard = (
    <div
      style={{
        paddingLeft: controllerPosition === 'left' ? '4rem' : '',
        right: controllerPosition === 'right' ? '1.25rem' : ''
      }}
      className="fixed bottom-2 w-96 "
    >
      <div
        style={{
          right: controllerPosition === 'right' ? '2.6rem' : '',
          maxHeight: open ? '300px' : '0px',
          background: bgColor
        }}
        className={classNames(
          'dark:shadow-none absolute overflow-hidden w-full text-xs  duration-150 ease-in bottom-12 rounded-xl shadow-sm'
        )}
      >
        <div>
          <div className="px-6 pt-6">
            <div className="flex justify-between">
              <div
                style={{
                  color: primaryColor
                }}
                className={classNames(
                  'font-bold   text-base mr-12 ease-out relative duration-200',
                  open ? 'top-0 opacity-100' : 'top-5 opacity-0'
                )}
              >
                {text?.titles?.card}
              </div>
              <div
                style={{
                  color: primaryColor
                }}
                className={classNames(
                  ' text-xs relative ease-out duration-200',
                  open ? 'top-0 opacity-100' : 'top-5 opacity-0'
                )}
              >
                AdOpt
              </div>
            </div>
            <div
              style={{
                color: textColor
              }}
              className={classNames(
                'my-1 text-white relative duration-200 ease-out delay-100',
                open ? 'top-0 opacity-100' : 'top-5 opacity-0'
              )}
            >
              {text?.bodyTexts?.card}
            </div>
            <div
              style={{
                color: textColor
              }}
              className={classNames(
                'relative duration-200 ease-out delay-150',
                open ? 'top-0 opacity-100' : 'top-5 opacity-0'
              )}
            >
              <span
                style={{
                  color: primaryColor,
                  cursor: 'pointer'
                }}
                className="mr-1 font-bold"
              >
                {text?.links?.policy}
              </span>{' '}
              -{' '}
              <span
                style={{
                  color: primaryColor
                }}
                className="font-bold"
              >
                {text?.links?.cookies}
              </span>{' '}
              -{' '}
              <span
                style={{
                  color: primaryColor,
                  cursor: 'pointer'
                }}
                className="ml-1 font-bold"
              >
                {text?.links?.terms}
              </span>
            </div>
          </div>
          <div
            style={{
              borderColor: hexToRGBA(textColor, 0.2)
            }}
            className={classNames(
              'flex mt-4 justify-between relative duration-200 delay-200',
              open ? 'top-0 opacity-100' : 'top-5 opacity-0',
              disclaimerTheme === 'modern'
                ? `border-t border-solid`
                : 'pb-6 px-6'
            )}
          >
            <div
              style={{
                borderColor:
                  disclaimerTheme === 'alternative'
                    ? textColor
                    : hexToRGBA(textColor, 0.2),
                color: textColor,
                cursor: 'pointer'
              }}
              onClick={() => setOpen((prev: boolean) => !prev)}
              className={classNames(
                'font-bold p-2 text-white hover:text-white grid place-items-center',
                disclaimerTheme === 'default' ? 'underline' : '',
                disclaimerTheme === 'modern' ? 'border-r flex-1' : '',
                disclaimerTheme === 'alternative' ? `border rounded-md p-4` : ''
              )}
            >
              {text?.buttons?.showPreferences}
            </div>
            <div
              onClick={() => setOpen((prev: boolean) => !prev)}
              style={{
                background: buttonsWithSameColor ? primaryColor : '',
                borderColor:
                  buttonsWithSameColor && disclaimerTheme !== 'modern'
                    ? primaryColor
                    : disclaimerTheme === 'modern'
                    ? hexToRGBA(textColor, 0.2)
                    : textColor,
                color: buttonsWithSameColor ? bgColor : textColor,
                cursor: 'pointer'
              }}
              className={classNames(
                ' font-bold p-2 text-white hover:text-white grid place-items-center',
                disclaimerTheme === 'default' ? 'border px-9 rounded-full' : '',
                disclaimerTheme === 'modern' ? 'border-r flex-1' : '',
                disclaimerTheme === 'alternative' ? `border rounded-md p-4` : ''
              )}
            >
              {text?.buttons?.rejectAll}
            </div>
            <div
              onClick={() => setOpen((prev: boolean) => !prev)}
              style={{
                background: primaryColor,
                borderColor: primaryColor,
                color: bgColor,
                cursor: 'pointer'
              }}
              className={classNames(
                'border font-bold p-2 text-gray-400 hover:text-gray-400 grid place-items-center',
                disclaimerTheme === 'default' ? 'border px-9 rounded-full' : '',
                disclaimerTheme === 'modern' ? 'flex-1' : '',
                disclaimerTheme === 'alternative' ? `border rounded-md p-4` : ''
              )}
            >
              {text?.buttons?.acceptAll}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
  const FullCard = (
    <div
      style={{
        height: open ? '80px' : '0px',
        width: `${document.getElementById('pageContent')?.offsetWidth}px`
      }}
      className={classNames(
        'fixed z-50 ',
        cardPosition === 'center-top' ? 'top-0' : ''
      )}
    >
      <div
        style={{
          height: open ? '80px' : '0px',
          background: bgColor
        }}
        className={classNames(
          'absolute text-xs w-full   duration-150 ease-in shadow-sm',
          cardPosition === 'center-top' ? 'top-0' : ''
        )}
      >
        <div className="p-3 w-full flex justify-between items-start">
          <div>
            <div className="flex items-center">
              <div
                style={{
                  color: primaryColor
                }}
                className={classNames(
                  'font-bold text-sm duration-200 text-primary mr-12 ease-out relative ',
                  open
                    ? 'top-0 opacity-100'
                    : `${
                        cardPosition === 'center-bottom' ? 'top-5' : '-top-5'
                      } opacity-0 `
                )}
              >
                {text?.titles?.card}
              </div>
              <div
                style={{
                  color: primaryColor
                }}
                className={classNames(
                  ' text-xs relative ease-out underline',
                  open
                    ? 'top-0 opacity-100 duration-200'
                    : `${
                        cardPosition === 'center-bottom' ? 'top-5' : '-top-5'
                      } opacity-0 `
                )}
              >
                <a href="https://goadopt.io" target="_blank" rel="noreferrer">
                  AdOpt
                </a>
              </div>
            </div>
            <div
              style={{
                color: textColor
              }}
              className={classNames(
                'mt-1  relative ease-out ',
                open
                  ? 'top-0 opacity-100  duration-200 delay-100'
                  : `${
                      cardPosition === 'center-bottom' ? 'top-5' : '-top-5'
                    } opacity-0`
              )}
            >
              {text?.bodyTexts?.card}
              <span
                style={{
                  color: primaryColor
                }}
                className="font-bold underline ml-1 cursor-pointer"
              >
                {text?.links?.policy}
              </span>{' '}
              -{' '}
              <span
                style={{
                  color: primaryColor
                }}
                className="font-bold"
              >
                {text?.links?.cookies}
              </span>{' '}
              -{' '}
              <span
                style={{
                  color: primaryColor
                }}
                className="font-bold text-primary underline cursor-pointer"
              >
                {text?.links?.terms}
              </span>
            </div>
          </div>

          <div
            onClick={() => setOpen((prev: boolean) => !prev)}
            className={classNames(
              'flex justify-between relative  ',
              open
                ? 'top-0 opacity-100 duration-200 delay-200'
                : `${
                    cardPosition === 'center-bottom' ? 'top-5' : '-top-5'
                  } opacity-0 `
            )}
          >
            <div
              style={{
                borderColor: textColor,
                color: textColor,
                cursor: 'pointer'
              }}
              className="px-4 font-bold  py-2 text-white hover:text-white underline"
            >
              {text?.buttons?.showPreferences}
            </div>
            <div
              style={{
                background: buttonsWithSameColor ? primaryColor : '',
                borderColor: buttonsWithSameColor ? bgColor : textColor,
                color: buttonsWithSameColor ? bgColor : textColor,
                cursor: 'pointer'
              }}
              className="px-8 font-bold  py-2 text-white hover:text-white mr-2 border rounded-full"
            >
              {text?.buttons?.rejectAll}
            </div>
            <div
              style={{
                background: primaryColor,
                borderColor: primaryColor,
                color: bgColor,
                cursor: 'pointer'
              }}
              className={classNames(
                ' border font-bold px-8 py-2  text-gray-400 hover:text-gray-400',
                disclaimerTheme === 'default' ? 'rounded-full' : 'rounded-md'
              )}
            >
              {text?.buttons?.acceptAll}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
  const FullCardBottom = (
    <div
      style={{
        width: `${document.getElementById('pageContent')?.offsetWidth}px`
      }}
      className={classNames(
        'fixed z-50 ',
        cardPosition === 'center-bottom' ? 'bottom-0' : ''
      )}
    >
      <div
        style={{
          height: open ? '80px' : '0px',
          background: bgColor
        }}
        className={classNames(
          'absolute text-xs w-full   duration-150 ease-in shadow-sm',
          cardPosition === 'center-bottom' ? 'bottom-0' : ''
        )}
      >
        <div className="p-3 w-full flex justify-between items-start">
          <div>
            <div className="flex items-center">
              <div
                style={{
                  color: primaryColor
                }}
                className={classNames(
                  'font-bold text-sm duration-200 text-primary mr-12 ease-out relative ',
                  open
                    ? 'top-0 opacity-100'
                    : `${
                        cardPosition === 'center-bottom' ? 'top-5' : ''
                      } opacity-0 `
                )}
              >
                {text?.titles?.card}
              </div>
              <div
                style={{
                  color: primaryColor
                }}
                className={classNames(
                  ' text-xs relative ease-out underline',
                  open
                    ? 'top-0 opacity-100 duration-200'
                    : `${
                        cardPosition === 'center-bottom' ? 'top-5' : ''
                      } opacity-0 `
                )}
              >
                <a href="https://goadopt.io" target="_blank" rel="noreferrer">
                  AdOpt
                </a>
              </div>
            </div>
            <div
              style={{
                color: textColor
              }}
              className={classNames(
                'mt-1  relative ease-out ',
                open
                  ? 'top-0 opacity-100  duration-200 delay-100'
                  : `${
                      cardPosition === 'center-bottom' ? 'top-5' : '-top-5'
                    } opacity-0`
              )}
            >
              {text?.bodyTexts?.card}
              <span
                style={{
                  color: primaryColor
                }}
                className="font-bold underline"
              >
                {text?.links?.policy}
              </span>{' '}
              -{' '}
              <span
                style={{
                  color: primaryColor
                }}
                className="font-bold"
              >
                {text?.links?.cookies}
              </span>{' '}
              -{' '}
              <span
                style={{
                  color: primaryColor
                }}
                className="font-bold text-primary underline"
              >
                {text?.links?.terms}
              </span>
            </div>
          </div>

          <div
            onClick={() => setOpen((prev: boolean) => !prev)}
            className={classNames(
              'flex justify-between relative  ',
              open
                ? 'top-0 opacity-100 duration-200 delay-200'
                : `${
                    cardPosition === 'center-bottom' ? 'top-5' : '-top-5'
                  } opacity-0 `
            )}
          >
            <div
              style={{
                borderColor: textColor,
                color: textColor,
                cursor: 'pointer'
              }}
              className="px-4 font-bold  py-2 text-white hover:text-white underline"
            >
              {text?.buttons?.showPreferences}
            </div>
            <div
              style={{
                background: buttonsWithSameColor ? primaryColor : '',
                borderColor: buttonsWithSameColor ? bgColor : textColor,
                color: buttonsWithSameColor ? bgColor : textColor,
                cursor: 'pointer'
              }}
              className="px-8 font-bold py-2 text-white hover:text-white mr-2 rounded-full border"
            >
              {text?.buttons?.rejectAll}
            </div>
            <div
              style={{
                background: primaryColor,
                borderColor: primaryColor,
                color: bgColor,
                cursor: 'pointer'
              }}
              className={classNames(
                ' border font-bold px-8 py-2  text-gray-400 hover:text-gray-400',
                disclaimerTheme === 'default' ? 'rounded-full' : 'rounded-md'
              )}
            >
              {text?.buttons?.acceptAll}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
  const FullCardAlternative = (
    <div
      style={{
        width: `${document.getElementById('pageContent')?.offsetWidth}px`
      }}
      className={classNames(
        'fixed z-50 ',
        cardPosition === 'center-top' ? 'top-0' : ''
      )}
    >
      <div
        style={{
          height: open ? '' : '0px',
          background: bgColor
        }}
        className={classNames(
          'absolute text-xs w-full   duration-150 ease-in shadow-sm',
          cardPosition === 'center-top' ? 'top-0' : ''
        )}
      >
        <div className="p-3 w-full flex items-center justify-between gap-12">
          <div>
            <div className="flex items-center">
              {text?.titles?.card && (
                <div
                  style={{
                    color: primaryColor
                  }}
                  className={classNames(
                    'font-bold text-sm duration-200 text-primary mr-12 ease-out relative ',
                    open
                      ? 'top-0 opacity-100'
                      : `${
                          cardPosition === 'center-bottom' ? 'top-5' : ''
                        } opacity-0 `
                  )}
                >
                  {text?.titles?.card}
                </div>
              )}
              <div
                style={{
                  color: primaryColor
                }}
                className={classNames(
                  ' text-xs relative ease-out underline',
                  open
                    ? 'top-0 opacity-100 duration-200'
                    : `${
                        cardPosition === 'center-bottom' ? 'top-5' : ''
                      } opacity-0 `
                )}
              >
                <a href="https://goadopt.io" target="_blank" rel="noreferrer">
                  AdOpt
                </a>
              </div>
            </div>
            <div
              style={{
                color: textColor
              }}
              className={classNames(
                'mt-1  relative ease-out ',
                open
                  ? 'top-0 opacity-100  duration-200 delay-100'
                  : `${
                      cardPosition === 'center-bottom' ? 'top-5' : '-top-5'
                    } opacity-0`
              )}
            >
              {text?.bodyTexts?.card}
              <span
                style={{
                  color: primaryColor
                }}
                className="font-bold underline"
              >
                {text?.links?.policy}
              </span>{' '}
              -{' '}
              <span
                style={{
                  color: primaryColor
                }}
                className="font-bold"
              >
                {text?.links?.cookies}
              </span>{' '}
              -{' '}
              <span
                style={{
                  color: primaryColor
                }}
                className="font-bold text-primary underline"
              >
                {text?.links?.terms}
              </span>
            </div>
          </div>

          <div
            onClick={() => setOpen((prev: boolean) => !prev)}
            className={classNames(
              'flex relative gap-4',
              open
                ? 'top-0 opacity-100 duration-200 delay-200'
                : `${
                    cardPosition === 'center-bottom' ? 'top-5' : '-top-5'
                  } opacity-0 `
            )}
          >
            <div
              style={{
                background: buttonsWithSameColor ? primaryColor : '',
                borderColor: buttonsWithSameColor ? bgColor : textColor,
                color: buttonsWithSameColor ? bgColor : textColor,
                cursor: 'pointer'
              }}
              className={`p-2 font-bold hover:bg-${textColor} hover:text-${primaryColor} border rounded-md whitespace-nowrap`}
            >
              {text?.buttons?.rejectAll}
            </div>
            <div
              style={{
                borderColor: textColor,
                color: textColor,
                cursor: 'pointer'
              }}
              className="p-2 font-bold text-white hover:text-white border rounded-md whitespace-nowrap"
            >
              {text?.buttons?.showPreferences}
            </div>
            <div
              style={{
                background: primaryColor,
                borderColor: primaryColor,
                color: textColor,
                cursor: 'pointer'
              }}
              className={classNames(
                'p-2 font-bold text-white hover:text-white border rounded-md whitespace-nowrap'
              )}
            >
              {text?.buttons?.acceptAll}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
  const FullCardBottomAlternative = (
    <div
      style={{
        width: `${document.getElementById('pageContent')?.offsetWidth}px`
      }}
      className={classNames(
        'fixed z-50 ',
        cardPosition === 'center-bottom' ? 'bottom-0' : ''
      )}
    >
      <div
        style={{
          height: open ? '' : '0px',
          background: bgColor
        }}
        className={classNames(
          'absolute text-xs w-full   duration-150 ease-in shadow-sm',
          cardPosition === 'center-bottom' ? 'bottom-0' : ''
        )}
      >
        <div className="p-3 w-full flex items-center justify-between gap-12">
          <div>
            <div className="flex items-center">
              {text?.titles?.card && (
                <div
                  style={{
                    color: primaryColor
                  }}
                  className={classNames(
                    'font-bold text-sm duration-200 text-primary mr-12 ease-out relative ',
                    open
                      ? 'top-0 opacity-100'
                      : `${
                          cardPosition === 'center-bottom' ? 'top-5' : ''
                        } opacity-0 `
                  )}
                >
                  {text?.titles?.card}
                </div>
              )}
              <div
                style={{
                  color: primaryColor
                }}
                className={classNames(
                  ' text-xs relative ease-out underline',
                  open
                    ? 'top-0 opacity-100 duration-200'
                    : `${
                        cardPosition === 'center-bottom' ? 'top-5' : ''
                      } opacity-0 `
                )}
              >
                <a href="https://goadopt.io" target="_blank" rel="noreferrer">
                  AdOpt
                </a>
              </div>
            </div>
            <div
              style={{
                color: textColor
              }}
              className={classNames(
                'mt-1  relative ease-out ',
                open
                  ? 'top-0 opacity-100  duration-200 delay-100'
                  : `${
                      cardPosition === 'center-bottom' ? 'top-5' : '-top-5'
                    } opacity-0`
              )}
            >
              {text?.bodyTexts?.card}
              <span
                style={{
                  color: primaryColor
                }}
                className="font-bold underline"
              >
                {text?.links?.policy}
              </span>{' '}
              -{' '}
              <span
                style={{
                  color: primaryColor
                }}
                className="font-bold underline"
              >
                {text?.links?.cookies}
              </span>{' '}
              -{' '}
              <span
                style={{
                  color: primaryColor
                }}
                className="font-bold text-primary underline"
              >
                {text?.links?.terms}
              </span>
            </div>
          </div>

          <div
            onClick={() => setOpen((prev: boolean) => !prev)}
            className={classNames(
              'flex relative gap-4',
              open
                ? 'top-0 opacity-100 duration-200 delay-200'
                : `${
                    cardPosition === 'center-bottom' ? 'top-5' : '-top-5'
                  } opacity-0 `
            )}
          >
            <div
              style={{
                background: buttonsWithSameColor ? primaryColor : '',
                borderColor: buttonsWithSameColor ? bgColor : textColor,
                color: buttonsWithSameColor ? bgColor : textColor,
                cursor: 'pointer'
              }}
              className={`p-2 font-bold border rounded-md whitespace-nowrap`}
            >
              {text?.buttons?.rejectAll}
            </div>
            <div
              style={{
                borderColor: textColor,
                color: textColor,
                cursor: 'pointer'
              }}
              className="p-2 font-bold text-white border rounded-md whitespace-nowrap"
            >
              {text?.buttons?.showPreferences}
            </div>
            <div
              style={{
                background: primaryColor,
                borderColor: primaryColor,
                color: textColor,
                cursor: 'pointer'
              }}
              className={classNames(
                'p-2 font-bold text-white border rounded-md whitespace-nowrap'
              )}
            >
              {text?.buttons?.acceptAll}
            </div>
          </div>
        </div>
      </div>
    </div>
  )

  function renderCard() {
    if (cardPosition === 'same') {
      return SmallCard
    }

    if (cardPosition === 'center-top' && disclaimerTheme !== 'alternative') {
      return FullCard
    }

    if (cardPosition === 'center-top' && disclaimerTheme === 'alternative') {
      return FullCardAlternative
    }

    if (cardPosition === 'center-bottom' && disclaimerTheme !== 'alternative') {
      return FullCardBottom
    }

    if (cardPosition === 'center-bottom' && disclaimerTheme === 'alternative') {
      return FullCardBottomAlternative
    }
  }

  return (
    <>
      {renderCard()}
      <div
        style={{
          paddingLeft: controllerPosition === 'left' ? '1rem' : '',
          right: controllerPosition === 'right' ? '0' : '',
          paddingRight: controllerPosition === 'right' ? '1rem' : ''
        }}
        className="fixed bottom-2"
      >
        <div
          className="w-12 h-12 mt-5 text-white shadow-sm cursor-pointer overflow-hidden rounded-full flex items-center justify-center"
          onClick={() => setOpen((prev: boolean) => !prev)}
        >
          {icon === 'adopt' ? (
            <IconAdopt fill={primaryColor} />
          ) : icon === 'lock' ? (
            <IconLock fill={primaryColor} />
          ) : icon === 'cookie' ? (
            <IconCookie fill={primaryColor} />
          ) : icon === 'custom' ? (
            <img src={imgIcon} />
          ) : null}
        </div>
      </div>
    </>
  )
}
