import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { CampaignInfo } from 'util/enums'
import { useLocalStorage } from './useLocalstorage'

export const useStoreCampaign = () => {
  const router = useRouter()
  const [campaignInfo, setCampaignInfo] = useLocalStorage<string>(
    'campaignInfo',
    ''
  )

  useEffect(() => {
    if (router.query) {
      let campaignInfoAux = {} as any
      Object.entries(CampaignInfo).forEach(entries => {
        const queryValue = router.query[entries[1]]
        if (queryValue) campaignInfoAux[entries[0]] = queryValue
      })
      if (campaignInfo) {
        campaignInfoAux = { ...JSON.parse(campaignInfo), ...campaignInfoAux }
      }
      setCampaignInfo(JSON.stringify(campaignInfoAux))
    }
  }, [router, setCampaignInfo, campaignInfo])
}
