import { gql } from '@apollo/client'

export const GET_USER_ORGANIZATIONS = gql`
  query GetOrganization($id: uuid!) {
    user_organization(
      where: { deleted_at: { _is_null: true }, user_id: { _eq: $id } }
    ) {
      org_role {
        name
      }
      organization {
        id
        name
        logo
        pathname
      }
    }
  }
`
export const GET_USER_BASE_ORGANIZATION = gql`
  query GetBaseOrganization($id: uuid!) {
    user_organization(
      where: { deleted_at: { _is_null: true }, user_id: { _eq: $id } }
    ) {
      organization(where: { base: { _eq: true } }) {
        name
        logo
        pathname
      }
    }
  }
`
export const GET_USER_ORGANIZATIONS_WITH_METRICS = gql`
  query GetOrganization($id: uuid!, $month: timestamptz) {
    user_organization(
      where: { deleted_at: { _is_null: true }, user_id: { _eq: $id } }
    ) {
      id
      org_role {
        name
      }
      organization {
        id
        name
        logo
        pathname
        base
        disclaimers {
          id
          pathname
          monthly_metrics(where: { start_date: { _eq: $month } }) {
            opt_ins
            opt_out
            unique_users
          }
        }
      }
    }
  }
`
export const GET_USER_ORGANIZATION = gql`
  query GetOrganization($organizationsPathname: String!, $month: timestamptz) {
    organization(where: { pathname: { _eq: $organizationsPathname } }) {
      id
      name
      logo
      pathname
      base
      migrated_to_optout_v2
      user_organization(where: { role: { _eq: "aW6ZQ7iQ" } }) {
        users {
          email
          subscription {
            current_period_start
            subscription_plan {
              name
              domains
              members
              simultaneous_consent_reports
              consent_report_time_range
              consent_report
              name
              controller {
                name
              }
              consent_report_frequency {
                name
              }
              scan_frequency_id
              scan_authentication
              disclaimer_close
              max_consent_ttl_in_days_allowed
              view_dashboard
              audit_log
              unique_users
              disclaimers
              identity_provider
              can_edit_dark_light_css
              can_edit_disclaimer_text
              can_edit_document_style
              possible_modules_to_update_consent_version
              themes
              webhooks
              tcf
              can_edit_injector_version
            }
          }
        }
      }
      disclaimers {
        pathname
        id
        monthly_metrics(
          where: { month_type: { _eq: "month" }, start_date: { _eq: $month } }
        ) {
          opt_ins
          opt_out
          unique_users
        }
      }
    }
  }
`

export const GET_USER_BASE_ORGANIZATIONS = gql`
  query {
    organization(where: { base: { _eq: true } }, limit: 1) {
      id
      name
      logo
      pathname
      base
      disclaimers(limit: 1) {
        id
      }
    }
  }
`
export interface UserBaseOrganization {
  organization: Array<{
    id: string
    name: string
    logo: string
    pathname: string
    base: boolean
    disclaimers: Array<{ id: string }>
  }>
}

export const CREATE_ORGANIZATION = gql`
  mutation CreateOrganization($name: String!, $pathname: String!) {
    create_organization(object: { name: $name, pathName: $pathname }) {
      data
      success
      message
      errorCode
      errorDetails
    }
  }
`
export const GET_ORGANIZATION_PLAN = gql`
  query GetOrganizationPlan($pathname: String!) {
    organization(where: { pathname: { _eq: $pathname } }, limit: 1) {
      user_organization(where: { role: { _eq: "aW6ZQ7iQ" } }) {
        users {
          subscription {
            subscription_plan {
              name
              domains
              simultaneous_consent_reports
              consent_report_time_range
              name
              controller {
                name
              }
              consent_report_frequency {
                name
              }
              scan_frequency_id
              scan_authentication
              disclaimer_close
            }
          }
        }
      }
    }
  }
`
export const GET_ORGANIZATION = gql`
  query GetOrganization($pathname: String!) {
    organization(where: { pathname: { _eq: $pathname } }, limit: 1) {
      id
      name
      logo
      pathname
      description
      modules_to_update_consent_version
    }
  }
`
export const GET_ORGANIZATION_WITH_SUBSCRIPTION = gql`
  query GetOrganization($pathname: String!) {
    organization(where: { pathname: { _eq: $pathname } }, limit: 1) {
      id
      name
      logo
      pathname
      description
      disclaimers(where: { deleted_at: { _is_null: true } }) {
        pathname
      }
      user_organization(where: { deleted_at: { _is_null: true } }) {
        role
        users {
          email
        }
      }
    }
  }
`
export const GET_ORGANIZATION_WITH_DISCLAIMERS = gql`
  query GetOrganization(
    $pathname: String!
    $month: timestamptz
    $discalimerLimit: Int
    $disclaimerOffset: Int
  ) {
    organization(
      where: { pathname: { _eq: $pathname }, deleted_at: { _is_null: true } }
      limit: 1
    ) {
      id
      name
      logo
      pathname
      description
      user_organization(where: { role: { _eq: "aW6ZQ7iQ" } }) {
        users {
          subscription {
            subscription_plan {
              domains
            }
          }
        }
      }
      disclaimers(
        limit: $discalimerLimit
        offset: $disclaimerOffset
        order_by: { created_at: desc, pathname: asc }
      ) {
        id
        pathname
        disclaimer_status {
          name
        }
        monthly_metrics(where: { start_date: { _eq: $month } }) {
          opt_ins
          opt_out
          unique_users
        }
        disclaimer_versions(limit: 1, order_by: { main_version: desc }) {
          disclaimer_version
          terms_version
          disclaimer_version
          disclaimer_style_version
          policy_version
          opt_out_version
          disclaimer_text_version
          disclaimer_website_version
          tags_version
        }
        disclaimer_websites {
          url
          version
          disclaimer_installed
        }
        tags {
          name
        }
        disclaimer_documents(order_by: { version: desc }, limit: 1) {
          document_master {
            id
          }
          documentMasterByDocumentMasterTermsId {
            id
          }
          documentMasterByDocumentMasterPrivacyId {
            id
          }
        }
      }
    }
  }
`

export const GET_ORGANIZATION_AND_DISCLAIMERS = gql`
  query GetOrganization($organizationLimit: Int!, $disclaimerLimit: Int!) {
    organization(
      where: { deleted_at: { _is_null: true } }
      limit: $organizationLimit
    ) {
      id
      name
      pathname

      disclaimers(
        order_by: { created_at: desc, pathname: asc }
        limit: $disclaimerLimit
      ) {
        id
        pathname
        disclaimer_status {
          name
        }
      }
    }
  }
`

export const GET_ORGANIZATION_WITH_SEARCHED_DISCLAIMERS = gql`
  query GetOrganization(
    $pathname: String!
    $month: timestamptz
    $disclaimerPathname: String!
  ) {
    organization(
      where: { pathname: { _eq: $pathname }, deleted_at: { _is_null: true } }
      limit: 1
    ) {
      id
      name
      logo
      pathname
      description
      user_organization(where: { role: { _eq: "aW6ZQ7iQ" } }) {
        users {
          subscription {
            subscription_plan {
              domains
            }
          }
        }
      }
      disclaimers(where: { pathname: { _similar: $disclaimerPathname } }) {
        id
        pathname
        disclaimer_status {
          name
        }
        monthly_metrics(where: { start_date: { _eq: $month } }) {
          opt_ins
          opt_out
          unique_users
        }
        disclaimer_versions(limit: 1, order_by: { main_version: desc }) {
          disclaimer_version
          terms_version
          disclaimer_version
          disclaimer_style_version
          policy_version
          opt_out_version
          disclaimer_text_version
          disclaimer_website_version
          tags_version
        }
        disclaimer_websites {
          url
          version
        }
        tags {
          name
        }
        disclaimer_documents(order_by: { version: desc }, limit: 1) {
          document_master {
            id
          }
          documentMasterByDocumentMasterTermsId {
            id
          }
          documentMasterByDocumentMasterPrivacyId {
            id
          }
        }
      }
    }
  }
`
export const GET_DISCLAIMERS_SIMPLIFIED = gql`
  query GetOrganization($pathname: String!) {
    organization(
      where: { pathname: { _eq: $pathname }, deleted_at: { _is_null: true } }
      limit: 1
    ) {
      user_organization(where: { role: { _eq: "aW6ZQ7iQ" } }) {
        users {
          subscription {
            subscription_plan {
              simultaneous_consent_reports
              consent_report_time_range
              consent_report_frequency {
                name
              }
            }
          }
        }
      }

      disclaimers {
        id
        pathname
      }
    }
  }
`
export const GET_ORGANIZATION_WITH_MEMBERS = gql`
  query GetOrganization($pathname: String!) {
    organization(
      where: { pathname: { _eq: $pathname }, deleted_at: { _is_null: true } }
      limit: 1
    ) {
      id
      name
      logo
      pathname
      description
      user_organization {
        id
        deleted_at
        org_role {
          name
          id
        }
        users {
          name
          email
        }
      }
    }
  }
`

export const UPDATE_ORGANIZATION = gql`
  mutation UpdateOrganization(
    $name: String
    $pathname: String
    $description: String
    $id: uuid!
  ) {
    update_organization_by_pk(
      pk_columns: { id: $id }
      _set: { name: $name, pathname: $pathname, description: $description }
    ) {
      id
      pathname
      name
      description
    }
  }
`

export const DELETE_ORGANIZATION = gql`
  mutation DeleteOrganization($orgId: String!) {
    delete_org(object: { orgId: $orgId }) {
      success
      data
      message
      errorCode
      errorDetails
    }
  }
`

export const TRANSFER_ORGANIZATION = gql`
  mutation TransferOrganization($email: String!, $orgId: String!) {
    transfer_organization(object: { email: $email, orgId: $orgId }) {
      success
      message
      errorCode
      errorDetails
    }
  }
`

export const SET_MODULES_TO_UPDATE_CONSENT_VERSION = gql`
  mutation SetModulesToUpdateConsentVersion(
    $organizationId: String!
    $modulesToUpdate: [String]!
  ) {
    set_modules_to_update_consent_version(
      object: {
        organizationId: $organizationId
        modulesToUpdate: $modulesToUpdate
      }
    ) {
      success
      message
      errorCode
      errorDetails
    }
  }
`
