import { motion } from 'framer-motion'
import React from 'react'
import { ColorPicker } from 'react-color-palette'
import { fadeIn } from 'public/animation/motion'

export default function ColorPickerComp({
  className,
  open,
  color,
  setColor
}: any) {
  return (
    <>
      {open ? (
        <motion.div
          initial="initial"
          animate="animate"
          variants={fadeIn()}
          className={className}
        >
          <ColorPicker
            width={260}
            height={150}
            color={color}
            onChange={setColor}
            hideHSV
          />
        </motion.div>
      ) : null}
    </>
  )
}
