import { Button } from 'components'
import { ModalWrapper } from './modal-wrapper'

export function AlertModal({
  modalOpen,
  setModalOpen,
  icon,
  title,
  subtitle,
  onclick
}: any) {
  return (
    <ModalWrapper modalOpen={modalOpen} setModalOpen={setModalOpen}>
      <div className="mb-6 flex justify-center">{icon}</div>
      <div className="text-lg dark:text-white text-center font-bold">
        {title}
      </div>
      <div className="text-md dark:text-gray-300 text-center ">{subtitle}</div>
      <div className="mt-6  flex justify-center">
        <Button
          className="px-10"
          onClick={() => {
            setModalOpen(false)
            if (onclick) onclick()
          }}
        >
          Ok
        </Button>
      </div>
    </ModalWrapper>
  )
}
