import { classNames } from 'util/shared'

interface TooltipProps {
  children: React.ReactNode
  content?: React.ReactNode
  className?: string
  size?: 'Small' | 'Normal' | 'Auto'
  horizontalPosition?: 'Left' | 'Center' | 'Right'
  verticalPosition?: 'Bottom' | 'Top'
}

const TooltipSize = {
  Small: 'min-w-[200px] text-sm',
  Normal: 'min-w-[300px] text-md',
  Auto: 'text-sm whitespace-nowrap'
}

const HorizontalTooltipPosition = {
  Left: 'left-0',
  Center: 'left-1/2 transform -translate-x-1/2',
  Right: 'right-0'
}

const VerticalTooltipPosition = {
  Bottom: 'top-[calc(100%+10px)]',
  Top: 'bottom-[calc(100%+10px)]'
}

export function Tooltip({
  children,
  size = 'Normal',
  content,
  horizontalPosition = 'Left',
  verticalPosition = 'Bottom',
  className
}: TooltipProps) {
  return (
    <div className={classNames('group relative', className)}>
      {children}
      {content && (
        <span
          className={classNames(
            'absolute  z-40 scale-0 transition-all rounded-md bg-white-200 shadow-[0_4px_10px_0px_rgba(0,0,0,0.2)] dark:shadow-[0_4px_10px_0px_rgba(100,100,100,0.2)]  dark:bg-gray  p-3  dark:text-white group-hover:scale-100',
            TooltipSize[size],
            HorizontalTooltipPosition[horizontalPosition],
            VerticalTooltipPosition[verticalPosition]
          )}
        >
          {content}
        </span>
      )}
    </div>
  )
}
