/* eslint-disable @typescript-eslint/no-unused-vars */
import { paths } from 'config/paths'
import { useRouter } from 'next/router'
import React from 'react'
import { Button } from '.'

export function ChangePlanButton({ children, orgId, ...props }: any) {
  const router = useRouter()

  return (
    <Button
      {...props}
      onClick={() => {
        router.push(paths.plans)
      }}
    >
      {children}
    </Button>
  )
}
