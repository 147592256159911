import { motion } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import { modalAnimation } from '../../public/animation/motion'
import { useTranslation } from 'react-i18next'
import { RiShieldFlashLine } from 'react-icons/ri'
import { arrayMove, classNames } from 'util/shared'
import {
  Select,
  ModalHeader,
  Button,
  ButtonType,
  Input,
  BodyText,
  H3,
  InfoTooltip,
  TooltipPosition
} from 'components'
import toast from 'react-hot-toast'
import { useMutation, useQuery } from '@apollo/client'
import {
  GET_DISCLIAMER_WEBSITES,
  SAVE_EDIT_AUTH_SCAN,
  TEST_AUTH_SCAN
} from 'queries/tags'
import { AuthenticationStepType } from 'util/enums'
import { HiChevronDown, HiChevronUp } from 'react-icons/hi'
import { MdEdit } from 'react-icons/md'
import { BiTrash } from 'react-icons/bi'
import { MultipleSelect } from 'components/shared/select/multiple-select'
import ScanAuthTestModal from './scan-auth-test-modal'
import { Validator } from 'util/validator'
import { useHandleError } from 'hooks/useHandleError'

export interface ScanAuthEventModalProps {
  modalOpen: boolean
  setModalOpen: (open: boolean) => void
  disclaimerId: string
  refetch: () => void
  selecteds: any
  setSelecteds: (e: any) => void
  data: EventData
  setData: (data: EventData) => void
  events: Array<EditableEventData> | undefined
  setEvents: (events: any) => void
}

export interface EventData {
  name: string
  loginUrl: string
  id?: string
}
export interface EditableEventData {
  type: AuthenticationStepType
  id: string
  value: string
}

export default function ScanAuthEventModal({
  modalOpen,
  setModalOpen,
  disclaimerId,
  refetch,
  selecteds,
  setSelecteds,
  data,
  setData,
  events,
  setEvents
}: ScanAuthEventModalProps) {
  const { t } = useTranslation()
  const { data: domainsData } = useQuery(GET_DISCLIAMER_WEBSITES, {
    skip: !disclaimerId,
    fetchPolicy: 'network-only',
    variables: {
      disclaimerId: disclaimerId
    }
  })

  const [testData, setTestData] = useState<any>()
  const [openTestModal, setOpenTestModal] = useState(false)
  const { handleError } = useHandleError()

  const [dataError, setDataError] = useState({
    name: '',
    loginUrl: '',
    domainUrls: '',
    events: ''
  })
  const [editableEvent, setEditableEvent] = useState<EditableEventData>({
    type: AuthenticationStepType.Type,
    id: '',
    value: ''
  })
  const [eventToEdit, setEventToEdit] = useState({
    index: 0,
    isEditing: false
  })
  const [saveEditAuthScan] = useMutation(SAVE_EDIT_AUTH_SCAN)
  const [testAuthScan] = useMutation(TEST_AUTH_SCAN)

  useEffect(() => {
    if (modalOpen) {
      setEditableEvent({
        type: AuthenticationStepType.Type,
        id: '',
        value: ''
      })
      setEventToEdit({
        index: 0,
        isEditing: false
      })
      setDataError({
        name: '',
        loginUrl: '',
        domainUrls: '',
        events: ''
      })
    }
  }, [modalOpen])

  function isValid() {
    let valid = true
    setDataError({
      name: '',
      loginUrl: '',
      domainUrls: '',
      events: ''
    })
    if (data.name?.length === 0) {
      valid = false
      setDataError(prev => {
        return { ...prev, name: t('scanAuthEventModal.error.name') }
      })
    }

    if (selecteds?.length === 0) {
      valid = false
      setDataError(prev => {
        return { ...prev, domainUrls: t('scanAuthEventModal.error.domains') }
      })
    }

    if (!Validator.isValidUrl(data.loginUrl)) {
      valid = false
      setDataError(prev => {
        return { ...prev, loginUrl: t('scanAuthEventModal.error.loginUrl') }
      })
    }

    if (events?.length === 0) {
      valid = false
      setDataError(prev => {
        return { ...prev, events: t('scanAuthEventModal.error.events') }
      })
    }

    return valid
  }

  async function handleSaveEdit() {
    if (isValid()) {
      toast.loading(t('scanAuthEventModal.loading'))
      const scanAuth = {
        loginUrl: data.loginUrl,
        name: data.name,
        domainIds: selecteds.map((item: { value: string }) => item.value),
        authenticationSteps: events?.map((item, index) => {
          return {
            type: item.type,
            elementId: item.id,
            value: item.value,
            execution_order: index
          }
        })
      }

      try {
        const result = await saveEditAuthScan({
          variables: {
            disclaimerId: disclaimerId,
            scanAuthId: data.id,
            scanAuthentication: scanAuth
          }
        })
        toast.dismiss()
        if (result?.data?.add_edit_scan_auth?.success) {
          toast.success(t('scanAuthEventModal.successMessage'))
          setModalOpen(false)
          refetch()
        } else {
          handleError(result?.data?.add_edit_scan_auth?.message)
        }
      } catch (error) {
        handleError()
      }
    }
  }

  function handleEditEvent() {
    const newEvents = [...(events ?? [])]

    newEvents.splice(eventToEdit.index, 1, editableEvent)

    setEvents(newEvents)
    setEditableEvent({
      type: AuthenticationStepType.Type,
      id: '',
      value: ''
    })
    setDataError(prev => {
      return { ...prev, events: '' }
    })

    setEventToEdit({ index: 0, isEditing: false })
  }

  function handleAddEvent() {
    setEvents((prev: any) => [...(prev ?? []), editableEvent])
    setEditableEvent({
      type: AuthenticationStepType.Type,
      id: '',
      value: ''
    })
    setDataError(prev => {
      return { ...prev, events: '' }
    })
  }

  async function handleTestScan() {
    let valid = true
    if (!Validator.isValidUrl(data.loginUrl)) {
      valid = false
      setDataError(prev => {
        return {
          ...prev,
          loginUrl: t('scanAuthEventModal.error.loginUrl')
        }
      })
    }

    if (events?.length === 0) {
      valid = false
      setDataError(prev => {
        return {
          ...prev,
          events: t('scanAuthEventModal.error.events')
        }
      })
    }

    if (valid) {
      setTestData(undefined)
      setOpenTestModal(true)

      try {
        const result = await testAuthScan({
          variables: {
            loginUrl: data.loginUrl,
            authenticationSteps: events?.map((item, index) => {
              return {
                type: item.type,
                elementId: item.id,
                value: item.value,
                execution_order: index
              }
            })
          }
        })

        setTestData(result?.data?.test_auth_scan)
      } catch (error) {
        handleError()
        setOpenTestModal(false)
      }
    }
  }

  return (
    <>
      {modalOpen ? (
        <div className="fixed z-50 top-0 left-0 bg-opacity-90 w-screen h-screen bg-black flex items-center justify-center">
          <motion.div
            initial="initial"
            animate="animate"
            variants={modalAnimation}
            className="z-50  rounded-md  w-[90%] max-w-[550px] scroll-1 max-h-[90vh] overflow-auto py-10 px-10 bg-white dark:bg-gray"
          >
            <ModalHeader
              icon={<RiShieldFlashLine className="text-primary" size={35} />}
              title={t('scanAuthEventModal.title')}
              subtitle={t('scanAuthEventModal.subtitle')}
              setModalOpen={setModalOpen}
            ></ModalHeader>
            <Input
              label={t('scanAuthEventModal.eventName')}
              value={data?.name ?? ''}
              maxLength={70}
              fieldError={dataError?.name ?? ''}
              contClass={'mt-5 mb-4 '}
              events={{
                onChange: (e: { value: string }) => {
                  setData({ ...data, name: e.value })
                }
              }}
            />
            <MultipleSelect
              label={t('scanAuthEventModal.urlSelect')}
              containerClasses={'mt-3'}
              fieldError={dataError?.domainUrls ?? ''}
              params={domainsData?.disclaimers?.[0]?.scan_domains?.map(
                (item: { url: string; id: string }) => {
                  return {
                    name: item.url,
                    value: item.id
                  }
                }
              )}
              selecteds={selecteds}
              setSelecteds={setSelecteds}
            />
            <Input
              label={t('scanAuthEventModal.loginUrl')}
              value={data?.loginUrl ?? ''}
              maxLength={255}
              fieldError={dataError?.loginUrl ?? ''}
              contClass={'mt-3'}
              events={{
                onChange: (e: { value: string }) => {
                  setData({ ...data, loginUrl: e.value })
                }
              }}
            />
            <H3 fontWeight="bold" className="mt-5">
              {t('scanAuthEventModal.events')}
            </H3>
            {events?.map((item, index) => {
              return (
                <div
                  key={index}
                  className="bg-[#eee] dark:bg-gray flex items-center border border-[#ddd] dark:border-gray-400 py-2 mb-2 px-5"
                >
                  <div className="mr-5">
                    <div
                      onClick={() => {
                        if (index !== 0) {
                          const auxEvents = arrayMove(
                            [...events],
                            index - 1,
                            index
                          )
                          setEvents(auxEvents)
                        }
                      }}
                      className="cursor-pointer bg-[#ddd] dark:bg-[#3F454E] w-5 h-5 rounded-sm mb-1"
                    >
                      <HiChevronUp size={20} />
                    </div>
                    <div
                      onClick={() => {
                        if (index + 1 !== events?.length) {
                          const auxEvents = arrayMove(
                            [...events],
                            index + 1,
                            index
                          )
                          setEvents(auxEvents)
                        }
                      }}
                      className="cursor-pointer bg-[#ddd] dark:bg-[#3F454E] w-5 h-5 rounded-sm"
                    >
                      <HiChevronDown size={20} />
                    </div>
                  </div>
                  <div className="flex-1 ">
                    <BodyText className="!text-primary !font-bold">
                      {item.type === AuthenticationStepType.Type
                        ? t('scanAuthEventModal.stepType.type')
                        : t('scanAuthEventModal.stepType.click')}
                    </BodyText>
                    <BodyText className="!text-sm truncate w-[280px]">
                      {t('scanAuthEventModal.idShort')}: {item.id}
                    </BodyText>

                    {item.type === AuthenticationStepType.Type && (
                      <BodyText className="!text-sm truncate w-[280px]">
                        {t('scanAuthEventModal.valueShort')}: {item.value}
                      </BodyText>
                    )}
                  </div>
                  <div className="flex gap-2">
                    <Button
                      buttonType={ButtonType.Secondary}
                      className="w-8 h-8"
                      onClick={() => {
                        setEventToEdit({
                          index,
                          isEditing: true
                        })
                        setEditableEvent(item)
                      }}
                    >
                      <MdEdit />
                    </Button>
                    <Button
                      buttonType={ButtonType.Secondary}
                      className="w-8 h-8 !bg-pink"
                      onClick={() => {
                        setEvents((prev: any) =>
                          prev?.filter(
                            (item: any, filterIndex: number) =>
                              filterIndex !== index
                          )
                        )
                      }}
                    >
                      <BiTrash />
                    </Button>
                  </div>
                </div>
              )
            })}

            <div
              className={classNames(
                'border  rounded-md p-2 ',
                dataError.events ? 'border-pink' : 'border-gray-200'
              )}
            >
              <Select
                label={t('scanAuthEventModal.eventType')}
                params={[
                  {
                    name: t('scanAuthEventModal.stepType.type'),
                    value: AuthenticationStepType.Type
                  },
                  {
                    name: t('scanAuthEventModal.stepType.click'),
                    value: AuthenticationStepType.Click
                  }
                ]}
                value={editableEvent.type}
                onChange={e => {
                  setEditableEvent(prev => ({
                    ...prev,
                    type: e.value as AuthenticationStepType
                  }))
                }}
              />

              {editableEvent.type === AuthenticationStepType.Type && (
                <Input
                  label={t('scanAuthEventModal.value')}
                  value={editableEvent.value}
                  maxLength={40}
                  contClass="mt-3"
                  fieldError=""
                  events={{
                    onChange: (e: { value: string }) => {
                      setEditableEvent(prev => ({
                        ...prev,
                        value: e.value
                      }))
                    }
                  }}
                />
              )}

              <Input
                label={t('scanAuthEventModal.id')}
                tooltip={
                  <InfoTooltip
                    position={TooltipPosition.Center}
                    className={'ml-2'}
                    description={t('tooltip.helperText.querySelector')}
                    containerClassName={'h-[120px]'}
                  />
                }
                value={editableEvent.id}
                fieldError=""
                contClass="mt-3"
                events={{
                  onChange: (e: { value: string }) => {
                    setEditableEvent(prev => ({
                      ...prev,
                      id: e.value
                    }))
                  }
                }}
              />
              <div className="flex justify-center">
                <Button
                  className="mt-3"
                  onClick={() => {
                    eventToEdit.isEditing ? handleEditEvent() : handleAddEvent()
                  }}
                >
                  {eventToEdit.isEditing
                    ? t('scanAuthEventModal.edit')
                    : t('scanAuthEventModal.add')}
                </Button>
              </div>
            </div>
            {dataError.events && <BodyText>* {dataError.events}</BodyText>}

            <div className="flex justify-end mt-8">
              <Button
                buttonType={ButtonType.Tertiary}
                onClick={() => setModalOpen(false)}
              >
                {t('scanAuthEventModal.cancel')}
              </Button>
              <Button
                buttonType={ButtonType.Secondary}
                onClick={handleTestScan}
                className="font-bold px-5 py-2 dark:bg-black mr-2 rounded"
              >
                {t('scanAuthEventModal.test')}
              </Button>
              <Button onClick={handleSaveEdit}>
                {t('scanAuthEventModal.save')}
              </Button>
            </div>
          </motion.div>
          <ScanAuthTestModal
            testData={testData}
            modalOpen={openTestModal}
            setModalOpen={setOpenTestModal}
          ></ScanAuthTestModal>
        </div>
      ) : null}
    </>
  )
}
