import { Button, ButtonSize, ButtonType, Link } from 'components'
import React, { Fragment, useState, useEffect } from 'react'
import { HiChevronDown, HiChevronRight } from 'react-icons/hi'
import HeaderDropdown from '../dropdown/header-dropdown'
import { useAuth } from 'contexts/auth-context'
import { useTranslation } from 'next-i18next'
import { MdOutlineHome } from 'react-icons/md'

interface HeaderProps {
  breadcrumb?: any
  buttonFunction?: () => void
  buttonText?: string
  outlineButtonText?: string
  showTutorialIndicator?: boolean
  endButtons?: React.ReactNode[]
}

export function Header({
  breadcrumb,
  buttonFunction,
  buttonText,
  outlineButtonText,
  endButtons,
  showTutorialIndicator
}: HeaderProps) {
  const { t } = useTranslation()
  const [showIndicator, setShowIndicator] = useState(false)

  useEffect(() => {
    if (showTutorialIndicator === true) {
      setShowIndicator(true)
      setTimeout(() => {
        setShowIndicator(false)
      }, 4000)
    }
  }, [showTutorialIndicator])

  const { user } = useAuth()
  return (
    <div className="flex justify-between py-3 mb-8 items-center border-b border-gray-200 dark:border-b-gray-500 mx-auto xl:mx-10 xl:px-0 px-5">
      <div className="dark:text-white items-center hidden lg:flex">
        {!!breadcrumb && (
          <>
            <Link href="/" className="font-bold text-primary">
              <MdOutlineHome size={20} />
            </Link>

            <HiChevronRight className="text-gray-200" size={20} />
          </>
        )}

        {breadcrumb?.map((breadItem: any, index: number) => {
          return (
            <div key={index} className="flex items-center">
              {breadItem.path ? (
                <Link
                  key={index}
                  href={breadItem.path}
                  className="font-bold text-primary"
                >
                  {breadItem.name}
                </Link>
              ) : (
                <div>{breadItem.name}</div>
              )}
              {index !== breadcrumb.length - 1 ? (
                <HiChevronRight className="text-gray-200" size={20} />
              ) : null}
            </div>
          )
        })}
      </div>
      <div className="flex flex-end items-center gap-4">
        {outlineButtonText ? (
          <Button
            buttonSize={ButtonSize.Small}
            buttonType={ButtonType.Tertiary}
          >
            {outlineButtonText}
          </Button>
        ) : null}
        {buttonText ? (
          <Button
            buttonSize={ButtonSize.Small}
            onClick={() => buttonFunction?.()}
          >
            {buttonText}
          </Button>
        ) : null}

        {endButtons?.map((button, index) => {
          return <Fragment key={index}>{button}</Fragment>
        })}

        <div className="relative">
          <HeaderDropdown>
            <HiChevronDown size={20} />
            <div className="ml-2 mr-3 bg-primary font-bold w-8 h-8 rounded-full flex justify-center items-center dark:text-black">
              {user?.name ? user?.name[0] : 'A'}
            </div>
            <div className="flex gap-2 items-center">
              <div className="text-left text-sm">{user?.name}</div>
              <div className="text-sm italic text-gray-300">{user?.email}</div>
            </div>
          </HeaderDropdown>
          {showIndicator && (
            <div className="absolute top-14 z-[2] bg-gray dark:bg-white rounded-md px-4 py-2 text-white dark:text-black">
              <div className="w-4 overflow-hidden inline-block absolute -top-2 left-11">
                <div className=" h-2 w-2 bg-black rotate-45 transform origin-bottom-left"></div>
              </div>
              {t('header.tutorialTooltip')}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
