import { GET_USER_ORGANIZATIONS } from 'queries/organization'
import { useQuery } from '@apollo/client'
import { useAuth } from 'contexts/auth-context'

interface RawOrganizationsData {
  org_role: {
    name: string
  }
  organization: {
    id: string
    name: string
    logo: string
    pathname: string
  }
}

export interface OrganizationsData {
  role: string
  id: string
  name: string
  logo: string
  pathname: string
}

export function useOrganizations() {
  const { user } = useAuth()
  const { data, loading, refetch } = useQuery<{
    user_organization: RawOrganizationsData[]
  }>(GET_USER_ORGANIZATIONS, {
    fetchPolicy: 'network-only',
    skip: !user?.id,
    variables: { id: user?.id }
  })

  function formatter(
    data: RawOrganizationsData[] | undefined
  ): OrganizationsData[] | undefined {
    return data?.map(userOrganization => ({
      role: userOrganization.org_role.name,
      ...userOrganization.organization
    }))
  }

  return { data: formatter(data?.user_organization), loading, refetch }
}
