import { motion } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import { fadeIn } from 'public/animation/motion'
import { RiChat4Line } from 'react-icons/ri'
import { useTranslation } from 'react-i18next'
import { classNames } from 'util/shared'
import { IoClose } from 'react-icons/io5'
import { formatUrl } from 'util/format'
import { Button, ButtonType, Input } from 'components'
import { Validator } from 'util/validator'
import { useHandleError } from 'hooks/useHandleError'
import { ModalWrapper } from './modal-wrapper'

export function CreateDisclaimer({
  modalOpen,
  setModalOpen,
  addDisclaimer,
  editName,
  editUrls,
  domains = 1,
  setUpgradeModalOpen
}: any) {
  const [nameError, setNameError] = useState('')
  const [urlError, setUrlError] = useState('')
  const [name, setName] = useState('')
  const [urls, setUrls] = useState([] as any)
  const [url, setUrl] = useState('')
  const [moreThanOne, setMoreThanOne] = useState(false)
  const { t } = useTranslation()
  const { handleError } = useHandleError()

  useEffect(() => {
    if (modalOpen) {
      setName(editName ?? '')
      setUrls(editUrls ?? ([] as any))
      setMoreThanOne(false)
      setNameError('')
      if (editUrls && editUrls.length > 1) {
        setMoreThanOne(true)
      }
    }
  }, [modalOpen])

  function saveDisclaimer() {
    let withoutError = true
    if (name.length === 0) {
      setNameError(t('modal.createDisclaimer.Type a name for your disclaimer'))
      withoutError = false
    }
    if (urls.length === 0) {
      setUrlError(`* ${t('modal.createDisclaimer.Add at least 1 site')}`)
      withoutError = false
    }
    if (withoutError) {
      addDisclaimer(name, urls, setNameError)
    }
  }

  function isValidLink() {
    let valid = true
    if (urls.length === domains) {
      setUpgradeModalOpen(true)
      valid = false
    }

    if (Validator.isValidUrl(url) === false) {
      handleError(t('modal.createDisclaimer.Type a valid URL'))
      valid = false
    }

    return valid
  }

  function handleAddLink() {
    if (isValidLink()) {
      setUrls((prev: any) => {
        setUrl('')
        if (prev.length === 1 && !moreThanOne) {
          setMoreThanOne(true)
        }
        return [encodeURI(url), ...prev]
      })
    }
  }

  return (
    <ModalWrapper
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      headerProps={{
        icon: <RiChat4Line className="text-primary" size={35} />,
        title: t('modal.createDisclaimer.Create disclaimer'),
        subtitle: t(
          'modal.createDisclaimer.Select on how many sites or URL this disclaimer will be used'
        )
      }}
      modalSize="medium"
    >
      <Input
        label={t('modal.createDisclaimer.Name')}
        placeholder={t(
          'modal.createDisclaimer.Type a name for your disclaimer'
        )}
        value={name}
        dis
        fieldError={nameError}
        events={{
          onChange: (e: any) => {
            setNameError('')
            setName(formatUrl(e.value))
          }
        }}
      />
      <div className="mt-2 flex">
        <div
          onClick={() => {
            if (urls.length < 2) {
              setMoreThanOne(false)
            }
          }}
          className={classNames(
            'cursor-pointer  duration-200 border  rounded flex-1 mr-1 p-2 border-gray-200',
            !moreThanOne ? 'opacity-100' : 'opacity-40 hover:opacity-100 '
          )}
        >
          <div className="flex items-center">
            <div
              className={classNames(
                'border  rounded-full w-4 h-4 flex items-center justify-center',
                !moreThanOne ? 'border-primary' : 'border-gray-200 '
              )}
            >
              {!moreThanOne ? (
                <div className="rounded-full bg-primary w-2 h-2"></div>
              ) : null}
            </div>
            <div className="font-bold text-primary ml-2">
              {t('modal.createDisclaimer.1 site')}
            </div>
          </div>
          <div>
            {t(
              'modal.createDisclaimer.The disclaimer will be responsible for only one site'
            )}
          </div>
        </div>
        <div
          className={classNames(
            'cursor-pointer  duration-200 border  rounded flex-1 ml-1 p-2 border-gray-200',
            moreThanOne ? 'opacity-100' : 'opacity-40 hover:opacity-100 '
          )}
        >
          <div
            onClick={() => {
              if (domains > 1) {
                setMoreThanOne(true)
              }
            }}
          >
            <div className="flex items-center">
              <div
                className={classNames(
                  'border  rounded-full w-4 h-4 flex items-center justify-center',
                  moreThanOne ? 'border-primary' : 'border-gray-200 '
                )}
              >
                {moreThanOne ? (
                  <div className="rounded-full bg-primary w-2 h-2"></div>
                ) : null}
              </div>
              <div className="font-bold text-primary ml-2">
                {t('modal.createDisclaimer.2 or more sites')}
              </div>
            </div>
            <div>
              {t(
                'modal.createDisclaimer.The disclaimer will be responsible for one or more sites'
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        className={classNames(
          'border  rounded p-2 mt-2 overflow-y-scroll scroll-1  h-32 border-gray-200'
        )}
      >
        <div className="font-bold mb-2">Url</div>
        <div
          className={classNames(
            'flex items-center border rounded  p-1',
            urlError.length !== 0 ? 'border-pink' : 'border-gray-200'
          )}
        >
          <input
            type="text"
            value={url}
            onChange={e => {
              setUrl(e.target.value)
              setUrlError('')
            }}
            className="focus:outline-none  w-full bg-white dark:bg-gray"
          />
          <Button onClick={handleAddLink}>
            {t('modal.createDisclaimer.addButton')}
          </Button>
        </div>
        {urlError.length !== 0 ? (
          <motion.div initial="initial" animate="animate" variants={fadeIn()}>
            {urlError}
          </motion.div>
        ) : null}
        <div>
          {urls?.map((item: any, index: any) => {
            return (
              <div
                className="flex gap-1 justify-between items-center border rounded border-gray-200 py-1 px-2 mt-1 break-all"
                key={index}
              >
                <div>{item}</div>
                <button
                  onClick={() =>
                    setUrls((prev: any) => {
                      return prev.filter((element: any) => {
                        return element !== item
                      })
                    })
                  }
                >
                  <IoClose />
                </button>
              </div>
            )
          })}
        </div>
      </div>

      <div className="flex justify-end mt-5">
        <Button
          buttonType={ButtonType.Tertiary}
          onClick={() => setModalOpen(false)}
          className="dark:bg-black mr-2"
        >
          {t('modal.createDisclaimer.Cancel')}
        </Button>
        <Button onClick={() => saveDisclaimer()}>
          {t('modal.createDisclaimer.Save')}
        </Button>
      </div>
    </ModalWrapper>
  )
}
