const easing = [0.6, -0.05, 0.01, 0.99]
export const fadeIn = (delay = 0) => {
  return {
    initial: {
      opacity: 0
    },
    animate: {
      opacity: 1,
      transition: {
        delay,
        duration: 0.6,
        ease: easing
      }
    }
  }
}
export const modalAnimation = {
  initial: {
    opacity: 0,
    scale: 0
  },
  animate: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.2,
      ease: easing
    }
  }
}

export const modalAnimation2 = {
  show: {
    // scale: 0.5,
    y: 100,
    transition: {
      ease: 'easeOut',
      duration: 0.3
    }
  },
  hide: {
    // scale: 0.5,
    y: 100,
    background: 'red'
  }
}

export const fadeInUp = {
  initial: {
    y: 10,
    opacity: 0
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.4,
      ease: easing
    }
  }
}
export const fadeInRight = {
  initial: {
    x: 10,
    opacity: 0
  },
  animate: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 1,
      ease: easing
    }
  }
}

export const fadeInLeft = {
  initial: {
    x: -10,
    opacity: 0
  },
  animate: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 1,
      ease: easing
    }
  }
}
export const stagger6 = {
  animate: {
    transition: {
      staggerChildren: 0.05
    }
  }
}

export const stagger3 = {
  animate: {
    transition: {
      staggerChildren: 0.01
    }
  }
}
export const stagger10 = {
  animate: {
    transition: {
      staggerChildren: 0.1
    }
  }
}
