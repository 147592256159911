import { BodyText } from 'components'
import { useRouter } from 'next/router'
import { classNames } from 'util/shared'
import { DisclaimerInstalledStatusColor } from 'models/disclaimer'

interface ConfigButtonProps {
  text: string
  path: string
  status: string
  icon: React.ReactNode
}

export function ConfigButton({ text, icon, path, status }: ConfigButtonProps) {
  const router = useRouter()

  const statusColorMap: DisclaimerInstalledStatusColor = {
    completed: 'bg-primary',
    partial_completed: 'bg-yellow',
    not_completed: 'bg-pink'
  }

  return (
    <div
      onClick={() => router.push(path)}
      className="flex items-center rounded-md overflow-hidden bg-white-200 dark:bg-gray gap-2 hover:bg-white-300 hover:dark:bg-gray-400 duration-200 cursor-pointer"
    >
      <div
        className={classNames(
          'w-10 h-10 flex items-center justify-center text-black',
          statusColorMap[status]
        )}
      >
        {icon}
      </div>
      <BodyText>{text}</BodyText>
    </div>
  )
}
