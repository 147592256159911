import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import { CANCEL_SCAN, SCHEDULE_SCAN } from 'queries/tags'
import toast from 'react-hot-toast'
import {
  BodyText,
  H2,
  ScanConfigModalProps,
  ScanScheduler,
  Select,
  Button,
  ButtonType
} from 'components'
import { classNames } from 'util/shared'
import { useStorage } from 'contexts/storage-context'
import { formatMonthlyCron } from 'util/format'
import { useHandleError } from 'hooks/useHandleError'

interface ScheduledScanProps extends ScanConfigModalProps {
  setUpgradeModalOpen: (value: boolean) => void
}

export default function ScheduledScan({
  scheduledScan,
  setUpgradeModalOpen,
  setModalOpen,
  disclaimer,
  modalOpen,
  refetch
}: ScheduledScanProps) {
  const { t } = useTranslation()
  const { activeOrganizationDetails } = useStorage()
  const fields = scheduledScan?.cron.split(' ')
  const { handleError } = useHandleError()
  const [weekDaysSelected, setWeekDaysSelected] = useState(
    !isNaN(Number(fields?.[4])) ? Number(fields?.[4]) : 0
  )
  const [frequency, setFrequency] = useState<ScanScheduler>(
    !isNaN(Number(fields?.[4]))
      ? ScanScheduler.Weekly
      : !isNaN(Number(fields?.[2]))
      ? ScanScheduler.Monthly
      : ScanScheduler.Inactive
  )

  useEffect(() => {
    if (modalOpen) {
      setWeekDaysSelected(!isNaN(Number(fields?.[4])) ? Number(fields?.[4]) : 0)

      setFrequency(
        !isNaN(Number(fields?.[4]))
          ? ScanScheduler.Weekly
          : !isNaN(Number(fields?.[2]))
          ? ScanScheduler.Monthly
          : ScanScheduler.Inactive
      )
    }
  }, [scheduledScan])

  const weekDays = [
    t('common.weekDays.san'),
    t('common.weekDays.mon'),
    t('common.weekDays.tues'),
    t('common.weekDays.wed'),
    t('common.weekDays.thurs'),
    t('common.weekDays.fri'),
    t('common.weekDays.sat')
  ]

  const [scheduleScan] = useMutation(SCHEDULE_SCAN)
  const [cancelScan] = useMutation(CANCEL_SCAN)

  const options = [
    ScanScheduler.Inactive,
    ScanScheduler.Weekly,
    ScanScheduler.Monthly
  ]

  async function saveScheduler() {
    toast.loading(t('scanConfigModal.saving'))
    try {
      if (frequency === ScanScheduler.Inactive && scheduledScan?.id) {
        await cancelScan({
          variables: {
            scanScheduleId: scheduledScan.id
          }
        })
        refetch()
        toast.dismiss()
        toast.success(t('scanConfigModal.savedConfig'))
      } else if (
        frequency === ScanScheduler.Weekly ||
        frequency === ScanScheduler.Monthly
      ) {
        if (
          (activeOrganizationDetails?.plan?.scan_frequency_id === 'NF0J6U3i' &&
            frequency === ScanScheduler.Monthly) ||
          ((activeOrganizationDetails?.plan?.scan_frequency_id === 'NF0J6U3i' ||
            activeOrganizationDetails?.plan?.scan_frequency_id ===
              'MY0fyGsh') &&
            frequency === ScanScheduler.Weekly)
        ) {
          setUpgradeModalOpen(true)
          toast.dismiss()
          return
        }

        const cron =
          frequency === ScanScheduler.Weekly
            ? `00 3 * * ${weekDaysSelected}`
            : formatMonthlyCron(
                activeOrganizationDetails?.plan?.current_period_start
              )

        await scheduleScan({
          variables: {
            scanType: 'NFzZYW3T',
            disclaimerId: disclaimer,
            cron
          }
        })
        refetch()
        toast.dismiss()
        toast.success(t('scanConfigModal.savedConfig'))
      }
    } catch (error) {
      toast.dismiss()
      handleError()
    }
  }
  return (
    <>
      <H2 fontWeight="bold">{t('scanConfigModal.scheduler.title')}</H2>
      <BodyText className="max-w-[700px]">
        {t('scanConfigModal.scheduler.description')}
      </BodyText>
      <Select
        label={t('scanConfigModal.scheduler.selectPlaceholder')}
        containerClasses={'mt-2 max-w-[350px]'}
        params={options.map(option => {
          return {
            name: t(`scanConfigModal.scheduler.selectOptions.${option}`),
            value: option
          }
        })}
        value={frequency ?? ''}
        onChange={e => {
          setFrequency(e.value as ScanScheduler)
        }}
      />
      <div className="mt-4">
        {frequency === ScanScheduler.Inactive ? (
          <BodyText>* {t('scanConfigModal.scheduler.inactiveTitle')}</BodyText>
        ) : frequency === ScanScheduler.Monthly ? (
          <BodyText>* {t('scanConfigModal.scheduler.monthlyTitle')}</BodyText>
        ) : (
          <div>
            <BodyText fontWeight={'bold'}>
              {t('scanConfigModal.scheduler.weeklyTitle')}
            </BodyText>
            <div className="flex cursor-pointer gap-2">
              {weekDays.map((day, index) => {
                return (
                  <div
                    key={index}
                    onClick={() => setWeekDaysSelected(index)}
                    className={classNames(
                      `px-2 flex items-center group justify-center dark:text-white  rounded-md w-10 h-8 relative overflow-hidden`,
                      weekDaysSelected === index
                        ? 'font-bold bg-primary dark:bg-primary '
                        : 'bg-white-200 dark:bg-gray'
                    )}
                  >
                    {weekDaysSelected !== index && (
                      <div className="absolute bottom-0 bg-white-300 w-full left-0 h-0 group-hover:h-full duration-200 z-[0]"></div>
                    )}
                    <span className="relative z-[1]">{day}</span>
                  </div>
                )
              })}
            </div>
          </div>
        )}
      </div>

      <div className="flex justify-end mt-5 gap-4">
        <Button
          onClick={() => setModalOpen(false)}
          buttonType={ButtonType.Secondary}
        >
          {t('scanConfigModal.cancel')}
        </Button>
        <Button onClick={saveScheduler}>{t('scanConfigModal.save')}</Button>
      </div>
    </>
  )
}
