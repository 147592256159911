import { Button } from 'components'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BiSearch } from 'react-icons/bi'

interface SearchProps {
  handleSearch: (item: string) => void
  onInputChange: () => void
}

export function Search({ handleSearch, onInputChange }: SearchProps) {
  const { t } = useTranslation()
  const [searchItem, setSearchItem] = useState('')
  return (
    <div className="flex items-center">
      <div className="h-10  relative rounded-md border border-primary">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <BiSearch className="h-5 w-5 text-primary" aria-hidden="true" />
        </div>
        <input
          type="search"
          name="seach"
          id="seach"
          value={searchItem}
          onChange={e => {
            onInputChange()
            setSearchItem(e.target.value)
          }}
          className="focus:ring-primary outline-none focus:border-primary block w-80 pl-10 py-2 sm:text-sm dark:bg-black pr-2 dark:text-white rounded-md"
          placeholder={t('disclaimer.consent log.Search cookie by id')}
        />
      </div>
      <Button onClick={() => handleSearch(searchItem)} className="ml-3">
        {t('disclaimer.consent log.Search')}
      </Button>
    </div>
  )
}
