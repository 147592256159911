import { AlertModal, Button, ButtonType } from 'components'
import { IoClose } from 'react-icons/io5'
import { classNames } from 'util/shared'
import { useAuth } from 'contexts/auth-context'
import { useMutation } from '@apollo/client'
import { EMAIL_VERIFY } from 'queries/user'
import { useTranslation } from 'react-i18next'
import { HiOutlineMail } from 'react-icons/hi'
import { useState } from 'react'

export default function EmailVerifyBanner() {
  const { t } = useTranslation()
  const { user } = useAuth()
  const [alertModal, setAlertModal] = useState(false)
  const [openVerifyEmail, setOpenVerifyEmail] = useState(true)
  const [emailVerify] = useMutation(EMAIL_VERIFY)
  return (
    <>
      <div
        className={classNames(
          'bg-pink duration-200 overflow-hidden flex items-center justify-between px-10 text-white gap-4',
          user?.emailVerified === false && openVerifyEmail
            ? 'h-auto py-2'
            : 'h-0'
        )}
      >
        <div
          className="flex items-center"
          onClick={() => {
            try {
              emailVerify({ variables: { email: user?.email } })
              setOpenVerifyEmail(false)
              setAlertModal(true)
            } catch (error) {
              console.log(error)
            }
          }}
        >
          <div>
            <HiOutlineMail size={20} />
          </div>
          <span className="ml-2">
            {t("verifyEmailWarning.You haven't verified your email yet.")}
            <span className="underline cursor-pointer ml-1">
              {t('verifyEmailWarning.Click here')}
            </span>
            {t('verifyEmailWarning.to verify')}
          </span>
        </div>
        <Button
          buttonType={ButtonType.Ghost}
          onClick={() => setOpenVerifyEmail(false)}
        >
          <IoClose size={16} className="text-white" />
        </Button>
      </div>
      <AlertModal
        modalOpen={alertModal}
        setModalOpen={setAlertModal}
        icon={
          <div className="p-3 bg-primary rounded-full text-black relative">
            <HiOutlineMail size={40} />
            <div className="absolute right-0 bottom-0 w-4 h-4 rounded-full bg-lightblue animate-bounce"></div>
          </div>
        }
        title={t('verifyEmailWarning.We just emailed you!')}
        subtitle={t(
          "verifyEmailWarning.Click on the link received to verify your email. Don't forget to check your spam box."
        )}
      />
    </>
  )
}
