import { Button, ButtonType, CreateIdentityProviderModal } from 'components'
import { useState } from 'react'

interface CreateIdpButtonProps {
  children: React.ReactNode
}

export function CreateIdpButton({ children }: CreateIdpButtonProps) {
  const [openModal, setModalOpen] = useState(false)

  return (
    <>
      <Button
        buttonType={ButtonType.Primary}
        onClick={() => {
          setModalOpen(true)
        }}
      >
        {children}
      </Button>
      <CreateIdentityProviderModal
        modalOpen={openModal}
        setModalOpen={setModalOpen}
      />
    </>
  )
}
