import React from 'react'
import { BodyText, Button, ButtonType } from 'components'
import { FiSettings } from 'react-icons/fi'
import { useTranslation } from 'next-i18next'
import appConfig from 'config/app-config'
import { MdContentCopy } from 'react-icons/md'
import { toast } from 'react-hot-toast'
import { ModalWrapper } from './modal-wrapper'

interface IdentityProviderConfigsModalProps {
  modalOpen: boolean
  setModalOpen: (value: boolean) => void
}

export function IdentityProviderConfigsModal({
  modalOpen,
  setModalOpen
}: IdentityProviderConfigsModalProps) {
  const { t } = useTranslation()

  return (
    <ModalWrapper
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      headerProps={{
        icon: <FiSettings size={20} className="text-primary" />,
        title: t('identityProviderConfigsModal.title'),
        subtitle: t('identityProviderConfigsModal.subtitle')
      }}
    >
      <div>
        <b>{t('identityProviderConfigsModal.identityId')}</b>
        <div className="flex items-center gap-2 mb-5">
          <Button
            buttonType={ButtonType.Ghost}
            className="bg-white-200 dark:bg-gray-400 w-8 h-8 "
            onClick={() => {
              toast.success(
                `${t('identityProviderConfigsModal.identityIdCopied')}!`
              )
              navigator.clipboard.writeText(appConfig.cognito.identityId)
            }}
          >
            <MdContentCopy />
          </Button>
          <BodyText className="!text-gray-300">
            {appConfig.cognito.identityId}
          </BodyText>
        </div>
        <b>{t('identityProviderConfigsModal.replyUrl')}</b>
        <div className="flex items-center gap-2">
          <Button
            buttonType={ButtonType.Ghost}
            className="bg-white-200 dark:bg-gray-400 w-8 h-8 "
            onClick={() => {
              toast.success(
                `${t('identityProviderConfigsModal.replyUrlCopied')}!`
              )
              navigator.clipboard.writeText(appConfig.cognito.replyUrl)
            }}
          >
            <MdContentCopy />
          </Button>
          <BodyText className="!text-gray-300">
            {appConfig.cognito.replyUrl}
          </BodyText>
        </div>
      </div>
      <div className="flex justify-end mt-8">
        <Button onClick={() => setModalOpen(false)}>
          {t('identityProviderConfigsModal.button')}
        </Button>
      </div>
    </ModalWrapper>
  )
}
