import React, { useState } from 'react'
import { Button, ButtonType, SpinLoading } from 'components'
import { RiChat4Line } from 'react-icons/ri'
import { useTranslation } from 'react-i18next'
import { useHandleError } from 'hooks/useHandleError'
import { ModalWrapper } from './modal-wrapper'
import toast from 'react-hot-toast'
import { useFetchDisclaimerList } from 'hooks/useFetchDisclaimerList'
import { useDocuments } from 'hooks/useDocuments'
import { MultipleSelect } from '../select/multiple-select'

interface AddDocumentToDisclaimerProps {
  open: boolean
  handleClose: () => void
  documentMasterId: string
  refetch: () => Promise<void>
}

interface DisclaimerIdsSelectedProps {
  name: string
  value: string
}

export function AddDisclaimerToDocument({
  open,
  handleClose,
  documentMasterId,
  refetch
}: AddDocumentToDisclaimerProps) {
  const { t } = useTranslation()
  const { handleError } = useHandleError()
  const [disclaimerIdsSelected, setDisclaimerIdsSelected] = useState<
    DisclaimerIdsSelectedProps[]
  >([])

  const { addDocumentToDisclaimer } = useDocuments({})

  const { loading, list } = useFetchDisclaimerList()

  async function handleSubmit() {
    if (isValid()) {
      toast.loading(t('modal.addDisclaimerToDocument.associating'))
      const result = await addDocumentToDisclaimer(
        documentMasterId,
        disclaimerIdsSelected.map(({ value }) => value)
      )

      toast.dismiss()

      if (result?.success) {
        toast.success(
          `${t('modal.addDisclaimerToDocument.associatingSuccess')}!`
        )
        await refetch()
        handleClose()
      } else {
        handleError(result?.message)
      }
    }
  }

  function isValid() {
    let valid = true
    if (!disclaimerIdsSelected.length) {
      handleError(t('modal.addDisclaimerToDocument.selectDisclaimer'))
      valid = false
    }

    return valid
  }

  return (
    <ModalWrapper
      modalOpen={open}
      setModalOpen={handleClose}
      headerProps={{
        icon: <RiChat4Line size={35} className="text-primary" />,
        title: t('modal.addDisclaimerToDocument.title'),
        subtitle: t('modal.addDisclaimerToDocument.subTitle')
      }}
    >
      {loading ? (
        <div className="flex justify-center">
          <SpinLoading />
        </div>
      ) : list?.length > 0 ? (
        <MultipleSelect
          label={t('modal.addDisclaimerToDocument.selectDisclaimer')}
          containerClasses={'mt-2'}
          params={list.map(({ id, pathname }) => {
            return {
              name: pathname,
              value: id
            }
          })}
          selecteds={disclaimerIdsSelected}
          setSelecteds={setDisclaimerIdsSelected}
        />
      ) : (
        <div className="py-2 px-1 rounded-md bg-white-200 dark:bg-black">
          {t('modal.addDisclaimerToDocument.noDisclaimer')}
        </div>
      )}

      {!loading && (
        <div className="flex justify-end mt-5">
          <Button
            buttonType={ButtonType.Secondary}
            onClick={handleClose}
            className="dark:bg-black mr-2"
          >
            {t('modal.addDisclaimerToDocument.cancel')}
          </Button>
          <Button onClick={() => handleSubmit()}>
            {t('modal.addDisclaimerToDocument.save')}
          </Button>
        </div>
      )}
    </ModalWrapper>
  )
}
