import { useMutation } from '@apollo/client'
import {
  BodyText,
  Button,
  ButtonSize,
  ButtonType,
  EditCard,
  H2,
  H3
} from 'components'
import { useAuth } from 'contexts/auth-context'
import { useTheme } from 'contexts/theme-context'
import { UPDATE_USER_LANGUAGE } from 'queries/user'
import { useEffect, useState } from 'react'
import { motion } from 'framer-motion'
import i18next from 'i18next'
import { useTranslation } from 'next-i18next'
import { BsFillSunFill, BsMoonStarsFill } from 'react-icons/bs'
import { classNames } from 'util/shared'
import { useHandleError } from 'hooks/useHandleError'

export function PlatformConfig() {
  const { user, setUser } = useAuth()
  const { theme, setTheme } = useTheme()
  const { t } = useTranslation()
  const [, setThemeState] = useState(false)
  const [updateUserLanguage] = useMutation(UPDATE_USER_LANGUAGE)
  const { handleError } = useHandleError()

  useEffect(() => {
    setThemeState(theme === 'dark')
  }, [])

  async function changeLanguage(language: 'pt' | 'en') {
    try {
      await updateUserLanguage({
        variables: {
          id: user?.id,
          language
        }
      })
      i18next.changeLanguage(language)
      setUser(oldUser => oldUser && { ...oldUser, language: language })
    } catch (error) {
      handleError()
    }
  }

  return (
    <EditCard className="mb-5">
      <H2>{t('configuration.platformConfigTitle')}</H2>

      <div className="max-w-md flex items-center gap-3 justify-between mt-4">
        <div className="flex items-center gap-2">
          <Button
            onClick={() => setTheme('light')}
            className={classNames(
              theme === 'light' ? 'bg-yellow' : 'bg-gray-300',
              'w-9 h-9 flex items-center justify-center group relative overflow-hidden'
            )}
            buttonType={ButtonType.Ghost}
          >
            <motion.div
              className="relative z-[1]"
              whileHover={{ scale: 1.2, transition: { duration: 0.2 } }}
            >
              <BsFillSunFill className="relative text-black" />
            </motion.div>
            {theme === 'dark' && (
              <div className="absolute bg-yellow bottom-0 left-0 w-full h-0 group-hover:h-full duration-500"></div>
            )}
          </Button>

          <Button
            onClick={() => setTheme('dark')}
            className={classNames(
              theme === 'dark' ? 'bg-primary' : 'bg-white-200',
              'w-9 h-9 flex items-center justify-center group relative overflow-hidden'
            )}
            buttonType={ButtonType.Ghost}
          >
            <motion.div
              className="relative z-[1]"
              whileHover={{ scale: 1.2, transition: { duration: 0.2 } }}
            >
              <BsMoonStarsFill className="relative text-black" />
            </motion.div>
            {theme === 'light' && (
              <div className="absolute bg-primary bottom-0 left-0 w-full h-0 group-hover:h-full duration-500"></div>
            )}
          </Button>
        </div>

        <BodyText className="dark:text-gray-300">
          {t('configuration.switchBetweenThemes')}
        </BodyText>
      </div>
      <H3 className="dark:text-white font-bold mt-4 mb-1">
        {t('configuration.chooseYourLanguage')}
      </H3>
      <div className="flex items-center gap-3">
        <Button
          buttonSize={ButtonSize.Small}
          buttonType={
            i18next.language === 'en'
              ? ButtonType.Primary
              : ButtonType.Secondary
          }
          onClick={() => {
            changeLanguage('en')
          }}
        >
          {t('configuration.english')}
        </Button>
        <Button
          buttonSize={ButtonSize.Small}
          onClick={() => {
            changeLanguage('pt')
          }}
          buttonType={
            i18next.language === 'pt'
              ? ButtonType.Primary
              : ButtonType.Secondary
          }
        >
          {t('configuration.portuguese')}
        </Button>
      </div>
    </EditCard>
  )
}
