var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"52b2b065151adc213a183db2a4b98bdb7779ecd9"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs'
import { BrowserTracing } from '@sentry/tracing'

const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN
const SENTRY_ENV = process.env.NEXT_PUBLIC_SENTRY_ENV
Sentry.init({
  dsn:
    SENTRY_DSN ||
    'https://b9390dd31ec642ab98641de61fc5a41a@o1120103.ingest.sentry.io/6155213',
  environment: SENTRY_ENV,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
  hideSourceMaps: false
})
