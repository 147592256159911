import React from 'react'
import Link from 'next/link'
import { classNames } from 'util/shared'
import { usePageSectionTabs } from 'hooks/usePageSectionTabs'

interface pageSectionProps {
  type: 'organization' | 'disclaimer'
  subTabs?: tabsProps[]
  routeSelected: string
}

interface tabsProps {
  name: string
  pathname?: string
  icon?: React.ReactNode
  page: string
}

export default function PageSection({
  type,
  subTabs,
  routeSelected
}: pageSectionProps) {
  const { organizationTabs, disclaimerTabs } = usePageSectionTabs()

  const tabs = type === 'organization' ? organizationTabs : disclaimerTabs

  return (
    <div className="px-10 mt-12 mb-8">
      <div className="hidden sm:block overflow-y-hidden overflow-x-auto scroll-1">
        <div className="flex items-center border-b border-gray-250">
          <nav
            className="flex flex-1 -mb-px space-x-6 xl:space-x-8 "
            aria-label="Tabs"
          >
            {tabs?.map((tab, index) => (
              <Link key={index} href={tab.pathname}>
                <div
                  key={tab.name}
                  style={{
                    borderBottom:
                      routeSelected === tab.page
                        ? ''
                        : '4px solid rgba(0,0,0,0)'
                  }}
                  className={classNames(
                    routeSelected === tab.page
                      ? 'border-primary text-primary border-b-4 border-b-primary'
                      : 'border-b-4 hover:!border-third-400 dark:hover:!border-gray-250 text-gray-300 dark:text-gray-250 hover:text-gray-400 dark:hover:text-white',
                    'whitespace-nowrap flex items-center font-bold duration-300 py-3 px-1 text-sm cursor-pointer group'
                  )}
                >
                  {tab.icon && (
                    <div className="flex items-center justify-center w-8 h-8 mr-2 rounded-full dark:bg-gray bg-white-200 group-hover:text-primary duration-300">
                      {tab.icon}
                    </div>
                  )}
                  {tab.name}
                </div>
              </Link>
            ))}
          </nav>
        </div>
        {!!subTabs && (
          <nav
            className="flex flex-1 -mb-px space-x-6 xl:space-x-8 h-16 bg-white-200 dark:bg-gray"
            aria-label="Tabs"
          >
            {subTabs?.map((tab, index) => {
              const Component = tab.pathname ? Link : 'div'

              return (
                <Component
                  key={index}
                  href={tab.pathname as string}
                  className="h-full flex items-center"
                >
                  <div
                    key={tab.name}
                    style={{
                      borderBottom:
                        routeSelected === tab.page
                          ? ''
                          : '4px solid rgba(0,0,0,0)'
                    }}
                    className={classNames(
                      tab.pathname === undefined &&
                        'text-gray-75 dark:text-gray-500',
                      routeSelected === tab.page
                        ? 'border-primary-400 text-primary-400 border-b-4 hover:text-primary-400'
                        : 'border-b-4 text-gray-400 dark:text-gray-250 hover:text-gray-700 ',
                      'whitespace-nowrap flex items-center font-bold duration-300 p-3 text-sm cursor-pointer'
                    )}
                  >
                    {tab.icon && (
                      <div className="flex items-center justify-center w-8 h-8 mr-2 rounded-full dark:bg-gray bg-white-200">
                        {tab.icon}
                      </div>
                    )}
                    {tab.name}
                  </div>
                </Component>
              )
            })}
          </nav>
        )}
      </div>
    </div>
  )
}
