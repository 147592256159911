/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable prefer-const */
export function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(' ')
}

export function arrayMove(
  arr: Array<unknown>,
  fromIndex: number,
  toIndex: number
) {
  const element = arr[fromIndex]
  arr.splice(fromIndex, 1)
  arr.splice(toIndex, 0, element)
  return arr
}

export function parseJwt(token: string | undefined) {
  if (!token) return
  const base64Url = token?.split('.')[1]
  const base64 = base64Url?.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      ?.map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join('')
  )

  return JSON.parse(jsonPayload)
}

export function getBase64(file: any) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}
export function hexToRgb(hex: string) {
  const result: any = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16),
    a: undefined
  }
}

export function hexToHsv(hex: string) {
  let { r, g, b }: any = hexToRgb(hex)
  ;(r /= 255), (g /= 255), (b /= 255)

  const max = Math.max(r, g, b),
    min = Math.min(r, g, b)
  let h: any,
    s,
    v = max

  const d = max - min
  s = max == 0 ? 0 : d / max

  if (max == min) {
    h = 0 // achromatic
  } else {
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0)
        break
      case g:
        h = (b - r) / d + 2
        break
      case b:
        h = (r - g) / d + 4
        break
    }

    h /= 6
  }

  return { h, s, v, a: undefined }
}

export const convertBase64 = (file: any) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.readAsDataURL(file)

    fileReader.onload = () => {
      resolve(fileReader.result)
    }

    fileReader.onerror = error => {
      reject(error)
    }
  })
}

function padTo2Digits(num: number) {
  return num.toString().padStart(2, '0')
}

export function convertMsToHM(milliseconds: number) {
  const seconds = Math.floor(milliseconds / 1000)
  const minutes = Math.floor(seconds / 60)
  const hours = Math.floor(minutes / 60)
  const days = Math.floor(hours / 24)
  const mounths = Math.floor(days / 30)
  const years = Math.floor(mounths / 12)

  if (mounths > 12) {
    return { number: years, type: 'years' }
  }
  if (days > 30) {
    return { number: mounths, type: 'mounths' }
  }
  if (hours > 24) {
    return { number: days, type: 'days' }
  }
  if (minutes > 60) {
    return { number: hours, type: 'hours' }
  }
  if (seconds > 60) {
    return { number: minutes, type: 'minutes' }
  }
  return { number: seconds, type: 'seconds' }
}

export function hexToRGBA(hex: string, alpha: any) {
  const r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16)

  if (alpha) {
    return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')'
  } else {
    return 'rgb(' + r + ', ' + g + ', ' + b + ')'
  }
}

export const getYesterday = () => {
  const d = new Date()
  d.setDate(d.getDate() - 1)
  return new Date(d)
}

export function sleep(ms: number) {
  return new Promise(resolve => {
    setTimeout(resolve, ms)
  })
}
