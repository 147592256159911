import { useMutation, useQuery } from '@apollo/client'
import { useStorage } from 'contexts/storage-context'
import {
  GET_DISCLAIMER_WITH_OPTIONS,
  SET_DISCLAIMER_OPTIONS
} from 'queries/disclaimers'
import { useDisclaimerVersions } from './useDisclaimerVersions'

interface RawDiscalimerOptionsProps {
  disclaimers: Array<DisclaimerTermsProps>
}
export interface DisclaimerOptions {
  consentTTLDays: number
  hideAfterConsent: boolean
  uncheckTags: boolean
  accessibility: boolean
  manualBlock: boolean
  ignoredUrls: string[]
  fallBackLanguage: string
  languagesToUse: string[]
  enableTCF?: boolean
  enableConsentWall?: boolean
}

interface DisclaimerTermsProps {
  id: string
  pathname: string
  created_at: string
  disclaimer_options: Array<{
    consent_ttl_in_days: number
    hide_disclaimer: boolean
    uncheck_tags: boolean
    manual_block: boolean
    tcf: boolean
    consent_wall: boolean
    accessibility: boolean
    ignore_urls: string[]
    languages_configuration: {
      fallback: string
      languages: string[]
    }
  }>
  disclaimer_status: { name: string }
  disclaimer_websites: Array<{
    url: string
    version: number
    disclaimer_installed: boolean
  }>
  tags: Array<{ name: string }>
  vendors: Array<{ vendor_id: string }>
}

export const useDisclaimerConfig = () => {
  const { activeDisclaimerPath, activeOrganizationDetails } = useStorage()

  const {
    data: disclaimerVersions,
    loading: loadingVersions,
    refetch: refetchVersions
  } = useDisclaimerVersions()

  const { data, loading, refetch } = useQuery(GET_DISCLAIMER_WITH_OPTIONS, {
    fetchPolicy: 'network-only',
    skip:
      !activeOrganizationDetails?.id &&
      !disclaimerVersions?.disclaimer_website_version &&
      !disclaimerVersions?.disclaimer_options_version,
    variables: {
      pathname: activeDisclaimerPath,
      organizationId: activeOrganizationDetails?.id,
      disclaimerOptionsVersion: disclaimerVersions?.disclaimer_options_version,
      disclaimerWebsiteVersion: disclaimerVersions?.disclaimer_website_version,
      disclaimerVendorsVersion: disclaimerVersions?.vendors_version ?? 0
    }
  })

  const [setDisclaimerOptions] = useMutation(SET_DISCLAIMER_OPTIONS)

  function formatter(data: RawDiscalimerOptionsProps | undefined) {
    return data?.disclaimers[0]
  }

  async function saveDisclaimerOptions(
    disclaimerId: string,
    data: DisclaimerOptions
  ) {
    const result = await setDisclaimerOptions({
      variables: {
        disclaimerId: disclaimerId,
        ...data
      }
    })
    return result?.data?.set_disclaimer_options
  }

  return {
    data: formatter(data),
    disclaimerVersions,
    loading: loading === true || loadingVersions === true,
    refetch,
    refetchVersions,
    saveDisclaimerOptions
  }
}
