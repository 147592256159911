import Image from 'next/image'
import { LanguagesTypes } from 'util/enums'

interface FlagProps {
  type: LanguagesTypes
}

export function Flag({ type }: FlagProps) {
  return (
    <Image
      src={`/flags/${type}.svg`}
      width={20}
      height={20}
      className="rounded-md !w-5 !h-5"
      alt={`Flag (${type})`}
    />
  )
}
