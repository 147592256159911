import React from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { BsCheckSquare } from 'react-icons/bs'

interface EditableDisclaimerCardProps {
  readOnly: boolean
  texts: any
  language: string
  legislation: string
  handleStateChange: (value: string, kind: string, attribute: string) => void
  handleCategoriesStateChange: (
    value: string,
    category: string,
    attribute: string
  ) => void
}

export default function EditablePreferenceCardAlternative({
  readOnly,
  texts,
  language,
  legislation,
  handleStateChange,
  handleCategoriesStateChange
}: EditableDisclaimerCardProps) {
  const { t } = useTranslation()

  function toastOnBlur() {
    toast(t('disclaimer.style.Text.saveWarning'), {
      icon: '⚠️'
    })
  }

  return (
    <div className="flex-1 text-gray max-w-[460px] bg-[#eee] rounded-b-lg border-t-4 border-t-primary">
      <div className="p-4">
        <div className="relative group mx-auto">
          <input
            onBlur={toastOnBlur}
            readOnly={readOnly}
            type="text"
            value={texts?.[language]?.[legislation]?.titles?.preference}
            onChange={e =>
              handleStateChange(e.target.value, 'titles', 'preference')
            }
            maxLength={50}
            className="w-full text-xl font-bold text-primary bg-[#eee] group focus:bg-[#ddd] outline-[#00DD80] px-2 py-1 cursor-pointer focus:cursor-text	 duration-200"
          />
          <div className="group-focus-within:block group-focus-within:z-10 hidden absolute right-4 -bottom-3 text-xs bg-primary px-1 rounded-sm">
            <span>
              {texts?.[language]?.[legislation]?.titles?.preference?.length}
            </span>
            /40
          </div>
        </div>

        <div className="h-[280px] overflow-y-scroll scroll-1 pl-1">
          <div className="relative group w-[70%]">
            <input
              onBlur={toastOnBlur}
              readOnly={readOnly}
              type="text"
              value={
                texts?.[language]?.[legislation]?.categories?.required?.name
              }
              onChange={e =>
                handleCategoriesStateChange(e.target.value, 'required', 'name')
              }
              maxLength={50}
              className="w-full text-md font-bold text-gray bg-[#eee] group focus:bg-[#ddd] outline-[#00DD80] py-1 cursor-pointer focus:cursor-text	 duration-200"
            />
            <div className="group-focus-within:block group-focus-within:z-10 hidden absolute right-4 -bottom-3 text-xs bg-primary px-1 rounded-sm">
              <span>
                {
                  texts?.[language]?.[legislation]?.categories?.required?.name
                    ?.length
                }
              </span>
              /40
            </div>
          </div>
          <div className="relative group w-[95%]">
            <textarea
              onBlur={toastOnBlur}
              readOnly={readOnly}
              value={
                texts?.[language]?.[legislation]?.categories?.required
                  ?.description
              }
              onChange={e =>
                handleCategoriesStateChange(
                  e.target.value,
                  'required',
                  'description'
                )
              }
              maxLength={400}
              className="w-full scroll-1 resize-none text-xs text-gray bg-[#eee] group focus:bg-[#ddd] outline-[#00DD80] px-2 py-1 cursor-pointer focus:cursor-text	 duration-200"
            />
            <div className="group-focus-within:block group-focus-within:z-10 hidden absolute right-4 -bottom-3 text-xs bg-primary px-1 rounded-sm">
              <span>
                {
                  texts?.[language]?.[legislation]?.categories?.required
                    ?.description?.length
                }
              </span>
              /200
            </div>
          </div>

          <div className="flex items-center">
            <BsCheckSquare />
            <div className="relative group w-[70%]">
              <input
                onBlur={toastOnBlur}
                readOnly={readOnly}
                type="text"
                value={
                  texts?.[language]?.[legislation]?.categories?.performance
                    ?.name
                }
                onChange={e =>
                  handleCategoriesStateChange(
                    e.target.value,
                    'performance',
                    'name'
                  )
                }
                maxLength={50}
                className="w-full text-md font-bold text-gray bg-[#eee] group focus:bg-[#ddd] outline-[#00DD80] px-2 py-1 cursor-pointer focus:cursor-text	 duration-200"
              />
              <div className="group-focus-within:block group-focus-within:z-10 hidden absolute right-4 -bottom-3 text-xs bg-primary px-1 rounded-sm">
                <span>
                  {
                    texts?.[language]?.[legislation]?.categories?.performance
                      ?.name?.length
                  }
                </span>
                /40
              </div>
            </div>
          </div>
          <div className="relative group w-[95%]">
            <textarea
              onBlur={toastOnBlur}
              readOnly={readOnly}
              value={
                texts?.[language]?.[legislation]?.categories?.performance
                  ?.description
              }
              onChange={e =>
                handleCategoriesStateChange(
                  e.target.value,
                  'performance',
                  'description'
                )
              }
              maxLength={400}
              className="w-full scroll-1 resize-none text-xs text-gray bg-[#eee] group focus:bg-[#ddd] outline-[#00DD80] px-2 py-1 cursor-pointer focus:cursor-text	 duration-200"
            />
            <div className="group-focus-within:block group-focus-within:z-10 hidden absolute right-4 -bottom-3 text-xs bg-primary px-1 rounded-sm">
              <span>
                {
                  texts?.[language]?.[legislation]?.categories?.performance
                    ?.description?.length
                }
              </span>
              /200
            </div>
          </div>
          <div className="flex items-center">
            <BsCheckSquare />
            <div className="relative group w-[70%]">
              <input
                onBlur={toastOnBlur}
                readOnly={readOnly}
                type="text"
                value={
                  texts?.[language]?.[legislation]?.categories?.functional?.name
                }
                onChange={e =>
                  handleCategoriesStateChange(
                    e.target.value,
                    'functional',
                    'name'
                  )
                }
                maxLength={50}
                className="w-full text-md font-bold text-gray bg-[#eee] group focus:bg-[#ddd] outline-[#00DD80] px-2 py-1 cursor-pointer focus:cursor-text	 duration-200"
              />
              <div className="group-focus-within:block group-focus-within:z-10 hidden absolute right-4 -bottom-3 text-xs bg-primary px-1 rounded-sm">
                <span>
                  {
                    texts?.[language]?.[legislation]?.categories?.functional
                      ?.name?.length
                  }
                </span>
                /40
              </div>
            </div>
          </div>
          <div className="relative group w-[95%]">
            <textarea
              onBlur={toastOnBlur}
              readOnly={readOnly}
              value={
                texts?.[language]?.[legislation]?.categories?.functional
                  ?.description
              }
              onChange={e =>
                handleCategoriesStateChange(
                  e.target.value,
                  'functional',
                  'description'
                )
              }
              maxLength={400}
              className="w-full scroll-1 resize-none text-xs text-gray bg-[#eee] group focus:bg-[#ddd] outline-[#00DD80] px-2 py-1 cursor-pointer focus:cursor-text	 duration-200"
            />
            <div className="group-focus-within:block group-focus-within:z-10 hidden absolute right-4 -bottom-3 text-xs bg-primary px-1 rounded-sm">
              <span>
                {
                  texts?.[language]?.[legislation]?.categories?.functional
                    ?.description?.length
                }
              </span>
              /200
            </div>
          </div>

          <div className="flex items-center">
            <BsCheckSquare />
            <div className="relative group w-[70%]">
              <input
                onBlur={toastOnBlur}
                readOnly={readOnly}
                type="text"
                value={
                  texts?.[language]?.[legislation]?.categories?.marketing?.name
                }
                onChange={e =>
                  handleCategoriesStateChange(
                    e.target.value,
                    'marketing',
                    'name'
                  )
                }
                maxLength={50}
                className="w-full text-md font-bold text-gray bg-[#eee] group focus:bg-[#ddd] outline-[#00DD80] px-2 py-1 cursor-pointer focus:cursor-text	 duration-200"
              />
              <div className="group-focus-within:block group-focus-within:z-10 hidden absolute right-4 -bottom-3 text-xs bg-primary px-1 rounded-sm">
                <span>
                  {
                    texts?.[language]?.[legislation]?.categories?.marketing
                      ?.name?.length
                  }
                </span>
                /40
              </div>
            </div>
          </div>
          <div className="relative group w-[95%]">
            <textarea
              onBlur={toastOnBlur}
              readOnly={readOnly}
              value={
                texts?.[language]?.[legislation]?.categories?.marketing
                  ?.description
              }
              onChange={e =>
                handleCategoriesStateChange(
                  e.target.value,
                  'marketing',
                  'description'
                )
              }
              maxLength={400}
              className="w-full scroll-1 resize-none text-xs text-gray bg-[#eee] group focus:bg-[#ddd] outline-[#00DD80] px-2 py-1 cursor-pointer focus:cursor-text	 duration-200"
            />
            <div className="group-focus-within:block group-focus-within:z-10 hidden absolute right-4 -bottom-3 text-xs bg-primary px-1 rounded-sm">
              <span>
                {
                  texts?.[language]?.[legislation]?.categories?.marketing
                    ?.description?.length
                }
              </span>
              /200
            </div>
          </div>

          <div className="flex items-center">
            <BsCheckSquare />
            <div className="relative group w-[70%]">
              <input
                onBlur={toastOnBlur}
                readOnly={readOnly}
                type="text"
                value={
                  texts?.[language]?.[legislation]?.categories?.statistics?.name
                }
                onChange={e =>
                  handleCategoriesStateChange(
                    e.target.value,
                    'statistics',
                    'name'
                  )
                }
                maxLength={50}
                className="w-full text-md font-bold text-gray bg-[#eee] group focus:bg-[#ddd] outline-[#00DD80] px-2 py-1 cursor-pointer focus:cursor-text	 duration-200"
              />
              <div className="group-focus-within:block group-focus-within:z-10 hidden absolute right-4 -bottom-3 text-xs bg-primary px-1 rounded-sm">
                <span>
                  {
                    texts?.[language]?.[legislation]?.categories?.statistics
                      ?.name?.length
                  }
                </span>
                /40
              </div>
            </div>
          </div>
          <div className="relative group w-[95%]">
            <textarea
              onBlur={toastOnBlur}
              readOnly={readOnly}
              value={
                texts?.[language]?.[legislation]?.categories?.statistics
                  ?.description
              }
              onChange={e =>
                handleCategoriesStateChange(
                  e.target.value,
                  'statistics',
                  'description'
                )
              }
              maxLength={400}
              className="w-full scroll-1 resize-none text-xs text-gray bg-[#eee] group focus:bg-[#ddd] outline-[#00DD80] px-2 py-1 cursor-pointer focus:cursor-text	 duration-200"
            />
            <div className="group-focus-within:block group-focus-within:z-10 hidden absolute right-4 -bottom-3 text-xs bg-primary px-1 rounded-sm">
              <span>
                {
                  texts?.[language]?.[legislation]?.categories?.statistics
                    ?.description?.length
                }
              </span>
              /200
            </div>
          </div>
        </div>
        <div className="py-[2px] bg-primary rounded-full flex-1 w-full mt-10 text-center">
          <div className="relative group ">
            <input
              onBlur={toastOnBlur}
              readOnly={readOnly}
              value={texts?.[language]?.[legislation]?.buttons?.acceptCustom}
              onChange={e =>
                handleStateChange(e.target.value, 'buttons', 'acceptCustom')
              }
              maxLength={20}
              className="w-[150px] text-center font-bold text-gray bg-primary group focus:bg-[#ddd] outline-[#00DD80] px-2 py-1 cursor-pointer focus:cursor-text	 duration-200"
            />
            <div className="group-focus-within:block group-focus-within:z-10 hidden absolute right-4 -bottom-3 text-xs bg-primary px-1 rounded-sm">
              <span>
                {
                  texts?.[language]?.[legislation]?.buttons?.acceptCustom
                    ?.length
                }
              </span>
              /20
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
