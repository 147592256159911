const IconAdopt = (props: any) => (
  <svg
    className={props.className}
    fill={props.fill}
    version="1.1"
    id="Camada_1"
    x="0px"
    y="0px"
    viewBox="0 0 64.5 67"
  >
    <style type="text/css"></style>
    <path
      className="st0"
      d="M32.3,0C14.4,0,0,15,0,33.5S14.4,67,32.3,67s32.3-15,32.3-33.5S50.1,0,32.3,0z M53,33.4L35,51.2
		C33.3,53,30.8,54,28.3,54s-4.9-1-6.7-2.7l-10.1-10c-1.8-1.8-2.7-4.1-2.7-6.6c0-2.5,1-4.9,2.7-6.6c1.8-1.8,4.1-2.7,6.6-2.7
		s4.9,1,6.6,2.7l3.5,3.5l11.4-11.3c0.9-0.9,1.9-1.6,3-2c1.1-0.5,2.4-0.7,3.6-0.7c1.2,0,2.5,0.2,3.6,0.7c1.1,0.5,2.2,1.2,3,2
		s1.6,1.9,2,3c0.5,1.1,0.7,2.4,0.7,3.6c0,1.2-0.2,2.4-0.7,3.6C54.5,31.5,53.8,32.5,53,33.4L53,33.4z"
    />
    <path
      className="st0"
      d="M51.3,22l-0.2-0.2c-0.6-0.6-1.4-1.2-2.3-1.5c-0.8-0.4-1.8-0.5-2.7-0.5s-1.8,0.2-2.7,0.5
		c-0.8,0.4-1.6,0.9-2.3,1.5L29.9,33l-6.8,6.8c-0.6,0.6-1.2,1.4-1.5,2.3c-0.4,0.8-0.5,1.8-0.5,2.7c0,0.9,0.2,1.8,0.5,2.7
		c0.4,0.8,0.9,1.6,1.5,2.3l0.2,0.2c1.3,1.3,3.1,2,4.9,2c1.9,0,3.6-0.7,4.9-2l4.3-4.3l13.8-13.7c0.6-0.6,1.2-1.4,1.5-2.3
		c0.4-0.8,0.5-1.8,0.5-2.7c0-0.9-0.2-1.8-0.5-2.7C52.5,23.4,51.9,22.7,51.3,22z M28.3,50.2c-1.1,0-2.2-0.3-3.1-0.9
		c-0.9-0.6-1.6-1.5-2-2.5c-0.4-1-0.5-2.1-0.3-3.2c0.2-1.1,0.7-2,1.5-2.8s1.8-1.3,2.8-1.5c1.1-0.2,2.2-0.1,3.2,0.3
		c1,0.4,1.9,1.1,2.5,2c0.6,0.9,0.9,2,0.9,3.1c0,1.5-0.6,2.9-1.6,3.9C31.2,49.6,29.8,50.2,28.3,50.2L28.3,50.2z"
    />
  </svg>
)
export default IconAdopt
