import { useMutation } from '@apollo/client'
import { Button, CreateDisclaimer, UpgradeModal } from 'components'
import { useStorage } from 'contexts/storage-context'
import { useHandleError } from 'hooks/useHandleError'
import { useTranslation } from 'next-i18next'
import { CREATE_DISCLAIMER } from 'queries/disclaimers'
import { useState } from 'react'
import { toast } from 'react-hot-toast'

interface CreateDisclaimerButtonProps {
  refetchDisclaimers: () => void
}

export function CreateDisclaimerButton({
  refetchDisclaimers
}: CreateDisclaimerButtonProps) {
  const { t } = useTranslation()
  const [openCreateDisclaimer, setOpenCreateDisclaimer] = useState(false)
  const [upgradeModalOpen, setUpgradeModalOpen] = useState(false)
  const [createDisclaimer] = useMutation(CREATE_DISCLAIMER)
  const { activeOrganizationDetails } = useStorage()
  const { handleError } = useHandleError()
  function openModal() {
    setOpenCreateDisclaimer(true)
  }

  async function addDisclaimer(
    name: string,
    urls: Array<string>,
    setNameError: any
  ) {
    toast.loading(t('org.disclaimers.Saving'))
    try {
      const result = await createDisclaimer({
        variables: {
          name: name,
          organizationId: activeOrganizationDetails?.id,
          domains: urls
        }
      })
      toast.dismiss()
      if (result?.data?.create_disclaimer?.success) {
        refetchDisclaimers()
        toast.success(`${t('org.disclaimers.Disclaimer created')}!`)
        setOpenCreateDisclaimer(false)
      } else {
        if (
          result?.data?.create_disclaimer?.message ===
          'You have reached the maximum number of disclaimers for your plan'
        ) {
          setUpgradeModalOpen(true)
        } else {
          handleError(result?.data?.create_disclaimer?.message)
        }
      }
    } catch (error: any) {
      toast.dismiss()
      if (error.message.includes('disclaimers_pathname_key')) {
        setNameError(t('org.disclaimers.This name is already in use'))
      } else {
        handleError()
      }
    }
  }

  return (
    <>
      <Button onClick={openModal}>
        {t('org.disclaimers.Create disclaimer')}
      </Button>
      <CreateDisclaimer
        modalOpen={openCreateDisclaimer}
        setModalOpen={setOpenCreateDisclaimer}
        addDisclaimer={addDisclaimer}
        domains={activeOrganizationDetails?.plan.domains}
        setUpgradeModalOpen={setUpgradeModalOpen}
      />
      <UpgradeModal
        modalOpen={upgradeModalOpen}
        setModalOpen={setUpgradeModalOpen}
      />
    </>
  )
}
