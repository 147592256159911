import { useLazyQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import { GET_USER_PLAN } from '../queries/user'
import { getUser } from '../services/auth-service'
import { parseJwt } from '../util/shared'
import i18next from 'i18next'
import * as Sentry from '@sentry/nextjs'
import moment from 'moment'
import mixpanel from 'config/mixpanel'

export interface UserData {
  email: string
  name?: string
  phoneNumber?: string
  emailVerified?: boolean
  id?: string
  plan?: UserPlan
  language?: string
  completedTutorial?: boolean
  globalRole?: GlobalRole
  migratedDocumentsV2?: boolean
}

export enum GlobalRole {
  User = 'AMEhbv1V',
  Admin = 'vm1fZYkl',
  Admax = 'sOE1uZjB',
  Api = 'Bf2eAUlN'
}
interface UserPlan {
  id: string
  name: string
  identity_provider: boolean
}

export function useUserSession() {
  const [authenticated, setAuthenticated] = useState(false)
  const [loading, setLoading] = useState(true)
  const [user, setUser] = useState<UserData | undefined>()

  const [loadUserData, { data: userData, loading: loadingUser }] = useLazyQuery(
    GET_USER_PLAN,
    {
      fetchPolicy: 'network-only'
    }
  )

  async function gettingUserSession() {
    const result = (await getUser()).data
    if (result.success) {
      let token = localStorage.getItem('adopt-token')
      if (result?.data?.idToken) {
        token = result.data.idToken
        localStorage.setItem('adopt-token', result.data.idToken)
      }
      if (token) {
        const user = parseJwt(token)
        setAuthenticated(true)
        loadUserData({ variables: { email: user.email } })
      }
    } else {
      setAuthenticated(false)
    }
    setTimeout(() => {
      setLoading(false)
    }, 500)
  }

  useEffect(() => {
    gettingUserSession()
  }, [authenticated])

  useEffect(() => {
    if (!loadingUser && userData?.users[0]) {
      mixpanel.identify(userData?.users[0]?.id)
      setUser({
        name: userData?.users[0]?.name,
        phoneNumber: userData?.users[0]?.phone_number,
        email: userData?.users[0]?.email,
        emailVerified: userData?.users[0]?.email_verify,
        completedTutorial: userData?.users[0]?.completed_tutorial,
        id: userData?.users[0]?.id,
        plan: userData?.users[0]?.subscription?.subscription_plan,
        language: userData?.users[0]?.language,
        globalRole: userData?.users[0]?.global_role,
        migratedDocumentsV2: userData?.users[0]?.migrated_documents_v2
      })
      Sentry.setUser({ email: userData?.users[0]?.email })
    } else {
      Sentry.setUser(null)
    }
  }, [userData])

  useEffect(() => {
    if (user?.language) {
      i18next.changeLanguage(user.language)
      moment.locale(user.language === 'pt' ? 'pt-BR' : 'en')
    }
  }, [user])

  return {
    authenticated,
    setAuthenticated,
    user,
    setUser,
    loading
  }
}
