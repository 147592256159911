import React, { Fragment } from 'react'
import 'styles/globals.css'
import 'react-phone-input-2/lib/style.css'
import 'react-color-palette/lib/css/styles.css'
import ProtectedRoutes from 'HOC/protected-router'
import { AuthProvider } from 'contexts/auth-context'
import { AnimatePresence } from 'framer-motion'
import { Toaster } from 'react-hot-toast'
import 'config/i18n'
import { ApolloProvider } from '@apollo/client'
import { handleClient } from 'config/apollo-client'
import { ThemeProvider } from 'contexts/theme-context'
import { useStoreCampaign } from 'hooks/useStoreCampaign'
import { SegmentControl } from 'hooks/useSegmentControl'
import { GlobalErrorBoundary } from 'components/shared/bondary/global'
import { AuditProvider } from 'contexts/audit-context'
import TourSystem from 'HOC/tour-system'
import { Installer } from 'components'
import 'moment/locale/pt-br'
import { GoogleAnalytics } from 'nextjs-google-analytics'
import 'config/mixpanel'

function AdoptApp({ Component, pageProps, router }: any) {
  const StorageProvider = Component.storageProvider || Fragment
  useStoreCampaign()

  return (
    <div className="overflow-x-hidden" translate="no">
      <ThemeProvider>
        <ApolloProvider client={handleClient()}>
          <AuthProvider>
            <TourSystem>
              <GlobalErrorBoundary>
                <AnimatePresence exitBeforeEnter>
                  <ProtectedRoutes guard={Component.guard} router={router}>
                    <AuditProvider>
                      <StorageProvider>
                        <Component {...pageProps} key={router.asPath} />
                        <Toaster position="top-right" reverseOrder={false} />
                        <Installer />
                        <GoogleAnalytics trackPageViews />
                        <SegmentControl />
                      </StorageProvider>
                    </AuditProvider>
                  </ProtectedRoutes>
                </AnimatePresence>
              </GlobalErrorBoundary>
            </TourSystem>
          </AuthProvider>
        </ApolloProvider>
      </ThemeProvider>
    </div>
  )
}

export default AdoptApp
