import React, { useState } from 'react'
import { BiCookie } from 'react-icons/bi'
import { useTranslation } from 'react-i18next'
import { UpgradeModal } from 'components'
import ScheduledScan from 'components/page-section/scheduled-scan'
import AuthenticatedScan from 'components/page-section/authenticated-scan'
import { ModalWrapper } from './modal-wrapper'

export interface ScanConfigModalProps {
  scheduledScan?: ScheduledScan
  disclaimer: string
  modalOpen: boolean
  setModalOpen: (open: boolean) => void
  refetch: () => void
}

export enum ScanScheduler {
  Inactive = 'inactive',
  Weekly = 'weekly',
  Monthly = 'monthly'
}
export interface ScheduledScan {
  id: string
  cron: string
  scheduled_to: string
}

export function ScanConfigModal({
  modalOpen,
  setModalOpen,
  disclaimer,
  scheduledScan,
  refetch
}: ScanConfigModalProps) {
  const { t } = useTranslation()
  const [upgradeModalOpen, setUpgradeModalOpen] = useState(false)

  return (
    <ModalWrapper
      modalSize="large"
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      headerProps={{
        icon: <BiCookie size={35} className="text-primary" />,
        title: t('scanConfigModal.title'),
        subtitle: t('scanConfigModal.subtitle')
      }}
    >
      <div className="max-h-[70vh] overflow-y-auto scroll-1 pr-6">
        <ScheduledScan
          scheduledScan={scheduledScan}
          disclaimer={disclaimer}
          setModalOpen={setModalOpen}
          refetch={refetch}
          setUpgradeModalOpen={setUpgradeModalOpen}
          modalOpen={modalOpen}
        />
        <div className="border-b border-gray-200 my-4"></div>

        <AuthenticatedScan
          disclaimerId={disclaimer}
          setModalOpen={setModalOpen}
        />
      </div>
      <UpgradeModal
        modalOpen={upgradeModalOpen}
        setModalOpen={setUpgradeModalOpen}
      />
    </ModalWrapper>
  )
}
