import { Disclosure, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'
import { Link } from 'components'
import { paths } from 'config/paths'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { classNames } from 'util/shared'

export function FaqSection({ plan }: any) {
  const { t } = useTranslation()
  const faqs = [
    {
      question: t('plans.faq.questionExpert.title'),
      answer: (
        <>
          <p>{t('plans.faq.questionExpert.description')}</p>
          <Link
            className="font-bold text-primary hover:sublime"
            href={t('links.calendly.scheduleMeeting')}
            target="_blank"
          >
            {t('plans.faq.questionExpert.cta')}
          </Link>
        </>
      )
    },
    {
      question: t('plans.faq.question1.title'),
      answer: (
        <>
          <p>{t('plans.faq.question1.description')}</p>
          <div className="mt-4">
            <b className="text-primary">
              1º. {t('plans.faq.question1.point1.title')}
            </b>
            <p>{t('plans.faq.question1.point1.paragraph1')}</p>
            <p>{t('plans.faq.question1.point1.paragraph2')}</p>
            <p>{t('plans.faq.question1.point1.paragraph3')}</p>
          </div>
          <div className="mt-4">
            <b className="text-primary">
              2º. {t('plans.faq.question1.point2.title')}
            </b>
            <p>{t('plans.faq.question1.point2.paragraph1')}</p>
            <p>{t('plans.faq.question1.point2.paragraph2')}</p>
          </div>
          <div className="mt-4">
            <b className="text-primary">
              3º. {t('plans.faq.question1.point3.title')}
            </b>
            <p>{t('plans.faq.question1.point3.paragraph1')}</p>
            <p>{t('plans.faq.question1.point3.paragraph2')}</p>
          </div>
        </>
      )
    },
    {
      question: t('plans.faq.question2.title'),
      answer: (
        <>
          <p>{t('plans.faq.question2.paragraph1')}</p>
          <br />
          <p>{t('plans.faq.question2.paragraph2')}</p>
          <br />
          <p>{t('plans.faq.question2.paragraph3')}</p>
        </>
      )
    },
    {
      question: t('plans.faq.question3.title'),
      answer: (
        <>
          <p>{t('plans.faq.question3.paragraph1')}</p>
        </>
      )
    },
    {
      question: t('plans.faq.question4.title'),
      answer: (
        <>
          <b className="text-primary">{t('plans.faq.question4.paragraph1')}</b>
          <p>{t('plans.faq.question4.paragraph2')}</p>
          <br />
          <b className="text-primary">{t('plans.faq.question4.paragraph3')}</b>
          <p>{t('plans.faq.question4.paragraph4')}</p>
          <p>{t('plans.faq.question4.paragraph5')}</p>
          <br />
          <p>{t('plans.faq.question4.paragraph6')}</p>
        </>
      )
    },
    {
      question: t('plans.faq.question5.title'),
      answer: (
        <>
          <p>{t('plans.faq.question5.paragraph1')}</p>
          <br />
          <p>{t('plans.faq.question5.paragraph2')}</p>
        </>
      )
    },
    {
      question: t('plans.faq.question6.title'),
      answer: (
        <>
          <p>{t('plans.faq.question6.paragraph1')}</p>
          <br />
          <p>{t('plans.faq.question6.paragraph2')}</p>
          <p>{t('plans.faq.question6.paragraph3')}</p>
        </>
      )
    },
    {
      question: t('plans.faq.question7.title'),
      answer: (
        <>
          <p>{t('plans.faq.question7.paragraph1')}</p>
          <br />
          <p>{t('plans.faq.question7.paragraph2')}</p>
          <br />
          <p>{t('plans.faq.question7.paragraph3')}</p>
          <br />
          <p>{t('plans.faq.question7.paragraph4')}</p>
        </>
      )
    },
    {
      question: t('plans.faq.question8.title'),
      answer: (
        <>
          <p>{t('plans.faq.question8.paragraph1')}</p>
          <br />
          <p>{t('plans.faq.question8.paragraph2')}</p>
          <br />
          <Link
            className="font-bold text-primary hover:sublime"
            href={
              plan?.name === 'enterprise monthly' ||
              plan?.name === 'enterprise yearly'
                ? paths.cs.pedro
                : paths.cs.fernanda
            }
            target="_blank"
          >
            {t('plans.faq.questionExpert.cta')}
          </Link>
        </>
      )
    }
  ]
  return (
    <div className="max-w-600 mx-auto">
      <div className="text-center font-bold dark:text-white text-xl mt-10">
        <b className="text-primary ">{t('plans.faq.title1')}</b>{' '}
        {t('plans.faq.title2')}:
      </div>
      {faqs.map(faq => (
        <Disclosure as="div" key={faq.question} className="pt-6">
          {({ open }) => (
            <>
              <dt className="text-lg">
                <Disclosure.Button className="text-left w-full flex justify-between items-start text-primary bg-white-200 dark:bg-gray rounded-md p-4">
                  <span className="text-primary">{faq.question}</span>
                  <span className="ml-6 h-7 flex items-center">
                    <ChevronDownIcon
                      className={classNames(
                        open ? '-rotate-180' : 'rotate-0',
                        'h-6 w-6 transform transition-all duration-500 ease-in-out'
                      )}
                      aria-hidden="true"
                    />
                  </span>
                </Disclosure.Button>
              </dt>
              <Transition
                enter="transition duration-300 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-150 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
              >
                <Disclosure.Panel as="dd" className="mt-4 ml-4 pr-12">
                  <div className="text-base dark:text-white">{faq.answer}</div>
                </Disclosure.Panel>
              </Transition>
            </>
          )}
        </Disclosure>
      ))}
    </div>
  )
}
