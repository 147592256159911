import { ReactNode } from 'react'
import { motion } from 'framer-motion'
import { fadeIn } from 'public/animation/motion'
import { classNames } from 'util/shared'

interface AccordionProps {
  title?: string
  customTitle?: ReactNode
  iconLeft?: ReactNode
  iconRight?: ReactNode
  children: ReactNode
  isOpen: boolean
  onClick: () => void
  className?: string
}

export function Accordion({
  title,
  customTitle,
  iconLeft,
  iconRight,
  children,
  isOpen,
  onClick,
  className
}: AccordionProps) {
  return (
    <div>
      <div
        className={classNames(
          isOpen ? 'text-primary' : 'hover:text-gray-400 dark:hover:text-white',
          iconRight ? 'justify-between' : '',
          'flex gap-3 text-gray-200  duration-500 items-center cursor-pointer',
          className
        )}
        onClick={onClick}
      >
        {iconLeft}
        {customTitle ? customTitle : <div>{title}</div>}
        {iconRight}
      </div>
      {isOpen && (
        <motion.div initial="initial" animate="animate" variants={fadeIn()}>
          <div className="text-gray-700 bg-gray-50">{children}</div>
        </motion.div>
      )}
    </div>
  )
}
