import CodeMirror from '@uiw/react-codemirror'
import { html } from '@codemirror/lang-html'
import { createTheme } from '@uiw/codemirror-themes'
import { tags as t } from '@lezer/highlight'
import { useTheme } from 'contexts/theme-context'

const lightTheme = createTheme({
  theme: 'light',
  settings: {
    background: '#F2F2F2',
    foreground: '#75baff',
    caret: '#ff2',
    selection: '#ddd',
    selectionMatch: '#333',
    lineHighlight: '#444',
    gutterBackground: '#333',
    gutterForeground: '#666'
  },
  styles: [
    { tag: t.variableName, color: '#0080ff' },
    { tag: [t.string, t.special(t.brace)], color: '#00DD80' },
    { tag: t.operator, color: '#888' },
    { tag: t.angleBracket, color: '#888' },
    { tag: t.tagName, color: '#1A88FF' },
    { tag: t.attributeName, color: '#888' }
  ]
})
const darkTheme = createTheme({
  theme: 'light',
  settings: {
    background: '#21262D',
    foreground: '#75baff',
    caret: '#ff2',
    selection: '#666',
    selectionMatch: '#333',
    lineHighlight: '#444',
    gutterBackground: '#333',
    gutterForeground: '#666'
  },
  styles: [
    { tag: t.variableName, color: '#0080ff' },
    { tag: [t.string, t.special(t.brace)], color: '#00DD80' },
    { tag: t.operator, color: '#ddd' },
    { tag: t.angleBracket, color: '#999' },
    { tag: t.tagName, color: '#1A88FF' },
    { tag: t.attributeName, color: '#ddd' }
  ]
})

interface CodeAreaProps {
  value?: string
}

export function CodeArea({ value }: CodeAreaProps) {
  const { theme } = useTheme()
  return (
    <CodeMirror
      theme={theme === 'dark' ? darkTheme : lightTheme}
      value={value}
      extensions={[html()]}
      editable={false}
      indentWithTab={false}
      basicSetup={{
        lineNumbers: false,
        foldGutter: false,
        highlightActiveLine: false
      }}
    />
  )
}
