import { BiCookie } from 'react-icons/bi'
import { CreateDisclaimerButton } from './create-disclaimer-button'
import { motion } from 'framer-motion'
import { useTranslation } from 'next-i18next'
import { stagger6 } from 'public/animation/motion'
import DisclaimerCard from './disclaimer-card'
import { DisclaimerData } from 'models/disclaimer'
import { SpinLoading } from 'components/shared/loading/spin-loading'
import { Button } from 'components/shared/buttons'
import { classNames } from 'util/shared'
import DisclaimerCardList from './disclaimer-card-list'

interface DisclaimerListProps {
  disclaimers: Array<DisclaimerData>
  fetchInitialData: () => void
  loading: boolean
  loadingInitialData: boolean
  hasMore: boolean
  handleLoadMore: () => void
  layoutView: string
}

export function DisclaimerList({
  disclaimers,
  fetchInitialData,
  loading,
  loadingInitialData,
  handleLoadMore,
  hasMore,
  layoutView
}: DisclaimerListProps) {
  const { t } = useTranslation()

  return (
    <motion.div initial="initial" animate="animate">
      {disclaimers?.length == 0 ? (
        <>
          {!loadingInitialData && !loading && (
            <div className="dark:text-white flex items-center justify-center flex-col w-full h-40">
              <BiCookie className="text-primary" size={40} />
              <div className="text-md mb-2 mt-1">
                {t("org.disclaimers.You down't have any disclaimer created")}
              </div>
              <CreateDisclaimerButton
                refetchDisclaimers={() => {
                  fetchInitialData()
                }}
              />
            </div>
          )}
        </>
      ) : (
        <motion.div
          initial="initial"
          animate="animate"
          variants={stagger6}
          className={classNames(
            `px-10 mt-8`,
            layoutView === 'grid'
              ? 'flex flex-wrap justify-between'
              : 'flex flex-col gap-2'
          )}
        >
          {disclaimers?.map((item, index) =>
            layoutView === 'grid' ? (
              <DisclaimerCard
                key={index}
                index={index}
                disclaimerData={item}
                refetch={() => {
                  fetchInitialData()
                }}
              />
            ) : (
              <DisclaimerCardList
                key={index}
                index={index}
                disclaimerData={item}
                refetch={() => {
                  fetchInitialData()
                }}
              />
            )
          )}
        </motion.div>
      )}

      {loading ? (
        <div className="flex h-44 justify-center items-center">
          <SpinLoading />
        </div>
      ) : (
        hasMore && (
          <div className="flex justify-center">
            <Button onClick={handleLoadMore}>Load more</Button>
          </div>
        )
      )}
    </motion.div>
  )
}
