import { GET_USER_ORGANIZATION } from 'queries/organization'
import { useLazyQuery, useMutation } from '@apollo/client'
import { useRouter } from 'next/router'
import moment from 'moment'
import { useOrganizations } from './useOrganizations'
import { useEffect, useState } from 'react'
import { Organization, Usage } from 'models/oganization'
import { GET_ORGANIZATION_USAGE } from 'queries/user'

interface RawOrganizationDetailsData {
  id: string
  name: string
  logo: string
  pathname: string
  base: boolean
  migrated_to_optout_v2: boolean
  user_organization: [
    {
      users: {
        email: string
        subscription: {
          current_period_start: string
          subscription_plan: {
            name: string
            domains: number
            simultaneous_consent_reports: number
            members: number
            consent_report_time_range: number
            consent_report: boolean
            controller: {
              name: string
            }
            consent_report_frequency: {
              name: string
            }
            scan_frequency_id: string
            scan_authentication: boolean
            disclaimer_close: boolean
            max_consent_ttl_in_days_allowed: number
            possible_modules_to_update_consent_version: string[]
            view_dashboard: boolean
            audit_log: boolean
            unique_users: number
            disclaimers: number
            current_period_start: string
            can_edit_dark_light_css: boolean
            can_edit_disclaimer_text: boolean
            can_edit_document_style: boolean
            themes: string[]
            webhooks: boolean
            can_edit_injector_version: boolean
            tcf: boolean
          }
        }
      }
    }
  ]
  disclaimers: []
}

export function useOrganizationDetails() {
  const router = useRouter()
  const thisMonth = moment().startOf('month').format('YYYY-MM-DD [00:00:00]')
  const { organizationPathname } = router.query
  const [activeOrganizationPath, setActiveOrganizationPath] = useState<string>()
  const [usage, setUsage] = useState<Usage>()
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState<any>()

  const { data: organizationsData } = useOrganizations()
  const [getUsage] = useMutation(GET_ORGANIZATION_USAGE)

  useEffect(() => {
    if (organizationPathname) {
      setActiveOrganizationPath(organizationPathname as string)
    } else {
      setActiveOrganizationPath(organizationsData?.[0].pathname)
    }
  }, [organizationPathname, organizationsData])

  useEffect(() => {
    refetch()
  }, [activeOrganizationPath])

  const [refetch] = useLazyQuery<{
    organization: RawOrganizationDetailsData[]
  }>(GET_USER_ORGANIZATION, {
    fetchPolicy: 'network-only',
    variables: {
      organizationsPathname: activeOrganizationPath,
      month: thisMonth
    },
    onCompleted: data => {
      setLoading(true)
      setData(data)
      setLoading(false)
    }
  })

  useEffect(() => {
    fetchOrganizationUsage()
  }, [data])

  async function fetchOrganizationUsage() {
    if (data?.organization?.[0]?.id) {
      const result = await getUsage({
        variables: {
          organizationId: data?.organization?.[0]?.id
        }
      })
      setUsage(result?.data?.get_organization_usage?.data)
    } else {
      setUsage(undefined)
    }
  }

  function formatter(
    data: RawOrganizationDetailsData[] | undefined
  ): Organization | undefined {
    if (!data || data.length === 0) return undefined

    const { user_organization, ...organization } = data?.[0] ?? {}
    const metrics = {} as any

    organization?.disclaimers?.forEach((disclaimer: any) => {
      disclaimer?.monthly_metrics?.forEach((domainData: any) => {
        Object.keys(domainData)?.forEach(value => {
          if (value !== '__typename') {
            metrics[value] =
              parseInt(metrics[value] ?? 0) + parseInt(domainData[value])
          }
        })
      })
    })

    return {
      metrics: metrics,
      ...organization,
      owner: {
        email: user_organization?.[0]?.users?.email
      },
      plan: {
        ...user_organization?.[0]?.users?.subscription.subscription_plan,
        current_period_start:
          user_organization?.[0]?.users?.subscription.current_period_start
      },
      usage
    }
  }

  return {
    data: formatter(data?.organization),
    loading,
    refetch,
    activeOrganizationPath
  }
}
