import { BodyText, ChangePlanButton, H1 } from 'components'
import { useTranslation } from 'next-i18next'

interface BlurProps {
  shouldNotShow?: boolean
  showText?: boolean
  title?: string
  subTitle?: string
}

export function Blur({
  shouldNotShow = true,
  showText,
  title,
  subTitle
}: BlurProps) {
  const { t } = useTranslation()

  return (
    <>
      {shouldNotShow === false && (
        <div className="absolute flex px-10 text-center items-center flex-col justify-center z-10 top-0 left-0 w-full h-full bg-white-200 dark:bg-gray bg-opacity-80 dark:bg-opacity-80 backdrop-blur-sm">
          {showText && (
            <>
              <H1>
                {title ? title : t('shared.withoutAccess.withoutAccessTitle')}
              </H1>
              <BodyText className="mb-10">
                {subTitle
                  ? subTitle
                  : t('shared.withoutAccess.withoutAccessSubtitle')}
              </BodyText>
              <ChangePlanButton>
                {t('shared.withoutAccess.withoutAccessButton')}
              </ChangePlanButton>
            </>
          )}
        </div>
      )}
    </>
  )
}
