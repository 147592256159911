import { gql } from '@apollo/client'

export const LIST_AUDIT_LOGS = gql`
  mutation GetAuditLogs(
    $endDate: String!
    $startDate: String!
    $lastDate: String
    $disclaimerIds: [String]
    $organizationIds: [String]
    $limit: Int
  ) {
    list_audit_logs(
      object: {
        endDate: $endDate
        startDate: $startDate
        lastDate: $lastDate
        disclaimerIds: $disclaimerIds
        organizationIds: $organizationIds
        limit: $limit
      }
    ) {
      data {
        logs {
          id
          action
          success
          organization
          userEmail
          userRole
          version
          date
          dataIn
          dataOut
        }
      }
      success
      message
      errorCode
      errorDetails
    }
  }
`

export const EXPORT_AUDIT_LOGS = gql`
  mutation ExportAuditLogs(
    $endDate: String!
    $startDate: String!
    $disclaimerIds: [String]
    $organizationIds: [String]
  ) {
    export_audit_logs(
      object: {
        endDate: $endDate
        startDate: $startDate
        disclaimerIds: $disclaimerIds
        organizationIds: $organizationIds
      }
    ) {
      data {
        link
        entriesCount
      }
      success
      message
      errorCode
      errorDetails
    }
  }
`
