import { H2, Button, ButtonType } from 'components'
import { paths } from 'config/paths'
import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'
import { BiMessageSquareError } from 'react-icons/bi'

export default function PageWithoutPermission() {
  const router = useRouter()
  const { organizationPathname } = router.query
  const { t } = useTranslation()
  return (
    <div className="h-full flex flex-col items-center justify-center">
      <BiMessageSquareError
        className="mx-auto dark:text-gray-400 text-gray-300 mb-2"
        size={60}
      />
      {organizationPathname ? (
        <H2 className="!dark:text-gray-400 !text-gray-300 ">
          {t('withoutPermission.titlePath')}{' '}
          <b>{`'${organizationPathname}'`}</b>
        </H2>
      ) : (
        <H2>{t('withoutPermission.title')}</H2>
      )}
      <Button
        onClick={() => {
          router.push(paths.organizations)
        }}
        buttonType={ButtonType.Secondary}
        className="mt-4"
      >
        {t('withoutPermission.button')}
      </Button>
    </div>
  )
}
