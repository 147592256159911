import { useTranslation } from 'react-i18next'
import { useStorage } from 'contexts/storage-context'
import { RiArrowDownSLine } from 'react-icons/ri'
import OrganizationDropdown from '../dropdown/organization-dropdown'

export default function SideBarOrganizationOption() {
  const { activeOrganizationDetails } = useStorage()
  const { t } = useTranslation()

  return (
    <OrganizationDropdown>
      <div
        className="flex items-center justify-between p-3 mx-4 text-white bg-third-500 dark:bg-gray-500 border border-white dark:border-primary rounded-lg dark:hover:text-primary hover:bg-third-600 dark:hover:bg-black duration-300"
      >
        <div className="font-bold truncate w-44 text-left">
          {activeOrganizationDetails?.name ?? t('sideBar.organization')}
        </div>
        <RiArrowDownSLine size={16} />
      </div>
    </OrganizationDropdown>
  )
}
