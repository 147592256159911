import { useMutation } from '@apollo/client'
import { Button, EditCard, H2, Input, PhoneNumberInput } from 'components'
import { useAuth } from 'contexts/auth-context'
import { UPDATE_USER } from 'queries/user'
import { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'next-i18next'
import { useHandleError } from 'hooks/useHandleError'
import { Validator } from 'util/validator'

export function UserConfig() {
  const { user, setUser } = useAuth()
  const { t } = useTranslation()
  const { handleError } = useHandleError()

  const [userName, setUserName] = useState(user?.name)
  const [userPhone, setUserPhone] = useState(user?.phoneNumber)
  const [disabledUpdateUserNameButton, setDisabledUpdateUserNameButton] =
    useState(true)
  const [disabledUpdateUserPhoneButton, setDisabledUpdateUserPhoneButton] =
    useState(true)

  const [updateUser] = useMutation(UPDATE_USER)

  const [phoneNumberError, setPhoneNumberError] = useState('')

  useEffect(() => {
    if (!userName || userName === user?.name) {
      setDisabledUpdateUserNameButton(true)
      return
    }

    setDisabledUpdateUserNameButton(false)
  }, [userName])

  const repeatNumberValidation =
    !(phoneNumberError !== '') && userPhone === user?.phoneNumber

  useEffect(() => {
    if (!userPhone || repeatNumberValidation) {
      setDisabledUpdateUserPhoneButton(true)
      return
    }
    setDisabledUpdateUserPhoneButton(false)
  }, [userPhone])

  const isValid = () => {
    if (userPhone && !Validator.isValidPhoneNumber(userPhone)) {
      setPhoneNumberError(t('register.error.phoneNumberError'))
      return false
    } else {
      setPhoneNumberError('')
      return true
    }
  }

  async function handleUpdateUserData() {
    toast.loading(t('configuration.loadingUpdateUserData'))
    try {
      if (!isValid()) {
        toast.dismiss()
        return
      }
      await updateUser({
        variables: {
          name: userName,
          phoneNumber: userPhone
        }
      })
      setUser(
        oldUser =>
          oldUser && { ...oldUser, name: userName, phoneNumber: userPhone }
      )
      setDisabledUpdateUserNameButton(true)
      setDisabledUpdateUserPhoneButton(true)
      toast.dismiss()
      toast.success(t('configuration.updateUserDataSuccessfully'))
    } catch (error) {
      toast.dismiss()
      handleError()
    }
  }

  return (
    <EditCard className="mt-5 mb-5">
      <H2>{t('configuration.userConfigTitle')} </H2>
      <div className="flex flex-col items-end max-w-md gap-3 mt-4">
        <Input
          label={t('configuration.changeAccountName')}
          placeholder={t('configuration.insertYourName')}
          value={userName}
          fieldError={''}
          contClass={'w-full'}
          maxLength={50}
          events={{
            onChange: (e: { value: string }) => {
              setUserName(e.value)
            }
          }}
        />
        <PhoneNumberInput
          label={t('configuration.changePhoneNumber')}
          value={userPhone}
          fieldError={''}
          contClass={'w-full'}
          maxLength={15}
          events={{ onChange: (e: string) => setUserPhone(e) }}
        />
        {phoneNumberError && (
          <p className="w-full text-sm text-center text-pink-300">
            {phoneNumberError}
          </p>
        )}
        <Button
          onClick={() => handleUpdateUserData()}
          disabled={
            disabledUpdateUserPhoneButton && disabledUpdateUserNameButton
          }
          className="mb-0.5"
        >
          {`${t('configuration.change')}`}
        </Button>
      </div>
    </EditCard>
  )
}
