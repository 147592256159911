import Radio from 'components/shared/radio/radio'
import { useTranslation } from 'react-i18next'
import { Select, ChangePlanButton, EditCard } from 'components'
import Image from 'next/image'
import IconStar from 'assets/imgs/icons/star.svg'
import { useStorage } from 'contexts/storage-context'

export type ControllerPosition = 'left' | 'right'
export type CardPosition = 'center-bottom' | 'center-top' | 'same'
export type DisclaimerThemes = 'default' | 'modern'

interface PositionProps {
  disclaimerPosition: ControllerPosition
  setDisclaimerPosition: (value: ControllerPosition) => void
  cardPosition: CardPosition
  setCardPosition: (value: CardPosition) => void
  cardStyle: DisclaimerThemes
  setCardStyle: (value: DisclaimerThemes) => void
  orgId: string
}

export function Position({
  disclaimerPosition,
  setDisclaimerPosition,
  cardPosition,
  setCardPosition,
  cardStyle,
  setCardStyle,
  orgId
}: PositionProps) {
  const { t } = useTranslation()
  const { activeOrganizationDetails } = useStorage()

  const adoptBlacklistEmails = ['pedro@goadopt.io', 'support@goadopt.io']

  const includesDeloitte =
    activeOrganizationDetails?.owner?.email?.includes('@deloitte.com')
  const includesBradescoSeguros =
    activeOrganizationDetails?.owner?.email?.includes('@bradescoseguros.com.br')
  const includesAdopt =
    activeOrganizationDetails?.owner?.email?.includes('@goadopt.io')
  const notIncludesBlacklistEmails = !adoptBlacklistEmails.includes(
    activeOrganizationDetails?.owner?.email as string
  )

  const isAlternativeThemeAble =
    (includesDeloitte || includesBradescoSeguros || includesAdopt) &&
    notIncludesBlacklistEmails

  const settings = [
    {
      name: t('disclaimer.style.left'),
      value: 'left',
      description: t('disclaimer.style.recommended')
    },
    {
      name: t('disclaimer.style.right'),
      value: 'right',
      description: ''
    }
  ]

  const settingsCard = [
    {
      name: t('disclaimer.style.Same as the controller'),
      value: 'same',
      description: ''
    },
    {
      name: t('disclaimer.style.Lower center'),
      value: 'center-bottom',
      description: ''
    },
    {
      name: t('disclaimer.style.Top center'),
      value: 'center-top',
      description: ''
    }
  ]
  const themes = [
    {
      name: t('disclaimer.style.Position.defaultTheme'),
      value: 'default'
    },
    {
      name: t('disclaimer.style.Position.modernTheme'),
      value: 'modern'
    }
  ]

  if (isAlternativeThemeAble) {
    themes.push({
      name: t('disclaimer.style.Position.alternativeTheme'),
      value: 'alternative'
    })
  }

  return (
    <div className="px-10">
      <EditCard className="mt-5">
        <div className="flex justify-between">
          <div>
            <div className="font-bold text-primary">{`${t(
              'disclaimer.style.Position.Controller Position'
            )}`}</div>
            <div className="text-gray-400">
              {`${t(
                'disclaimer.style.Position.Select which side your notice will appear'
              )}`}
            </div>
          </div>
        </div>
        <div className="flex mt-4">
          <Radio
            state={disclaimerPosition}
            setState={setDisclaimerPosition}
            settings={settings}
          />
        </div>
        <div className="mt-8">
          <div className="font-bold text-primary">{`${t(
            'disclaimer.style.Text.bannerPreview'
          )}`}</div>
          <div className="mt-4">
            <Radio
              state={cardPosition}
              setState={setCardPosition}
              settings={settingsCard}
            />
          </div>
        </div>
        <div className="mt-8">
          <div className="font-bold text-primary">{`${t(
            'disclaimer.style.Position.themeLabel'
          )}`}</div>
          <div className="flex items-end gap-8">
            <div className="relative">
              <Select
                containerClasses={'mt-4 w-[350px]'}
                params={themes}
                value={cardStyle}
                disabled={
                  !activeOrganizationDetails?.plan?.themes?.includes('modern')
                }
                onChange={e => {
                  setCardStyle(e.value as DisclaimerThemes)
                }}
              />
              {!activeOrganizationDetails?.plan?.themes?.includes('modern') && (
                <div className="absolute w-4 h-4 t top-2 -right-2">
                  <Image src={IconStar} alt="Business" />
                </div>
              )}
            </div>
            {!activeOrganizationDetails?.plan?.themes?.includes('modern') && (
              <ChangePlanButton orgId={orgId}>
                {t('disclaimer.style.Upgrade')}
              </ChangePlanButton>
            )}
          </div>
        </div>
      </EditCard>
    </div>
  )
}
