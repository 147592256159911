import React, { ReactNode, useState } from 'react'

interface TooltipProps {
  content: string
  position?: 'top' | 'right' | 'bottom' | 'left'
  children: ReactNode
}

export function CustomTooltip({
  content,
  position = 'top',
  children
}: TooltipProps) {
  const [isVisible, setIsVisible] = useState(false)

  const getPositionClasses = () => {
    switch (position) {
      case 'top':
        return 'bottom-full left-1/2 transform -translate-x-1/2 mb-4'
      case 'right':
        return 'left-full top-1/2 transform -translate-y-1/2 ml-4'
      case 'bottom':
        return 'top-full left-1/2 transform -translate-x-1/2 mt-4'
      case 'left':
        return 'right-full top-1/2 transform -translate-y-1/2 mr-4'
      default:
        return ''
    }
  }

  const getTriangle = () => {
    switch (position) {
      case 'top':
        return (
          <div className="absolute bottom-[-6px] left-1/2 transform -translate-x-1/2">
            <div className="w-0 h-0 border-l-[6px] border-l-transparent border-r-[6px] border-r-transparent border-t-[6px] border-t-white-200 dark:border-t-gray" />
          </div>
        )
      case 'right':
        return (
          <div className="absolute left-[-6px] top-1/2 transform -translate-y-1/2">
            <div className="w-0 h-0 border-t-[6px] border-t-transparent border-b-[6px] border-b-transparent border-r-[6px] border-r-white-200 dark:border-r-gray" />
          </div>
        )
      case 'bottom':
        return (
          <div className="absolute top-[-6px] left-1/2 transform -translate-x-1/2">
            <div className="w-0 h-0 border-l-[6px] border-l-transparent border-r-[6px] border-r-transparent border-b-[6px] border-b-white-200 dark:border-b-gray" />
          </div>
        )
      case 'left':
        return (
          <div className="absolute right-[-6px] top-1/2 transform -translate-y-1/2">
            <div className="w-0 h-0 border-t-[6px] border-t-transparent border-b-[6px] border-b-transparent border-l-[6px] border-l-white-200 dark:border-l-gray" />
          </div>
        )
      default:
        return null
    }
  }

  return (
    <div
      className="relative flex"
      onMouseEnter={() => setIsVisible(true)}
      onMouseLeave={() => setIsVisible(false)}
    >
      {children}
      {isVisible && (
        <div className={`absolute z-10 ${getPositionClasses()}`}>
          <div className="relative p-2 bg-white-200 dark:bg-gray dark:text-white text-sm rounded-md shadow-lg">
            {content}
            {getTriangle()}
          </div>
        </div>
      )}
    </div>
  )
}
