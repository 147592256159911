import { Button, ButtonType } from 'components/shared/buttons'
import ConfirmModal from 'components/shared/modal/confirm-modal'
import { useIdentityProvider } from 'hooks/useIdentityProvider'
import { useTranslation } from 'next-i18next'
import { useState } from 'react'
import { toast } from 'react-hot-toast'
import { BiTrash } from 'react-icons/bi'
import { IoIosWarning } from 'react-icons/io'

interface DeleteIdpButtonProps {
  identityProvider: string
}

export function DeleteIdpButton({ identityProvider }: DeleteIdpButtonProps) {
  const { t } = useTranslation()
  const [openConfirmModal, setOpenConfirmModal] = useState(false)
  const { deleteIdentityProvider } = useIdentityProvider()

  return (
    <>
      <Button
        buttonType={ButtonType.Ghost}
        className="w-8 h-8 bg-pink text-white"
        onClick={() => {
          setOpenConfirmModal(true)
        }}
      >
        <BiTrash />
      </Button>
      <ConfirmModal
        modalOpen={openConfirmModal}
        setModalOpen={setOpenConfirmModal}
        title={t('configuration.identityProvider.deleteTitle')}
        subtitle={t('configuration.identityProvider.deleteSubtitle')}
        buttonText={t('configuration.identityProvider.deleteButton')}
        cancelText={t('configuration.identityProvider.cancelButton')}
        icon={<IoIosWarning size={30} className="text-pink" />}
        buttonAction={async () => {
          toast.loading(t('common.loading'))
          await deleteIdentityProvider(identityProvider)
          toast.dismiss()
        }}
      />
    </>
  )
}
