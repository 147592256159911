import { useEffect } from 'react'
import TagManager from 'react-gtm-module'
import appConfig, { Environment } from 'config/app-config'
import Script from 'next/script'
import { useAuth } from 'contexts/auth-context'
import Head from 'next/head'
import { useTranslation } from 'next-i18next'

declare global {
  interface Window {
    clarity: any
  }
}

export function Installer() {
  const { user } = useAuth()
  const { t } = useTranslation()

  useEffect(() => {
    if (appConfig.environment === Environment.Prod) {
      TagManager.initialize({ gtmId: appConfig.thirdPartyServices.gtmId })
    }
  }, [])

  useEffect(() => {
    if (window && user?.id && appConfig.clarityId) {
      window?.clarity('set', 'userId', user?.id)
    }
  }, [user?.id, window, appConfig.clarityId])

  return (
    <>
      <Head>
        <title>{t('head.title')}</title>
        <meta name="description" content={t('head.description')} />
        <base href="/" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="shortcut icon" href="/static/favicon.ico" />
      </Head>
      {appConfig.environment === Environment.Prod && (
        <>
          <Script
            async
            src="https://www.googletagmanager.com/gtag/js?id=AW-396302292"
          ></Script>
          <Script>
            {`
              window.dataLayer = window.dataLayer || []
              function gtag() {
                dataLayer.push(arguments)
              }
              gtag('js', new Date())
              gtag('config', 'AW-396302292')
              `}
          </Script>
        </>
      )}

      {appConfig.clarityId && user?.id && (
        <Script id="ms-clarity" strategy="afterInteractive">
          {`
        (function(c,l,a,r,i,t,y){
            c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
            t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
            y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
          })(window, document, "clarity", "script", "${appConfig.clarityId}");
        `}
        </Script>
      )}

      {appConfig.thirdPartyServices.freshChatToken &&
        appConfig.environment === Environment.Prod && (
          <Script>
            {`
                function initFreshChat() {
                  window.fcWidget.init({
                    token: '${appConfig.thirdPartyServices.freshChatToken}',
                    host: 'https://wchat.freshchat.com',
                    config: {
                      cssNames: {
                        widget: 'fc_frame',
                        open: 'fc_open',
                        expanded: 'fc_expanded'
                      }
                    }              
                  })
                }
                function initialize(i, t) {
                  var e
                  i.getElementById(t)
                    ? initFreshChat()
                    : (((e = i.createElement('script')).id = t),
                      (e.async = !0),
                      (e.src = 'https://wchat.freshchat.com/js/widget.js'),
                      (e.onload = initFreshChat),
                      i.head.appendChild(e))
                }
                function initiateCall() {
                  initialize(document, 'freshchat-js-sdk')
                }
                initiateCall()`}
          </Script>
        )}
    </>
  )
}
