import { motion } from 'framer-motion'
import { SelectorIcon } from '@heroicons/react/solid'
import { classNames } from 'util/shared'
import { H3 } from 'components'
import { useTranslation } from 'next-i18next'
import { fadeIn } from 'public/animation/motion'
import { useState } from 'react'

interface SelectProps {
  label: string
  params: Array<{ name: string; value: string }>
  containerClasses?: string
  fieldError?: string
  selecteds: Array<{ name: string; value: string }>
  setSelecteds: (selected: Array<{ name: string; value: string }>) => void
  disabled?: boolean
}

export function MultipleSelect(props: SelectProps) {
  const {
    label,
    params,
    fieldError,
    selecteds,
    setSelecteds,
    containerClasses,
    disabled = false
  } = props
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)

  function handleOnChange(e: any) {
    const selectedItem = params.find(item => item.value === e.target.id)
    if (selectedItem) {
      if (!selecteds?.some(item => item.value === e.target.id)) {
        setSelecteds([...selecteds, selectedItem])
      } else {
        setSelecteds(
          selecteds?.filter((item: any) => item.value !== e.target.id)
        )
      }
    }
  }

  return (
    <div className={containerClasses}>
      <H3 fontWeight="bold">{label}</H3>
      <div className="mt-1 relative">
        <button
          onClick={() => {
            if (!disabled) setOpen(true)
          }}
          className="bg-white dark:bg-black dark:text-white relative w-full border border-gray-400 dark:border-gray-400 rounded-md shadow-sm pl-3 pr-10 py-2 h-11 text-left cursor-pointer focus:outline-none "
        >
          {selecteds?.length > 0 ? (
            <span className="block truncate">
              ({selecteds.length}) -{' '}
              {selecteds.map(item => item.name).join(', ')}
            </span>
          ) : (
            <span>{t('common.placeholders.multipleSelect')}</span>
          )}
          <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <SelectorIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </span>
        </button>
        <div
          className={classNames(
            'fixed h-screen w-screen top-0 left-0 z-10',
            open ? 'block' : 'hidden'
          )}
          onClick={() => {
            setOpen(false)
          }}
        ></div>
        <ul
          className={classNames(
            ' absolute z-10 mt-1 w-full bg-white-200 dark:bg-black shadow-lg scroll-1 max-h-60 rounded-md py-1  ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm border border-gray-400 cursor-pointer',
            open ? 'block' : 'hidden'
          )}
        >
          {params?.map((param, index) => (
            <li
              key={index}
              id={param.value}
              onClick={handleOnChange}
              className={classNames(
                'cursor-pointer select-none relative py-2 pl-3 pr-9 ',
                selecteds?.some(selected => selected.value === param.value)
                  ? 'bg-primary text-gray! dark:text-gray! '
                  : selecteds?.find(item => item.value === param.value)
                  ? 'dark:bg-black-200 bg-white-200 dark:text-white! text-gray! dark:hover:text-white'
                  : 'dark:text-white text-gray'
              )}
            >
              {param.name}
            </li>
          ))}
        </ul>
      </div>
      {fieldError && fieldError?.length > 0 && (
        <motion.div initial="initial" animate="animate" variants={fadeIn()}>
          <p className="text-sm pl-1 dark:text-white">{`* ${fieldError}`}</p>
        </motion.div>
      )}
    </div>
  )
}
