import { useTranslation } from 'react-i18next'
import { ModalWrapper } from 'components/shared/modal/modal-wrapper'
import { RiShieldCheckLine } from 'react-icons/ri'

type RequestTypesModalProps = {
  modalOpen: boolean
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export function RequestTypesModal({
  modalOpen,
  setModalOpen
}: RequestTypesModalProps) {
  const { t } = useTranslation()

  const texts = [
    {
      title: t('modal.requestTypes.sections.title1'),
      content: t('modal.requestTypes.sections.content1')
    },
    {
      title: t('modal.requestTypes.sections.title2'),
      content: t('modal.requestTypes.sections.content2')
    },
    {
      title: t('modal.requestTypes.sections.title3'),
      content: t('modal.requestTypes.sections.content3')
    },
    {
      title: t('modal.requestTypes.sections.title4'),
      content: t('modal.requestTypes.sections.content4')
    },
    {
      title: t('modal.requestTypes.sections.title5'),
      content: t('modal.requestTypes.sections.content5')
    },
    {
      title: t('modal.requestTypes.sections.title6'),
      content: t('modal.requestTypes.sections.content6')
    },
    {
      title: t('modal.requestTypes.sections.title7'),
      content: t('modal.requestTypes.sections.content7')
    },
    {
      title: t('modal.requestTypes.sections.title8'),
      content: t('modal.requestTypes.sections.content8')
    },
    {
      title: t('modal.requestTypes.sections.title9'),
      content: t('modal.requestTypes.sections.content9')
    },
    {
      title: t('modal.requestTypes.sections.title10'),
      content: t('modal.requestTypes.sections.content10')
    },
    {
      title: t('modal.requestTypes.sections.title11'),
      content: t('modal.requestTypes.sections.content11')
    }
  ]

  return (
    <ModalWrapper
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      headerProps={{
        icon: <RiShieldCheckLine className="text-primary" size={22} />,
        title: t('modal.requestTypes.title'),
        subtitle: t('modal.requestTypes.subTitle')
      }}
      modalSize="medium"
    >
      <div className="overflow-y-scroll scroll-1 p-9 border max-h-[70vh] border-gray-400 rounded-lg">
        <p className="text-gray-250 text-[16px] leading-6">
          {t('modal.requestTypes.mainContent')}
        </p>

        {texts.map(({ title, content }, index) => (
          <>
            <p className="mt-6 mb-3 dark:text-white text-lg leading-6">
              {index + 1}. {title}
            </p>
            <p className="text-gray-250 text-[16px] leading-6">{content}</p>
          </>
        ))}
      </div>
    </ModalWrapper>
  )
}
