import { useAuth } from 'contexts/auth-context'
import { useCallback, useEffect, useState } from 'react'

interface Segment {
  identify: (
    userId: string,
    params: Partial<{ name: string; email: string; LAST_PLAN_IN_CART: string }>
  ) => void
  track: (
    eventName: SegmentEvent,
    params?: Partial<{ path: string; plan: any }>
  ) => void
  page: (
    pageName: string,
    params: Partial<{ path: string; reference?: string | number }>
  ) => void
}
declare global {
  interface Window {
    analytics: Segment
  }
}

export enum SegmentEvent {
  SubscriptionAccess = 'subscription_access',
  FlashOnboarding = 'flash_onboarding'
}

export const useSegmentControl = () => {
  const [identified, setIdentified] = useState<boolean>(false)
  const { user } = useAuth()

  const sendIdentify = useCallback(
    async (payload?: { LAST_PLAN_IN_CART: string }) => {
      if (user) {
        window?.analytics?.identify(user.id!, {
          name: `${user.name}`,
          email: user.email,
          ...payload
        })
        setIdentified(true)
      }
    },
    [user, identified]
  )

  const sendEvent = useCallback(
    (eventName: SegmentEvent, payload: any) => {
      sendIdentify()
      window?.analytics?.track(eventName, payload)
    },
    [sendIdentify]
  )

  return { sendEvent, sendIdentify }
}

export const SegmentControl = () => {
  const { sendIdentify } = useSegmentControl()
  const { loading } = useAuth()

  useEffect(() => {
    if (!loading) {
      sendIdentify()
    }
  }, [loading])

  return null
}
