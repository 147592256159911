import React, { useEffect, useMemo, useState } from 'react'
import {
  Button,
  Header,
  OrganizationSectionTitle,
  PageContent,
  Dropdown,
  AddDisclaimerToDocument,
  SearchWithoutButton
} from 'components'
import OrganizationHeader from 'components/shared/header/organization-header'
import PageSection from 'components/shared/page-section/page-section'
import { useTranslation } from 'react-i18next'
import { useRouter } from 'next/router'
import { StorageProvider, useStorage } from 'contexts/storage-context'
import { Guards } from 'util/enums'
import { FiSettings } from 'react-icons/fi'
import moment from 'moment'
import { useDocuments } from 'hooks/useDocuments'
import ConfirmModal from 'components/shared/modal/confirm-modal'
import { IoIosWarning } from 'react-icons/io'
import toast from 'react-hot-toast'
import { useHandleError } from 'hooks/useHandleError'
import {
  DisclaimersQuantity,
  ListDocumentProps,
  ReturnListDocumentProps
} from 'models/documents'
import appConfig from 'config/app-config'
import { TbExternalLink } from 'react-icons/tb'
import { Column, Table } from 'components/shared/table'
import { Flag } from 'components/shared/flag'
import { Badge } from 'components/shared/badge'
import {
  PillButton,
  PillButtonType
} from 'components/shared/buttons/pill-button'
import Image from 'next/image'
import FeedbackButton from 'components/shared/feedbackBadge'

interface Document {
  name: string
  type: React.ReactNode
  languages: React.ReactNode
  notices: React.ReactNode
  createdAt: string
  updatedAt: string
  actions: React.ReactNode
}

export const documentTypes: { [key: string]: string } = {
  cookies: 'document_cookies',
  privacy: 'document_privacies',
  terms: 'document_terms'
}

export const documentTextTypes: { [key: string]: string } = {
  cookies: 'document_cookies_texts',
  privacy: 'document_privacy_texts',
  terms: 'document_terms_texts'
}

export default function OrganizationDocuments() {
  const { activeOrganizationDetails } = useStorage()
  const router = useRouter()
  const { t } = useTranslation()
  const { organizationPathname } = router.query
  const { handleError } = useHandleError()
  const {
    listDocuments,
    listDocumentsLoading,
    refetchListDocuments,
    deleteDocument,
    getDisclaimerQuantity
  } = useDocuments({})

  const documentCategories: { name: string; value: string }[] = [
    {
      name: t('org.documents.table.all'),
      value: ''
    },
    {
      name: t('org.documents.table.privacy'),
      value: 'privacy'
    },
    {
      name: t('org.documents.table.cookies'),
      value: 'cookies'
    },
    {
      name: t('org.documents.table.terms'),
      value: 'terms'
    }
  ]

  const generateDocumentButtons = [
    {
      label: t('org.documents.generatePrivacyButton'),
      onClick: () =>
        router.push(`/org/${organizationPathname}/documents/privacy`)
    },
    {
      label: t('org.documents.generateCookieButton'),
      onClick: () =>
        router.push(`/org/${organizationPathname}/documents/cookies`)
    },
    {
      label: t('org.documents.generateTermButton'),
      onClick: () => router.push(`/org/${organizationPathname}/documents/terms`)
    }
  ]

  const columns: Column<Document>[] = [
    { key: 'name', label: t('org.documents.table.name'), sortable: true },
    {
      key: 'type',
      label: t('org.documents.table.document'),
      align: 'center'
    },
    {
      key: 'languages',
      label: t('org.documents.table.languages'),
      align: 'center'
    },
    {
      key: 'notices',
      label: t('org.documents.table.disclaimers'),
      align: 'center'
    },
    {
      key: 'createdAt',
      label: t('org.documents.table.createdDate'),
      align: 'center',
      sortable: true
    },
    {
      key: 'updatedAt',
      label: t('org.documents.table.updatedDate'),
      align: 'center',
      sortable: true
    },
    { key: 'actions', label: t('org.documents.table.actions'), align: 'right' }
  ]

  const [filterInput, setFilterInput] = useState('')
  const [listDocumentData, setListDocumentData] = useState<
    ReturnListDocumentProps[]
  >([])
  const [loadingTable, setLoadingTable] = useState(true)
  const [openConfirmModal, setOpenConfirmModal] = useState(false)
  const [openDisclaimerToDocumentModal, setOpenDisclaimerToDocumentModal] =
    useState(false)
  const [deleteDocumentId, setDeleteDocumentId] = useState('')
  const [documentMasterId, setDocumentMasterId] = useState('')
  const [activeFilter, setActiveFilter] = useState(documentCategories[0])

  async function handleAssociateDocument(id: string) {
    setDocumentMasterId(id)
    setOpenDisclaimerToDocumentModal(true)
  }

  async function handleEditDocument(document: ListDocumentProps) {
    const { document_type, id } = document
    router.push(
      `/org/${organizationPathname}/documents/${document_type}/?documentId=${id}`
    )
  }

  async function handleDeleteDocument() {
    toast.loading(t('org.documents.deleteDocument'))
    const result = await deleteDocument(deleteDocumentId)
    toast.dismiss()

    if (result?.success) {
      toast.success(t('org.documents.deleteDocumentSuccess'))
      refetchListDocuments()
    } else {
      handleError(result?.message)
    }

    setOpenConfirmModal(false)
    setDeleteDocumentId('')
  }

  function handleOpenModalConfirm(id: string) {
    setOpenConfirmModal(true)
    setDeleteDocumentId(id)
  }

  async function formatDocumentList() {
    let associatedDisclaimers: DisclaimersQuantity[]

    const result = await getDisclaimerQuantity()

    if (!result) return

    if (result.success) {
      associatedDisclaimers = result?.data
    } else {
      handleError(result?.message)
    }

    const formattedData = listDocuments?.document_master?.map(document => {
      return {
        id: document.id,
        created_at: document.created_at,
        updated_at:
          document[documentTypes[document.document_type]]?.[0]?.updated_at,
        document_type: document.document_type,
        document_languages: document[
          documentTypes[document.document_type]
        ]?.[0]?.[documentTextTypes[document.document_type]]?.map(
          (item: any) => item.language
        ),
        document_name:
          document[documentTypes[document.document_type]]?.[0]?.document_name,
        associated_disclaimers:
          associatedDisclaimers?.find(
            ({ document_master_id }) => document_master_id === document.id
          )?.disclaimer_quantity ?? 0
      }
    })

    setListDocumentData(formattedData ?? [])
    setLoadingTable(false)
  }

  useEffect(() => {
    if (!listDocumentsLoading) {
      formatDocumentList()
    }
  }, [listDocuments, listDocumentsLoading])

  const list: ReturnListDocumentProps[] = useMemo(() => {
    return listDocumentData.filter(document => {
      const typeMatches =
        !activeFilter.value || document.document_type === activeFilter.value
      const nameMatches =
        !filterInput ||
        document.document_name.toLowerCase().includes(filterInput.toLowerCase())

      return typeMatches && nameMatches
    })
  }, [listDocumentData, filterInput, activeFilter])

  function Actions({ document }: { document: ReturnListDocumentProps }) {
    return (
      <div className="flex gap-1 justify-end">
        <a
          target="_blank"
          rel="noreferrer"
          href={`${appConfig.hubUrl}/document/${document.id}`}
          className="flex justify-center items-center w-8 h-8 bg-white-300 dark:bg-gray-400 rounded text-black dark:text-white"
        >
          <TbExternalLink size={16} />
        </a>
        <div className="flex justify-center ">
          <Dropdown
            position="bottomRight"
            items={[
              {
                label: t('org.documents.actions.associateDocument'),
                onClick: () => handleAssociateDocument(document.id)
              },
              {
                label: t('org.documents.actions.editDocument'),
                onClick: () => handleEditDocument(document)
              },
              {
                label: t('org.documents.actions.deleteDocument'),
                onClick: () => handleOpenModalConfirm(document.id)
              }
            ]}
          >
            <div className="text-black flex justify-center items-center w-8 h-8 bg-primary rounded ">
              <FiSettings size={18} />
            </div>
          </Dropdown>
        </div>
      </div>
    )
  }

  const formattedDocuments = useMemo(() => {
    return list.map(doc => ({
      name: doc.document_name,
      type: (
        <Badge purpose={'documentTypes'} type={doc?.document_type}>
          {t(`org.documents.table.${doc?.document_type}`)}
        </Badge>
      ),
      languages: (
        <div className="flex gap-1  justify-center flex-wrap">
          {doc.document_languages?.slice(0, 3).map(language => (
            <Flag key={language} type={language}></Flag>
          ))}
          {doc.document_languages?.length > 3 && (
            <div className="w-5 h-5 dark:bg-gray-500 text-xs rounded-md flex items-center justify-center">
              +{doc.document_languages?.length - 3}
            </div>
          )}
        </div>
      ),
      notices: (
        <span className="text-primary font-bold">
          {doc?.associated_disclaimers}
        </span>
      ),

      createdAt: moment(doc.created_at).utc().format('DD/MM/YYYY'),
      updatedAt: moment(doc.updated_at).utc().format('DD/MM/YYYY'),
      actions: <Actions document={doc} />
    }))
  }, [list])

  return (
    <PageContent
      header={
        <Header
          breadcrumb={[
            {
              name: activeOrganizationDetails?.name,
              path: `/org/${activeOrganizationDetails?.pathname}/disclaimers`
            },
            { name: t('org.documents.pageName') }
          ]}
        />
      }
    >
      <OrganizationHeader />
      <PageSection type="organization" routeSelected="documents" />
      <OrganizationSectionTitle title={t('org.documents.title')} />
      <div className="px-10 flex w-full mb-5">
        <div className="flex flex-wrap  gap-3">
          <SearchWithoutButton
            disabled={loadingTable}
            placeholder={t('org.documents.searchDocumentsPlaceholder')}
            onInputChange={e => setFilterInput(e)}
          />
          <div className="flex gap-3 items-center">
            {documentCategories?.map((cat, index: number) => {
              return (
                <PillButton
                  disabled={formattedDocuments.length === 0 && !loadingTable}
                  buttonType={
                    activeFilter.value === cat.value
                      ? PillButtonType.Selected
                      : PillButtonType.Default
                  }
                  key={index}
                  onClick={() => setActiveFilter(cat)}
                >
                  {cat.name}
                </PillButton>
              )
            })}
          </div>
        </div>
        <div className="ml-auto">
          <Dropdown position="left" items={generateDocumentButtons}>
            <Button>{t('org.documents.generateDocument')}</Button>
          </Dropdown>
        </div>
      </div>
      <div className="px-10 w-full mb-10">
        <Table
          loading={loadingTable}
          columns={columns}
          data={formattedDocuments}
          withoutElements={
            <div className="flex flex-col gap-4 items-center justify-center py-10">
              <Image
                src="/icons/empty-folder.svg"
                width={80}
                height={60}
                className="text-primary fill-[red] stroke-[red]"
              ></Image>
              <div>{t('org.documents.withoutDocument')}</div>
              <Dropdown position="left" items={generateDocumentButtons}>
                <Button>{t('org.documents.generateDocument')}</Button>
              </Dropdown>
            </div>
          }
        />
      </div>
      {openDisclaimerToDocumentModal && (
        <AddDisclaimerToDocument
          open={openDisclaimerToDocumentModal}
          handleClose={() => setOpenDisclaimerToDocumentModal(false)}
          documentMasterId={documentMasterId}
          refetch={formatDocumentList}
        />
      )}
      {openConfirmModal && (
        <ConfirmModal
          modalOpen={openConfirmModal}
          setModalOpen={setOpenConfirmModal}
          title={t('modal.confirmationDeleteDocuments.title')}
          subtitle={t('modal.confirmationDeleteDocuments.subtitle')}
          buttonText={t('modal.confirmationDeleteDocuments.delete')}
          cancelText={t('modal.confirmationDeleteDocuments.cancel')}
          icon={<IoIosWarning size={30} className="text-pink" />}
          buttonAction={handleDeleteDocument}
        />
      )}
      <FeedbackButton featureId="documents" />
    </PageContent>
  )
}

OrganizationDocuments.storageProvider = StorageProvider
OrganizationDocuments.guard = Guards.OnlyAuthorized
