import React, { useEffect, useState } from 'react'
import { BiCookie } from 'react-icons/bi'
import { useTranslation } from 'react-i18next'
import { BodyText, Button, H2, InfoTooltip, TooltipPosition } from 'components'
import { classNames, convertMsToHM } from 'util/shared'
import { RiEmotionSadLine } from 'react-icons/ri'
import i18next from 'i18next'
import { useLazyQuery } from '@apollo/client'
import { GET_TAGS_WITH_DETAILS } from 'queries/tags'
import { AiOutlineLoading } from 'react-icons/ai'
import { AutoBlockSourcesModal } from './auto-block-sources-modal'
import { ModalWrapper } from './modal-wrapper'

interface CookiesModalProps {
  modalOpen: boolean
  setModalOpen: (open: boolean) => void
  selectedTag?: Tag
  disclaimerId?: string
}

export interface Tag {
  displayName: string
  category: string
  id: string
  cookies?: Array<Cookie>
  local_storages?: Array<LocalStorage>
}
interface Cookie {
  name: string
  blocked?: string
  region?: string
  description?: string
  expiration_milliseconds?: number
  source?: string
  cookie_list?: { cookie_contents: Array<{ description: string }> }
}
interface LocalStorage {
  name: string
  blocked?: string
  region?: string
  description?: string
  local_storage_list?: {
    local_storage_contents: Array<{ description: string }>
  }
}

export function CookiesModal({
  modalOpen,
  setModalOpen,
  selectedTag,
  disclaimerId
}: CookiesModalProps) {
  const { t } = useTranslation()
  const [tab, setTab] = useState(0)
  const [list, setList] = useState<Cookie[]>()
  const [tagData, setTagData] = useState<Tag>()
  const [loading, setLoading] = useState(false)
  const [openSourcesConfigs, setOpenSourcesConfig] = useState(false)

  const [fetchTag] = useLazyQuery(GET_TAGS_WITH_DETAILS, {
    fetchPolicy: 'network-only',
    variables: {
      id: selectedTag?.id,
      language: i18next.language ?? 'en'
    },
    onCompleted: data => {
      const tagData = data?.tags?.[0]
      tagData.cookies.map((cookie: Cookie) => {
        cookie.description =
          cookie?.cookie_list?.cookie_contents?.[0]?.description
        return cookie
      })
      tagData.local_storages.map((localStorage: LocalStorage) => {
        localStorage.description =
          localStorage?.local_storage_list?.local_storage_contents?.[0]?.description

        return localStorage
      })

      if (tagData.cookies.length === 0 && tagData.local_storages.length) {
        setTab(1)
        setList(data?.tags?.[0]?.local_storages)
      } else {
        setTab(0)
        setList(data?.tags?.[0]?.cookies)
      }

      setTagData(tagData as Tag)
      setLoading(false)
    }
  })

  useEffect(() => {
    if (modalOpen) {
      setLoading(true)
      setList([])
      fetchTag()
    }
  }, [modalOpen])

  return (
    <ModalWrapper
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      headerProps={{
        icon: <BiCookie className="text-primary" size={35} />,
        title: t('cookiesModal.title', {
          tagName: selectedTag?.displayName
        }),
        subtitle: t('cookiesModal.subtitle')
      }}
      modalSize="medium"
    >
      {loading ? (
        <div className="dark:text-white h-[45vh] flex items-center justify-center">
          <div className="flex items-center justify-center w-5 h-5 mr-3 animate-spin text-primary">
            <AiOutlineLoading size={25} />
          </div>
          <div>{t('org.subscribe.loading')}</div>
        </div>
      ) : (
        <>
          <div className="pr-4">
            <div className="flex font-bold border-b border-gray-200 ">
              <div
                onClick={() => {
                  setList(tagData?.cookies ?? [])
                  setTab(0)
                }}
                className={classNames(
                  tab === 0
                    ? 'text-primary border-b-2 border-primary'
                    : 'text-black dark:text-white',
                  'px-4 cursor-pointer'
                )}
              >
                Cookies
              </div>
              <div
                onClick={() => {
                  setList(tagData?.local_storages ?? [])
                  setTab(1)
                }}
                className={classNames(
                  tab === 1
                    ? 'text-primary border-b-2 border-primary'
                    : 'text-black dark:text-white',
                  'px-4 cursor-pointer'
                )}
              >
                Local Storage
              </div>
            </div>
          </div>
          {list?.length === 0 ? (
            <div className="text-center h-[45vh] flex items-center justify-center flex-col">
              <RiEmotionSadLine
                className="mx-auto mb-2 text-gray-400"
                size={40}
              />
              <H2>{t('cookiesModal.noCookiesFound')}</H2>
            </div>
          ) : (
            <div className="overflow-y-scroll scroll-1 pr-4 h-[45vh]">
              {list?.map(item => {
                return (
                  <div className="py-4 break-words border-b border-gray-200 ">
                    <H2 fontWeight={'bold'} className="mb-2">
                      {item.name}
                    </H2>
                    <div className="flex flex-wrap justify-between">
                      {item.expiration_milliseconds && (
                        <div className="flex-[3]">
                          <BodyText
                            className="text-gray-400 dark:text-gray-400"
                            fontWeight="bold"
                          >
                            {t('cookiesModal.expiration')}
                          </BodyText>
                          <BodyText className="">
                            {(() => {
                              if (!item.expiration_milliseconds)
                                return t(`common.unknown`)
                              const { number, type } = convertMsToHM(
                                item.expiration_milliseconds
                              )

                              if (item.expiration_milliseconds === -1) {
                                return t('cookiesModal.sessionCookie')
                              }

                              return `${number} ${t(
                                `common.timeMeasure.${type}`
                              )}`
                            })()}
                          </BodyText>
                        </div>
                      )}
                      <div className="flex-[2]">
                        <div className="flex items-center">
                          <BodyText
                            className="text-gray-400 dark:text-gray-400"
                            fontWeight="bold"
                          >
                            {t('cookiesModal.region')}
                          </BodyText>
                          <InfoTooltip
                            position={
                              tab === 0
                                ? TooltipPosition.Center
                                : TooltipPosition.LeftBottom
                            }
                            className={'ml-2'}
                            description={t(
                              'tooltip.helperText.cookieBannerRegion'
                            )}
                            containerClassName={'h-[120px]'}
                          />
                        </div>
                        <BodyText className="">
                          {item.region ?? t(`common.unknown`)}
                        </BodyText>
                      </div>
                      <div className="flex-[2]">
                        <div className="flex items-center">
                          <BodyText
                            className="text-gray-400 dark:text-gray-400"
                            fontWeight="bold"
                          >
                            {t('cookiesModal.blocked')}
                          </BodyText>
                          <InfoTooltip
                            position={TooltipPosition.RightBottom}
                            className={'ml-2'}
                            description={t(
                              'tooltip.helperText.cookieBannerBlocked'
                            )}
                            documentation={t('links.documentation.autoBlock')}
                            containerClassName={'h-[120px]'}
                          />
                        </div>

                        <BodyText
                          className={`${
                            selectedTag?.category === 'required' || item.blocked
                              ? 'bg-primary'
                              : 'bg-pink'
                          } inline-block dark:text-black text-black rounded-sm px-2 font-bold`}
                        >
                          {t(`cookiesModal.${item.blocked}`)}
                        </BodyText>
                      </div>
                    </div>
                    {item.source && (
                      <div className="mt-1">
                        <BodyText
                          className="text-gray-400 dark:text-gray-400"
                          fontWeight="bold"
                        >
                          {t('cookiesModal.source')}
                        </BodyText>
                        <BodyText className="">
                          {item.source ?? t(`common.unknown`)}
                        </BodyText>
                      </div>
                    )}
                    <div className="mt-1">
                      <BodyText
                        className="text-gray-400 dark:text-gray-400"
                        fontWeight="bold"
                      >
                        {t('cookiesModal.description')}
                      </BodyText>
                      <BodyText className="">
                        {item?.description ??
                          t(`cookiesModal.withoutDescription`)}
                      </BodyText>
                    </div>
                  </div>
                )
              })}
            </div>
          )}

          {selectedTag?.category !== 'required' && disclaimerId && (
            <div className="flex justify-end pt-4">
              <Button onClick={() => setOpenSourcesConfig(true)}>
                {t('cookiesModal.openSources')}
              </Button>
            </div>
          )}

          {openSourcesConfigs && (
            <AutoBlockSourcesModal
              disclaimerId={disclaimerId}
              data={selectedTag}
              modalOpen={openSourcesConfigs}
              setModalOpen={setOpenSourcesConfig}
              setCookieModalOpen={setModalOpen}
            />
          )}
        </>
      )}
    </ModalWrapper>
  )
}
