import React, { useEffect, useState } from 'react'
import { classNames } from 'util/shared'

interface RadioProps {
  settings: Array<{
    name: string
    value: string | boolean
    description?: string
  }>
  state: string | boolean
  setState: (value: any) => void
}

export default function Radio({ settings, state, setState }: RadioProps) {
  const [selected, setSelected] = useState(
    settings.filter(element => element.value === state)[0] ?? settings[0]
  )
  useEffect(() => {
    setSelected(
      settings.filter(element => element.value === state)[0] ?? settings[0]
    )
  }, [state])
  return (
    <div className="flex items-center">
      {settings?.map((item, index) => {
        return (
          <div
            key={index}
            onClick={() => {
              setSelected(item)
              setState(item.value)
            }}
            className="flex items-center mr-4 cursor-pointer dark:text-white"
          >
            <div
              className={classNames(
                selected.value === item.value
                  ? 'border-primary'
                  : 'border-gray-200 dark:border-white',
                'w-4 h-4 border-2  rounded-full mr-2 flex items-center justify-center'
              )}
            >
              <div
                className={classNames(
                  'bg-primary  w-2 h-2 rounded-full duration-200 relative transform',
                  selected.value === item.value ? 'scale-100' : 'scale-0'
                )}
              ></div>
            </div>
            <div>{item.name}</div>
          </div>
        )
      })}
    </div>
  )
}
