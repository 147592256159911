import { HiOutlineTemplate } from 'react-icons/hi'
import { ModalWrapper } from './modal-wrapper'
import { useTranslation } from 'next-i18next'
import { useRef, useState } from 'react'
import { SpinLoading } from '../loading/spin-loading'
import { useDocumentQuestions } from 'contexts/document-questions-context'

interface IframeModalProps {
  src: string
  documentType: 'privacy' | 'cookies' | 'terms'
}

export function IframeModal({ src, documentType }: IframeModalProps) {
  const iframeRef = useRef<HTMLIFrameElement>(null)
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(true)

  const {
    styleFields,
    getTransformedStyleFields,
    iframeModalOpen,
    setIframeModalOpen
  } = useDocumentQuestions()

  async function handleLoad() {
    if (iframeRef.current && iframeRef.current.contentWindow) {
      const transformedStyleFields = await getTransformedStyleFields(styleFields)

      iframeRef.current.contentWindow.postMessage(
        {
          type: 'config',
          message: { style: transformedStyleFields, documentType }
        },
        'https://alpha-hub.goadopt.io'
      )
    }
    setIsLoading(false)
  }

  return (
    <ModalWrapper
      modalOpen={iframeModalOpen}
      setModalOpen={setIframeModalOpen}
      headerProps={{
        icon: <HiOutlineTemplate className="text-primary" size={30} />,
        title: t('modal.iframeModal.title'),
        subtitle: t('modal.iframeModal.subTitle')
      }}
      modalSize={styleFields.device === 'mobile' ? 'small' : 'large'}
    >
      <div className="h-[70vh] rounded-md border border-gray-100 dark:border-gray-400 p-2 flex justify-center">
        {isLoading && (
          <div className="h-full w-full flex justify-center items-center">
            <SpinLoading />
          </div>
        )}
        <iframe
          ref={iframeRef}
          onLoad={handleLoad}
          src={src}
          width="100%"
          height="100%"
        ></iframe>
      </div>
    </ModalWrapper>
  )
}
