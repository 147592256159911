const IconCookie = (props: any) => (
  <svg
    fill={props.fill}
    className="select-none"
    version="1.1"
    id="Camada_1"
    x="0px"
    y="0px"
    viewBox="0 0 64.5 67"
  >
    <style type="text/css"></style>
    <g>
      <path
        className="st0"
        d="M28.6,25.1c0-1.3-1.1-2.4-2.4-2.4c-1.2,0-2.3,1.2-2.3,2.4c0,1.3,1.1,2.4,2.3,2.4
		C27.5,27.5,28.6,26.4,28.6,25.1z"
      />
      <path
        className="st0"
        d="M28.6,41.5c-1.3,0-2.3,0.9-2.3,2.3c0,1.4,1,2.4,2.3,2.4c1.2,0,2.3-1,2.3-2.3C30.9,42.5,29.9,41.5,28.6,41.5z"
      />
      <path
        className="st0"
        d="M21.6,34.1c-1.2,0-2.3,1.1-2.3,2.3c0,1.4,0.9,2.4,2.4,2.4c1.2,0,2.2-1,2.3-2.2C24,35.2,23,34.1,21.6,34.1z"
      />
      <path
        className="st0"
        d="M35.6,27.7c-1.2,0-2.4,1.1-2.3,2.4c0,1.3,1.1,2.4,2.4,2.3c1.3,0,2.3-1.1,2.3-2.4
		C37.9,28.8,36.8,27.7,35.6,27.7z"
      />
      <path
        className="st0"
        d="M42.6,38.4c-1.3,0-2.4,1-2.4,2.3c0,1.2,1.2,2.5,2.3,2.4c1.3,0,2.3-1.1,2.3-2.4C44.9,39.5,43.9,38.4,42.6,38.4z
		"
      />
      <path
        className="st0"
        d="M32.3,0C14.8,0,0.7,15,0.7,33.5S14.8,67,32.3,67s31.6-15,31.6-33.5S49.7,0,32.3,0z M31.7,53.6
		C20.2,53.3,12,43.2,12.8,32.1C13.7,19.9,24,11.7,35.6,13.6c-0.1,1.7,0.2,3.4,1.3,4.8c0.7,0.9,1.6,1.6,2.5,2.3
		c0.4,0.3,0.7,0.5,0.7,1.1c0,4.2,2.4,7.1,6.4,7.9c0.2,0,0.6,0.2,0.7,0.5c0.7,2.4,2.3,3.9,4.6,4.7C51.4,44.5,43.2,54,31.7,53.6z"
      />
    </g>
  </svg>
)
export default IconCookie
