import { motion } from 'framer-motion'
import React, { useState, Fragment, useEffect } from 'react'
import { IoIosWarning } from 'react-icons/io'
import { fadeIn } from 'public/animation/motion'
import { classNames } from 'util/shared'
import { H3 } from 'components'
import { Listbox, Transition } from '@headlessui/react'

export function UrlInput(props: any) {
  const {
    id,
    name,
    value,
    validate,
    placeholder,
    maxLength,
    label = '',
    tooltip,
    type = 'text',
    events = {},
    classes = '',
    contClass,
    fieldError,
    disabled,
    eye = false,
    protocol
  } = props

  const [typeAux] = useState(type)
  const { onChange, onChangeProtocol, ...restEvents } = events

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (typeof onChange === 'function') {
      onChange({
        value: event.target.value
      })
    }
  }

  const fieldProps = {
    ...restEvents,
    id,
    name,
    value,
    validate,
    maxLength,
    placeholder,
    disabled,
    onChange: handleChange
  }
  const params = ['https://', 'http://']

  const [selected, setSelected] = useState(protocol)

  useEffect(() => {
    onChangeProtocol?.(selected)
  }, [selected])

  return (
    <div className={contClass}>
      <div className="flex items-center">
        <H3 fontWeight="bold">{label}</H3>
        {tooltip}
      </div>

      <div
        className={classNames(
          classes,
          'flex items-center dark:text-white w-full box-border rounded-md bg-white dark:bg-black border ',
          fieldError.length > 0 ? 'border-pink' : 'border-gray-400'
        )}
      >
        <Listbox disabled={disabled} value={selected} onChange={setSelected}>
          <div className="py-2 rounded-l-md relative bg-white-200 dark:bg-gray">
            <Listbox.Button
              className={classNames(
                'w-20  dark:text-white relative px-2 cursor-pointer focus:outline-none '
              )}
            >
              <span className="block truncate">{selected}</span>
            </Listbox.Button>

            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 w-32 bg-white-200 dark:bg-black shadow-lg scroll-1 max-h-60 rounded-md py-1  ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm border border-gray-400 cursor-pointer">
                {params?.map(item => (
                  <Listbox.Option
                    key={item}
                    className={({ active }) =>
                      classNames(
                        'cursor-pointer select-none relative py-2 pl-3 pr-3 ',
                        selected === item
                          ? 'bg-primary text-gray! dark:text-gray! '
                          : active
                          ? 'dark:bg-black-200 bg-white-200 dark:text-white! text-gray! dark:hover:text-white'
                          : 'dark:text-white text-gray'
                      )
                    }
                    value={item}
                  >
                    {({ selected }) => (
                      <div
                        className={classNames(
                          'inset-y-0 right-0 flex items-center justify-between'
                        )}
                      >
                        <span
                          className={classNames(
                            selected ? 'font-semibold' : 'font-normal',
                            'block truncate '
                          )}
                        >
                          {item}
                        </span>
                      </div>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </Listbox>
        <div className="flex-1 relative flex items-center">
          <input
            className="appearance-none rounded-r-md w-full outline-none px-3 py-2 dark:autocompleteInput bg-white dark:bg-black"
            type={typeAux}
            {...fieldProps}
          />
          {fieldError.length > 0 ? (
            <motion.div initial="initial" animate="animate" variants={fadeIn()}>
              <IoIosWarning
                className={classNames(
                  'absolute top-1/2 transform -translate-y-1/2 text-pink',
                  eye === true ? 'right-14' : 'right-5'
                )}
                size={20}
              />
            </motion.div>
          ) : null}
        </div>
      </div>
      {fieldError.length > 0 ? (
        <motion.div initial="initial" animate="animate" variants={fadeIn()}>
          <p className="text-sm pl-1 dark:text-white">{`* ${fieldError}`}</p>
        </motion.div>
      ) : null}
    </div>
  )
}
