import React, { useState } from 'react'
import { BiTrash } from 'react-icons/bi'
import { useTranslation } from 'react-i18next'
import { BodyText, H2, H3, UpgradeModal, Button, ButtonType } from 'components'
import { MdEdit } from 'react-icons/md'
import IconStarPurple from 'assets/imgs/icons/star-purple.svg'
import ScanAuthEventModal, {
  EditableEventData,
  EventData
} from 'components/modal/scan-auth-event-modal'
import { useMutation, useQuery } from '@apollo/client'
import { GET_AUTH_SCAN_EVENTS, REMOVE_AUTH_SCAN_EVENTS } from 'queries/tags'
import { AuthenticationStepType } from 'util/enums'
import Image from 'next/image'
import { IoIosWarning } from 'react-icons/io'
import ConfirmModal from 'components/shared/modal/confirm-modal'
import { useDisclaimerVersions } from 'hooks/useDisclaimerVersions'
import { useStorage } from 'contexts/storage-context'
import { useHandleError } from 'hooks/useHandleError'
import toast from 'react-hot-toast'

interface AuthenticatedScanProps {
  setModalOpen: (value: boolean) => void
  disclaimerId: string
}

interface AuthSteps {
  authentication_type_id: string
  element_id: string
  value: string
}

interface AuthScanEventsProps {
  name: string
  id: string
  login_url: string
  scan_domain_authentications: Array<{
    id: string
    scan_domain: {
      url: string
      id: string
    }
  }>
  authentication_steps: Array<AuthSteps>
}

export default function AuthenticatedScan({
  setModalOpen,
  disclaimerId
}: AuthenticatedScanProps) {
  const { t } = useTranslation()
  const [upgradeModalOpen, setUpgradeModalOpen] = useState(false)
  const [addEventModalOpen, setAddEventModalOpen] = useState(false)
  const [itemToDelete, setItemToDelete] = useState<AuthScanEventsProps>()
  const [deleteEventWarningOpen, setDeleteEventWarningOpen] = useState(false)
  const [removeScanAuth] = useMutation(REMOVE_AUTH_SCAN_EVENTS)
  const { activeOrganizationDetails } = useStorage()
  const { handleError } = useHandleError()

  const { data: disclaimerVersions } = useDisclaimerVersions()
  const { data: authScanData, refetch } = useQuery<{
    scan_authentication: Array<AuthScanEventsProps>
  }>(GET_AUTH_SCAN_EVENTS, {
    skip: !disclaimerId && !disclaimerVersions?.scan_auth_version,
    fetchPolicy: 'network-only',
    variables: {
      disclaimerId: disclaimerId,
      version: disclaimerVersions?.scan_auth_version
    }
  })

  const [selecteds, setSelecteds] = useState<
    Array<{ name: string; value: string }>
  >([])
  const [data, setData] = useState<EventData>({
    name: '',
    loginUrl: ''
  })
  const [events, setEvents] = useState<Array<EditableEventData>>()

  function handleEdit(item: AuthScanEventsProps) {
    setData({
      name: item.name,
      loginUrl: item.login_url,
      id: item.id
    })

    setEvents(
      item?.authentication_steps?.map(step => {
        return {
          id: step.element_id,
          type: step.authentication_type_id as AuthenticationStepType,
          value: step.value
        }
      })
    )

    const domains = item?.scan_domain_authentications?.map(item => {
      return {
        name: item?.scan_domain?.url,
        value: item?.scan_domain?.id
      }
    })
    setSelecteds(domains)
    setAddEventModalOpen(true)
  }

  async function handleDelete() {
    if (itemToDelete) {
      toast.loading(t('scanConfigModal.deletingEvent'))
      const result = await removeScanAuth({
        variables: {
          authScanId: itemToDelete.id
        }
      })
      toast.dismiss()
      if (result?.data?.remove_scan_auth?.success) {
        toast.success(t('scanConfigModal.deletingEventSuccess'))
        setDeleteEventWarningOpen(false)
        refetch()
      } else {
        handleError(result?.data?.remove_scan_auth?.message)
      }
    }
  }

  return (
    <>
      <H2 fontWeight="bold">{t('scanConfigModal.authenticated.title')}</H2>
      <BodyText className="max-w-[700px]">
        {t('scanConfigModal.authenticated.description')}
      </BodyText>
      {authScanData?.scan_authentication?.map((item, index: number) => {
        return (
          <div
            key={index}
            className="border border-gray-200 px-4 py-3 rounded-md flex justify-between items-end mb-2"
          >
            <div>
              <div className="flex items-center gap-2">
                <H3
                  fontWeight="bold"
                  className="!text-primary !dark:text-primary"
                >
                  {item.name}
                </H3>
                <div className="w-1 h-1 rounded-full bg-gray-200"></div>
                <H3>{item.login_url}</H3>
              </div>
              <div className="flex items-center gap-2 mt-4">
                <div className="bg-gray-100 rounded-sm px-2 ">
                  {item?.scan_domain_authentications?.length}
                </div>
                <BodyText>{t('scanConfigModal.registeredDomains')}</BodyText>

                <div className="bg-gray-100 rounded-sm px-2 ml-4">
                  {item?.authentication_steps?.length}
                </div>
                <BodyText>{t('scanConfigModal.eventsConfigured')}</BodyText>
              </div>
            </div>
            <div className="flex gap-2">
              <Button
                buttonType={ButtonType.Secondary}
                className="w-8 h-8"
                onClick={() => handleEdit(item)}
              >
                <MdEdit />
              </Button>
              <Button
                onClick={() => {
                  setDeleteEventWarningOpen(true)
                  setItemToDelete(item)
                }}
                buttonType={ButtonType.Secondary}
                className="w-8 h-8 !bg-pink"
              >
                <BiTrash />
              </Button>
            </div>
          </div>
        )
      })}
      <div className="flex justify-end mt-5 gap-4">
        <Button
          onClick={() => setModalOpen(false)}
          buttonType={ButtonType.Secondary}
        >
          {t('scanConfigModal.cancel')}
        </Button>
        <div className="relative">
          <Button
            onClick={() => {
              if (activeOrganizationDetails?.plan.scan_authentication) {
                setEvents([])
                setData({ name: '', loginUrl: '' })
                setAddEventModalOpen(true)
                setSelecteds([])
              } else {
                setUpgradeModalOpen(true)
              }
            }}
          >
            {t('scanConfigModal.add')}
          </Button>
          {activeOrganizationDetails?.plan?.scan_authentication === false && (
            <div className="h-4 w-4 t absolute -top-2 -right-2">
              <Image src={IconStarPurple} alt="Enterprise" />
            </div>
          )}
        </div>
      </div>
      <ScanAuthEventModal
        disclaimerId={disclaimerId}
        modalOpen={addEventModalOpen}
        setModalOpen={setAddEventModalOpen}
        refetch={refetch}
        selecteds={selecteds}
        setSelecteds={setSelecteds}
        data={data}
        setData={setData}
        events={events}
        setEvents={setEvents}
      />

      <ConfirmModal
        modalOpen={deleteEventWarningOpen}
        setModalOpen={setDeleteEventWarningOpen}
        title={t('scanConfigModal.deleteModal.title')}
        subtitle={t('scanConfigModal.deleteModal.subtitle')}
        buttonText={t('scanConfigModal.deleteModal.delete')}
        cancelText={t('scanConfigModal.deleteModal.cancel')}
        icon={<IoIosWarning size={30} className="text-pink" />}
        buttonAction={handleDelete}
      />
      <UpgradeModal
        modalOpen={upgradeModalOpen}
        setModalOpen={setUpgradeModalOpen}
      />
    </>
  )
}
