import { Button } from 'components'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import Image from 'next/image'
import IconStarGreen from 'assets/imgs/star-green.svg'
import IconStarYellow from 'assets/imgs/star-yellow.svg'
import IconStarPurple from 'assets/imgs/star-purple.svg'
import { classNames } from 'util/shared'
import { useRouter } from 'next/router'

import appConfig, { Environment } from 'config/app-config'
import { PlanPrices, PlanRecurring, PlanType } from 'models/plans'

interface PlanData {
  price: number
  recurring: PlanRecurring
  planType: PlanType
  featuresLength: number
  off?: number
}

interface PlanCardProps {
  planData: PlanData
  userPlan: any
}

const upgradePlansMap: any = {
  starter: [
    'Starter',
    'Business month',
    'Business year',
    'Enterprise month',
    'Enterprise year'
  ],
  'business monthly': [
    'Business month',
    'Business year',
    'Enterprise month',
    'Enterprise year'
  ],
  'business yearly': ['Business year', 'Enterprise month', 'Enterprise year'],
  'enterprise monthly': ['Enterprise month', 'Enterprise year'],
  'enterprise yearly': ['Enterprise year']
}

export function PlanCard({ planData, userPlan }: PlanCardProps) {
  const { t } = useTranslation()
  const router = useRouter()

  const isDevEnvironment =
    appConfig.environment === Environment.Local ||
    appConfig.environment === Environment.Dev

  function selectPlan() {
    router.push({
      pathname: '/subscribe',
      query: {
        stripePriceId:
          PlanPrices[isDevEnvironment ? Environment.Dev : Environment.Prod][
            planData.recurring
          ][planData.planType]
      }
    })
  }

  const renderButton = useMemo(() => {
    if (isDevEnvironment) {
      return true
    }

    return upgradePlansMap[userPlan?.name]?.includes(
      `${planData?.planType} ${planData.recurring}`
    )
      ? true
      : false
  }, [planData])

  const isCurrentPlan =
    userPlan?.name.includes(planData?.planType?.toLowerCase()) &&
    userPlan.recurring_interval === planData.recurring

  return (
    <div
      className={classNames(
        ' px-4 py-5 rounded-md dark:text-white border border-gray relative group overflow-hidden duration-200',
        planData.planType === 'Starter'
          ? 'hover:border-primary'
          : planData.planType === 'Business'
          ? 'hover:border-yellow'
          : 'hover:border-purple'
      )}
    >
      <div
        className={classNames(
          'absolute top-0 left-0 h-2 group-hover:h-3 w-full duration-200',
          planData.planType === 'Starter'
            ? 'bg-primary'
            : planData.planType === 'Business'
            ? 'bg-yellow'
            : 'bg-purple'
        )}
      ></div>
      <div className="flex h-full flex-col justify-between  ">
        <div className="flex-1 ">
          <div className="font-bold text-2xl leading-10 relative flex items-center justify-between">
            <div>{planData.planType}</div>
            {planData?.off && (
              <div
                className={classNames(
                  ' right-0 text-sm h-6  items-center justify-center px-1 hidden lg:flex rounded-sm ',
                  planData.planType === 'Business' ? 'bg-yellow' : 'bg-purple',
                  planData.planType === 'Enterprise'
                    ? 'text-[#fff]'
                    : 'text-black'
                )}
              >
                {planData?.off}% OFF
              </div>
            )}
          </div>
          {planData?.price !== 0 ? (
            <div className="flex items-end">
              <div className="text-5xl leading-10">
                U$
                {planData.price}
              </div>
              <div className="text-md">/ {t('plans.period.month')}</div>
            </div>
          ) : (
            <div className="text-5xl leading-10">Free</div>
          )}
          <div className="text-sm h-6">
            {planData.recurring === PlanRecurring.Year &&
              planData?.price !== 0 &&
              `(U$${planData?.price * 12} total)`}
          </div>
          <div className="font-bold max-w-300 mt-5">
            {t(`plans.${planData.planType}.description`)}
          </div>
          <div className="mt-5">
            {Array(planData.featuresLength)
              .fill(null)
              .map((_, i) => i + 1)
              .map((index: number) => {
                return (
                  <div key={index} className="flex gap-2">
                    <div>
                      {planData.planType === 'Starter' ? (
                        <Image
                          width={15}
                          height={15}
                          src={IconStarGreen}
                          alt="starter"
                        />
                      ) : planData.planType === 'Business' ? (
                        <Image
                          width={15}
                          height={15}
                          src={IconStarYellow}
                          alt="business"
                        />
                      ) : (
                        <Image
                          width={15}
                          height={15}
                          src={IconStarPurple}
                          alt="starter"
                        />
                      )}
                    </div>
                    <div className="font-bold flex-1">
                      {t(`plans.${planData.planType}.point${index}`)}
                    </div>
                  </div>
                )
              })}
          </div>
        </div>
        <div className="flex h-11 mt-5 ">
          {renderButton && (
            <Button
              onClick={selectPlan}
              disabled={isCurrentPlan}
              className={'disabled:hover:shadow-none w-full'}
            >
              {isCurrentPlan
                ? t('plans.current plan')
                : t('plans.contract plan')}
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}
