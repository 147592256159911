// components/RichTextEditor.tsx
import dynamic from 'next/dynamic'
import { useEffect, useState } from 'react'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { stateToHTML } from 'draft-js-export-html'
import { EditorState, ContentState, convertFromHTML } from 'draft-js'
import { classNames } from 'util/shared'
import { useTheme } from 'contexts/theme-context'

const Editor = dynamic(
  () => import('react-draft-wysiwyg').then(mod => mod.Editor),
  { ssr: false }
)

const toolbarOptions = [
  'history',
  'blockType',
  'inline',
  'fontSize',
  'list',
  'textAlign',
  'link'
]

interface RichTextEditorProps {
  value: string // Espera um HTML string
  onChange: (value: string) => void // Espera um HTML string
}

const RichTextEditor: React.FC<RichTextEditorProps> = ({ value, onChange }) => {
  const [editorState, setEditorState] = useState<EditorState>(
    EditorState.createEmpty()
  )
  const { theme } = useTheme()

  useEffect(() => {
    if (value) {
      const blocksFromHTML = convertFromHTML(value)
      const contentState = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      )
      setEditorState(EditorState.createWithContent(contentState))
    }
  }, [])

  const handleEditorChange = (state: EditorState) => {
    const contentState = state.getCurrentContent()
    const html = stateToHTML(contentState)
    setEditorState(state)
    onChange(html)
  }

  return (
    <div className="border-gray-75 dark:border-gray-400 border rounded-[8px] p-3 h-full overflow-y-auto scroll-1">
      <Editor
        editorState={editorState}
        onEditorStateChange={handleEditorChange}
        toolbarClassName={classNames(
          'text-black !rounded-t-[8px] dark:bg-gray-500 dark:border-gray-500 custom-toolbar',
          theme === 'dark' && 'dark-custom-toolbar'
        )}
        wrapperClassName="bg-white-200 dark:bg-black rounded-[8px]"
        editorClassName="min-h-[55vh] p-2"
        toolbar={{
          options: toolbarOptions,
          inline: {
            options: ['bold', 'italic', 'underline', 'strikethrough']
          },
          blockType: {
            inDropdown: true,
            options: ['Normal', 'H1', 'H2']
          },
          link: {
            options: ['link']
          }
        }}
      />
    </div>
  )
}

export default RichTextEditor
