import React, { Fragment } from 'react'
import { motion } from 'framer-motion'

interface OrganizationSectionTitleProps {
  title: string
  subtitle?: string
  endComponents?: Array<React.ReactNode>
}

export function OrganizationSectionTitle({
  title,
  subtitle,
  endComponents
}: OrganizationSectionTitleProps) {
  return (
    <div className="px-10 mt-8 mb-3">
      <motion.div
        initial="initial"
        animate="animate"
        className="flex flex-wrap justify-between"
      >
        <span className="dark:text-white text-2xl bold">{title}</span>
        <div className="flex items-center gap-3">
          {endComponents?.map((component, index) => {
            return <Fragment key={index}>{component}</Fragment>
          })}
        </div>
      </motion.div>
      <span className="dark:text-gray-200 text-md">{subtitle}</span>
    </div>
  )
}
