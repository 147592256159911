import axios from 'axios'
import { PlanTypes } from 'models/plans'
import { PlatformLanguageType } from 'util/enums'

const adoptlang =
  typeof window !== 'undefined'
    ? window.localStorage.getItem('i18nextLng')
    : 'pt'

const authApi = axios.create({
  withCredentials: true,
  baseURL: `${process.env.API_BASE_URL}/auth`,
  headers: {
    'adopt-lang': adoptlang
  }
})

const schema = axios.create({
  withCredentials: true,
  baseURL: `${process.env.API_BASE_URL}`
})

export async function getCurrentLegislation() {
  return (await schema.get('/tag/get-current-legislation')).data
}

interface RegisterData {
  name: string
  email: string
  phoneNumber: string
  password: string
  language: PlatformLanguageType
  referralCode: string | null
  url: string | null
  integrationType: string | null
  userId: string | null
  code: string | null
  admUser: string | null
  store: string | null
  apiAddress: string | null
  interestedPlan: PlanTypes | null
  utmSource: string | null
  utmMedium: string | null
  utmCampaign: string | null
  utmTerm: string | null
  utmContent: string | null
}

export interface RegisterQueryStringProps {
  referralCode: string | null
  url: string | null
  integrationType: string | null
  userId: string | null
  code: string | null
  admUser: string | null
  store: string | null
  apiAddress: string | null
  interested_plan: PlanTypes | null
  utm_source: string | null
  utm_medium: string | null
  utm_campaign: string | null
  utm_term: string | null
  utm_content: string | null
}

export async function userRegister(data: RegisterData) {
  return await authApi.post('register', data)
}

export async function userRegisterInvitedUser(
  name: string,
  token: string | string[] | undefined,
  password: string
) {
  return await authApi.post('register-invited-user', {
    name: name,
    token: token,
    password: password
  })
}

export async function userLogin(email: string, password: string) {
  return await authApi.post('login', {
    email: email,
    password: password
  })
}

export async function validateEmail(token: string) {
  return await authApi.post('validate-email', {
    token: token
  })
}

export async function getUser() {
  return await authApi.post('get-user')
}

export async function forgotPassword(email: string) {
  return await authApi.post('forgot-password', {
    email
  })
}
export async function recoveryPassword(password: string, token: any) {
  return await authApi.post('recovery-password', {
    password,
    token
  })
}
export async function logout() {
  return await authApi.post('logout', {})
}

export async function ssoGenerateLoginLink(identityProvider: string) {
  return await authApi.post('sso-generate-login-link', {
    identityProvider
  })
}

export async function ssoAccessToken(idToken: string) {
  return await authApi.post('sso-access-token', {
    idToken
  })
}
