import { SmallText } from '../typography'
import { Select, Value } from './select'

interface FontFamilySelectProps {
  label: string
  value: Value
  onChange: (selected: { value: Value; name: string }) => void
}

export function FontFamilySelect({
  label,
  value,
  onChange
}: FontFamilySelectProps) {
  const fonts = [
    { name: 'Roboto', value: 'roboto' },
    { name: 'Nunito', value: 'nunito' },
    { name: 'OpenSans', value: 'openSans' },
    { name: 'Lato', value: 'lato' },
    { name: 'Montserrat', value: 'montserrat' },
    { name: 'Poppins', value: 'poppins' }
  ]

  return (
    <div className="w-full">
      <SmallText className="dark:!text-gray-250">{label}</SmallText>
      <Select
        backgroundColor="bg-white-200 dark:bg-gray"
        params={fonts}
        value={value}
        borderColor="border-gray-200 dark:border-gray-400"
        containerClasses="dark:text-gray-250"
        onChange={onChange}
      />
    </div>
  )
}
